const $ = window.jQuery;

import Swal from "sweetalert2";
import Component from "../../../../core/js/components/Component";
import Form from "../../../../core/js/components/Form/Form";
import TextareaField from "../Form/TextareaField";
import SelectField from "../../../../core/js/components/Form/SelectField";
import OptionField from "../../../../core/js/components/Form/OptionField";
import SubmitField from "../../../../core/js/components/Form/SubmitField";
import Storage from '../../../../core/js/utils/Storage';
import OfferStatusLogsTable from "../OfferStatusLogsTable/OfferStatusLogsTable";
import dayjs from "dayjs";

export default class OfferStatusSelector extends Component {

    constructor(offer, statusList = [], statusLogs = [], financialsList = []) {
        super();
        this.offerData = offer;
        this.statusList = statusList;
        this.isKO = offer.status.id_key === 'KO';
        this.isRejected = offer.status.id_key === 'RIFIUTATA';
        this.isApproved = offer.status.id_key === 'APPROVATA';
        this.isWon = offer.status.id_key === 'VINTA';
        this.isLost = offer.status.id_key === 'PERSA';
        this.isAutoFinanziamentoRejected = offer.status.id_key === 'AUTOFINANZIAMENTO_RIFIUTATO';
        this.statusLog = statusLogs;
        this.permissions = window.PROFILE.roles;
        this.limitedStatus = ['INVIATA', 'VINTA', 'CONTRATTI_CARICATI', 'DOCUMENTI_CARICATI', 'FINITA', 'RICHIESTA_AUTOFINANZIAMENTO'];
        this.financials = financialsList;
        this.isOfferExpired = offer.expired;
        this.documents = [];
    }

    install() {
        super.install();
        $('#offer-status-roadmap').html(this.render());
        this.initListeners();

        // Init status icon by checking offer status logs
        const statusLogKeys = this.statusLog.map(s => this.getStatusById(s.id_offer_status).id_key);
        const multipleStatusLabel = $('.timeline__status[data-status="APPROVATA"] .timeline__label');

        if (statusLogKeys.includes('VINTA')) {
            $('.timeline__status[data-status="VINTA"] .timeline__label').text('VINTA');
            const button = $(`.timeline__status[data-status="VINTA"] .timeline__actions img[data-status="VINTA"]`);
            button.addClass('selected');
            button.removeClass('disabled');
        } else if (statusLogKeys.includes('PERSA')) {
            $('.timeline__status[data-status="VINTA"] .timeline__label').text('PERSA');
            const button = $(`.timeline__status[data-status="VINTA"] .timeline__actions img[data-status="PERSA"]`);
            button.addClass('selected');
            button.removeClass('disabled');
        }

        if (statusLogKeys.includes('APPROVATA')) {
            $('.timeline__status[data-status="APPROVATA"] .timeline__label').text('APPROVATA');
            const button = $(`.timeline__status[data-status="APPROVATA"] .timeline__actions img[data-status="APPROVATA"]`);
            button.addClass('selected');
            button.removeClass('disabled');
        } else if (statusLogKeys.includes('KO')) {
            $('.timeline__status[data-status="APPROVATA"] .timeline__label').text('KO');
            const button = $(`.timeline__status[data-status="APPROVATA"] .timeline__actions img[data-status="KO"]`);
            button.addClass('selected');
            button.removeClass('disabled');
        } else if (statusLogKeys.includes('RIFIUTATA')) {
            $('.timeline__status[data-status="APPROVATA"] .timeline__label').text('RIFIUTATA');
            const button = $(`.timeline__status[data-status="APPROVATA"] .timeline__actions img[data-status="RIFIUTATA"]`);
            button.addClass('selected');
            button.removeClass('disabled');
        }

        if (statusLogKeys.includes('AUTOFINANZIAMENTO_APPROVATO')) {
            $('.timeline__status[data-status="AUTOFINANZIAMENTO_APPROVATO"] .timeline__label').text('AUTOFIN. APPROVATO');
            const button = $(`.timeline__status[data-status="AUTOFINANZIAMENTO_APPROVATO"] .timeline__actions img[data-status="AUTOFINANZIAMENTO_APPROVATO"]`);
            button.addClass('selected');
            button.removeClass('disabled');
        } else if (statusLogKeys.includes('AUTOFINANZIAMENTO_RIFIUTATO')) {
            $('.timeline__status[data-status="AUTOFINANZIAMENTO_APPROVATO"] .timeline__label').text('AUTOFIN. RIFIUTATO');
            const button = $(`.timeline__status[data-status="AUTOFINANZIAMENTO_APPROVATO"] .timeline__actions img[data-status="AUTOFINANZIAMENTO_RIFIUTATO"]`);
            button.addClass('selected');
            button.removeClass('disabled');
        }

        if (statusLogKeys.includes('RICHIESTA_AUTOFINANZIAMENTO')) {
            $('.timeline__status[data-status="RICHIESTA_AUTOFINANZIAMENTO"] .timeline__label').text('Richiesta Autofin.');
            const button = $(`.timeline__status[data-status="RICHIESTA_AUTOFINANZIAMENTO"] .timeline__actions img[data-status="RICHIESTA_AUTOFINANZIAMENTO"]`);
            button.addClass('selected');
            button.removeClass('disabled');
        } else if (statusLogKeys.includes('RICHIESTA_AUTOFINANZIAMENTO_NO')) {
            $('.timeline__status[data-status="RICHIESTA_AUTOFINANZIAMENTO"] .timeline__label').text('No Autofin.');
            const button = $(`.timeline__status[data-status="RICHIESTA_AUTOFINANZIAMENTO"] .timeline__actions img[data-status="RICHIESTA_AUTOFINANZIAMENTO_NO"]`);
            button.addClass('selected');
            button.removeClass('disabled');
        }

        if (Number(this.offerData.duration) > 60) {
            $('.timeline__status[data-status="RICHIESTA_AUTOFINANZIAMENTO"]').remove();
            $('.timeline__status[data-status="AUTOFINANZIAMENTO_APPROVATO"]').remove();
            this.statusList = this.statusList
                .filter(s => !s.id_key.includes('AUTOFINANZIAMENTO'))
                .map(status => {
                    if (status.priority > 2) {
                        return {...status, priority: status.priority - 2 };
                    }
                    return {...status};
                });
            const newOfferStatus = this.statusList.find(s => s.id_key === this.offerData.status.id_key);
            this.offerData.status.priority = newOfferStatus.priority;
        }

        if (statusLogKeys.includes('RICHIESTA_AUTOFINANZIAMENTO_NO')) {
            $('.timeline__status[data-status="AUTOFINANZIAMENTO_APPROVATO"]').remove();
            this.statusList = this.statusList
                .filter(s => !['AUTOFINANZIAMENTO_APPROVATO', 'AUTOFINANZIAMENTO_RIFIUTATO'].includes(s.id_key))
                .map(status => {
                    if (status.priority > 4) {
                        return {...status, priority: status.priority - 1 };
                    }
                    return {...status};
                });
            const newOfferStatus = this.statusList.find(s => s.id_key === this.offerData.status.id_key);
            this.offerData.status.priority = newOfferStatus.priority;
        } else if (statusLogKeys.includes('RICHIESTA_AUTOFINANZIAMENTO')) {
            $('.timeline__status[data-status="AUTOFINANZIAMENTO_APPROVATO"]').attr('data-hidden', 0);
        }

        // console.log(this.offerData.status);
        // console.log("after update", this.statusList);

    }

    setDocuments(documentsCollections = []) {
        this.documents = documentsCollections.map(collection => collection.files).flat();
        return this;
    }

    renderFormHtml(selectedStatus) {

        const financialsOptions = [];

        const emptyOpt = new OptionField()
            .setValue('')
            .setLabel('Seleziona finanziaria');
        financialsOptions.push(emptyOpt);
        const parsedFinancialsOpts = this.financials.map(option => {
            const {name, id, rejected} = option;
            return new OptionField()
                .setLabel(rejected ? `${name} (rifiutata)` : name)
                .setValue(id)
                .setDisabled(rejected);
        });
        financialsOptions.push(...parsedFinancialsOpts);

        const financialField = new SelectField()
            .setLabel('Finanziaria')
            .setFieldName('id_financial')
            .setRequired(true)
            .addOptions(financialsOptions);
        const notesField = new TextareaField()
            .setLabel('Note')
            .setFieldName('notes');
        const submitFormBtn = new SubmitField()
            .setValue('Salva')
            .setType('submit');

        let formFields = [];
        if (!['VINTA', 'PERSA', 'KO', 'AUTOFINANZIAMENTO_APPROVATO', 'AUTOFINANZIAMENTO_RIFIUTATO'].includes(selectedStatus.id_key)) {
            formFields.push(financialField);
        }
        formFields = [...formFields, notesField, submitFormBtn];
        const form = new Form()
            .addFields(formFields)
            .setAction(`/offer/${this.offerData.id}/status`)
            .setMethod('POST');

        form.onSubmit(async (data) => {

            const params = {
                ...data,
                status: selectedStatus
            };

            const iconsButtons = $(`.timeline__actions img[data-status]`);
            iconsButtons.each((i, icon) => {
                const isStatus = $(icon).attr('data-status') === selectedStatus.id_key;
                $(icon).toggleClass('selected', isStatus);
                $(icon).toggleClass('disabled', !isStatus);
            });

            this.isApproved = selectedStatus.id_key === 'APPROVATA';
            this.isRejected = selectedStatus.id_key === 'RIFIUTATA';
            this.isKO = selectedStatus.id_key === 'KO';
            this.isWon = selectedStatus.id_key === 'VINTA';
            this.isLost = selectedStatus.id_key === 'PERSA';
            this.isAutoFinanziamentoRejected = selectedStatus.id_key === 'AUTOFINANZIAMENTO_RIFIUTATO';

            let statusLabelEl;
            if (['VINTA', 'PERSA'].includes(selectedStatus.id_key)) {
                statusLabelEl = $('.timeline__status[data-status="VINTA"] .timeline__label');
            }
            if (['APPROVATA', 'RIFIUTATA', 'KO'].includes(selectedStatus.id_key)) {
                statusLabelEl = $('.timeline__status[data-status="APPROVATA"] .timeline__label');
            }
            if (['AUTOFINANZIAMENTO_APPROVATO', 'AUTOFINANZIAMENTO_RIFIUTATO'].includes(selectedStatus.id_key)) {
                statusLabelEl = $('.timeline__status[data-status="AUTOFINANZIAMENTO_APPROVATO"] .timeline__label');
            }
            statusLabelEl.text(selectedStatus.label);

            this.handleChangeStatus(params);
        });

        return form.render();
    }

    initListeners() {
        const stApi = new Storage();
        $('.offer-status-label').off('click').on('click', (e) => {
            e.stopPropagation();
            const body = $('body');
            const opened = body.attr('class').includes('with-status-selector');
            if (opened) {
                body.removeClass('with-status-selector');
                stApi.setData('OFFER_STATUS_SELECTOR_OPENED', false);
            } else {
                body.addClass('with-status-selector');
                stApi.setData('OFFER_STATUS_SELECTOR_OPENED', true);
            }
        });

        // Trigger offer status change
        $('.timeline__status .timeline__label').off('click').on('click', async (e) => {
            const selectedStatusKey = $(e.currentTarget).closest('.timeline__status').attr('data-status');
            const currentStatus = this.offerData.status;

            if (currentStatus.id_key === 'FINITA' && selectedStatusKey === 'INVIATA') {
                const selectedStatus = this.getStatusByKey(selectedStatusKey);
                this.handleChangeStatus({status: selectedStatus});
            }

            if (
                !this.canTriggerStatusChange(selectedStatusKey) ||
                this.isAutoFinanziamentoRejected ||
                ['APPROVATA', 'VINTA', 'AUTOFINANZIAMENTO_APPROVATO', 'RICHIESTA_AUTOFINANZIAMENTO'].includes(selectedStatusKey) ||
                this.isLimitedStatus(selectedStatusKey)
            ) {
                return;
            }

            if (selectedStatusKey === 'DOCUMENTI_CARICATI' && !this.hasDocuments()) {
                await Swal.fire({
                    customClass: 'scary-modal',
                    closeButtonHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0.14 329.27 329.26"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    showCancelButton: false,
                    allowOutsideClick: true,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    title: "Attenzione!",
                    text: "Nessun documento caricato"
                });
                return;
            }

            const selectedStatus = this.getStatusByKey(selectedStatusKey);
            this.handleChangeStatus({status: selectedStatus});
        });

        // Trigger offer status change on icons click (approve, reject, ko)
        $(`.timeline__actions img[data-status]`).off('click').on('click', async (e) => {
            const targetIcon = $(e.target);
            const selectedStatusKey = targetIcon.attr('data-status');
            const selectedStatus = this.getStatusByKey(selectedStatusKey);
            const currentStatus = this.offerData.status;

            if (
                currentStatus.id_key !== 'RIFIUTATA' &&
                selectedStatusKey === currentStatus.id_key
            ) {
                return;
            }

            if (this.isLimitedStatus(selectedStatusKey)) {
                return;
            }

            if (this.isApproved || this.isKO || this.isWon || this.isLost || this.isAutoFinanziamentoRejected) {
                return;
            }

            if (
                currentStatus.id_key !== 'FINITA' &&
                ['RICHIESTA_AUTOFINANZIAMENTO', 'RICHIESTA_AUTOFINANZIAMENTO_NO'].includes(selectedStatusKey)
            ) {
                return;
            }

            if (currentStatus.id_key !== 'INVIATA' && ['VINTA', 'PERSA'].includes(selectedStatusKey)) {
                return;
            }

            if (
                currentStatus.id_key === 'INVIATA' &&
                ['AUTOFINANZIAMENTO_APPROVATO', 'AUTOFINANZIAMENTO_RIFIUTATO'].includes(selectedStatusKey)
            ) {
                return;
            }

            if (
                !['DOCUMENTI_CARICATI', 'RIFIUTATA'].includes(currentStatus.id_key) &&
                ['APPROVATA', 'RIFIUTATA', 'KO'].includes(selectedStatusKey)
                // || 'DOCUMENTI_CARICATI' !== currentStatus.id_key && 'RIFIUTATA' === selectedStatusKey
            ) {
                return;
            }



            if (!['VINTA', 'RICHIESTA_AUTOFINANZIAMENTO', 'RICHIESTA_AUTOFINANZIAMENTO_NO'].includes(selectedStatusKey)) {
                Swal.fire({
                    customClass: 'scary-modal',
                    closeButtonHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0.14 329.27 329.26"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    showCancelButton: false,
                    allowOutsideClick: true,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    title: ['APPROVATA', 'RIFIUTATA', 'KO'].includes(selectedStatusKey) ? "Finanziarie" : "Note",
                    html: this.renderFormHtml(selectedStatus),
                });
            }

            if (['VINTA', 'RICHIESTA_AUTOFINANZIAMENTO', 'RICHIESTA_AUTOFINANZIAMENTO_NO'].includes(selectedStatusKey)) {
                const params = {
                    id_offer: this.offerData.id,
                    status: selectedStatus,
                    id_financial: null,
                    notes: null
                };
                this.handleChangeStatus(params);
            }

        });

        // Open status logs modal
        $('.timeline__status-btn').off('click').on('click', (e) => {
            const offerStatusLogsTable = new OfferStatusLogsTable(this.statusLog).render();
            Swal.fire({
                customClass: 'scary-modal scary-modal--status-logs',
                closeButtonHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0.14 329.27 329.26"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg>',
                showCloseButton: true,
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: true,
                allowEscapeKey: false,
                allowEnterKey: false,
                html: offerStatusLogsTable
            });
        });
    }

    renderStatusHtml(status) {
        const { id_key, priority, hidden } = status;
        const isStatusHidden = !!hidden;
        if (isStatusHidden) {
            return;
        }

        const { status: offerStatus } = this.offerData;
        const isPreviousStatus = priority < offerStatus.priority;
        const isCurrentStatus = priority === offerStatus.priority;
        const isNextStatus = priority > offerStatus.priority;

        const statusModifiers = [];
        if (isPreviousStatus) {
            statusModifiers.push('previous');
        }
        if (isCurrentStatus) {
            statusModifiers.push('current');
        }
        if (isNextStatus) {
            statusModifiers.push('next');
        }

        let iconsHtml = '';
        let isTemporaryHidden = false;
        if (status.id_key === "APPROVATA") {
            iconsHtml = `
                <img src="../../../assets/images/ok.svg" alt="confirm" class="disabled" data-status="APPROVATA">
                <img src="../../../assets/images/rifiutata.svg" alt="reject" class="disabled" data-status="RIFIUTATA">
                <img src="../../../assets/images/ko.svg" alt="ko" class="disabled" data-status="KO">
            `;
        }

        if (status.id_key === "VINTA") {
            iconsHtml = `
                <img src="../../../assets/images/ok.svg" alt="confirm" class="disabled" data-status="VINTA">
                <img src="../../../assets/images/rifiutata.svg" alt="reject" class="disabled" data-status="PERSA">
            `;
        }

        if (status.id_key === "RICHIESTA_AUTOFINANZIAMENTO") {
            iconsHtml = `
                <img src="../../../assets/images/si-blue.svg" alt="confirm" class="disabled" data-status="RICHIESTA_AUTOFINANZIAMENTO">
<!--                <img src="../../../assets/images/no-blue.svg" alt="cancel" class="disabled" data-status="RICHIESTA_AUTOFINANZIAMENTO_NO">-->
            `;
        }

        if (status.id_key === "AUTOFINANZIAMENTO_APPROVATO") {
            isTemporaryHidden = true;
            iconsHtml = `
                <img src="../../../assets/images/ok.svg" alt="confirm" class="disabled" data-status="AUTOFINANZIAMENTO_APPROVATO">
                <img src="../../../assets/images/rifiutata.svg" alt="reject" class="disabled" data-status="AUTOFINANZIAMENTO_RIFIUTATO">
            `;
        }

        if (this.isLimitedStatus(id_key) || this.isOfferExpired) {
            statusModifiers.push('disabled');
        }

        const modifiersHtml = statusModifiers.map(m => `timeline__status--${m}`).join(' ');

        return `
            <div 
              class="timeline__status ${modifiersHtml}"
              data-status="${id_key}"
              data-hidden="${isTemporaryHidden ? 1 : 0}"
            >
              <div class="timeline__icon"></div>
              <p class="timeline__label">${status.label}</p>
              <div class="timeline__actions">${iconsHtml}</div> 
            </div>
        `;
    }

    handleChangeStatus(params) {
        // Keep track of current offer status state
        // and trigger "offer/status-change" event
        this.offerData.status = params.status;
        // $(document).trigger('offer/status-change', { status: selectedStatus, offerId: this.offerData.id, notes });
        const apiParams = {
            id_offer: this.offerData.id,
            currentStatus: this.offerData.status,
            status: params.status,
            id_financial: params.id_financial || null,
            notes: params.notes || null,
            statusList: this.statusList,
            statusLog: this.statusLog,
        };
        $(document).trigger('offer/status-change', apiParams);
    }

    getStatusByKey(key) {
        return this.statusList.find(s => s.id_key === key);
    }

    getStatusById(id) {
        return this.statusList.find(s => s.id === id);
    }

    canTriggerStatusChange(statusKey) {
        const isSameStatus = this.isCurrentStatus(statusKey);
        const isNextStatus = this.isNextStatus(statusKey);
        return !isSameStatus && isNextStatus && !this.isKO && !this.isRejected;
    }

    isCurrentStatus(statusKey) {
        const newStatus = this.getStatusByKey(statusKey);
        return this.offerData.status.id_key === newStatus.id_key;
    }

    isNextStatus(statusKey) {
        const nextStatus = this.getStatusByKey(statusKey);
        const currentStatus = this.offerData.status;
        return nextStatus.priority > currentStatus.priority && nextStatus.priority - currentStatus.priority === 1;
    }

    isPreviousStatus(statusKey) {
        const newStatus = this.getStatusByKey(statusKey);
        const currentStatus = this.offerData.status;
        return currentStatus.priority > newStatus.priority;
    }

    isLimitedStatus(statusKey) {
        return this.permissions.includes('RD_OFFER_STATUS_LIMITED') &&
            !this.limitedStatus.includes(statusKey);
    }

    hasDocuments() {
        return this.documents.length > 0;
    }

    render() {
        const statusHtml = this.statusList.map((status) => this.renderStatusHtml(status)).join('');
        return `
          <div class="timeline-wrapper">
            ${this.isOfferExpired ? `<div class="timeline__banner"><img src="/assets/images/expired.svg" alt="expired">OFFERTA SCADUTA</div>` : ''}
            <div class="timeline ${this.isOfferExpired ? '' : ''}">
              <div class="timeline__track"></div>
              ${statusHtml}
            </div>
            <div class="timeline__status-btn">
              <img src="/assets/images/actions/status.svg" alt="status">
              STORICO DEGLI STATI
            </div>
          </div>
        `;
    }

}