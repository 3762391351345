import OfferDocumentsApi from "../api/OfferDocumentsApi";

const $ = window.jQuery;

import _ from 'lodash';
import Page from './Page';
import Form from '../../../core/js/components/Form/Form';
import OfferApi from '../api/OfferApi';
import OfferRowApi from '../api/OfferRowApi';
import CustomerApi from '../api/CustomerApi';
import CustomerLocationApi from '../api/CustomerLocationApi';
import CustomerLocationShippingApi from '../api/CustomerLocationShippingApi';
import ProductApi from '../api/ProductApi';
import TextField from '../../../core/js/components/Form/TextField';
import EmailField from '../../../core/js/components/Form/EmailField';
import SelectField from '../../../core/js/components/Form/SelectField';
import OptionField from '../../../core/js/components/Form/OptionField';
import SubmitField from '../../../core/js/components/Form/SubmitField';
import HiddenField from '../../../core/js/components/Form/HiddenField';
import CheckboxField from '../../../core/js/components/Form/CheckboxField';
import NumberField from '../components/Form/NumberField';
import PriceField from '../components/Form/PriceField';
import ButtonField from '../components/Form/ButtonField';
import SelectBooleanField from '../../../core/js/components/Form/SelectBooleanField';
import TextareaField from '../components/Form/TextareaField';
import ContentField from '../components/Form/ContentField';
import Accordion from '../components/Accordion/Accordion';
import MultistepForm from '../components/MultistepForm/MultistepForm';
import BasicGrid from '../components/BasicGrid/BasicGrid';
import BasicGridItem from '../components/BasicGrid/BasicGridItem';
import Storage from '../../../core/js/utils/Storage';
import Footer from '../../../core/js/components/Form/Footer';
import ScaryJqueryConfirm from '../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm';
import ScarySelect2 from '../components/Plugins/ScarySelect2';
import OfferStatusApi from '../api/OfferStatusApi';
import OfferStatusListApi from '../api/OfferStatusListApi';
import OfferStatusLogsApi from '../api/OfferStatusLogsApi';
import OfferStatusSelector from '../components/OfferStatusSelector/OfferStatusSelector';
import OfferFinancialsApi from '../api/OfferFinancialsApi';
import OfferNotificationApi from '../api/OfferNotificationApi';
import OfferGenerateDocumentApi from '../api/OfferGenerateDocumentApi';

export default class OfferFormPage extends Page {
	
	constructor(){
		super();
		
		this.offerId = false;
		this.data = false;
		
		this.customers = [];
		this.customerLocations = [];
		this.customerLocationShippings = [];
		this.financings = [];
		
		this.multistepForm = false;
		this.steps = [];
		
		this.rowLocationSelectFields = [];
		this.rowShippingSelectFields = [];
		
		this.fieldsToRevalidate = [];
		
		this.afterSaveAction = undefined;
		
		this.activityLabels = {
			company: 'Azienda',
			pro: 'Professionista',
			pa: 'PA',
			firm: 'Studio Associato',
			association: 'Associazione'
		};
		
		this.configurationLabels = {
			top: 'Alimentazione originali',
			inner: 'Comunicazione',
			right: 'Finitura',
			bottom: 'Alimentazione carta'
		};
	}
	
	async init(offer_id) {
		const stApi = new Storage();
		
		// check for "create" and currentOffer
		if(offer_id === 'create'){ // create a new offer
			const offerApi = new OfferApi();
			const res = await offerApi.post({
				'cod_erp': '',
				'id_company': stApi.getData('current-company-' + PROFILE.id),
			});
			stApi.setData('current-offer-' + PROFILE.id, res.user_id); // set it as current
			$.spa.navigate(`/offer/manage/${res.user_id}`);
			return false;
		}
		
		$('#scary').addClass('loading'); // handle loading
		
		this.offerId = offer_id;
		
		// retrieve current offer data
		await this.loadOfferData(false);
		stApi.setData('current-offer-' + PROFILE.id, this.data.id); // set it as current
		stApi.setData('current-company-' + PROFILE.id, this.data.id_company); // set its company as current company
		
		// retrieve all customers
		const customerApi = new CustomerApi();
		const customers = await customerApi.setShowAjaxLoader(false).collection().catch(function(e){
			const errmsg = 'Non è stato possibile recuperare i clienti';
			$(document).trigger('message', ['error', errmsg]);
			throw errmsg;
		});
		this.customers = customers.data;
		
		// preload customer locations
		if(typeof this.data.customer !== 'undefined' && this.data.customer !== null){
			await this.retrieveCustomerLocations(this.data.customer.id_original);
		}
		
		this
			.setTitle(`Gestione offerta (${this.data.cod_erp})`)
			.setMeta('description', 'Gestione offerta')
			.setBreadcrumb([
				{ label: 'Offerte', href: '/offers' },
				`Gestione offerta (${this.data.cod_erp})`
			]);

		// Offer status
		const offerStatusListApi = new OfferStatusListApi();
		const offerStatusLogsApi = new OfferStatusLogsApi().setOfferId(offer_id);
		const financialsApi = new OfferFinancialsApi().setOfferId(offer_id);
		const offerDocumentsApi = new OfferDocumentsApi().setOfferId(offer_id);
		const { data: statusList } = await offerStatusListApi.setShowAjaxLoader(false).collection();
		const { data: offerStatusLogs  } = await offerStatusLogsApi.setShowAjaxLoader(false).get('');
		const { data: financialsList } = await financialsApi.setShowAjaxLoader(false).collection({price: this.data.price, duration: this.data.duration});
		const { data: offerDocumentsCollections } = await offerDocumentsApi.setShowAjaxLoader(false).collection();
		$('.offer-status-label').show().html(this.data.status.label);
		new OfferStatusSelector(this.data, statusList, offerStatusLogs, financialsList)
			.setDocuments(offerDocumentsCollections)
			.install();
		// ./ Offer status
		
		$('#scary').removeClass('loading'); // handle loading

		return super.init(offer_id);
	}

	exit() {
		$('.offer-status-label').hide();
		$('#offer-status-roadmap').removeClass('open');
		super.exit();
	}
	
	async content(offer_id){
		const stApi = new Storage();

		if(offer_id === 'create'){ // handle create only on init()
			stApi.setData(`last-offer-step`, {offer: offer_id, step: 0});
			return false;
		}
		
		this.steps.push(this.prepareStepCustomerAndProducts.bind(this));
		this.steps.push(this.prepareStepDuration.bind(this));
		this.steps.push(this.prepareStepPrices.bind(this));
		this.steps.push(this.prepareStepReview.bind(this));
		
		this.multistepForm = new MultistepForm();
		this.multistepForm
			.addClasses('offer-container padded-container')
			.addScreen({label: 'Anagrafica e Prodotti', content: await this.steps[0]()})
			.addScreen({label: 'Durata e subentro', content: this.steps[1]()})
			.addScreen({label: 'Prezzi', content: this.steps[2]()})
			.addScreen({label: 'Riepilogo e provvigioni', content: await this.steps[3]()})
			.onChangeScreen(this.loadOfferData.bind(this));

		const lastOfferStep = stApi.getData(`last-offer-step`);
		if (lastOfferStep && lastOfferStep.offer === Number(offer_id)) {
			this.multistepForm.gotoScreen(lastOfferStep.step);
		}

		if(this.data.locked){ // if offer is locked
			this.multistepForm.setMenuNavigation(true); // let the user move freely forward/backward on wizard navigation
		}
		
		$('main .main').html(this.multistepForm.render() + Footer.render());
		this.multistepForm.activate();
		Footer.activate();
		
		this.attachInteractions();
	}
	
	async loadOfferData(setShowAjaxLoader = true){
		const stApi = new Storage();
		const offerApi = new OfferApi();
		const offerData = await offerApi.setShowAjaxLoader(setShowAjaxLoader).get(this.offerId);
		this.data = offerData.data;
		if(this.multistepForm){
			stApi.setData(`last-offer-step`, {offer: this.data.id, step: this.multistepForm.currentScreen});
			this.multistepForm.screens[this.multistepForm.currentScreen].content = await this.steps[this.multistepForm.currentScreen]();
			this.multistepForm.refreshCurrentScreenContent();
			this.attachSpecialActionButtons();
			this.attachSpecialBehaviours();			
		}
	}
	
	attachSpecialActionButtons(){
		const _page = this;
		
		if(this.multistepForm.currentScreen === 3){ // step: review
		
			// if offer is locked
			if(this.data.locked){
				// change "save" button text
				$('.screen[data-id="3"] .form-actions input.submit', `#${this.multistepForm.name}`).attr('class', 'close').val('Chiudi');
			}
			
			// add "save and download" and "save and send" buttons
			if(this.data.offer_status_priority > 1){ // only after FINITA status
				const saveAndDownload = new ButtonField();
				saveAndDownload
						.addClassHref('save-and-download')
						.setValue(this.data.locked ? 'Scarica e chiudi' : 'Salva e scarica');
				const saveAndSend = new ButtonField();
				saveAndSend
						.addClassHref('save-and-send')
						.setValue(this.data.locked ? 'Chiudi e invia' : 'Salva e invia');
				$('.screen[data-id="3"] .form-actions > .formfield:last', `#${this.multistepForm.name}`).append(saveAndDownload.render() + saveAndSend.render());
					
				// handle "save and download" click
				$(`#${saveAndDownload.name}`).on('click', function(e){
					e.preventDefault();
					_page.afterSaveAction = 'download';
					$('.screen[data-id="3"] .form-actions input[type="submit"]', `#${_page.multistepForm.name}`).trigger('click');
				});
					
				// handle "save and send" click
				$(`#${saveAndSend.name}`).on('click', function(e){
					e.preventDefault();
					const confirmSend = new ScaryJqueryConfirm(); // $.confirm send
					confirmSend
						.setTitle('Attenzione')
						.setContent('Sei sicuro di voler inviare l’Offerta all’indirizzo email del Cliente?')
						.modalConfirm(async (data) => {
							_page.afterSaveAction = 'send';
							$('.screen[data-id="3"] .form-actions input[type="submit"]', `#${_page.multistepForm.name}`).trigger('click');
						})
						.render();
				});
			}
			
		}
	}
	
	attachSpecialBehaviours(){
		// auto-calculate prices when no price is inserted
		if(this.data.fee_markup === null || this.data.fee_markup === ''){
			$('[name="fee_markup"]', $(`#${this.multistepForm.name}`)).trigger('change');
		}
		if(this.data.cc_bn_markup === null || this.data.cc_bn_markup === ''){
			$('[name="cc_bn_markup"]', $(`#${this.multistepForm.name}`)).trigger('change');
		}
		if(this.data.cc_color_markup === null || this.data.cc_color_markup === ''){
			$('[name="cc_color_markup"]', $(`#${this.multistepForm.name}`)).trigger('change');
		}
	}
	
	//
	// WIZARD STEPS
	//
	
	// STEP: customer, products, location, shipping
	async prepareStepCustomerAndProducts(){
		const _page = this;
		
		// customers
		const idCustomer = new ScarySelect2();
		idCustomer
			.setFieldName('id_customer')
			.setRequired(true)
			.setLabel('Cliente')
			.setPlaceholder('Seleziona')
			.setAllowClear(true)
			.setWidth('100%');
		const customerOpts = [];
		const emptyOpt = new OptionField();
		emptyOpt
			.setValue('');
		customerOpts.push(emptyOpt);
		this.customers.map(function(customer){
			const option = new OptionField();
			option
				.setValue(customer.id)
				.setLabel(customer.company_name);
			customerOpts.push(option);
		});
		idCustomer.addOptions(customerOpts);
			
		const activity = new TextField();
		activity
			.setFieldName('activity')
			.setLabel('Tipo Attività')
			.setReadonly();
			
		const vat = new TextField();
		vat
			.setFieldName('vat')
			.setLabel('Partita IVA')
			.setReadonly();
			
		const fiscalcode = new TextField();
		fiscalcode
			.setFieldName('fiscalcode')
			.setLabel('Codice fiscale')
			.setReadonly();
			
		const sdi = new TextField();
		sdi
			.setFieldName('sdi')
			.setLabel('SDI')
			.setReadonly();
			
		const pec = new TextField();
		pec
			.setFieldName('pec')
			.setLabel('PEC')
			.setReadonly();
			
		const customerFiscalFieldset = new Form();
		customerFiscalFieldset
			.addClasses('fieldset-inline inner no-margin-bottom')
			.addFields([
				activity,
				vat,
				fiscalcode,
				sdi,
				pec
			]);
		
		const customerFieldset = new Form();
		customerFieldset
			.setTitle('Dati aziendali')
			.addClasses('fieldset-anagrafica fullwidth label-left collapsible')
			.addFields([
				customerFiscalFieldset
			]);

		// set customer values
		if(typeof this.data.customer !== 'undefined' && this.data.customer !== null){
			idCustomer.setValue(this.data.customer.id_original);
			activity.setValue(this.data.customer.activity ? this.activityLabels[this.data.customer.activity] : '');
			vat.setValue(this.data.customer.vat);
			fiscalcode.setValue(this.data.customer.fiscalcode);
			sdi.setValue(this.data.customer.sdi);
			pec.setValue(this.data.customer.pec);
		} else {
			customerFieldset.addClasses('hidden collapsed');
		}
		
		// disable editing if offer is locked
		if(this.data.locked){
			idCustomer.setDisabled();
		}
		
		const accordion = new Accordion();
		accordion
			.setFieldName('accordion-row')
			.addTitle('Prodotti selezionati')
			.addClasses('products-accordion');
			
		if(Array.isArray(this.data.rows)){
			for(const row of this.data.rows){
				if(row.product){
					const product = row.product;
					
					let label = `<span class="product-name">${product.name}</span>`;
					if(typeof product.configurations !== 'undefined' && Array.isArray(product.configurations) && product.configurations.length){
						label += '<ul class="product-configurations">';
						product.configurations.forEach(/* jshint -W083 */(configuration) => {
							label += `<li class="configuration-name"><span>${this.configurationLabels[configuration.code]}:</span> ${configuration.value}</li>`;
						});
						label += '</ul>';
					}
					
					accordion.addItem({
						label: label,
						content: await this.prepareOfferRow(row, product)
					});
				} else {
					console.log(`Product ${row.id_product} not found`);
				}
			}
		}
		
		const btnAddMoreProducts = new ButtonField();
		btnAddMoreProducts
			.setFieldName('btn_addmoreproducts')
			.addClassHref('btn-addmoreproducts')
			.setValue('Aggiungi prodotti');
			
		const step = new HiddenField();
		step
			.setFieldName('step')
			.setValue('anagrafica');
		
		const stepForm = new Form();
		stepForm
			.setAction('/')
			.setTitle('Anagrafica')
			.addClasses('fullwidth label-left')
			.addFields([
				step,
				idCustomer,
				customerFieldset,
				... this.data.locked ? [] : [btnAddMoreProducts],
				accordion
			]);
			
		if(!(Array.isArray(this.data.rows) && this.data.rows.length)){
			const noProductsSelected = new ContentField();
			noProductsSelected
				.setFieldName('content-no_products_selected')
				.setContent('<div class="no-products-selected">Nessun prodotto selezionato</div>');
			stepForm.addFields(noProductsSelected);
		}
		
		stepForm.onSubmit(async function(res){
			
			// skip save if offer is locked
			if(_page.data.locked){
				_page.multistepForm.nextScreen();
				window.scrollTo({top: 0});
				return false;
			}
			
			const stepValues = stepForm.getValues();
			
			const rowsIds = [];
			for (let key of Object.keys(stepValues)) {
				if (key.endsWith('_id_row')) {
					rowsIds.push(stepValues[key]);
				}
			}
			
			// handle custom validations
			$('input', `#${stepForm.name}`).removeClass('invalid');
			let isValid = true;
			if(rowsIds.length){
				for (const rowId of rowsIds) {
					// force fill of shipping_floor_num if shipping_floor_delivery is selected
					if(stepValues[`${rowId}_shipping_floor_delivery`] === '1'){
						for (const field of [`${rowId}_shipping_floor_num`]) {
							if(stepValues[field] === ''){
								const $accordionItem = $(`[name="${field}"]`).closest('.item');
								if(!$accordionItem.hasClass('open')){
									$('h4', $accordionItem).trigger('click');
								}
								$(`[name="${field}"]`).addClass('invalid').focus();
								isValid = false;
								break;
							}
						}
					}
					if(!isValid){
						break;
					}
				}
			}
			if(!isValid){
				return false;
			}
			
			// send form
			const offerApi = new OfferApi();
			offerApi.setForm(stepForm);
			const result = await offerApi.put(_page.offerId, stepValues);
			if(stepForm.hasErrors()){
				$(document).trigger('message', ['error', result.data]);
			} else {
				$(document).trigger('message', ['success', result.data]);
				$(document).trigger('offer-refreshcartbtn'); // refresh cart qty
				if(_page.afterSaveAction === 'addmoreproducts'){ // handle save triggered by "add more products"
					$.spa.navigate(`/catalog/${DEFAULT_CATEGORY}`);
					return false;
				}
				_page.multistepForm.nextScreen();
				window.scrollTo({top: 0});
			}
		});
		
		return stepForm;
	}

    // STEP: duration, takeover, pickup
	prepareStepDuration(){
		const _page = this;
		
		// duration
		const duration = new SelectField();
		duration
			.setFieldName('duration')
			.setLabel('Durata');
		const durationOpt24 = new OptionField();
		durationOpt24
			.setValue('24')
			.setLabel('24 mesi')
			.setDefault(this.data?.duration === '24');
		const durationOpt36 = new OptionField();
		durationOpt36
			.setValue('36')
			.setLabel('36 mesi')
			.setDefault(this.data?.duration === '36');
		const durationOpt48 = new OptionField();
		durationOpt48
			.setValue('48')
			.setLabel('48 mesi')
			.setDefault(this.data?.duration === '48');
		const durationOpt60 = new OptionField();
		durationOpt60
			.setValue('60')
			.setLabel('60 mesi')
			.setDefault();
		const durationOpt72 = new OptionField();
		durationOpt72
			.setValue('72')
			.setLabel('72 mesi')
			.setDefault(this.data?.duration === '72');
		duration.addOptions([
			durationOpt24,
			durationOpt36,
			durationOpt48,
			durationOpt60,
			durationOpt72
		]);
		
		const durationDisclaimer = new ContentField();
		durationDisclaimer
			.setFieldName('duration-disclaimer')
			.setContent('NB: non è previsto autofinanziamento per la durata di 72 mesi');
		
		const contractFieldset = new Form();
		contractFieldset
			.setTitle('Contratto')
			.addFields([
				duration,
				durationDisclaimer
			]);
		
		// takeover    
		const takeover = new SelectBooleanField();
		takeover
			.setFieldName('takeover')
			.setLabel('Si effettua il subentro');
		
		const takeover_notes = new TextareaField();
		takeover_notes
			.setFieldName('takeover_notes')
			.setLabel('Note subentro');
		
		const takeover_price = new PriceField();
		takeover_price
			.setFieldName('takeover_price')
			.setLabel('Top up subentro');
		if(this.data.hardware_price){
			takeover_price
				.setMax(_.round(parseFloat(this.data.hardware_price) * 2, 2));
		}
		
		const takeoverFieldset = new Form();
		takeoverFieldset
			.setTitle('Subentro a noleggio (solo printing)')
			.addFields([
				takeover,
				takeover_notes,
				takeover_price
			]);
		
		// pickup
		const pickup = new SelectBooleanField();
		pickup
			.setFieldName('pickup')
			.setLabel('Si effettua il ritiro');
		
		const pickup_notes = new TextareaField();
		pickup_notes
			.setFieldName('pickup_notes')
			.setLabel('Descrizione dell’usato')
			.setPlaceholder('Indicare marca e modello della stampante');
		
		const pickup_price = new PriceField();
		pickup_price
			.setFieldName('pickup_price')
			.setLabel('Valore dell’usato');
		if(this.data.hardware_price){
			pickup_price
				.setMax(_.round(parseFloat(this.data.hardware_price) * 0.2, 2));
		}
			
		const pickup_num_a3 = new NumberField();
		pickup_num_a3
			.setFieldName('pickup_num_a3')
			.setLabel('Numero stampanti A3');
			
		const pickup_num_a4 = new NumberField();
		pickup_num_a4
			.setFieldName('pickup_num_a4')
			.setLabel('Numero stampanti A4');
			
		const pickup_location = new TextField();
		pickup_location
			.setFieldName('pickup_location')
			.setLabel('Ubicazione');
			
		const pickup_floor = new TextField();
		pickup_floor
			.setFieldName('pickup_floor')
			.setLabel('Piano');
		
		const pickupFieldset = new Form();
		pickupFieldset
			.setTitle('Ritiro dell’usato (solo printing)')
			.addFields([
				pickup,
				pickup_notes,
				pickup_price,
				pickup_num_a3,
				pickup_num_a4,
				pickup_location,
				pickup_floor
			]);
		
		// set values
		duration.setValue(this.data?.duration === null ? '60' : this.data?.duration);
		takeover.setValue(this.data.takeover === null ? '0' : this.data.takeover);
		takeover_notes.setValue(this.data.takeover_notes);
		takeover_price.setValue(this.data.takeover_price);
		pickup.setValue(this.data.pickup === null ? '0' : this.data.pickup);
		pickup_notes.setValue(this.data.pickup_notes);
		pickup_price.setValue(this.data.pickup_price);
		pickup_num_a3.setValue(this.data.pickup_num_a3 === null ? 0 : this.data.pickup_num_a3);
		pickup_num_a4.setValue(this.data.pickup_num_a4 === null ? 0 : this.data.pickup_num_a4);
		pickup_location.setValue(this.data.pickup_location);
		pickup_floor.setValue(this.data.pickup_floor);

		if(this.data.takeover !== '1'){
			takeover_notes.addWrapperClasses('hidden');
			takeover_price.addWrapperClasses('hidden');
		}		
		if(this.data.pickup !== '1'){
			pickup_notes.addWrapperClasses('hidden');
			pickup_price.addWrapperClasses('hidden');
			pickup_num_a3.addWrapperClasses('hidden');
			pickup_num_a4.addWrapperClasses('hidden');
			pickup_location.addWrapperClasses('hidden');
			pickup_floor.addWrapperClasses('hidden');
		}
		
		// disable editing if offer is locked
		if(this.data.locked){
			duration.setDisabled();
			takeover.setDisabled();
			takeover_notes.setReadonly();
			takeover_price.setReadonly();
			pickup.setDisabled();
			pickup_notes.setReadonly();
			pickup_price.setReadonly();
			pickup_num_a3.setReadonly();
			pickup_num_a4.setReadonly();
			pickup_location.setReadonly();
			pickup_floor.setReadonly();
		}
		
		const step = new HiddenField();
		step
			.setFieldName('step')
			.setValue('duration');
			
		const stepForm = new Form();
		stepForm
			.setAction('/')
			.setTitle('Durata e Subentro')
			.addClasses('fullwidth label-left')
			.addFields([
				step,
				contractFieldset,
				takeoverFieldset,
				pickupFieldset
			]);
			
		stepForm.onSubmit(async function(res){
			
			const stepValues = stepForm.getValues();
			
			// custom field validations
			$('input', `#${stepForm.name}`).removeClass('invalid');
			let isValid = true;
			
			if(stepValues.takeover === '1'){
				// force fill of takeover fields if selected
				for (const field of [takeover_notes, takeover_price]) {
					if(stepValues[field.field_name] === ''){
						$(`#${field.name}`).addClass('invalid').focus();
						isValid = false;
						break;
					}
				}
				// check that pickup_num_a3 + pickup_num_a4 isn’t higher than number of products in offer
				let totQty = 0;
				if(Array.isArray(_page.data.rows) && _page.data.rows.length){
					for(const row of _page.data.rows){
						totQty += parseInt(row.qty);
					}
				}
				if(parseInt(stepValues.pickup_num_a3) + parseInt(stepValues.pickup_num_a4) > totQty){
					$(`#${pickup_num_a3.name}`).addClass('invalid').focus();
					$(`#${pickup_num_a4.name}`).addClass('invalid');
					isValid = false;
				}
				if(!isValid){
					return false;
				}
			}
			if(stepValues.pickup === '1'){
				// force fill of pickup fields if selected
				for (const field of [pickup_notes, pickup_price, pickup_location, pickup_floor]) {
					if(stepValues[field.field_name] === ''){
						$(`#${field.name}`).addClass('invalid').focus();
						isValid = false;
						break;
					}
				}
				if(!isValid){
					return false;
				}
			}
			
			// skip save if offer is locked
			if(_page.data.locked){
				_page.multistepForm.nextScreen();
				window.scrollTo({top: 0});
				return false;
			}
			
			const offerApi = new OfferApi();
			offerApi.setForm(stepForm);
			
			const result = await offerApi.put(_page.offerId, stepValues);
			if(stepForm.hasErrors()){
				$(document).trigger('message', ['error', result.data]);
			} else {
				$(document).trigger('message', ['success', result.data]);
				_page.multistepForm.nextScreen();
				window.scrollTo({top: 0});
			}
		});
		
		return stepForm;
	}
	
	// STEP: fees, copies, prices
	prepareStepPrices(){
		const _page = this;
		this.fieldsToRevalidate = [];
		
		// fee
		const fee = new PriceField();
		fee
			.setLabel('Canone VS')
			.setFieldName('fee')
			.addClasses('field-starting-price')
			.setReadonly();
		
		const fee_markup = new NumberField();
		fee_markup
			.setLabel('Ricarico')
			.setFieldName('fee_markup')
			.setCleave('prefix', ' %')
			.setCleave('tailPrefix', true)
			.addClasses('text-right field-markup');
		
		const fee_final = new PriceField();
		fee_final
			.setLabel('Prezzo vendita')
			.setFieldName('fee_final')
			.addClasses('field-final-price')
			.setRequired(true)
			.setMin(this.data.fee);
		this.fieldsToRevalidate.push(fee_final);
			
		const feeFieldset = new Form();
		feeFieldset
			.setTitle('Canone hardware')
			.addClasses('fieldset-inline markup-container')
			.addFields([
				fee,
				fee_markup,
				fee_final
			]);
		
		// copies
		const ccFieldset = new Form();
		ccFieldset
			.setTitle('Copie mensili incluse');
			
		if(this.data.cc_bn) // bn
		{
			const cc_bn_num = new NumberField();
			cc_bn_num
				.setLabel('Numero copie B/N')
				.setFieldName('cc_bn_num')
				.addClasses('field-multiplier')
				.setValue(this.data.cc_bn_num)
				.setReadonly();
		
			const cc_bn = new PriceField();
			cc_bn
				.setLabel('Costo unificato B/N')
				.setFieldName('cc_bn')
				.addClasses('field-starting-price precision4')
				.setCleave('numeralDecimalScale', 4)
				.setValue(this.data.cc_bn)
				.setReadonly();
				
			const cc_bn_markup = new NumberField();
			cc_bn_markup
				.setLabel('Ricarico')
				.setFieldName('cc_bn_markup')
				.setCleave('prefix', ' %')
				.setCleave('tailPrefix', true)
				.addClasses('text-right field-markup')
				.setMax(100)
				.setValue((this.data.cc_bn_markup === null || this.data.cc_bn_markup === '') ? 0 : this.data.cc_bn_markup);
			
			const cc_bn_final = new PriceField();
			cc_bn_final
				.setLabel('Prezzo vendita')
				.setFieldName('cc_bn_final')
				.setCleave('numeralDecimalScale', 4)
				.addClasses('field-final-price precision4')
				.setValue(this.data.cc_bn_final)
				.setRequired(true)
				.setMin(this.data.cc_bn);
			this.fieldsToRevalidate.push(cc_bn_final);
				
			// disable editing if offer is locked
			if(this.data.locked){
				cc_bn_markup.setReadonly();
				cc_bn_final.setReadonly();
			}
				
			const ccBnFieldset = new Form();
			ccBnFieldset
				.addClasses('fieldset-inline inner markup-container')
				.addFields([
					cc_bn_num,
					cc_bn,
					cc_bn_markup,
					cc_bn_final
				]);
				
			ccFieldset.addFields(ccBnFieldset);
		} else {
			const cc_bn_markup = new HiddenField();
			cc_bn_markup
				.setFieldName('cc_bn_markup')
				.setValue(0);
			
			const cc_bn_final = new HiddenField();
			cc_bn_final
				.setFieldName('cc_bn_final')
				.setValue(0);
				
			ccFieldset.addFields([
				cc_bn_markup,
				cc_bn_final
			]);
		}
		
		if(this.data.cc_color) // color
		{
			const cc_color_num = new NumberField();
			cc_color_num
				.setLabel('Numero copie a colori')
				.setFieldName('cc_color_num')
				.addClasses('field-multiplier')
				.setValue(this.data.cc_color_num)
				.setReadonly();
		
			const cc_color = new PriceField();
			cc_color
				.setLabel('Costo unificato a colori')
				.setFieldName('cc_color')
				.addClasses('field-starting-price precision4')
				.setCleave('numeralDecimalScale', 4)
				.setValue(this.data.cc_color)
				.setReadonly();
				
			const cc_color_markup = new NumberField();
			cc_color_markup
				.setLabel('Ricarico')
				.setFieldName('cc_color_markup')
				.setCleave('prefix', ' %')
				.setCleave('tailPrefix', true)
				.addClasses('text-right field-markup')
				.setMax(100)
				.setValue((this.data.cc_color_markup === null || this.data.cc_color_markup === '') ? 0 : this.data.cc_color_markup);
			
			const cc_color_final = new PriceField();
			cc_color_final
				.setLabel('Prezzo vendita')
				.setFieldName('cc_color_final')
				.setCleave('numeralDecimalScale', 4)
				.addClasses('field-final-price precision4')
				.setValue(this.data.cc_color_final)
				.setRequired(true)
				.setMin(this.data.cc_color);
			this.fieldsToRevalidate.push(cc_color_final);
				
			// disable editing if offer is locked
			if(this.data.locked){
				cc_color_markup.setReadonly();
				cc_color_final.setReadonly();
			}
				
			const ccColorFieldset = new Form();
			ccColorFieldset
				.addClasses('fieldset-inline inner markup-container')
				.addFields([
					cc_color_num,
					cc_color,
					cc_color_markup,
					cc_color_final
				]);
				
			ccFieldset.addFields(ccColorFieldset);
		} else {
			const cc_color_markup = new HiddenField();
			cc_color_markup
				.setFieldName('cc_color_markup')
				.setValue(0);
			
			const cc_color_final = new HiddenField();
			cc_color_final
				.setFieldName('cc_color_final')
				.setValue(0);
				
			ccFieldset.addFields([
				cc_color_markup,
				cc_color_final
			]);
		}
		
		// extra copies
		const ccExtraFieldset = new Form();
		ccExtraFieldset
			.setTitle('Copie mensili eccedenti');
			
		if(this.data.cc_bn) // extra bn
		{
			const cc_extra_bn = new PriceField();
			cc_extra_bn
				.setLabel('Costo unificato B/N')
				.setFieldName('cc_extra_bn')
				.addClasses('field-starting-price precision4')
				.setCleave('numeralDecimalScale', 4)
				.setValue(this.data.cc_bn) // same as included
				.setReadonly();
				
			const cc_extra_bn_markup = new NumberField();
			cc_extra_bn_markup
				.setLabel('Ricarico')
				.setFieldName('cc_extra_bn_markup')
				.setCleave('prefix', ' %')
				.setCleave('tailPrefix', true)
				.addClasses('text-right field-markup')
				.setMax(100)
				.setValue((this.data.cc_extra_bn_markup === null || this.data.cc_extra_bn_markup === '') ? 0 : this.data.cc_extra_bn_markup);
			
			const cc_extra_bn_final = new PriceField();
			cc_extra_bn_final
				.setLabel('Prezzo vendita')
				.setFieldName('cc_extra_bn_final')
				.setCleave('numeralDecimalScale', 4)
				.addClasses('field-final-price precision4')
				.setValue((this.data.cc_extra_bn_final === null || this.data.cc_extra_bn_final === '') ? this.data.cc_bn : this.data.cc_extra_bn_final)
				.setRequired(true)
				.setMin(this.data.cc_bn);
			this.fieldsToRevalidate.push(cc_extra_bn_final);
				
			// disable editing if offer is locked
			if(this.data.locked){
				cc_extra_bn_markup.setReadonly();
				cc_extra_bn_final.setReadonly();
			}
				
			const ccExtraBnFieldset = new Form();
			ccExtraBnFieldset
				.addClasses('fieldset-inline inner markup-container')
				.addFields([
					cc_extra_bn,
					cc_extra_bn_markup,
					cc_extra_bn_final
				]);
				
			ccExtraFieldset.addFields(ccExtraBnFieldset);
		} else {
			const cc_extra_bn_markup = new HiddenField();
			cc_extra_bn_markup
				.setFieldName('cc_extra_bn_markup')
				.setValue(0);
			
			const cc_extra_bn_final = new HiddenField();
			cc_extra_bn_final
				.setFieldName('cc_extra_bn_final')
				.setValue(0);
				
			ccExtraFieldset.addFields([
				cc_extra_bn_markup,
				cc_extra_bn_final
			]);
		}
		
		if(this.data.cc_color) // extra color
		{
			const cc_extra_color = new PriceField();
			cc_extra_color
				.setLabel('Costo unificato a colori')
				.setFieldName('cc_extra_color')
				.addClasses('field-starting-price precision4')
				.setCleave('numeralDecimalScale', 4)
				.setValue(this.data.cc_color) // same as included
				.setReadonly();
				
			const cc_extra_color_markup = new NumberField();
			cc_extra_color_markup
				.setLabel('Ricarico')
				.setFieldName('cc_extra_color_markup')
				.setCleave('prefix', ' %')
				.setCleave('tailPrefix', true)
				.addClasses('text-right field-markup')
				.setMax(100)
				.setValue((this.data.cc_extra_color_markup === null || this.data.cc_extra_color_markup === '') ? 0 : this.data.cc_extra_color_markup);
			
			const cc_extra_color_final = new PriceField();
			cc_extra_color_final
				.setLabel('Prezzo vendita')
				.setFieldName('cc_extra_color_final')
				.setCleave('numeralDecimalScale', 4)
				.addClasses('field-final-price precision4')
				.setValue((this.data.cc_extra_color_final === null || this.data.cc_extra_color_final === '') ? this.data.cc_color : this.data.cc_extra_color_final)
				.setRequired(true)
				.setMin(this.data.cc_color);
			this.fieldsToRevalidate.push(cc_extra_color_final);
				
			// disable editing if offer is locked
			if(this.data.locked){
				cc_extra_color_markup.setReadonly();
				cc_extra_color_final.setReadonly();
			}
				
			const ccExtraBnFieldset = new Form();
			ccExtraBnFieldset
				.addClasses('fieldset-inline inner markup-container')
				.addFields([
					cc_extra_color,
					cc_extra_color_markup,
					cc_extra_color_final
				]);
				
			ccExtraFieldset.addFields(ccExtraBnFieldset);
		} else {
			const cc_extra_color_markup = new HiddenField();
			cc_extra_color_markup
				.setFieldName('cc_extra_color_markup')
				.setValue(0);
			
			const cc_extra_color_final = new HiddenField();
			cc_extra_color_final
				.setFieldName('cc_extra_color_final')
				.setValue(0);
				
			ccExtraFieldset.addFields([
				cc_extra_color_markup,
				cc_extra_color_final
			]);
		}
		
		// total
		const total_customer = new PriceField();
		total_customer
			.setLabel('Totale canone mensile comprensivo eventuali copie incluse')
			.setFieldName('total_customer')
			.addClasses('field-total-price')
			.setReadonly();
			
		const totalFieldset = new Form();
		totalFieldset
			.setTitle('Totale')
			.addClasses('fieldset-inline')
			.addFields([
				total_customer
			]);
		
		// set values
		fee.setValue(this.data.fee);
		fee_markup.setValue((this.data.fee_markup === null || this.data.fee_markup === '') ? 0 : this.data.fee_markup);
		fee_final.setValue(this.data.fee_final);
		total_customer.setValue(_.round(this.data.total_customer, 2));
		
		// disable editing if offer is locked
		if(this.data.locked){
			fee_markup.setReadonly();
			fee_final.setReadonly();
		}
		
		const step = new HiddenField();
		step
			.setFieldName('step')
			.setValue('prices');
		
		// step form
		const stepForm = new Form();
		stepForm
			.setAction('/')
			.setTitle('Prezzi')
			.addClasses('fullwidth label-left')
			.addFields([
				step,
				feeFieldset,
				ccFieldset,
				ccExtraFieldset,
				totalFieldset
			]);
			
		stepForm.onSubmit(async function(res){
			
			// skip save if offer is locked
			if(_page.data.locked){
				_page.multistepForm.nextScreen();
				window.scrollTo({top: 0});
				return false;
			}
			
			const offerApi = new OfferApi();
			offerApi.setForm(stepForm);
			
			const result = await offerApi.put(_page.offerId, stepForm.getValues());
			if(stepForm.hasErrors()){
				$(document).trigger('message', ['error', result.data]);
			} else {
				$(document).trigger('message', ['success', result.data]);
				_page.multistepForm.nextScreen();
				window.scrollTo({top: 0});
			}
		});
		
		return stepForm;
	}

	// STEP: review
	async prepareStepReview(){
		const _page = this;
		
		const priceFormatter = new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'});
		
		const notes = new HiddenField();
		notes
			.setFieldName('notes')
			.setValue('');
			
		const step = new HiddenField();
		step
			.setFieldName('step')
			.setValue('review');
			
		const total_customer = new ContentField();
		total_customer
			.setFieldName('content-total_customer')
			.setContent(`<div class="review-box with-image review-box--total_customer">Rata finale <span>${this.data.total_customer === null ? '-' : priceFormatter.format(_.round(parseFloat(this.data.total_customer), 2))}</span></div>`);
		
		const duration = new ContentField();
		duration
			.setFieldName('content-duration')
			.setContent(`<div class="review-box with-image review-box--duration">Durata <span>${this.data.duration === null ? '-' : this.data.duration} mesi</span></div>`);
			
		// retrieve financials using API to create various commissions at runtime
		const financialsApi = new OfferFinancialsApi().setOfferId(this.data.id);
		const { data: financials } = await financialsApi.collection({price: this.data.price, duration: this.data.duration});
		
		// prepare commissions grid items
		const commissions = [];
		const approvedFinancial = financials.find(financial => financial.approved);
		if(typeof approvedFinancial !== 'undefined') {
			commissions.push({
				label: approvedFinancial.name,
				value: parseFloat(this.data.commission_financial_chosen)
			});
		} else {
			financials.forEach(financial => {
				// ((canone_cliente - canone_VS ) / coeff_B x molt_B) * provvigione_agente / 100
				commissions.push({
					label: financial.name,
					value: (parseFloat(this.data.total_customer) - parseFloat(this.data.total_user)) / parseFloat(financial.coefficient) * parseFloat(financial.multiplier) * parseFloat(this.data.user_commission) / 100
				});
			});
		}
		
		const commissionsGrid = new BasicGrid();
		commissionsGrid
			.setFieldName('commissions-grid')
			.addClasses('commissions-grid');
		commissions.map(function(commission, idx){
			let commissionContent = `Provvigione totale:<span>${commission.value === null ? '-' : priceFormatter.format(_.round(commission.value, 2))}</span>`;
			if(commission.value !== null && (commission.label === 'AUTOFINANZIAMENTO' || commission.label === 'Autofinanziamento' || commission.label === 'autofinanziamento')) {
				commissionContent += `<small>liquidata trimestralmente<br>in ${priceFormatter.format(_.round(commission.value / _page.data.duration * 3, 2))} per ${_page.data.duration} mesi</small>`;
			}
			const basicGridItem = new BasicGridItem();
			basicGridItem
				.addClasses('commission-box')
				.setTitle(commission.label)
				.setContent(commissionContent);
			if(idx === 0){
				basicGridItem.addClasses('commission-box--best');
			}
			commissionsGrid.addItem(basicGridItem);
		});
		
		// step form
		const stepForm = new Form();
		stepForm
			.setAction('/')
			.setTitle('Riepilogo e provvigioni')
			.addClasses('fullwidth label-left wizard-last')
			.addFields([
				step,
				total_customer,
				duration,
				commissionsGrid,
				notes
			]);
		
		stepForm.onSubmit(async function(res){
			
			// skip save if offer is locked
			if(!_page.data.locked){
				const offerApi = new OfferApi();
				offerApi.setForm(stepForm);
			
				const result = await offerApi.put(_page.offerId, stepForm.getValues());
				if(stepForm.hasErrors()){
					$(document).trigger('message', ['error', result.data]);
					return false;
				} else {
					$(document).trigger('message', ['success', result.data]);
				}
			}
			
			if(_page.afterSaveAction === 'download'){
				const offerGenerateDocumentApi = new OfferGenerateDocumentApi();
				offerGenerateDocumentApi.setOfferId(_page.offerId).setType('offerdelivery').post()
					.then((result) => {
						if(typeof result?.data?.fileurl === 'undefined'){
							$(document).trigger('message', ['error', 'Si è verificato un errore']);
							return false;
						}
						setTimeout(() => { // workaround for Safari
							window.open(result.data.fileurl, '_top');
						});
						// if offer is locked return to offers
						if(_page.data.locked){
							$.spa.navigate('/offers');
						}
					})
					.catch((result) => {
						$(document).trigger('message', ['error', result.responseJSON.error]);
					});
			} else if(_page.afterSaveAction === 'send') {
				const offerNotificationApi = new OfferNotificationApi();
				const result = await offerNotificationApi.setOfferId(_page.offerId).setType('documents-to-customer').post({documents: ['offer']})
					.catch((e) => {
						$(document).trigger('message', ['error', 'Si è verificato un errore nell’invio della notifica correlata all’ordine']);
					});
			}
			
			// if offer is locked return to offers
			if(_page.data.locked && _page.afterSaveAction !== 'download'){
				$.spa.navigate('/offers');
			}
			
		});
		
		return stepForm;
	}
	
	//
	// OFFER ROW
	//

	// single offer row
	async prepareOfferRow(row, product){
		
		const prefix = row.id + '_';
		
		// id row
		const idRow = new HiddenField();
		idRow
			.setFieldName(prefix + 'id_row')
			.setValue(row.id);
		
		// qty
		const qty = new NumberField();
		qty
			.setFieldName(prefix + 'qty')
			.setLabel('Quantità')
			.addWrapperClasses('qty')
			.setRequired(true)
			.setValue(row.qty);
		
		//
		// copies
		//
		
		const ccFieldsetFields = [];
		
		let cc_bn_num_each;
		if(product.cc_bn_base_price > 0) // bn
		{
			cc_bn_num_each = new NumberField();
			cc_bn_num_each
				.setFieldName(prefix + 'cc_bn_num_each')
				.setLabel('Numero copie B/N')
				.setStepper(true)
				.setStep(100)
				.setRequired(true);
			if(product.cc_max_bn){
				cc_bn_num_each
					.setLabel(`Numero copie B/N (max ${product.cc_max_bn})`)
					.setMax(product.cc_max_bn);
			}
		}
		else
		{
			cc_bn_num_each = new HiddenField();
			cc_bn_num_each
				.setFieldName(prefix + 'cc_bn_num_each')
				.setValue(0);
		}
		ccFieldsetFields.push(cc_bn_num_each);
		
		let cc_color_num_each;
		if(product.cc_color_base_price > 0) // bn
		{
			cc_color_num_each = new NumberField();
			cc_color_num_each
				.setFieldName(prefix + 'cc_color_num_each')
				.setLabel('Numero copie a colori')
				.setStepper(true)
				.setStep(50)
				.setRequired(true);
			if(product.cc_max_color){
				cc_color_num_each
					.setLabel(`Numero copie a colori (max ${product.cc_max_color})`)
					.setMax(product.cc_max_color);
			}
		}
		else
		{
			cc_color_num_each = new HiddenField();
			cc_color_num_each
				.setFieldName(prefix + 'cc_color_num_each')
				.setValue(0);
		}
		ccFieldsetFields.push(cc_color_num_each);
		
		const ccFieldset = new Form();
		ccFieldset
			.setTitle('Copie incluse (cad.)')
			.addClasses('fieldset-inline')
			.addFields(ccFieldsetFields);
		
		//
		// location
		//
		
		const idLocation = new SelectField();
		idLocation
			.setFieldName(prefix + 'id_location')
			.setRequired(true)
			.setLabel('Sede');
		const locationOpts = [];
		const emptyOpt = new OptionField();
		emptyOpt
			.setValue('')
			.setLabel('Seleziona');
		locationOpts.push(emptyOpt);    
		this.customerLocations.map(function(location){
			const option = new OptionField();
			option
				.setValue(location.id)
				.setLabel(location.name);
			locationOpts.push(option);
		});
		idLocation.addOptions(locationOpts);
		this.rowLocationSelectFields.push(idLocation); // to retrieve instance on update

		const location_type = new TextField();
		location_type
			.setFieldName(prefix + 'location_type')
			.setLabel('Tipo')
			.setReadonly();
		
		const location_address = new TextField();
		location_address
			.setFieldName(prefix + 'location_address')
			.setLabel('Indirizzo')
			.setReadonly();
		
		const location_city = new TextField();
		location_city
			.setFieldName(prefix + 'location_city')
			.setLabel('Città')
			.setReadonly();
			
		const location_zip = new TextField();
		location_zip
			.setFieldName(prefix + 'location_zip')
			.setLabel('CAP')
			.setReadonly();
			
		const location_id_region = new HiddenField();
		location_id_region
			.setFieldName(prefix + 'location_id_region');
		
		const location_region = new TextField();
		location_region
			.setFieldName(prefix + 'location_region')
			.setLabel('Provincia')
			.setDisabled();
			
		const location_region_other = new HiddenField();
		location_region_other
			.setFieldName(prefix + 'location_region_other');
			
		const location_id_country = new HiddenField();
		location_id_country
			.setFieldName(prefix + 'location_id_country');
		
		const location_country = new TextField();
		location_country
			.setFieldName(prefix + 'location_country')
			.setLabel('Nazione')
			.setDisabled();
			
		const locationAddressFieldset = new Form();
		locationAddressFieldset
			.addClasses('fieldset-inline fieldset-location-address inner')
			.addFields([
				location_type,
				location_address,
				location_city,
				location_zip,
				location_id_region,
				location_region,
				location_region_other,
				location_id_country,
				location_country
			]);
		
		const location_opening_time = new TextField();
		location_opening_time
			.setFieldName(prefix + 'location_opening_time')
			.setLabel('Orari di apertura')
			.setReadonly();
		
		const location_ztl = new TextField();
		location_ztl
			.setFieldName(prefix + 'location_ztl')
			.setLabel('ZTL')
			.setReadonly();
			
		const location_ref_name = new TextField();
		location_ref_name
			.setFieldName(prefix + 'location_ref_name')
			.setLabel('Nome referente')
			.setReadonly();
			
		const location_ref_email = new EmailField();
		location_ref_email
			.setFieldName(prefix + 'location_ref_email')
			.setLabel('Email referente')
			.setReadonly();
			
		const location_ref_phone = new TextField();
		location_ref_phone
			.setFieldName(prefix + 'location_ref_phone')
			.setLabel('Telefono referente')
			.setReadonly();
			
		const locationRefFieldset = new Form();
		locationRefFieldset
			.addClasses('fieldset-inline fieldset-location-ref inner')
			.addFields([
				location_ref_name,
				location_ref_email,
				location_ref_phone
			]);
			
		const location_notes = new TextareaField();
		location_notes
			.setFieldName(prefix + 'location_notes')
			.setLabel('Note')
			.setReadonly();
		
		const locationDataFieldset = new Form();
		locationDataFieldset
			.addClasses('fieldset-location-data inner no-margin-bottom')
			.addFields([
				locationAddressFieldset,
				location_opening_time,
				location_ztl,
				locationRefFieldset,
				location_notes
			]);
			
		const locationFieldset = new Form();
		locationFieldset
			.setTitle('Sede')
			.addClasses('fieldset-location')
			.addFields([
				idLocation,
				locationDataFieldset
			]);
		
		// precompile shipping values
		const shippingTemplates = new SelectField();
		shippingTemplates
			.setFieldName(prefix + 'id_shipping')
			.setLabel('Precompila i campi per la spedizione');
		this.rowShippingSelectFields.push(shippingTemplates); // to retrieve instance on update
		await this.fillCustomerLocationShippings(
			shippingTemplates.name,
			(typeof this.data.customer !== 'undefined' && this.data.customer !== null) ? this.data.customer.id_original : false,
			(typeof row.location !== 'undefined' && row.location !== null) ? row.location.id_original : false
		);
		
		// save shipping template
		const saveShippingTemplate = new CheckboxField();
		saveShippingTemplate
			.setFieldName(prefix + 'shipping_save_template')
			.setLabel('Salva i dati come template')
			.setValue(1);
			
		const shippingTemplateFieldset = new Form();
		shippingTemplateFieldset
			.addClasses('fieldset-inline fieldset-shipping-template')
			.addFields([
				shippingTemplates,
				saveShippingTemplate
			]);
		
		// reference office
		const shipping_ref_office = new TextField();
		shipping_ref_office
			.setFieldName(prefix + 'shipping_ref_office')
			.setLabel('Ufficio di riferimento');
			
		const shipping_ref_name = new TextField();
		shipping_ref_name
			.setFieldName(prefix + 'shipping_ref_name')
			.setLabel('Nome referente');
			
		const shipping_ref_phone = new TextField();
		shipping_ref_phone
			.setFieldName(prefix + 'shipping_ref_phone')
			.setLabel('Telefono referente');
			
		const shipping_ref_email = new EmailField();
		shipping_ref_email
			.setFieldName(prefix + 'shipping_ref_email')
			.setLabel('Email referente');
			
		const shippingRefFieldset = new Form();
		shippingRefFieldset
			.addClasses('fieldset-inline fieldset-shipping-ref')
			.addFields([
				shipping_ref_office,
				shipping_ref_name,
				shipping_ref_phone,
				shipping_ref_email
			]);
		
		// notes
		const shipping_notes = new TextareaField();
		shipping_notes
			.setFieldName(prefix + 'shipping_notes')
			.setLabel('Note')
			.addWrapperClasses('formfield-notes');
			
		// delivery
		const shipping_delivery_times = new TextField();
		shipping_delivery_times
			.setFieldName(prefix + 'shipping_delivery_times')
			.setLabel('Orari di consegna');
			
		const shipping_floor_delivery = new SelectBooleanField();
		shipping_floor_delivery
			.setFieldName(prefix + 'shipping_floor_delivery')
			.setLabel('Consegna al piano')
			.setRequired(true);
			
		const shipping_floor_num = new NumberField();
		shipping_floor_num
			.setFieldName(prefix + 'shipping_floor_num')
			.setLabel('Piano di consegna')
			.addClasses('required')
			.append('<span class="required"></span>');
		
		const shipping_fe_present = new SelectBooleanField();
		shipping_fe_present
			.setFieldName(prefix + 'shipping_fe_present')
			.setLabel('Disponibilità ascensore')
			.setValues('0', 'No', 'Sì (minimo 75x75 cm LargxProf)');
			
		const shipping_fe_width = new NumberField();
		shipping_fe_width
			.setFieldName(prefix + 'shipping_fe_width')
			.setLabel('Larghezza utile porta');
			
		const shipping_fe_depth = new NumberField();
		shipping_fe_depth
			.setFieldName(prefix + 'shipping_fe_depth')
			.setLabel('Profondità interna');
			
		const deliveryElevatorFieldset = new Form();
		deliveryElevatorFieldset
			.addClasses('fieldset-delivery-elevator fieldset-inline inner')
			.addFields([
				shipping_fe_present,
				shipping_fe_width,
				shipping_fe_depth
			]);
		
		const shipping_st_type = new SelectField();
		shipping_st_type
			.setFieldName(prefix + 'shipping_st_type')
			.setLabel('Tipologia di scale');
		const stairsTypologyOpt0 = new OptionField();
		stairsTypologyOpt0
			.setValue('')
			.setLabel('Seleziona');
		const stairsTypologyOpt1 = new OptionField();
		stairsTypologyOpt1
			.setValue('type1')
			//.setLabel('Tipo 1 (2 rampe con pianerottolo spezzato)');
			.setLabel('2 rampe con pianerottolo spezzato');
		const stairsTypologyOpt2 = new OptionField();
		stairsTypologyOpt2
			.setValue('type2')
			//.setLabel('Tipo 2 (2 rampe con pianerottolo intero)');
			.setLabel('2 rampe con pianerottolo intero');
		const stairsTypologyOpt3 = new OptionField();
		stairsTypologyOpt3
			.setValue('type3')
			//.setLabel('Tipo 3 (90 gradi con pianerottolo spezzato)');
			.setLabel('90 gradi con pianerottolo spezzato');
		const stairsTypologyOpt4 = new OptionField();
		stairsTypologyOpt4
			.setValue('type4')
			//.setLabel('Tipo 4 (90 gradi con pianerottolo intero)');
			.setLabel('90 gradi con pianerottolo intero');
		shipping_st_type.addOptions([
			stairsTypologyOpt0,
			stairsTypologyOpt1,
			stairsTypologyOpt2,
			stairsTypologyOpt3,
			stairsTypologyOpt4
		]);
		
		const shipping_st_num = new NumberField();
		shipping_st_num
			.setFieldName(prefix + 'shipping_st_num')
			.setLabel('Numero di rampe');
			
		const shipping_st_width = new NumberField();
		shipping_st_width
			.setFieldName(prefix + 'shipping_st_width')
			.setLabel('Larghezza delle scale');
			
		const shipping_st_depth = new NumberField();
		shipping_st_depth
			.setFieldName(prefix + 'shipping_st_depth')
			.setLabel('Profondità delle scale');
			
		const deliveryStairsFieldset = new Form();
		deliveryStairsFieldset
			.addClasses('fieldset-delivery-stairs fieldset-inline inner')
			.addFields([
				shipping_st_type,
				shipping_st_num,
				shipping_st_width,
				shipping_st_depth
			]);
			
		const floorDeliveryFieldset = new Form();
		floorDeliveryFieldset
			.addClasses('fieldset-floor-delivery inner no-margin-bottom')
			.addFields([
				shipping_floor_num,
				deliveryElevatorFieldset,
				deliveryStairsFieldset
			]);
			
		const deliveryFieldset = new Form();
		deliveryFieldset
			.setTitle('Consegna')
			.addClasses('fieldset-delivery')
			.addFields([
				shipping_delivery_times,
				shipping_floor_delivery,
				floorDeliveryFieldset
			]);
			
		// network			
		const shipping_num_workstations = new SelectField();
		shipping_num_workstations
			.setFieldName(prefix + 'shipping_num_workstations')
			.setLabel('Numero di postazioni')
			.setRequired(true);
		const shipping_num_workstationsOpt3 = new OptionField();
		shipping_num_workstationsOpt3
			.setValue(3)
			.setLabel('3');
		const shipping_num_workstationsOpt5 = new OptionField();
		shipping_num_workstationsOpt5
			.setValue(5)
			.setLabel('5');
		const shipping_num_workstationsOpt7 = new OptionField();
		shipping_num_workstationsOpt7
			.setValue(7)
			.setLabel('7');
		const shipping_num_workstationsOpt10 = new OptionField();
		shipping_num_workstationsOpt10
			.setValue(10)
			.setLabel('10');
		const shipping_num_workstationsOpt15 = new OptionField();
		shipping_num_workstationsOpt15
			.setValue(15)
			.setLabel('15');
		const shipping_num_workstationsOpt20 = new OptionField();
		shipping_num_workstationsOpt20
			.setValue(20)
			.setLabel('20');
		shipping_num_workstations.addOptions([
			shipping_num_workstationsOpt3,
			shipping_num_workstationsOpt5,
			shipping_num_workstationsOpt7,
			shipping_num_workstationsOpt10,
			shipping_num_workstationsOpt15,
			shipping_num_workstationsOpt20
		]);
			
		const shipping_net_type = new SelectField();
		shipping_net_type
			.setFieldName(prefix + 'shipping_net_type')
			.setLabel('Tipologia di postazioni');
		const networkTypeOptWindows = new OptionField();
		networkTypeOptWindows
			.setValue('windows')
			.setLabel('Windows');
		const networkTypeOptLinux = new OptionField();
		networkTypeOptLinux
			.setValue('linux')
			.setLabel('Linux');
		const networkTypeOptMac = new OptionField();
		networkTypeOptMac
			.setValue('mac')
			.setLabel('Mac');
		const networkTypeOptWindowsMac = new OptionField();
		networkTypeOptWindowsMac
			.setValue('windows-mac')
			.setLabel('Win Mac');
		const networkTypeOptWindowsLinux = new OptionField();
		networkTypeOptWindowsLinux
			.setValue('windows-linux')
			.setLabel('Win Linux');
			
		shipping_net_type.addOptions([
			networkTypeOptWindows,
			networkTypeOptLinux,
			networkTypeOptMac,
			networkTypeOptWindowsMac,
			networkTypeOptWindowsLinux
		]);
		
		const networkFirstFieldset = new Form();
		networkFirstFieldset
			.addClasses('fieldset-inline inner')
			.addFields([
				shipping_num_workstations,
				shipping_net_type
			]);
		
		const shipping_net_domain = new SelectBooleanField();
		shipping_net_domain
			.setFieldName(prefix + 'shipping_net_domain')
			.setLabel('Installazione su server');
			
		const shipping_net_password = new TextField();
		shipping_net_password
			.setFieldName(prefix + 'shipping_net_password')
			.setLabel('Password di rete');
			
		const shipping_net_cable = new SelectField();
		shipping_net_cable
			.setFieldName(prefix + 'shipping_net_cable')
			.setLabel('Cavo di rete');
		const networkCableOptNo = new OptionField();
		networkCableOptNo
			.setValue('no')
			.setLabel('No');
		const networkCableOpt1m = new OptionField();
		networkCableOpt1m
			.setValue('1m')
			.setLabel('1m');
		const networkCableOpt2m = new OptionField();
		networkCableOpt2m
			.setValue('2m')
			.setLabel('2m');
		const networkCableOpt3m = new OptionField();
		networkCableOpt3m
			.setValue('3m')
			.setLabel('3m');
		const networkCableOpt5m = new OptionField();
		networkCableOpt5m
			.setValue('5m')
			.setLabel('5m');
		shipping_net_cable.addOptions([
			networkCableOptNo,
			networkCableOpt1m,
			networkCableOpt2m,
			networkCableOpt3m,
			networkCableOpt5m
		]);
		
		const networkSecondFieldset = new Form();
		networkSecondFieldset
			.addClasses('fieldset-inline inner')
			.addFields([
				shipping_net_domain,
				shipping_net_password,
				shipping_net_cable
			]);
		
		const shipping_internet = new SelectBooleanField();
		shipping_internet
			.setFieldName(prefix + 'shipping_internet')
			.setLabel('Presenza Internet');
		
		const shipping_rooms_ready = new SelectBooleanField();
		shipping_rooms_ready
			.setFieldName(prefix + 'shipping_rooms_ready')
			.setLabel('Locali pronti')
			.setValue('1');
			
		const networkThirdFieldset = new Form();
		networkThirdFieldset
			.addClasses('fieldset-inline inner')
			.addFields([
				shipping_internet,
				shipping_rooms_ready
			]);
		
		const networkFieldset = new Form();
		networkFieldset
			.setTitle('Rete')
			.addClasses('fieldset-network')
			.addFields([
				networkFirstFieldset,
				networkSecondFieldset,
				networkThirdFieldset
			]);
		
		const shippingFieldset = new Form();
		shippingFieldset
			.setTitle('Spedizione')
			.addClasses('fieldset-shipping')
			.addFields([
				shippingTemplateFieldset,
				shippingRefFieldset,
				shipping_notes,
				deliveryFieldset,
				networkFieldset
			]);
			
		/*const btnGoToProduct = new ButtonField();
		btnGoToProduct
			.setFieldName(prefix + 'btn_gotoproduct')
			.addClassHref('btn-gotoproduct')
			.setValue('Vai al prodotto')
			.onClick(`/product/view/${product.id_original}`);*/
		
		const btnDeleteRow = new ButtonField();
		btnDeleteRow
			.setFieldName(prefix + 'btn_deleterow')
			.addClassHref('btn-deleterow')
			.setValue('Rimuovi prodotto');
			
		const rowActionsFieldset = new Form();
		rowActionsFieldset
			.addClasses('row-actions')
			.addFields([
				//btnGoToProduct,
				qty,
				... this.data.locked ? [] : [btnDeleteRow]
			]);
			
		// set copies values
		cc_bn_num_each.setValue((row.cc_bn_num_each === null || row.cc_bn_num_each === '') ? ((product.cc_suggested_bn === null || product.cc_suggested_bn === '') ? 0 : product.cc_suggested_bn) : row.cc_bn_num_each);
		cc_color_num_each.setValue((row.cc_color_num_each === null || row.cc_color_num_each === '') ? ((product.cc_suggested_color === null || product.cc_suggested_color === '') ? 0 : product.cc_suggested_color) : row.cc_color_num_each);
		
		// set location values
		if(typeof this.data.customer !== 'undefined' && this.data.customer === null){
			locationFieldset.addClasses('hidden');
		}
		if(typeof row.location !== 'undefined' && row.location !== null){
			idLocation.setValue(row.location.id_original);
			location_type.setValue(row.location.type);
			location_address.setValue(row.location.address);
			location_city.setValue(row.location.city);
			location_zip.setValue(row.location.zip);
			location_id_region.setValue(row.location.id_region);
			location_region.setValue(row.location.region_other ? row.location.region_other : row.location.region); // display region or region_other if defined
			location_id_country.setValue(row.location.id_country);
			location_country.setValue(row.location.country);
			location_opening_time.setValue(row.location.opening_time);
			location_ztl.setValue(row.location.ztl === '1' ? 'Sì' : 'No');
			location_ref_name.setValue(row.location.ref_name);
			location_ref_email.setValue(row.location.ref_email);
			location_ref_phone.setValue(row.location.ref_phone);
			location_notes.setValue(row.location.notes);
		} else {
			locationDataFieldset.addClasses('hidden');
		}
		
		// set shipping values
		if(typeof row.shipping !== 'undefined' && row.shipping !== null){
			shippingTemplates.setValue(row.shipping.id_original);
			shipping_ref_office.setValue(row.shipping.ref_office);
			shipping_ref_name.setValue(row.shipping.ref_name);
			shipping_ref_phone.setValue(row.shipping.ref_phone);
			shipping_ref_email.setValue(row.shipping.ref_email);
			shipping_delivery_times.setValue(row.shipping.delivery_times);
			shipping_floor_delivery.setValue(row.shipping.floor_delivery === null ? '0' : row.shipping.floor_delivery);
			shipping_floor_num.setValue(row.shipping.floor_num);
			shipping_fe_present.setValue(row.shipping.fe_present === null ? '0' : row.shipping.fe_present);
			shipping_fe_width.setValue(row.shipping.fe_width);
			shipping_fe_depth.setValue(row.shipping.fe_depth);
			shipping_st_num.setValue(row.shipping.st_num);
			shipping_st_width.setValue(row.shipping.st_width);
			shipping_st_depth.setValue(row.shipping.st_depth);
			shipping_st_type.setValue(row.shipping.st_type);
			shipping_num_workstations.setValue(row.shipping.num_workstations === null ? '3' : row.shipping.num_workstations);
			shipping_internet.setValue(row.shipping.internet === null ? '0' : row.shipping.internet);
			shipping_net_type.setValue(row.shipping.net_type);
			shipping_net_domain.setValue(row.shipping.net_domain === null ? '0' : row.shipping.net_domain);
			shipping_net_password.setValue(row.shipping.net_password);
			shipping_net_cable.setValue(row.shipping.net_cable);
			shipping_rooms_ready.setValue(row.shipping.rooms_ready === null ? '1' : row.shipping.rooms_ready);
			shipping_notes.setValue(row.shipping.notes);
			
			if(row.shipping.floor_delivery === '0' || row.shipping.floor_delivery === null){
				floorDeliveryFieldset.addClasses('hidden');
			}
			if(row.shipping.fe_present === '0' || row.shipping.fe_present === null){
				shipping_fe_width.addWrapperClasses('hidden');
				shipping_fe_depth.addWrapperClasses('hidden');
			}
		} else {
			shippingFieldset.addClasses('hidden');
		}
		
		// disable editing if offer is locked
		if(this.data.locked){
			qty.setReadonly();
			cc_bn_num_each.setReadonly();
			cc_color_num_each.setReadonly();
			idLocation.setDisabled();
			shippingTemplateFieldset.addClasses('hidden');
			shipping_ref_office.setReadonly();
			shipping_ref_name.setReadonly();
			shipping_ref_phone.setReadonly();
			shipping_ref_email.setReadonly();
			shipping_delivery_times.setReadonly();
			shipping_floor_delivery.setDisabled();
			shipping_floor_num.setReadonly();
			shipping_fe_present.setDisabled();
			shipping_fe_width.setReadonly();
			shipping_fe_depth.setReadonly();
			shipping_st_num.setReadonly();
			shipping_st_width.setReadonly();
			shipping_st_depth.setReadonly();
			shipping_st_type.setDisabled();
			shipping_num_workstations.setDisabled();
			shipping_internet.setDisabled();
			shipping_net_type.setDisabled();
			shipping_net_domain.setDisabled();
			shipping_net_password.setReadonly();
			shipping_net_cable.setDisabled();
			shipping_rooms_ready.setDisabled();
			shipping_notes.setReadonly();
		}
		
		const rowForm = new Form();
		rowForm
			.setAction('/')
			.addClasses('product-shipping fullwidth label-left');
		rowForm.child = true;
		rowForm.addFields([
			idRow,
			rowActionsFieldset,
			ccFieldset,
			locationFieldset,
			shippingFieldset
		]);
		
		return rowForm;
	}

	//
	// GETTERS
	//
	
	// retrieve customer locations
	async retrieveCustomerLocations(id_customer){
		if(id_customer){
			const loadingTarget = $('.offer-container').attr('id');
			const customerLocationApi = new CustomerLocationApi();
			let customerLocations = await customerLocationApi.setShowAjaxLoader(typeof loadingTarget !== 'undefined').setTargetElement(`#${loadingTarget}`).setCustomerId(parseInt(id_customer)).collection().catch(function(e){
				const errmsg = 'Non è stato possibile recuperare le sedi';
				$(document).trigger('message', ['error', errmsg]);
				throw errmsg;
			});
			this.customerLocations = customerLocations.data;
		}
	}
	
	// refresh customer locations
	refreshCustomerLocations(){
		if(this.customerLocations){
			const _this = this;
			
			// prepare new options
			const locationOpts = [];
			let locationOptsHtml = '';
			const emptyOpt = new OptionField();
			emptyOpt
				.setValue('')
				.setLabel('Seleziona');
			locationOpts.push(emptyOpt);
			locationOptsHtml += emptyOpt.render();
			this.customerLocations.map(function(location){
				const option = new OptionField();
				option
					.setValue(location.id)
					.setLabel(location.name);
				locationOpts.push(option);
				locationOptsHtml += option.render();
			});
			
			// refresh in every OfferRow the location <select>
			$('[name$="id_location"]').each(function(){
				const locationObj = _this.rowLocationSelectFields.find(obj => {
					return obj.name === $(this).attr('id');
				});
				if(typeof locationObj !== 'undefined'){
					locationObj.resetOptions();
					locationObj.addOptions(locationOpts);
					$(this).html(locationOptsHtml).trigger('change');
				}
			});
			
		}
	}
	
	// fill customer location shipping templates
	async fillCustomerLocationShippings(fieldName, id_customer, id_location){
		const shippingObj = this.rowShippingSelectFields.find(obj => {
			return obj.name === fieldName;
		});
		if(typeof shippingObj !== 'undefined'){
			
			// prepare options
			const shippingOpts = [];
			let shippingOptsHtml = '';
			const emptyOpt = new OptionField();
			emptyOpt
				.setValue('')
				.setLabel('Se vuoi precompilare i dati sottostanti, seleziona un template');
			shippingOpts.push(emptyOpt);
			shippingOptsHtml += emptyOpt.render();
			
			// retrieve shipping templates
			if(id_customer && id_location){
				const loadingTarget = $('.offer-container').attr('id');
				const customerLocationShippingApi = new CustomerLocationShippingApi();
				const customerLocationShippings = await customerLocationShippingApi.setShowAjaxLoader(typeof loadingTarget !== 'undefined').setTargetElement(`#${loadingTarget}`).setCustomerId(id_customer).setLocationId(id_location).collection().catch(function(e){
					const errmsg = 'Non è stato possibile recuperare i template di spedizione';
					$(document).trigger('message', ['error', errmsg]);
					throw errmsg;
				});
				customerLocationShippings.data.map(function(shipping){
					const option = new OptionField();
					option
						.setValue(shipping.id)
						.setLabel(shipping.label);
					shippingOpts.push(option);
					shippingOptsHtml += option.render();
				});
			}
			
			// refresh OfferRow location shipping <select>
			shippingObj.resetOptions();
			shippingObj.addOptions(shippingOpts);
			$(`#${fieldName}`).html(shippingOptsHtml);
		}
	}
	
	//
	// FILLERS
	//
	
	// fill customer fieldset
	fillCustomerFieldset(id_customer){
		const selectedCustomer = this.customers.find(x => x.id === parseInt(id_customer));
		if(selectedCustomer){
			const _this = this;
			$('.fieldset-anagrafica input').each(function(idx, input){
				const inputName = $(input).attr('name');
				if(inputName === 'activity'){
					$(input).val(_this.activityLabels[selectedCustomer[inputName]]);
				} else {
					$(input).val(selectedCustomer[inputName]);
				}
			});
		} else {
			$('.fieldset-anagrafica input').each(function(idx, input){
				$(input).val('');
			});
		}
	}
	
	// fill customer location fieldset
	fillCustomerLocationFieldset($container, id_location){
		const selectedCustomerLocation = this.customerLocations.find(x => x.id === parseInt(id_location));
		if(selectedCustomerLocation){
			$('input, textarea, select:not([name$="id_location"])', $container).each(function(){
				const fieldName = $(this).attr('name').replace(/[0-9]+_location_/, '');
				let val = selectedCustomerLocation[fieldName];
				if(fieldName === 'ztl'){
					val = val === '1' ? 'Sì' : 'No';
				} else if(fieldName === 'region'){
					val = selectedCustomerLocation.region_other ? selectedCustomerLocation.region_other : selectedCustomerLocation.region;
				}
				$(this).val(val);
			});
		} else {
			$('input, textarea, select:not([name$="id_location"])', $container).each(function(){
				$(this).val('');
			});
		}
	}
	
	// fill customer location shipping fieldset
	async fillCustomerLocationShippingFieldset($container, id_customer, id_location, id_shipping){
		const customerLocationShippingApi = new CustomerLocationShippingApi();
		const customerLocationShipping = await customerLocationShippingApi.setCustomerId(id_customer).setLocationId(id_location).setShippingId(id_shipping).get().catch(function(e){
			const errmsg = 'Non è stato possibile recuperare il template di spedizione';
			$(document).trigger('message', ['error', errmsg]);
			throw errmsg;
		});
		$('input, textarea, select:not([name$="id_shipping"])', $container).each(function(){
			const fieldName = $(this).attr('name').replace(/[0-9]+_shipping_/, '');
			let val = customerLocationShipping.data[fieldName];
			if(['floor_delivery', 'fe_present', 'net_domain', 'internet', 'rooms_ready'].includes(fieldName)){
				val = val === '1' ? '1' : '0';
			}
			$(this).val(val).trigger('change');
		});
	}
	
	calculateTotalPrice(){
		const finalPriceFee = parseFloat($('[name="fee_final"]').val().replace(',', '.').replace('€ ', ''));
		const finalPriceCcBn = parseFloat($('[name="cc_bn_final"]').val().replace(',', '.').replace('€ ', ''));
		const finalPriceCcColor = parseFloat($('[name="cc_color_final"]').val().replace(',', '.').replace('€ ', ''));
		const totalPriceCcBn = $('[type="hidden"][name="cc_bn_final"]').length ? 0 : finalPriceCcBn * parseFloat($('[name="cc_bn_final"]').closest('.markup-container').find('.field-multiplier').val()) * parseInt(this.data.duration) * parseFloat(this.data.financial_coefficient_best) / parseFloat(this.data.financial_multiplier_best);
		const totalPriceCcColor = $('[type="hidden"][name="cc_color_final"]').length ? 0 : finalPriceCcColor * parseFloat($('[name="cc_color_final"]').closest('.markup-container').find('.field-multiplier').val()) * parseInt(this.data.duration) * parseFloat(this.data.financial_coefficient_best) / parseFloat(this.data.financial_multiplier_best);
		const totalPrice = _.round(finalPriceFee + totalPriceCcBn + totalPriceCcColor, 2);
		$(document).find('.field-total-price').val('€ ' + (isNaN(totalPrice) ? '' : _.round(totalPrice, 2).toString().replace('.', ',')));
	}
	
	//
	// EVENTS
	//

	// custom interactions on fields
	attachInteractions(){
		const _this = this;
		const $context = $(`#${this.multistepForm.name}`);
		
		// disable "Anagrafica e Prodotti" nextButton if no products are selected
		if(!(Array.isArray(this.data.rows) && this.data.rows.length)){
			$(`${this.multistepForm.screens[0].nextButton}`).attr('disabled', true);
		}
		
		// add more products
		$context.on('click', '.btn-addmoreproducts', function(e){
			e.preventDefault();
			_this.afterSaveAction = 'addmoreproducts';
			$('.screen[data-id="0"] .form-actions input[type="submit"]', `#${_this.multistepForm.name}`).trigger('click');
		});
		
		// customer
		$context.on('change', '[name="id_customer"]', async function(){
			_this.fillCustomerFieldset($(this).val());
			await _this.retrieveCustomerLocations($(this).val());
			_this.refreshCustomerLocations();
			$('.fieldset-anagrafica, .fieldset-location', $context).toggleClass('hidden', $(this).val() === '');
		});
		
		// location
		$context.on('change', '[name$="id_location"]', async function(){
			$('.fieldset-location-data', $(this).closest('fieldset')).toggleClass('hidden', $(this).val() === '');
			$('.fieldset-shipping', $(this).closest('.item')).toggleClass('hidden', $(this).val() === '');
			_this.fillCustomerLocationFieldset($(this).closest('fieldset'), $(this).val());
			await _this.fillCustomerLocationShippings($('[name$="id_shipping"]', $(this).closest('.product-shipping')).attr('id'), $('[name="id_customer"]').val(), $(this).val());
			$('[name$="floor_delivery"]', $(this).closest('.product-shipping')).val('0').trigger('change');
			$('[name$="delivery_times"]', $(this).closest('.product-shipping')).val($(this).closest('.product-shipping').find('[name$="opening_time"]').val());
		});
		
		// location shipping
		$context.on('change', '[name$="id_shipping"]', async function(){
			const $form = $(this).closest('.product-shipping');
			const selectedShippingTemplateId = $(this).val();
			if(selectedShippingTemplateId !== ''){
				await _this.fillCustomerLocationShippingFieldset($('.fieldset-shipping', $form), $('[name="id_customer"]').val(), $('[name$="id_location"]', $form).val(), selectedShippingTemplateId);
			}
		});
		
		// floor delivery
		$context.on('change', '[name$="floor_delivery"]', function(){
			let $fieldset = $(this).closest('fieldset');
			$('.fieldset-floor-delivery', $fieldset).toggleClass('hidden', $(this).val() === '0');
			if($(this).val() === '0'){
				$('[name$="fe_present"]', $fieldset).val('0').trigger('change');
			}
		});
		
		// elevator
		$context.on('change', '[name$="fe_present"]', function(){
			$('[name$="fe_width"], [name$="fe_depth"]', $(this).closest('fieldset')).closest('.formfield').toggleClass('hidden', $(this).val() === '0');
		});
		
		// takeover
		$context.on('change', '[name="takeover"]', function(){
			$('[name="takeover_notes"], [name="takeover_price"]', $(this).closest('form')).closest('.formfield').toggleClass('hidden', $(this).val() === '0');
			if($(this).val() === '0'){
				$('[name="takeover_notes"]', $(this).closest('form')).val('');
				$('[name="takeover_price"]', $(this).closest('form')).val('€ ');
			}
		});
		
		// pickup
		$context.on('change', '[name="pickup"]', function(){
			$('[name="pickup_notes"], [name="pickup_price"], [name="pickup_num_a3"], [name="pickup_num_a4"], [name="pickup_location"], [name="pickup_floor"]', $(this).closest('form')).closest('.formfield').toggleClass('hidden', $(this).val() === '0');
			if($(this).val() === '0'){
				$('[name="pickup_notes"], [name="pickup_location"], [name="pickup_floor"]', $(this).closest('form')).val('');
				$('[name="pickup_num_a3"], [name="pickup_num_a4"]', $(this).closest('form')).val(0);
				$('[name="pickup_price"]', $(this).closest('form')).val('€ ');
			}
		});
		
		// collapsible fieldsets
		$context.on('click', 'fieldset.collapsible > .fieldsettitle', function(){
			$(this).closest('.collapsible').toggleClass('collapsed');
		});
		
		// delete row
		$context.on('click', '.btn-deleterow', async function(e){
			e.preventDefault();
			const $accordionItem = $(this).closest('li.item');
			const rowId = $(this).closest('.product-shipping').find('input[name$="id_row"]').val(); // get row id
			const offerRowApi = new OfferRowApi();
			await offerRowApi.setOfferId(parseInt(_this.data.id)).delete(rowId) // delete row
				.then(() => {
					$(document).trigger('message', ['success', 'Prodotto rimosso dall’offerta']);
					_this.refresh();
				})
				.catch((e) => {
					$(document).trigger('message', ['error', e.responseJSON.error]);
				});
		});
		
		// markups
		$context.on('change keyup', '.field-markup', function(e){
			const $container = $(this).closest('.markup-container');
			const startingPrice = parseFloat($('.field-starting-price', $container).val().replace(',', '.').replace('€ ', ''));
			const markupPercentage = parseFloat($(this).val().replace(',', '.').replace(' %', ''));
			const finalPrice = _.round(startingPrice + startingPrice * markupPercentage / 100, $('.field-final-price', $container).hasClass('precision4') ? 4 : 2);
			$('.field-final-price', $container).val('€ ' + (isNaN(finalPrice) ? '' : _.round(finalPrice, $('.field-final-price', $container).hasClass('precision4') ? 4 : 2).toString().replace('.', ',')));
			
			const finalPriceObj = _this.fieldsToRevalidate.find(obj => {
				return obj.name === $('.field-final-price', $container).attr('id');
			});
			if(typeof finalPriceObj !== 'undefined'){
				finalPriceObj.validate();
			}
			
			_this.calculateTotalPrice();
		});
		$context.on('change keyup', '.field-final-price', function(e){
			const $container = $(this).closest('.markup-container');
			const startingPrice = parseFloat($('.field-starting-price', $container).val().replace(',', '.').replace('€ ', ''));
			const finalPrice = parseFloat($(this).val().replace(',', '.').replace('€ ', ''));
			const markupPercentage = _.round(Math.max(((finalPrice - startingPrice) / startingPrice) * 100, 0), 2);
			$('.field-markup', $container).val((isNaN(markupPercentage) ? '' : _.round(markupPercentage, 2).toString().replace('.', ',')) + ' %');
			_this.calculateTotalPrice();
		});
	}

	//
	// MANAGE FORM SUBMISSION
	//

	// get screen data
	getStepData($step){
		
		const data = {};
		
		$('input', $step).each(function(){
			data[$(this).attr('name')] = $(this).val();
		});
		
	}
	
}
