const $ = window.jQuery;

import _ from 'lodash';
import Page from "./Page";
import SingleProductApi from "../api/SingleProductApi";
import AccessoryPositionApi from "../api/AccessoryPositionApi";
import ScarySelect2 from "../components/Plugins/ScarySelect2";
import OptionField from "../../../core/js/components/Form/OptionField";
import Storage from '../../../core/js/utils/Storage';
import Footer from "../../../core/js/components/Form/Footer";
import FinancialTypeApi from "../api/FinancialTypeApi";

export default class SingleProductPage extends Page {

  constructor() {
    super();
    this.accessoriesTop = undefined;
    this.accessoriesInner = undefined;
    this.accessoriesRight = undefined;
    this.accessoriesBottom = undefined;
  }

  async init() {
    //super.init();

    this
      .setTitle('Prodotto')
      .setMeta('description', 'Prodotto')
      .setBreadcrumb([
        { label: 'Catalogo', href: '/catalog/' + DEFAULT_CATEGORY },
        'Prodotto'
      ]);

    return super.init();
  }

  async content(product_id) {

    const stApi = new Storage();
	
	$('#scary').addClass('loading'); // handle loading
	
    const singleProductApi = new SingleProductApi();
    const response = await singleProductApi.setShowAjaxLoader(false).get(product_id);
	if (response.data) {
		
      let datiProd = response.data[0];
	  
	  const prezzoBase = parseFloat(datiProd.base_price_format);
	  
    const financialTypeApi = new FinancialTypeApi();
    const financialTypeData1 = await financialTypeApi.setShowAjaxLoader(false).setType('best').setPrice('1001').setDuration(60).get();
    const financialTypeData2 = await financialTypeApi.setShowAjaxLoader(false).setType('best').setPrice('2501').setDuration(60).get();
    const financialTypeData3 = await financialTypeApi.setShowAjaxLoader(false).setType('best').setPrice('10001').setDuration(60).get();
    const financialTypeData4 = await financialTypeApi.setShowAjaxLoader(false).setType('best').setPrice('25001').setDuration(60).get();
    const financialTypeData5 = await financialTypeApi.setShowAjaxLoader(false).setType('best').setPrice('50001').setDuration(60).get();
	
	let coefficient, multiplier;
	if(prezzoBase > 50000){
		coefficient = financialTypeData5.data.coefficient;
		multiplier = financialTypeData5.data.multiplier;
	} else if(prezzoBase >= 25000){
		coefficient = financialTypeData4.data.coefficient;
		multiplier = financialTypeData4.data.multiplier;
	} else if(prezzoBase >= 10000){
		coefficient = financialTypeData3.data.coefficient;
		multiplier = financialTypeData3.data.multiplier;
	} else if(prezzoBase >= 2500){
		coefficient = financialTypeData2.data.coefficient;
		multiplier = financialTypeData2.data.multiplier;
	} else {
		coefficient = financialTypeData1.data.coefficient;
		multiplier = financialTypeData1.data.multiplier;
	}
	coefficient = parseFloat(coefficient);
	multiplier = parseFloat(multiplier);

    //INIZIO - accessories - inner position
      //console.log("inizio - loadInnerAccessories");

      //console.log("idProduct: " + idProductInner);
      //console.log("Position: " + positionInner);

      const accessoryPositionApiInner = new AccessoryPositionApi();

      this.accessoriesInner = await accessoryPositionApiInner.setShowAjaxLoader(false).accessories(product_id, 'inner').catch(function (e) {
          console.log("funzione errore");
          const errmsg = 'Errore nello scaricare i gli accessori in posizione inner';
          $(document).trigger('message', [ 'error', errmsg ]);
              throw errmsg;
      });

      //console.log("dataAI Inner:" + this.accessoriesInner.data);

      this.accessoriesInner = this.accessoriesInner.data;

      //console.log("accessoriesInner:" + this.accessoriesInner);

      //console.log("fine - loadInnerAccessories");
    //FINE - accessories - inner position

    const cmbAccComunicazione = this.getComunizioneAcc(coefficient, multiplier, stApi);
    //const cmbAccAlto = this.getAlimentazioniOriginaliAcc();
    //const cmbAccDestro = this.getRightAccessories();

    //INIZIO - accessories - inner position
      //console.log("inizio - loadBottomAccessories");

      //console.log("idProduct: " + idProductBottom);
      //console.log("Position: " + positionBottom);

      const accessoryPositionApiBottom = new AccessoryPositionApi();

      this.accessoriesBottom = await accessoryPositionApiBottom.setShowAjaxLoader(false).accessories(product_id, 'bottom').catch(function (e) {
          console.log("funzione errore");
          const errmsg = 'Errore nello scaricare i gli accessori in posizione bottom';
          $(document).trigger('message', [ 'error', errmsg ]);
              throw errmsg;
      });

      //console.log("dataAI Bottom:" + this.accessoriesBottom.data);

      this.accessoriesBottom = this.accessoriesBottom.data;

      //console.log("accessoriesBottom:" + this.accessoriesBottom);

      //console.log("fine - loadBottomAccessories");
    //FINE - accessories - inner position

    const cmbAccAlimentazioneCarta = this.getAlimentazioneCartaAcc(coefficient, multiplier, stApi);

    //INIZIO - accessories - inner position
      //console.log("inizio - loadTopAccessories");

      //console.log("idProduct: " + idProductTop);
      //console.log("Position: " + positionTop);

      const accessoryPositionApiTop = new AccessoryPositionApi();

      this.accessoriesTop= await accessoryPositionApiTop.setShowAjaxLoader(false).accessories(product_id, 'top').catch(function (e) {
          console.log("funzione errore");
          const errmsg = 'Errore nello scaricare i gli accessori in posizione top';
          $(document).trigger('message', [ 'error', errmsg ]);
              throw errmsg;
      });

      //console.log("dataAI Top:" + this.accessoriesTop.data);

      this.accessoriesTop = this.accessoriesTop.data;

      //console.log("accessoriesTop:" + this.accessoriesTop);

      //console.log("fine - loadTopAccessories");
    //FINE - accessories - inner position

    const cmbAccAlimentazioniOriginali = this.getAlimentazioniOriginaliAcc(coefficient, multiplier, stApi);

    //INIZIO - accessories - inner position
      //console.log("inizio - loadRightAccessories");

      //console.log("idProduct: " + idProductRight);
      //console.log("Position: " + positionRight);

      const accessoryPositionApiRight = new AccessoryPositionApi();

      this.accessoriesRight = await accessoryPositionApiRight.setShowAjaxLoader(false).accessories(product_id, 'right').catch(function (e) {
          console.log("funzione errore");
          const errmsg = 'Errore nello scaricare i gli accessori in posizione right';
          $(document).trigger('message', [ 'error', errmsg ]);
              throw errmsg;
      });

      //console.log("dataAI Right:" + this.accessoriesRight.data);

      this.accessoriesRight = this.accessoriesRight.data;

      //console.log("accessoriesRight:" + this.accessoriesRight);

      //console.log("fine - loadRightAccessories");
    //FINE - accessories - inner position

    const cmbAccFinitura = this.getFinituraAcc(coefficient, multiplier, stApi);

      //INIZIO HTML
	  
      let lblPathImgUrl = "";
      if(datiProd.image == null){
          lblPathImgUrl = "/assets/images/no-image.png";
      }
      else{
          lblPathImgUrl = datiProd.image.fileurl;
      }

      let productHtml = "<div class='prodottoPr'>";
      productHtml += "<div class='imgPr'>";
      productHtml += "<img src='"+lblPathImgUrl+"' />";

      //console.log("id_datasheet" + datiProd.id_datasheet);
      //console.log("id_datasheet" + datiProd.datasheet.fileurl);

      if(datiProd.id_datasheet != null){
        //console.log("dentro if datasheet");
        productHtml += "<div class='schedaTecnicaPr'>";
        productHtml += "<div class='imagePr'>";
        productHtml += "<a href='"+datiProd.datasheet.fileurl+"' target='_blank' title='Vedi Scheda Tecnica Prodotto'>";
        productHtml += "<img alt='Vedi Scheda Tecnica Prodotto' src='/assets/images/download-pdf.png' /></a></div>";
        productHtml += "<div class='testoPr'>";
        productHtml += "<a href='"+datiProd.datasheet.fileurl+"' target='_blank' title='Vedi Scheda Tecnica Prodotto'>";
        productHtml += "<span class='lblSchedaTecnicaPr'>SCHEDA TECNICA</span>";
        productHtml += "</a>";
        productHtml += "</div>";
        productHtml += "</div>";
      }

      productHtml += "</div>";
      productHtml += "<div class='infoPr'>";
      productHtml += "<div class='brandPr'>" + datiProd.brand + "</div>";
      productHtml += "<div class='titoloPr'>" + datiProd.name + "</div>";

      let lblAvailable = "";
      if(datiProd.available == '1') {
        lblAvailable = "&nbsp;<img src='/assets/images/si.png' width='15px' height='15px'>";
        lblAvailable += "&nbsp;&nbsp;<span style='color:green'>Disponibile</span>";
      }
      if(datiProd.available == '0') {
        lblAvailable = "&nbsp;<img src='/assets/images/no.png' width='15px' height='15px'>";
        lblAvailable += "&nbsp;&nbsp;<span style='color:red'>Non Disponibile</span>";
      }

      let lblType = "";
      if(datiProd.type == "MFP")  lblType = "Multifunzione (MFP)";
      if(datiProd.type == "PRT")  lblType = "Stampante (PRT)";

      productHtml += "<div class='dispoPr'>" + lblAvailable + "</div>";
      productHtml += "<div class='skuPr'>" + datiProd.artcode_erp + "</div>";
      productHtml += "<div class='tipoPr'>" + lblType + "</div>";
      productHtml += "<div class='formatoPr'>" + datiProd.format + "</div>";
      productHtml += "<div class='colorePr'>" + datiProd.color + "</div>";
      //productHtml += "<div class='pesoPr'>" + datiProd.weight_format + "&nbsp;kg</div>";

      productHtml += "<div><b>CC Prezzo Vs BN:</b> " + datiProd.cc_bn_base_price + "&nbsp;&euro;</div>";
      productHtml += "<div><b>CC Prezzo Vs Colore:</b> " + datiProd.cc_color_base_price + "&nbsp;&euro;</div>";
      productHtml += "<div><b>Copie Max BN:</b> " + datiProd.cc_max_bn + "</div>";
      productHtml += "<div><b>Copie Max Colore:</b> " + datiProd.cc_max_color + "</div>";
      productHtml += "<div><b>Copie BN Suggerite:</b> " + datiProd.cc_suggested_bn + "</div>";
      productHtml += "<div style='margin-bottom: 20px;'><b>Copie Colori Suggerite:</b> " + datiProd.cc_suggested_color + "</div>";

      productHtml += "<div class='bloccoPr1'><hr class='hrBloccoPr1'></div>";
      productHtml += "<div class='desPr'>" + datiProd.description + "</div>";
      productHtml += "<div class='bloccoPr2'>&nbsp;</div>";
      productHtml += "<div class='accessoriPr'>";

      if(datiProd.type != "PRT") productHtml += "<div class='topPr'><br>" + cmbAccAlimentazioniOriginali.render() + "</div>";

      productHtml += "<div class='latoSinistroPr'><br>"+ cmbAccFinitura.render() + "</div>";
      productHtml += "<div class='latoDestroPr'><br>" + cmbAccComunicazione.render() +  "</div>";
      productHtml += "<div class='basePr'><br>" + cmbAccAlimentazioneCarta.render() + "</div>";
      productHtml += "</div>";
      productHtml += "<div class='bloccoPr3'>&nbsp;</div>";

      const prezzoCatalogo = _.round(prezzoBase * coefficient / multiplier, 2).toString().replace('.', ',');

      if(stApi.getData('privacy-price-agent') === "off"){
        productHtml += "<div class='prezzoPr'>" + prezzoCatalogo + "&nbsp;&euro;</div>";
      }
      
      productHtml += "<div class='offertaPr'>";
      productHtml += "<div class='qtaPr'>";
      productHtml += "<input id='qta' type='text' value='1'>";
      /*productHtml += "<a style='cursor:pointer' onclick='document.getElementById(\"qta\").value = parseInt(document.getElementById(\"qta\").value) - 1;'><img class='menoPr' src='/assets/images/meno.png' /><a/>";
      productHtml += "<a style='cursor:pointer' onclick='document.getElementById(\"qta\").value = parseInt(document.getElementById(\"qta\").value) + 1;'><img class='piuPr' src='/assets/images/piu.png' /><a/>";*/
      productHtml += "<img style='cursor:pointer' class='menoPr' src='/assets/images/meno.png' />";
      productHtml += "<img style='cursor:pointer' class='piuPr' src='/assets/images/piu.png' />";
      productHtml += "</div>";
      productHtml += "<div class='aggiungiPr'>";
      productHtml += "<div class='bottonePr'>";
      productHtml += "<img class='imgOfferta' src='/assets/images/offer.svg'>";
      productHtml += "<span class='btnAggiungiOfferta'>Aggiungi nuovo prodotto</span>";
      productHtml += "</div>";
      productHtml += "</div>";
      productHtml += "</div>";

      productHtml += "<div class='offertaPrDis'>";
      productHtml += "<div class='qtaPrDis'>";
      productHtml += "<input id='qta' type='text' value='1' disabled><img class='menoPrDis' src='/assets/images/meno.png' /><img class='piuPrDis' src='/assets/images/piu.png' />";
      productHtml += "</div>";
      productHtml += "<div class='aggiungiPrDis'>";
      productHtml += "<div class='bottonePrDis'>";
      productHtml += "<img class='imgOfferta' src='/assets/images/offer.svg'>";
      productHtml += "<span class='btnAggiungiOfferta'>Aggiungi nuovo prodotto</span>";
      productHtml += "</div>";
      productHtml += "</div>";
      productHtml += "</div>";

      productHtml += "</div>";
      productHtml += "</div>";
      productHtml += "</div>";

      //FINE HTML

      let isSelValAccTop = false;
      let isSelValAccInner = false;
      let isSelValAccRight = false;
      let isSelValAccBottom = false;

      let dispo = datiProd.available;
      //console.log("dispo: " + dispo);
      
      /*const pageView = new ProductView(response.data);
      pageView.setData(response.data);
	  $('main .main').html(pageView.render() + htmlAccessoriDinamnici);*/
      
      $('main .main').html(productHtml + Footer.render(true));
      Footer.activate(this);

      $('.menoPr').off('click').on('click', function(){
        //console.log("qta: " + parseInt($('#qta').val()));
        let qta = parseInt($('#qta').val());
        if(qta != 0){
          $('#qta').val(qta - 1); 
        }
        //console.log("qta: " + parseInt($('#qta').val()));
      });

      $('.piuPr').off('click').on('click', function(){
        //console.log("qta: " + parseInt($('#qta').val()));
        let qta = parseInt($('#qta').val());
        $('#qta').val(qta + 1);
        //console.log("qta: " + parseInt($('#qta').val()));
      });
	  
	  const accessoriesPositions = datiProd.type === 'PRT' ? ['inner', 'right', 'bottom'] : ['top', 'inner', 'right', 'bottom'];

      $('.aggiungiPr .bottonePr').off('click').on('click', function(){
        const product = {
          id: datiProd.id,
          configurations: []
        };
        accessoriesPositions.forEach((position) => {
          const accessoryId = $(`.select-accessory-${position}`).val();
          if(accessoryId !== '' && accessoryId !== '0'){
            product.configurations.push({
              code: position,
              productId: accessoryId
            });
          }
        });
        const qta = $('#qta').val();
            $(document).trigger('offer-addtocart', [product, qta]);
      });

      
      if(dispo == "0"){
        nascondiQtaEOffertaBtn();
      }
      else{
        vediQtaEOffertaBtn();
      }
      
      $('.select-accessory-top').on('change', function(){
        let valTop = $(this).val();
        if(valTop != ""){
          isSelValAccTop = true;
        }
        else{
          isSelValAccTop = false;
        }

        /*if(isSelValAccTop && isSelValAccInner && isSelValAccRight && isSelValAccBottom && dispo != "0"){
          vediQtaEOffertaBtn();
        }
        else{
          nascondiQtaEOffertaBtn();
        }*/

        if(dispo == "0"){
          nascondiQtaEOffertaBtn();
        }

      });

      $('.select-accessory-inner').on('change', function(){
        let valInner = $(this).val();
        if(valInner != ""){
          isSelValAccInner = true;
        }
        else{
          isSelValAccInner = false;
        }

        /*if(isSelValAccTop && isSelValAccInner && isSelValAccRight && isSelValAccBottom && dispo != "0"){
          vediQtaEOffertaBtn();
        }
        else{
          nascondiQtaEOffertaBtn();
        }*/

        if(dispo == "0"){
          nascondiQtaEOffertaBtn();
        }
      });

      $('.select-accessory-right').on('change', function(){
        let valRight = $(this).val();
        if(valRight != ""){
          isSelValAccRight = true;
        }
        else{
          isSelValAccRight = false;
        }

        /*if(isSelValAccTop && isSelValAccInner && isSelValAccRight && isSelValAccBottom && dispo != "0"){
          vediQtaEOffertaBtn();
        }
        else{
          nascondiQtaEOffertaBtn();
        }*/

        if(dispo == "0"){
          nascondiQtaEOffertaBtn();
        }
      });

      $('.select-accessory-bottom').on('change', function(){
        let valBottom = $(this).val();
        if(valBottom != ""){
          isSelValAccBottom = true;
        }
        else{
          isSelValAccBottom = false;
        }

        /*if(isSelValAccTop && isSelValAccInner && isSelValAccRight && isSelValAccBottom && dispo != "0"){
          vediQtaEOffertaBtn();
        }
        else{
          nascondiQtaEOffertaBtn();
        }*/

        if(dispo == "0"){
          nascondiQtaEOffertaBtn();
        }
      });

    } else {
      $('#scary').removeClass('loading'); // handle loading
      return;
    }
	
    $('#scary').removeClass('loading'); // handle loading

    function vediQtaEOffertaBtn(){
        $('.offertaPr').show();
        $('.offertaPr').css('display', 'flex');
        $('.offertaPrDis').hide();
    }

    function nascondiQtaEOffertaBtn(){
        $('.offertaPr').hide();
        $('.offertaPrDis').show();
        $('.offertaPrDis').css('display', 'flex');
    }
    
  }

  getAlimentazioniOriginaliAcc(coefficient, multiplier, stApi) {
    const topAccSelect = new ScarySelect2();
    topAccSelect
        .setFieldName('id')
        .setRequired(true)
        .setLabel('Alimentazione Originali')
        .addClasses('select-accessory-top')
        .setMultiple(false);
    const emptyopt = new OptionField();
    emptyopt
        .setValue('0')
        .setLabel('Alimentazione di Base');
    const noopt = new OptionField();
    /*noopt
        .setValue('0')
        .setLabel('Nesssun Accessorio');*/

    topAccSelect.addOptions(emptyopt);
    const options = [];


    if(this.accessoriesTop != undefined){
      this.accessoriesTop.map(function (accTop) {
          
        const prezzoFinaleTop = _.round(parseFloat(accTop.base_price) * coefficient / multiplier, 2).toString().replace('.', ',');

        let lblTopPrice = '';
        if(stApi.getData('privacy-price-agent') === "off"){
          lblTopPrice = ' (a partire da '+prezzoFinaleTop+' &euro;)';
        }
        
        const option = new OptionField();
          option
          .setValue(accTop.id)
          .setLabel(accTop.name + lblTopPrice);
          /*if (brand.id === defbrand) {
              option.setDefault(true);
          }*/

          options.push(option);
      });
    }

    topAccSelect.addOptions(options);

    //topAccSelect.addOptions(noopt);

    return topAccSelect;
  }  

  getComunizioneAcc(coefficient, multiplier, stApi) {
    const innerAccSelect = new ScarySelect2();
    innerAccSelect
        .setFieldName('id')
        .setRequired(true)
        .setLabel('Comunicazione')
        .addClasses('select-accessory-inner')
        .setMultiple(false);
    const emptyopt = new OptionField();
    emptyopt
        .setValue('0')
        .setLabel('Nessun Accessorio');
    const noopt = new OptionField();
    /*noopt
        .setValue('0')
        .setLabel('Nesssun Accessorio');*/

    innerAccSelect.addOptions(emptyopt);
    const options = [];

    if(this.accessoriesInner != undefined){
      this.accessoriesInner.map(function (accInn) {
        
        const prezzoFinaleInn = _.round(parseFloat(accInn.base_price) * parseFloat(coefficient) / parseFloat(multiplier), 2).toString().replace('.', ',');

        let lblInnPrice = '';
        if(stApi.getData('privacy-price-agent') === "off"){
          lblInnPrice = ' (a partire da '+prezzoFinaleInn+' &euro;)';
        }
        
        const option = new OptionField();
          option
          .setValue(accInn.id)
          .setLabel(accInn.name + lblInnPrice);
          /*if (brand.id === defbrand) {
              option.setDefault(true);
          }*/

          options.push(option);
      });
    }

    innerAccSelect.addOptions(options);

    //innerAccSelect.addOptions(noopt);

    return innerAccSelect;
  } 

  getFinituraAcc(coefficient, multiplier, stApi) {
    const rightAccSelect = new ScarySelect2();
    rightAccSelect
        .setFieldName('id')
        .setRequired(true)
        .setLabel('Finitura')
        .addClasses('select-accessory-right')
        .setMultiple(false);
    const emptyopt = new OptionField();
    emptyopt
        .setValue('0')
        .setLabel('Nessun Accessorio');
    /*const noopt = new OptionField();
    noopt
        .setValue('0')
        .setLabel('Nesssun Accessorio');*/

    rightAccSelect.addOptions(emptyopt);
    const options = [];

    if(this.accessoriesRight != undefined){
      this.accessoriesRight.map(function (accRig) {
        
        const prezzoFinaleRig = _.round(parseFloat(accRig.base_price) * coefficient / multiplier, 2).toString().replace('.', ',');

        let lblRigPrice = '';
        if(stApi.getData('privacy-price-agent') === "off"){
          lblRigPrice = ' (a partire da '+prezzoFinaleRig+' &euro;)';
        }
        
        const option = new OptionField();
          option
          .setValue(accRig.id)
          .setLabel(accRig.name + lblRigPrice);
          /*if (brand.id === defbrand) {
              option.setDefault(true);
          }*/

          options.push(option);
      });
    }

    rightAccSelect.addOptions(options);

    //rightAccSelect.addOptions(noopt);

    return rightAccSelect;
  }  

  getAlimentazioneCartaAcc(coefficient, multiplier, stApi) {
    const bottomAccSelect = new ScarySelect2();
    bottomAccSelect
        .setFieldName('id')
        .setRequired(true)
        .setLabel('Alimentazione Carta')
        .addClasses('select-accessory-bottom')
        .setMultiple(false);
    const emptyopt = new OptionField();
    emptyopt
        .setValue('0')
        .setLabel('Alimentazione di Base');
    /*const noopt = new OptionField();
    noopt
        .setValue('0')
        .setLabel('Nesssun Accessorio');*/

    bottomAccSelect.addOptions(emptyopt);
    const options = [];

    if(this.accessoriesBottom != undefined){
      this.accessoriesBottom.map(function (accBot) {
          
        const prezzoFinaleBot = _.round(parseFloat(accBot.base_price) * coefficient / multiplier, 2).toString().replace('.', ',');

        let lblBotPrice = '';
        if(stApi.getData('privacy-price-agent') === "off"){
          lblBotPrice = ' (a partire da '+prezzoFinaleBot+' &euro;)';
        }
        
        const option = new OptionField();
          option
          .setValue(accBot.id)
          .setLabel(accBot.name + lblBotPrice);
          /*if (brand.id === defbrand) {
              option.setDefault(true);
          }*/

          options.push(option);
      });
    }
    

    bottomAccSelect.addOptions(options);

    //bottomAccSelect.addOptions(noopt);

    return bottomAccSelect;
  } 

}
