const $ = window.jQuery;

import Component from '../../../../core/js/components/Component';

export default class BasicGridItem extends Component {
	
	constructor() {
		super();
		this.name = this.getUniqId();
		this.field_name = '';
		
		this.title = false;
		this.content = false;
	}
	
	setTitle(title){
		this.title = title;
		
		return this;
	}
	
	setContent(content){
		this.content = content;
		
		return this;
	}
	
	render() {
		const title = this.title ? `<div class="basicgriditem-title">${this.title}</div>` : '';
		const content = this.content ? ((typeof this.content === 'object' && typeof this.content.render === 'function') ? this.content.render() : this.content) : '';
		
		return `<li id="${this.name}" class="basicgriditem ${this.classes.join(' ')}">
			${title}
			${content}
		</li>`;
	}
	
}
