const $ = window.jQuery;

import _ from "lodash";

import Page from "./Page";
import ActionsMenu from "../components/ActionsMenu/ActionsMenu";
import ActionsMenuItem from "../components/ActionsMenu/ActionsMenuItem";
import ScaryDataTable from "../components/Plugins/ScaryDataTable";
import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
import ProductAccessoryApi from "../api/ProductAccessoryApi";
import ImageField from "../../../core/js/components/Form/ImageField";
import AccessoryProductApi from "../api/AccessoryProductApi";
import Footer from "../../../core/js/components/Form/Footer";

export default class AccessoriesProductPage extends Page {

  constructor() {
    super();
  }

  async init(product_id) {

	let productName = await this.getProductName(product_id);

	this.setTitle('Accessori Prodotto')
      .setMeta('description', 'Accessori Prodotto')
	  .setBreadcrumb([
        { label: 'Prodotti', href: '/products'},{ label: productName, href: '/product/manage/'+product_id },
        'Gestione Accessori'
      ]);

	return super.init(product_id);
  }

  async content(product_id) {
	
	const _page = this;
	
	//
	// actions menu
	//

    const actions_menu = new ActionsMenu();
	actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-product-accessories']);
	actions_menu.addUlClasses('actions-menu');
	
	const btn_view = new ActionsMenuItem();
	btn_view
		.addClasses([ 'element', 'only-icon', 'visibility' ])
		.setTitle('Vedi Tutti gli Accessori')
		.setOn('click', () => $.spa.navigate('/product/'+product_id+'/accessories', true))
		.setRoles('RD_ADMIN_USERS');
		
	/*const btn_edit = new ActionsMenuItem();
	btn_edit
		.addClasses([ 'element', 'only-icon', 'edit' ])
		.setTitle('Modifica')
		.setOn('click', () => $.spa.navigate('/product/manage/'+product_id+'/accessory/manage/create', true))
		.setRoles('RD_ADMIN_USERS');
		
	const btn_delete = new ActionsMenuItem();
	btn_delete
		.addClasses([ 'element', 'only-icon', 'delete', 'disabled' ])
		.setTitle('Elimina')
		.setRoles('RD_ADMIN_USERS');*/
		
	/*actions_menu.addItems([
		btn_add,
		btn_edit,
		btn_delete
	]);*/

	/*actions_menu.addItems([
		btn_view
	]);*/

	//
	// locations table
	//
	
	// $.confirm delete
	/*const confirmDelete = new ScaryJqueryConfirm();
	confirmDelete
		.setTitle('Attenzione')
		.setContent('Sei sicuro di voler cancellare l\'accessorio del prodotto?');*/

	// quick-open dt row on double-click
	/*$(document).on('dblclick', '.dt-product-accessories tbody tr', function(){
		let targetId = parseInt($(this).closest('tr').find('td').first().text());
		$.spa.navigate(`/product/manage/${product_id}/accessory/manage/${targetId}`, true);
	});*/

	//$(document).on('click','.select-checkbox',async function(event){
	//$(document).on('change','input.associated',async function(event){
	$(document).one('click','input.associated',async function(event){

		let targetId = parseInt($(this).closest("tr").find('td:eq(1)').text());

		let id_product1 = product_id;
		let id_product2 = targetId;
		let azione = 'delete';

		//console.log("product - id_product1: " + id_product1);
		//console.log("product - id_product2: " + id_product2);
		//console.log("product - azione: " + azione);

		let values = {id_product1, id_product2, azione };

		const accessoryProductApi = new AccessoryProductApi();
		const res = await accessoryProductApi.post(values);

		let msg = res.data;
		let status_code = res.statusCode;

		if(status_code == "200"){
			//$(document).trigger('message', [ 'success', msg ]);
			
			//prova test 2023-05-31 bf
			$(document).stop();
		}
		else{
			//$(document).trigger('message', [ 'error', msg ]);
		}

		//prova test 2023-05-31 bf
		dt_accessories.refresh();
		
		//prova test 2023-05-31 bf
		_page.refresh();

	});
		
	// handle dt_locations select/deselect
	const dt_accessories_onSelectDeselect = function(e, dt, type, indexes){
		
		// check for selected rows
		let targetIds = [];
		if (type === 'row') {
			dt.rows({selected: true}).every(function(idx){
				const targetId = $(dt.row(idx).node()).attr('data-id');
				if(typeof targetId !== 'undefined'){
					targetIds.push(parseInt(targetId));
				}
			});
		}
		
		$(`#${btn_view.name}`)
			.off('click')
			.toggleClass('disabled', targetIds.length === 0);
		
		if(targetIds.length > 0){

			$(`#${btn_view.name}`).on('click', () => $.spa.navigate('/product/'+product_id+'/accessories', true));
			/*$(`#${btn_delete.name}`).on('click', () => {
				confirmDelete
					.modalConfirm(async (data) => {
						const productAccessoryApi = new ProductAccessoryApi();
						await productAccessoryApi.delete(targetIds[0])
							.then(() => {
								$.spa.navigate('/product/manage/'+product_id+'/accessories', true);
							})
							.catch((e) => {
								$(document).trigger('message', [ 'error', e.responseJSON.error ]);
							});
					})
					.render();
			});*/
		}
		
	};
	const debounced_dt_accessories_onSelectDeselect = _.debounce(dt_accessories_onSelectDeselect, 100);

	const dt_accessories = new ScaryDataTable();
	dt_accessories
		.setUrl('/accessoryProduct/'+product_id) //si vedono tutti gli accessori associati al prodotto
		.setSelect('single')
		.setOnSelect(debounced_dt_accessories_onSelectDeselect)
		.setOnDeselect(debounced_dt_accessories_onSelectDeselect)
		.setRowCallback(function(row, data){
			dt_accessories.defaultRowCallback(row, data);
			$('input.associated', row).prop('checked', data.associated == 1);

			if ( data.available == 0 ) {
				$('.lblDispo', row).html( '<img src=\'/assets/images/no.png\'>' );
			}
			if ( data.available == 1 ) {
				$('.lblDispo', row).html( '<img src=\'/assets/images/si.png\'>' );
			}
		})
		.setColumnDefs([
			{orderable: false, targets: 0},
			{targets: 9, className: "lblDispo"},
			{targets: 7, data: "description", render: function ( data, type, row, meta ) {
				return type === 'display' && data.length > 20 ?
				  '<span title="'+data+'">'+data.substr( 0, 20 )+'...</span>' :
				  data;
			  }
		  	}
		])
		.setOrder([
			[1, 'asc']
		])
		.addField('associated', 'Associato', {
			render: function(){
				return '<input type="checkbox" class="associated">';
			}
		})
		.addField('id', 'ID')
		.addField('cat', 'Catalogo')
		.addField('brand', 'Marchio')
		.addField('name', 'Nome')
		.addField('artcode_erp', 'Codice ERP')
		//.addField('type', 'Tipologia')
		.addField('description', 'Descrizione')
		.addField('posdes', 'Posizione')
		.addField('weight_format', 'Peso Kg')
		.addField('available', 'Disponibile')
		.addField('base_price_format', 'Prezzo Vs €')
		/*.addField('transfer_price', 'Prezzo Vendita')
		.addField('cc_bn_base_price', 'Cc BN Prezzo Base')
		.addField('cc_bn_transfer_price', 'Cc BN Prezzo Vendita')
		.addField('cc_color_base_price', 'Cc ColorePrezzo Base')
		.addField('cc_color_transfer_price', 'Cc Colore Prezzo Vendita')
		.addField('cc_light_base_price', 'Cc Prezzo Base Leggero')
		.addField('cc_light_transfer_price', 'Cc Prezzo Vendita Leggero')
		.addField('cc_max_bn', 'Cc Max Copie BN')
		.addField('cc_max_color', 'Cc Max Copie Colore')
		.addField('cc_suggested_bn', 'Cc Copie BN Suggerite')
		.addField('cc_suggested_color', 'Cc Copie Colori Suggerite')*/
		.addField('sort', 'Ordine Cat. Prod.')
		//.setResponsivePriority('id', 0)
		//.setResponsivePriority('select-checkbox',0)
		.setResponsivePriority('name', 0)
		.setResponsivePriority('artcode_erp', 1)
		.setPageLen(100)
		.setLenChanghe(false)
		.setNumPag(false)
		.addClasses('dt-product-accessories');

	//inizio - prendo il totale degli accessori del prodotto
	
	let id_product1 = product_id;
	let id_product2 = product_id;
	let azione = 'count';

	let totAccProd = await this.getCountAccProd(id_product1, id_product2, azione);

	//fine - prendo il totale degli accessori del prodotto

	//inizio - prendo il totale degli accessori
	
	let id_product1All = product_id;
	let id_product2All = product_id;
	let azioneAll = 'countall';

	let totAccProdAll = await this.getCountTotAcc(id_product1All, id_product2All, azioneAll);

	//fine - prendo il totale degli accessori

	let urlAssocAccAll = '/product/'+product_id+'/accessoriesAll';
	//let $htmlFiltroAcc = "<div class='filterProdAcc'><a href='"+urlAssocAccAll+"'>Vedi Tutti</a> | <b>Solo Associati</b></div>";
	let $htmlFiltroAcc = "<div class='filterProdAcc'><b>Associati ("+totAccProd+")</b> / <a href='"+urlAssocAccAll+"'>Tutti ("+totAccProdAll+")</a></div>";

	// render
	$('main .main').html($htmlFiltroAcc + actions_menu.render() + dt_accessories.render() + Footer.render());
    dt_accessories.activate();
	Footer.activate(this);
  }
}