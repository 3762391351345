const $ = window.jQuery;

import _ from 'lodash';
import dayjs from 'dayjs';
import Swal from "sweetalert2";
import Footer from '../../../core/js/components/Form/Footer';
import Storage from '../../../core/js/utils/Storage';
import ScaryJqueryConfirm from '../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm';
import OfferApi from '../api/OfferApi';
import OfferNotificationApi from '../api/OfferNotificationApi';
import OfferGenerateDocumentApi from '../api/OfferGenerateDocumentApi';
import ActionsMenu from '../components/ActionsMenu/ActionsMenu';
import ActionsMenuItem from '../components/ActionsMenu/ActionsMenuItem';
import DocumentsTab from "../components/OfferDocumentsTabs/DocumentsTab";
import OfferStatusLogsTable from '../components/OfferStatusLogsTable/OfferStatusLogsTable';
import ScaryDataTable from '../components/Plugins/ScaryDataTable';
import Tab from "../components/Tabs/Tab";
import Tabs from "../components/Tabs/Tabs";
import Page from './Page';

export default class OffersPage extends Page {

	constructor() {
		super();
		
		this.filterValidity = 'all';

		this.setTitle('Offerte')
			.setMeta('description', 'Offerte')
			.setBreadcrumb('Offerte');
	}

	renderCountColumnValue(value) {
		return`
			<div class="attachments-count">
				${value ? '<img src="/assets/images/attachment.svg" alt="documenti" class="attachments-img">' : ''}
				<span>${value || '&mdash;'}</span>
			</div>
		`;
	}

	content() {
		const _page = this;
		const stApi = new Storage();

		//
		// actions menu
		//

		const actions_menu = new ActionsMenu();
		actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-offers']);
		actions_menu.addUlClasses('actions-menu');

		const btn_add = new ActionsMenuItem();
		btn_add
			.addClasses(['element', 'only-icon', 'add'])
			.setTitle('Aggiungi')
			.setOn('click', () => $.spa.navigate('/offer/manage/create'))
			.setRoles(['RD_ADMIN_OFFERS', 'RD_SUB_OFFERS', 'RD_OWN_OFFERS']);

		const btn_edit = new ActionsMenuItem();
		btn_edit
			.addClasses(['element', 'only-icon', 'edit', 'disabled'])
			.setTitle('Modifica')
			.setRoles(['RD_ADMIN_OFFERS', 'RD_SUB_OFFERS', 'RD_OWN_OFFERS']);

		const btn_delete = new ActionsMenuItem();
		btn_delete
			.addClasses(['element', 'only-icon', 'delete', 'disabled'])
			.setTitle('Elimina')
			.setRoles(['RD_ADMIN_OFFERS', 'RD_SUB_OFFERS', 'RD_OWN_OFFERS']);

		const btn_shipping_pdf = new ActionsMenuItem();
		btn_shipping_pdf
			.addClasses(['element', 'only-icon', 'modulo_spedizione', 'disabled'])
			.setTitle('Vedi Modulo Spedizione PDF')
			.setRoles(['RD_ADMIN_USERS', 'RD_SUB_OFFERS', 'RD_OWN_OFFERS']);
			
		const btn_offer_pdf = new ActionsMenuItem();
		btn_offer_pdf
			.addClasses(['element', 'only-icon', 'modulo_offerta', 'disabled'])
			.setTitle('Vedi Documento di Offerta PDF')
			.setRoles(['RD_ADMIN_USERS', 'RD_SUB_OFFERS', 'RD_OWN_OFFERS']);
			
		const btn_offer_shipping_pdf = new ActionsMenuItem();
		btn_offer_shipping_pdf
			.addClasses(['element', 'only-icon', 'modulo_offerta_spedizione', 'disabled'])
			.setTitle('Vedi Documento di Offerta e Modulo di Spedizone PDF')
			.setRoles(['RD_ADMIN_USERS', 'RD_SUB_OFFERS', 'RD_OWN_OFFERS']);
		
		const btn_contract_pdf = new ActionsMenuItem();
		btn_contract_pdf
			.addClasses(['element', 'only-icon', 'modulo_contratto', 'disabled'])
			.setTitle('Vedi Documento di Contratto PDF')
			.setRoles(['RD_ADMIN_USERS', 'RD_SUB_OFFERS', 'RD_OWN_OFFERS']);

		const btn_docs = new ActionsMenuItem();
		btn_docs
			.addClasses(['element', 'only-icon', 'folder', 'disabled'])
			.setTitle('Docs')
			.setRoles(['RD_ADMIN_USERS', 'RD_SUB_OFFERS', 'RD_OWN_OFFERS']);

		const btn_status = new ActionsMenuItem();
		btn_status
			.addClasses(['element', 'only-icon', 'status', 'disabled'])
			.setTitle('Cambio stati')
			.setRoles(['RD_ADMIN_USERS', 'RD_SUB_OFFERS', 'RD_OWN_OFFERS']);
			
		const btn_send = new ActionsMenuItem();
		btn_send
			.addClasses(['element', 'only-icon', 'send', 'disabled'])
			.setTitle('Invia documenti a Cliente')
			.setRoles(['RD_ADMIN_USERS', 'RD_SUB_OFFERS', 'RD_OWN_OFFERS']);

		actions_menu.addItems([
			btn_add,
			btn_edit,
			btn_delete,
			btn_shipping_pdf,
			btn_offer_pdf,
			btn_offer_shipping_pdf,
			btn_contract_pdf,
			btn_docs,
			btn_status,
			btn_send
		]);

		//
		// documents modal
		//
		let tabsGroup;
		$(document).on('offer-documents/get/success', (e, data) => {
			const { agentDocuments, adminDocuments, offerId, offer, statusLogs } = data;
			const agentDocumentsTab = new DocumentsTab()
				.setOfferId(offerId)
				.setDocuments(agentDocuments)
				.setTabType('AGENT')
				.setCustomerType(offer.customer?.activity)
				.setStatusLogs(statusLogs);
			const agentTab = new Tab()
				.setLabel('Docs Agente')
				.setContent(agentDocumentsTab);

			const adminDocumentsTab = new DocumentsTab()
				.setOfferId(offerId)
				.setDocuments(adminDocuments)
				.setTabType('ADMIN');
			const adminTab = new Tab()
				.setLabel('Docs Amministrazione')
				.setContent(adminDocumentsTab);

			tabsGroup = new Tabs()
				.addTabs([agentTab, adminTab])
				.setInitialTab(0);
		});

		//
		// selected offer status logs table
		//
		let offerStatusLogsTable = '';
		$(document).on('offer-status-logs/get/success', (e, data) => {
			const { logs } = data;
			offerStatusLogsTable = new OfferStatusLogsTable(logs);
		});

		//
		// offers table
		//

		// $.confirm delete
		const confirmDelete = new ScaryJqueryConfirm();
		confirmDelete
			.setTitle('Attenzione')
			.setContent('Sei sicuro di voler cancellare l’offerta?');
		
		// $.confirm send
		const confirmSend = new ScaryJqueryConfirm();
		confirmSend
			.setTitle('Attenzione')
			.setContent('Sei sicuro di inviare i Documenti d’Offerta selezionati all’indirizzo email del Cliente?');

		// quick-open dt row on double-click
		$(document).on('dblclick', '.dt-offers tbody tr', function () {
			const targetId = $(this).attr('data-id');
			if (typeof targetId !== 'undefined') {
				$.spa.navigate(`/offer/manage/${targetId}`);
			}
		});

		// handle dt_offers select/deselect
		const dt_offers_onSelectDeselect = function (e, dt, type, indexes) {
			
			const targets = [];
			if (type === 'row') {
				dt.rows({selected: true}).every(function(idx){
					if(typeof dt.row(idx).data().id !== 'undefined'){
						targets.push(dt.row(idx));
					}
				});
				$(document).trigger('offer-documents/get', { offerId: targets.length ? targets[0].data().id : false });
				$(document).trigger('offer-status-logs/get', { offerId: targets.length ? targets[0].data().id : false });
			}
			
			$(`#${btn_edit.name}, #${btn_delete.name}, #${btn_docs.name}, #${btn_status.name}`)
				.off('click')
				.toggleClass('disabled', targets.length === 0);
				
			$(`#${btn_shipping_pdf.name}, #${btn_offer_pdf.name}, #${btn_offer_shipping_pdf.name}, #${btn_send.name}`)
				.off('click')
				.toggleClass('disabled', targets.length === 0 || targets[0].data().offer_status_priority < 2); // available from status FINITA
				
			$(`#${btn_contract_pdf.name}`)
				.off('click')
				.toggleClass('disabled', targets.length === 0 || targets[0].data().offer_status_priority < 8); // available from status APPROVATA

			if (targets.length > 0) {
			
				$(`#${btn_edit.name}`).on('click', () => $.spa.navigate(`/offer/manage/${targets[0].data().id}`));

				$(`#${btn_delete.name}`).on('click', () => {
					confirmDelete
						.modalConfirm(async (data) => {
							const offerId = targets[0].data().id;
							const offerApi = new OfferApi();
							await offerApi.delete(offerId)
								.then(() => {
									if (stApi.getData('current-offer-' + PROFILE.id) === offerId) {
										stApi.delData('current-offer-' + PROFILE.id);
									}
									_page.refresh();
								})
								.catch((e) => { });
						})
						.render();
				});
				
				$(`#${btn_shipping_pdf.name}:not(.disabled)`).on('click', async function () {
					const offerId = targets[0].data().id;
					const offerGenerateDocumentApi = new OfferGenerateDocumentApi();
					offerGenerateDocumentApi.setOfferId(offerId).setType('delivery').post()
						.then((result) => {
							if(typeof result?.data?.fileurl === 'undefined'){
								$(document).trigger('message', ['error', 'Si è verificato un errore']);
								return false;
							}
							setTimeout(() => { // workaround for Safari
								window.open(result.data.fileurl, '_top');
							});
						})
						.catch((result) => {
							$(document).trigger('message', ['error', result.responseJSON.error]);
						});
				});
				
				$(`#${btn_offer_pdf.name}:not(.disabled)`).on('click', async function () {
					const offerId = targets[0].data().id;
					const offerGenerateDocumentApi = new OfferGenerateDocumentApi();
					offerGenerateDocumentApi.setOfferId(offerId).setType('offer').post()
						.then((result) => {
							if(typeof result?.data?.fileurl === 'undefined'){
								$(document).trigger('message', ['error', 'Si è verificato un errore']);
								return false;
							}
							setTimeout(() => { // workaround for Safari
								window.open(result.data.fileurl, '_top');
							});
						})
						.catch((result) => {
							$(document).trigger('message', ['error', result.responseJSON.error]);
						});
				});
				
				$(`#${btn_offer_shipping_pdf.name}:not(.disabled)`).on('click', async function () {
					const offerId = targets[0].data().id;
					const offerGenerateDocumentApi = new OfferGenerateDocumentApi();
					offerGenerateDocumentApi.setOfferId(offerId).setType('offerdelivery').post()
						.then((result) => {
							if(typeof result?.data?.fileurl === 'undefined'){
								$(document).trigger('message', ['error', 'Si è verificato un errore']);
								return false;
							}
							setTimeout(() => { // workaround for Safari
								window.open(result.data.fileurl, '_top');
							});
						})
						.catch((result) => {
							$(document).trigger('message', ['error', result.responseJSON.error]);
						});
				});
				
				$(`#${btn_contract_pdf.name}:not(.disabled)`).on('click', async function () {
					const offerId = targets[0].data().id;
					const offerGenerateDocumentApi = new OfferGenerateDocumentApi();
					offerGenerateDocumentApi.setOfferId(offerId).setType('contract').post()
						.then((result) => {
							if(typeof result?.data?.fileurl === 'undefined'){
								$(document).trigger('message', ['error', 'Si è verificato un errore']);
								return false;
							}
							setTimeout(() => { // workaround for Safari
								window.open(result.data.fileurl, '_top');
							});
						})
						.catch((result) => {
							$(document).trigger('message', ['error', result.responseJSON.error]);
						});
				});
				
				
				$(`#${btn_docs.name}`).on('click', () => {
					Swal.fire({
						customClass: 'scary-modal',
						closeButtonHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0.14 329.27 329.26"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg>',
						showCloseButton: true,
						html: tabsGroup.render(),
						showConfirmButton: false,
						showCancelButton: false,
						allowOutsideClick: true,
						allowEscapeKey: false,
						allowEnterKey: false,
						willClose() {
							_page.refresh();
						}
					});
				});

				$(`#${btn_status.name}`).on('click', () => {
					Swal.fire({
						customClass: 'scary-modal scary-modal--status-logs',
						closeButtonHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0.14 329.27 329.26"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg>',
						showCloseButton: true,
						html: offerStatusLogsTable.render(),
						showConfirmButton: false,
						showCancelButton: false,
						allowOutsideClick: true,
						allowEscapeKey: false,
						allowEnterKey: false,
					});
				});
			
				$(`#${btn_send.name}`).on('click', async function(){
					
					Swal.fire({
						title: 'Invia',
						customClass: 'scary-modal scary-modal--send-docs',
						closeButtonHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0.14 329.27 329.26"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg>',
						showCloseButton: true,
						html: `<h3>Scegli quali documenti inviare al cliente</h3><form>
							<div class="field"><label><input type="checkbox" name="document[]" value="delivery">Modulo di spedizione</label></div>
							<div class="field"><label><input type="checkbox" name="document[]" value="offer">Offerta/Preventivo</label></div>
							<div class="field"><label><input type="checkbox" name="document[]" value="contract">Contratto</label></div>
						</form>`,
						showConfirmButton: true,
						confirmButtonText: 'Conferma',
						showCancelButton: false,
						allowOutsideClick: true,
						allowEscapeKey: false,
						allowEnterKey: false
					}).then((result) => {
						if(result.isConfirmed){
							const documentsSelected = [];
							$('.scary-modal--send-docs input[name="document[]"]:checked').each(function(){
								documentsSelected.push($(this).val());
							});
							confirmSend.modalConfirm(async (data) => {
								const offerId = targets[0].data().id;
								const offerNotificationApi = new OfferNotificationApi();
								offerNotificationApi.setOfferId(offerId).setType('documents-to-customer').post({documents: documentsSelected})
									.then((result) => {
										$(document).trigger('message', ['success', 'Documenti inviati']);
									})
									.catch((result) => {
										$(document).trigger('message', ['error', result.responseJSON.error]);
									});
							}).render();
						}
					});
						
				});
			
			}

		};
		const debounced_dt_offers_onSelectDeselect = _.debounce(dt_offers_onSelectDeselect, 100);

		const dt_offers = new ScaryDataTable();
		dt_offers
			.setUrl('/offer')
			.setSelect('single')
			.setOnSelect(debounced_dt_offers_onSelectDeselect)
			.setOnDeselect(debounced_dt_offers_onSelectDeselect)
			.setRowCallback(function (row, data) {
				dt_offers.defaultRowCallback(row, data);
				if (typeof data.id !== 'undefined' && stApi.getData('current-offer-' + PROFILE.id) === data.id) { // check for current offer
					$(row).addClass('current-offer');
				}
			})
			.setAjaxData(function(d){
				d.validity = _page.filterValidity;
			})
			.addField('cod_erp', 'Cod ERP', {
				className: 'col-cod_erp',
				render: function (data, type, row, meta) {
					let string = row.cod_erp;
					if(row.expired){
						string = `<span class="expired" title="Offerta scaduta"><img src="/assets/images/expired.svg" alt="Attenzione"></span>` + string;
					} else if(row.to_reprice === '1'){
						string = `<span class="to-reprice" title="Offerta da riprezzare"><img src="/assets/images/warning.svg" alt="Attenzione"></span>` + string;
					}
					return string;
				}
			})
			.addField('user', 'Utente')
			.addField('created_at', 'Data', {
				render: function (data, type, row, meta) {
					return dayjs(data).isValid() ? dayjs(data).format('DD/MM/YY - HH:mm') : data;
				}
			})
			.addField('company_name', 'Rag. Soc.')
			.addField('offer_status', 'Stato', {
				render: function (data, type, row, meta) {
					let string = `${row.offer_status_label}`;
					if(row.expired){
						string = `SCADUTA - ` + string;
					}
					return string;
				}
			})
			.addField('documents_counts.identity_card', 'CI', {
				render: (data, type, row, meta) => {
					return this.renderCountColumnValue(row.documents_counts.identity_card);
				}
			})
			.addField('documents_counts.fiscal_code', 'CF', {
				render: (data, type, row, meta) => {
					return this.renderCountColumnValue(row.documents_counts.fiscal_code);
				}
			})
			.addField('documents_counts.privacy', 'Privacy', {
				render: (data, type, row, meta) => {
					return this.renderCountColumnValue(row.documents_counts.privacy);
				}
			})
			.addField('documents_counts.constitutive_act', 'Atto Cost.', {
				render: (data, type, row, meta) => {
					return this.renderCountColumnValue(row.documents_counts.constitutive_act);
				}
			})
			.addField('documents_counts.registration_card_copy', 'Iscr. albo', {
				render: (data, type, row, meta) => {
					return this.renderCountColumnValue(row.documents_counts.registration_card_copy);
				}
			})
			.addField('documents_counts.signed_offer', 'Off. Firmata', {
				render: (data, type, row, meta) => {
					return this.renderCountColumnValue(row.documents_counts.signed_offer);
				}
			})
			.addField('documents_counts.signed_loan_agreement', 'Fin Firmato', {
				render: (data, type, row, meta) => {
					return this.renderCountColumnValue(row.documents_counts.signed_loan_agreement);
				}
			})
			.addField('documents_counts.signed_full_service_agreement', 'Full Service', {
				render: (data, type, row, meta) => {
					return this.renderCountColumnValue(row.documents_counts.signed_full_service_agreement);
				}
			})
			.addField('documents_counts.signed_rent_agreement', 'Noleggio', {
				render: (data, type, row, meta) => {
					return this.renderCountColumnValue(row.documents_counts.signed_rent_agreement);
				}
			})
			.addClasses('dt-offers')
			.setOrder([
				[2, 'desc']
			]);

		// render
		$('main .main').html(actions_menu.render() + dt_offers.render() + Footer.render());
		dt_offers.activate();
		Footer.activate();
		
		// add custom filters section
		$('.dataTables_length').wrap('<div class="dataTables_filters-left"></div>');
		$('.dataTables_filter').wrap('<div class="dataTables_filters-right"></div>');
		$('.dataTables_filters-left, .dataTables_filters-right').wrapAll('<div class="dataTables_filters"></div>');
		$('.dataTables_length').after(`<div class="dataTables_validity"><label>Validità <select name="filter_validity"><option value="all">Tutte</option><option value="valid">Valide</option><option value="expired">Scadute</option></label></div>`);
		$('.dataTables_validity select').on('change', function(){
			_page.filterValidity = $(this).val();
			dt_offers.table.ajax.reload();
		});
	}

}
