const $ = window.jQuery;

import Page from './Page';
import Form from '../../../core/js/components/Form/Form';
import TextField from '../../../core/js/components/Form/TextField';
import OptionField from '../../../core/js/components/Form/OptionField';
import HiddenField from '../../../core/js/components/Form/HiddenField';
import SubmitField from '../../../core/js/components/Form/SubmitField';
import NumberField from '../components/Form/NumberField';
import PriceField from '../components/Form/PriceField';
import ScarySelect2 from '../components/Plugins/ScarySelect2';
import ShippingTableRateApi from '../api/ShippingTableRateApi';
import CountryApi from '../api/CountryApi';
import RegionApi from '../api/RegionApi';
import Footer from '../../../core/js/components/Form/Footer';

export default class ShippingTableRateFormPage extends Page {
	
	constructor() {
		super();
		
		this.countries = null;
		this.regions = null;
	}

	async init(rate_id) {		
		this
			.setTitle('Gestione tariffa')
			.setMeta('description', 'Gestione tariffa di spedizione')
			.setBreadcrumb([
				{label: 'Costi e Tariffe', href: '/shipping-tablerates'},
				'Gestione tariffa'
			]);
			
		return super.init(rate_id);
	}

	async content(rate_id) {
		const _page = this;
		
		const mainForm = new Form();
		mainForm
			.setAction('/shipping-tablerate')
			.setMethod('POST')
			.setTitle('Gestisci tariffa di spedizione')
			.addClasses('padded-container label-left');
		
		let data;
		if (rate_id !== 'create') { // update shipping rate
			const shippingTableRateApi = new ShippingTableRateApi();
			const rate = await shippingTableRateApi.get(rate_id);
			if (!rate) {
				return;	
			}
			data = rate.data;
		}

		const in_edit = typeof data !== 'undefined';

		this.setPageTitle(in_edit, mainForm, 'Nuova Tariffa di Spedizione', 'Modifica Tariffa di Spedizione');
		
		const dest_zip = new HiddenField();
		dest_zip
			.setFieldName('dest_zip')
			.setValue('');
			
		const condition_name = new HiddenField();
		condition_name
			.setFieldName('condition_name')
			.setValue('weight');
		
		await this.retrieveCountries();
		const dest_country_id = new ScarySelect2();
		dest_country_id
			.setFieldName('dest_country_id')
			.setLabel('Nazione')
			.setPlaceholder('Tutte le nazioni')
			.setAllowClear(true);
		const countryOptions = [];
		const countryBlankOption = new OptionField();
		countryBlankOption
			.setValue('');
		countryOptions.push(countryBlankOption);
		this.countries.map((country) => {
			const option = new OptionField();
			option
				.setValue(country.iso2)
				.setLabel(country.label);
			if (data?.dest_country_id === country) {
				option.setDefault(true);
			}
			countryOptions.push(option);
		});
		dest_country_id.addOptions(countryOptions);
		if(in_edit && data.is_system === '1'){
			dest_country_id.setDisabled();
		}
		
		await this.retrieveRegions(in_edit ? data.dest_country_id : null);
		const dest_region_id = new ScarySelect2();
		dest_region_id
			.setFieldName('dest_region_id')
			.setLabel('Provincia')
			.setPlaceholder('Tutte le province')
			.setAllowClear(true);
		const regionOptions = [];
		const regionBlankOption = new OptionField();
		regionBlankOption
			.setValue('');
		regionOptions.push(regionBlankOption);
		this.regions.map((region) => {
			const option = new OptionField();
			option
				.setValue(region.id)
				.setLabel(region.label);
			if (data?.dest_region_id === region) {
				option.setDefault(true);
			}
			regionOptions.push(option);
		});
		dest_region_id.addOptions(regionOptions);
		if(in_edit && data.is_system === '1'){
			dest_region_id.setDisabled();
		}
		
		const condition_value = new NumberField();
		condition_value
			.setFieldName('condition_value')
			.setLabel('Peso (a partire da)')
			.setCleave('prefix', ' kg')
			.setCleave('tailPrefix', true)
			.addClasses('text-right')
			.setRequired(true);
		if(in_edit && data.is_system === '1'){
			condition_value.setReadonly();
		}
			
		const price = new PriceField();
		price
			.setFieldName('price')
			.setLabel('Consegna')
			.setRequired(true);
			
		const inst_included_workstations = new PriceField();
		inst_included_workstations
			.setFieldName('inst_included_workstations')
			.setLabel('Installazione')
			.setRequired(true);
			
		const carepack = new PriceField();
		carepack
			.setFieldName('carepack')
			.setLabel('CarePack')
			.setRequired(true);
			
		const submit = new SubmitField();
		submit
			.setFieldName('submit_shippingtablerate')
			.setValue(in_edit ? 'Aggiorna' : 'Salva');
		
		if(in_edit){
			if(data.dest_country_id !== null){
				dest_country_id.setValue(data.dest_country_id);
			}
			if(data.dest_region_id !== null){
				dest_region_id.setValue(data.dest_region_id);
			}
			condition_value.setValue(data.condition_value);
			price.setValue(data.price);
			inst_included_workstations.setValue(data.inst_included_workstations);
			carepack.setValue(data.carepack);
		}

		mainForm.addFields([
			dest_country_id,
			dest_region_id,
			dest_zip,
			condition_name,
			condition_value,
			price,
			inst_included_workstations,
			carepack,
			submit
		]);

		mainForm.onSubmit(async function(result){
			const shippingTableRateApi = new ShippingTableRateApi();
			shippingTableRateApi.setForm(mainForm);

			let res, values = mainForm.getValues();
			if(in_edit){
				if(data.is_system === '1'){ // re-add values for "disabled" select fields
					values.dest_country_id = data.dest_country_id;
					values.dest_region_id = data.dest_region_id;
				}
				res = await shippingTableRateApi.put(rate_id, values);
			} else {
				res = await shippingTableRateApi.post(values);
			}
			if (mainForm.hasErrors()) {
				$(document).trigger('message', ['error', res.data]);
			} else {
				$(document).trigger('message', ['success', res.data]);
				$.spa.navigate('/shipping-tablerates');
			}
		});

		$('main .main').html(mainForm.render());
		mainForm.activate();

		$('main .main').html(mainForm.render() + Footer.render());
		mainForm.activate();
		Footer.activate(this);
		
		// handle country change
		$(`#${dest_country_id.name}`).on('change', async function(){
			// retrieve new regions
			await _page.retrieveRegions($(this).val());
			
			// update regions on Component
			const regionOptions = [];
			let regionOptionsHtml = '';
			const regionBlankOption = new OptionField();
			regionBlankOption
				.setValue('');
			regionOptions.push(regionBlankOption);
			regionOptionsHtml += regionBlankOption.render();
			_page.regions.map((region) => {
				const option = new OptionField();
				option
					.setValue(region.id)
					.setLabel(region.label);
				if (data?.dest_region_id === region) {
					option.setDefault(true);
				}
				regionOptions.push(option);
				regionOptionsHtml += option.render();
			});
			dest_region_id.resetOptions();
			dest_region_id.addOptions(regionOptions);
			
			// update regions on DOM
			$(`#${dest_region_id.name}`).html(regionOptionsHtml);			
		});
	}
	
	async retrieveCountries() {
		const countryApi = new CountryApi();
		const countries = await countryApi.collection().catch(function (e) {
			const errmsg = 'Non è stato possibile recuperare le nazioni';
			$(document).trigger('message', ['error', errmsg]);
			throw errmsg;
		});
		this.countries = countries.data;
	}
	
	async retrieveRegions(countryId = null) {
		const regionApi = new RegionApi();
		const regions = await regionApi.setCountryId(countryId).collection().catch(function (e) {
			const errmsg = 'Non è stato possibile recuperare le regioni';
			$(document).trigger('message', ['error', errmsg]);
			throw errmsg;
		});
		this.regions = regions.data;
	}
	
}
