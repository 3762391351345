import CrudApi from '../../../core/js/api/CrudApi';

export default class CustomerLocationShippingApi extends CrudApi {
	
	constructor() {
		super();
	
		this.customerId = undefined;
		this.locationId = undefined;
		this.shippingId = undefined;
	}
	
	setCustomerId(customer_id) {
		this.customerId = customer_id;
		return this;
	}
	
	setLocationId(location_id) {
		this.locationId = location_id;
		return this;
	}
	
	setShippingId(shipping_id) {
		this.shippingId = shipping_id;
		return this;
	}
	
	crudUrl() {
		return `/customer/${this.customerId}/location/${this.locationId}/shipping${typeof this.shippingId !== 'undefined' ? '/' + this.shippingId : ''}`; 
	}
	
}
  