import { gen, default as random_string } from '../../../core/js/utils/random_string';

const $ = window.jQuery;

$(function(){
	$(document).on('message', function(e, type, message, timeout = 2500, closeButton = false){
		
		const id = random_string(5, [...gen(65, 90), ...gen(97, 122)]);
		
		const close = type !== 'errors_4xx' ? (closeButton ? '<a class="close">&#10006;</a>' : '') : '';
		
		$('#messages').append(`<div id="msg-${id}" class="message ${type}" style="display: none;"><div class="msg-content">${message}</div>${close}</div>`);
		$(`#msg-${id}`).fadeIn();
		
		if(closeButton){
			$(`#msg-${id} .close`).on('click', function(e){
				$(this).parent('.message').fadeOut();
			});
		}
		
		if(Number.isInteger(timeout)){
			setTimeout(function(){
				$(`#msg-${id}`).fadeOut();
			}, timeout);
		}
		
		if(type === 'errors_4xx'){
			$('main .main').html('');
		}
		
	});
});
