import HiddenField from "./HiddenField";

const $ = window.jQuery;

import Form from "./Form";

export default class RelationalForm extends Form {

  constructor() {
    super();

    if (this.constructor == RelationalForm) {
      throw new Error("You cannot instantiate RelationalForm abstract class");
    }

    this.type = 'manytomany';

    this.name = 'relational-form';
    this.name = this.getUniqId();

    this.add_button = '+';
    this.del_button = '-';
    this.rel_button = '···';

    this.relational_fields = [];

    this.mtm_callback = () => {};

    this.def_fields = [];
  }

  setAddButton(add_button) {
    this.add_button = add_button;

    return this;
  }

  setDelButton(del_button) {
    this.del_button = del_button;

    return this;
  }

  setRelButton(rel_button) {
    this.rel_button = rel_button;

    return this;
  }

  setManyToManyCallback(mtm_callback) {
    this.mtm_callback = mtm_callback;

    return this;
  }

  getValues() {
    const values = {};
    this.relational_fields.forEach(function (field) {
      if (typeof field.getValues === 'function') {
        // The field is a Form
        values[field.field_name] = field.getValues();
      } else if ([ 'image', 'submit' ].indexOf(field.type) === -1) {
        values[field.field_name] = field.getValue();
      }
    });
    return values;
  }

  setData(data) {
    if (Array.isArray(data)) {
      const _this = this;
      let count = 0;
      const last_field = this.fields.pop();
      data.forEach(function (record) {
        const last_relational_field = last_field.clone();
        last_relational_field.append(`<a href="#" class="relational-remove-button" data-id="${count}">${_this.del_button}</a>`);
        _this.fields.forEach(function (field) {
          const relational_field = Object.assign({}, field);
          relational_field.setValue(record[field.field_name]);
          relational_field.field_name += `[${count}]`;

          _this.relational_fields.push(relational_field);
        });
        _this.relational_fields.push(last_relational_field);
        count++;
      });
      this.def_fields.push(last_field);
    } else {
      throw 'Data must be an array';
    }
  }

  activate() {
    const _this = this;

    $(document).on('click', '#' + this.name + ' .relational-add-button', function (e) {
      e.preventDefault();
      $('#' + _this.name + ' .relational-fields').append(_this._getAddFields(_this.getUniqId(true)));
    });

    $(document).on('click', '#' + this.name + ' .relational-remove-button', function (e) {
      e.preventDefault();
      const del_id = $(this).attr('data-id');
      let new_relational_fields = [];
      _this.relational_fields.forEach(function (field) {
        if (!field.name.match(`${del_id}$`)) {
          new_relational_fields.push(field);
        }
      });
      _this.relational_fields = new_relational_fields;
      $('#' + _this.name + ' .relational-field-' + del_id).remove();
    });

    if (this.type === 'manytomany') {
      $(document).on('click', '#' + this.name + ' .relational-rel-button', function (e) {
        e.preventDefault();
        const data = _this.mtm_callback();
        $('#' + _this.name + ' .relational-fields').append(_this._getAddFields(_this.getUniqId(true), data));
      });
    }
  }

  _getAddFields(id, data) {
    let add_fields = [];
    const _this = this;

    if (typeof data?.id !== 'undefined') {
      const id_field = new HiddenField();
      id_field
        .setFieldName('id')
        .setValue(data.id);

      add_fields.push(id_field);
    }
    this.fields.forEach(function (field) {
      const relational_field = field.clone();
      relational_field.field_name += `[]`;
      if (data && typeof data[field.field_name] !== 'undefined') {
        relational_field.setValue(data[field.field_name]);
        relational_field.setDisabled(true);
      }

      _this.relational_fields.push(relational_field);
      add_fields.push(relational_field);
    });

    return `
      <div class="relational-field-add relational-field-${id}">
        ${_this.renderFields(add_fields)}
        <a href="#" class="relational-remove-button" data-id="${id}">${_this.del_button}</a>
      </div>
    `;

  }

  render() {
    const rel_button = this.type === 'manytomany' ? `<a href="#" class="relational-rel-button">${this.rel_button}</a>` : '';
    this.addClasses(`${this.type}-form`);
    return `
      <div id="${this.name}" class="${this.classes.join(' ')}">
        <div class="relational-fields">
          ${this.renderFields(this.def_fields)}
        </div>
        <div class="relational-add">
          <a href="#" class="relational-add-button">${this.add_button}</a>
          ${rel_button}
        </div>
      </div>
    `;
  }
}