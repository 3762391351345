export default class Storage {

	constructor() {
		if (typeof(Storage) === 'undefined') {
			throw 'Storage API not available';
		}
		this.data = localStorage;
		this.session = sessionStorage;
	}

	setData(param, value) {
		this.data[param] = localStorage[param] = this._castValue(value);
		
		return this;
	}

	getData(param) {
		return this._uncastValue(this.data[param]);
	}

	delData(param) {
		delete this.data[param];
		localStorage.removeItem(param);
		
		return this;
	}

	setSession(param, value) {
		this.session[param] = sessionStorage[param] = this._castValue(value);
		
		return this;
	}

	getSession(param) {
		return this._uncastValue(this.session[param]);
	}

	delSession(param) {
		delete this.session[param];
		sessionStorage = this.session;
		
		return this;
	}

	_castValue(value) {
		return typeof value === 'object' ? JSON.stringify(value) : value;
	}

	_uncastValue(value) {
		try {
			return JSON.parse(value);
		} catch (e) {
			return value;
		}
	}
}
