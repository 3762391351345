import $ from 'jquery';
import { default as App, classes } from '../core/js/App.js';
import AuthApi from '../core/js/api/AuthApi'; // this line is needed to load core's AuthApi
import RouterApi from '../core/js/api/RouterApi'; // this line is needed to load core's RouterApi
import Storage from '../core/js/utils/Storage';
import '../core/css/core.css'; // import styles
import './js/events/general'; // import events
import './js/pages/index'; // import pages

$(function(){
	$('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1.0">');
	$('head').append('<link rel="icon" href="/assets/images/favicon.ico">');
  
	// load bootstrap api calls to window namespace
	classes.RouterApi = RouterApi;
	classes.AuthApi = AuthApi;
	
	// add special classes to body
	const stApi = new Storage();
	$('body').toggleClass('menu-closed', stApi.getData('menu-status') === 'closed');

	// Instantiate new app
	let app = new App();

	// start app
	app.bootstrap().then(function () {
		// App is started and routing has triggered the route
		// you can execute your own code here which is triggered after the first page load
	});
});
