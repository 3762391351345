import _ from "lodash";

const $ = window.jQuery;

import MenuItem from "../../../../core/js/components/Menu/MenuItem";

export default class ActionsMenuItem extends MenuItem {

  constructor() {
    super();

    this.name = 'actions-menu-';
  }

  setOn(actionType, actionHandler) {
    if(this.title){
      $(document).on(actionType, `#${this.name}`, actionHandler);
    }
	
    return this;
  }
  
  setOff(actionType, actionHandler = false) {
    if(this.title){
      if(actionHandler){
        $(document).off(actionType, `#${this.name}`, actionHandler);
      } else {
        $(document).off(actionType, `#${this.name}`);
      }
    }
	
    return this;
  }

  render() {
    return this.hasAccess() ? `<li id="${this.name}" class="${this.classes.join(' ')}" title="${this.title}">
      <label>${this.title}</label>
    </li>` : '';
  }

}