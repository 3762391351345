const $ = window.jQuery;
require('select2')(window, $);
$.fn.select2.amd.define('select2/i18n/en', [], require('select2/dist/js/i18n/en'));
$.fn.select2.amd.define('select2/i18n/it', [], require('select2/dist/js/i18n/it'));

import SelectField from '../../../../js/components/Form/SelectField';

export default class ScarySelect2 extends SelectField {
	
	constructor() {
		super();
		
		// parameters as described on https://select2.org/configuration/options-api
		this.language = 'en';
		this.allowClear = false;
		this.debug = false;
		this.dropdownAutoWidth = false;
		this.dropdownCssClass = '';
		this.dropdownParent = $(document.body);
		this.matcher = undefined;
		this.maximumInputLength = 0;
		this.maximumSelectionLength = 0;
		this.minimumInputLength = 0;
		this.minimumResultsForSearch = 0;
		this.placeholder = null;
		this.selectionCssClass = '';
		this.selectOnClose = false;
		this.sorter = undefined;
		this.theme = 'default';
		this.tokenSeparators = null;
		this.width = 'resolve';
		this.scrollAfterSelect = false;
	}
	
	setLanguage(iso2){
		this.language = ['en', 'it'].includes(iso2) ? iso2 : 'en';
		
		return this;
	}
	
	setAllowClear(value){
		this.allowClear = Boolean(value);
		if(this.allowClear && this.placeholder === null){
			this.setPlaceholder('');
		}
		
		return this;
	}
	
	setDebug(value){
		this.debug = Boolean(value);
		
		return this;
	}
	
	setDropdownAutoWidth(value){
		this.dropdownAutoWidth = Boolean(value);
		
		return this;
	}
	
	setDropdownCssClass(classes){
		this.dropdownCssClass = classes;
		
		return this;
	}

	setDropdownParent(node){
		this.dropdownParent = node;
		
		return this;
	}
	
	setMatcher(matchFunction){
		this.matcher = matchFunction;
		
		return this;
	}
	
	setMaximumInputLength(length){
		this.maximumInputLength = parseInt(length);
		
		return this;
	}

	setMaximumSelectionLength(length){
		this.maximumSelectionLength = parseInt(length);
		
		return this;
	}

	setMinimumInputLength(length){
		this.minimumInputLength = parseInt(length);
		
		return this;
	}

	setMinimumResultsForSearch(value){
		this.minimumResultsForSearch = parseInt(value);
		
		return this;
	}

	setPlaceholder(value){
		this.placeholder = value;
		
		return this;
	}

	setSelectionCssClass(classes){
		this.selectionCssClass = classes;
		
		return this;
	}

	setSelectOnClose(value){
		this.selectOnClose = Boolean(value);
		
		return this;
	}
	
	setSorter(sorterFunction){
		this.sorter = sorterFunction;
		
		return this;
	}
	
	setTheme(themeName){
		this.theme = themeName;
		
		return this;
	}
	
	setTokenSeparators(separators){
		this.tokenSeparators = separators;
		
		return this;
	}
	
	setWidth(width){
		this.width = width;
		
		return this;
	}

	setScrollAfterSelect(value){
		this.scrollAfterSelect = Boolean(value);
		
		return this;
	}
	
	activate() {
		let params = {
			language: this.language,
			allowClear: this.allowClear,
			debug: this.debug,
			dropdownAutoWidth: this.dropdownAutoWidth,
			dropdownCssClass: this.dropdownCssClass,
			dropdownParent: this.dropdownParent,
			maximumInputLength: this.maximumInputLength,
			maximumSelectionLength: this.maximumSelectionLength,
			minimumInputLength: this.minimumInputLength,
			minimumResultsForSearch: this.minimumResultsForSearch,
			placeholder: this.placeholder,
			selectionCssClass: this.selectionCssClass,
			selectOnClose: this.selectOnClose,
			theme: this.theme,
			tokenSeparators: this.tokenSeparators,
			width: this.width,
			scrollAfterSelect: this.scrollAfterSelect
		};
		if(typeof this.matcher !== 'undefined'){
			params.matcher = this.matcher;
		}
		if(typeof this.sorter !== 'undefined'){
			params.sorter = this.sorter;
		}
		$(`#${this.name}`).select2(params);
	}
	
}
