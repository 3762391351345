const $ = window.jQuery;

import Page from "./Page";
import Form from "../../../core/js/components/Form/Form";
import TextField from "../../../core/js/components/Form/TextField";
import NumberField from "../components/Form/NumberField";
import PriceField from "../components/Form/PriceField";
import OptionField from "../../../core/js/components/Form/OptionField";
import FileField from "../../../core/js/components/Form/FileField";
import HiddenField from "../../../core/js/components/Form/HiddenField";
import ButtonField from "../components/Form/ButtonField";
import TextareaField from "../components/Form/TextareaField";
import SubmitField from "../../../core/js/components/Form/SubmitField";
import ManyToManyForm from "../../../core/js/components/Form/ManyToManyForm";
import ScarySelect2 from "../components/Plugins/ScarySelect2";
import FileApi from "../../../core/js/api/FileApi";
import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
import ImageProductApi from "../api/ImageProductApi";
import FinancialApi from "../api/FinancialApi";
import Footer from "../../../core/js/components/Form/Footer";

export default class FinancialFormPage extends Page {

  constructor() {
    super();

    this
      .setTitle('Gestione finanziarie')
      .setMeta('description', 'Gestione finanziarie')
      .setBreadcrumb([
        { label: 'Finanziarie', href: '/financials' },
        'Gestione finanziaria'
      ]);
  }

  async init(financial_id) {
    super.init();
  }

  async content(financial_id) {
    const financialForm = new Form();
    const subProductForm = new ManyToManyForm();

    financialForm
      .setAction('/financial')
      .setTitle('Gestisci finanziaria')
      .addClasses('padded-container label-left');
    //.addClasses('fullwidth label-left');
    let data;

    if (financial_id === 'create') {
      // Create form
      financialForm.setMethod('POST');
    } else {
      // Update form
      financialForm.setMethod('PUT');

      const financialApi = new FinancialApi();
      const financialData = await financialApi.get(financial_id);
      if (financialData) {
        data = financialData.data[0];
      } else {
        $(document).trigger('404', [financialApi.url + '/' + financial_id]);
        return;
      }
    }

    const in_edit = typeof data !== 'undefined';

    const name = new TextField();
    name
      .setFieldName('name')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Nome');
    if (in_edit && data.is_system === '1') {
      name.setReadonly();
    }

    const zone = new ScarySelect2();
    zone
      .setFieldName('zone')
      .setLabel('Fascia')
      .setPlaceholder('Seleziona un valore')
      .setRequired(true)
      .setMultiple(false);
    const optionsType = [];

    const optionType0 = new OptionField();
    optionType0
      .setValue('')
      .setLabel("")
      .setDefault(data?.zone === "");
    const optionType1 = new OptionField();
    optionType1
      .setValue('500-999,99')
      .setLabel('500-999,99')
      .setDefault(data?.zone === '500-999,99');
    const optionType2 = new OptionField();
    optionType2
      .setValue('1.000-2.499,99')
      .setLabel("1.000-2.499,99")
      .setDefault(data?.zone === "1.000-2.499,99");
    const optionType3 = new OptionField();
    optionType3
      .setValue('2.500-4.999,99')
      .setLabel('2.500-4.999,99')
      .setDefault(data?.zone === '2.500-4.999,99');
    const optionType4 = new OptionField();
    optionType4
      .setValue('5.000-9.999,99')
      .setLabel('5.000-9.999,99')
      .setDefault(data?.zone === '5.000-9.999,99');
    const optionType5 = new OptionField();
    optionType5
      .setValue('10.000-11.999,99')
      .setLabel('10.000-11.999,99')
      .setDefault(data?.zone === '10.000-11.999,99');
    const optionType6 = new OptionField();
    optionType6
      .setValue('12.000-24.999,99')
      .setLabel('12.000-24.999,99')
      .setDefault(data?.zone === '12.000-24.999,99');
    const optionType7 = new OptionField();
    optionType7
      .setValue('25.000-49.999,99')
      .setLabel('25.000-49.999,99')
      .setDefault(data?.zone === '25.000-49.999,99');
    const optionType8 = new OptionField();
    optionType8
      .setValue('>50.000')
      .setLabel('>50.000')
      .setDefault(data?.zone === '>50.000');
    optionsType.push(optionType0);
    optionsType.push(optionType1);
    optionsType.push(optionType2);
    optionsType.push(optionType3);
    optionsType.push(optionType4);
    optionsType.push(optionType5);
    optionsType.push(optionType6);
    optionsType.push(optionType7);
    optionsType.push(optionType8);
    zone.addOptions(optionsType);
    if (in_edit && data.is_system === '1') {
      zone.setDisabled();
    }

    const duration = new ScarySelect2();
    duration
      .setFieldName('duration')
      .setLabel('Durata')
      .setPlaceholder('Seleziona un valore')
      .setRequired(true)
      .setMultiple(false);
    const optionsTypeDuration = [];

    const optionType0Duration = new OptionField();
    optionType0Duration
      .setValue('')
      .setLabel("")
      .setDefault(data?.duration === "");
    const optionType1Duration = new OptionField();
    optionType1Duration
      .setValue('24')
      .setLabel("24")
      .setDefault(data?.duration === "24");
    const optionType2Duration = new OptionField();
    optionType2Duration
      .setValue('36')
      .setLabel("36")
      .setDefault(data?.duration === "36");
    const optionType3Duration = new OptionField();
    optionType3Duration
      .setValue('48')
      .setLabel("48")
      .setDefault(data?.duration === "48");
    const optionType4Duration = new OptionField();
    optionType4Duration
      .setValue('60')
      .setLabel("60")
      .setDefault(data?.duration === "60");
    const optionType5Duration = new OptionField();
    optionType5Duration
      .setValue('72')
      .setLabel("72")
      .setDefault(data?.duration === "72");
    optionsTypeDuration.push(optionType0Duration);
    optionsTypeDuration.push(optionType1Duration);
    optionsTypeDuration.push(optionType2Duration);
    optionsTypeDuration.push(optionType3Duration);
    optionsTypeDuration.push(optionType4Duration);
    optionsTypeDuration.push(optionType5Duration);
    duration.addOptions(optionsTypeDuration);
    if (in_edit && data.is_system === '1') {
      duration.setDisabled();
    }

    const coefficient = new NumberField();
    coefficient
      .setLabel('Coefficiente')
      .setFieldName('coefficient')
      .setCleave('numeralDecimalScale', 4)
      .setRequired(true);

    const multiplier = new NumberField();
    multiplier
      .setLabel('Moltiplicatore')
      .setFieldName('multiplier')
      .setRequired(true);

    const submit = new SubmitField();
    submit
      .setFieldName('submit_product')
      .setValue(in_edit ? 'Aggiorna' : 'Salva');

    if (in_edit) {
      name.setValue(data.name);
      zone.setValue(data.zone);
      duration.setValue(data.duration);
      coefficient.setValue(data.coefficient);
      multiplier.setValue(data.multiplier);
    }

    const formFields = [
      name,
      zone,
      duration,
      coefficient,
      multiplier,
      submit
    ];

    financialForm.addFields(formFields);

    financialForm.onSubmit(async function (res) {

      const financialApi = new FinancialApi();
      financialApi.setForm(financialForm);

      let values = financialForm.getValues();

      let msg = '';
      if (in_edit) {
        if (data.is_system === '1') { // re-add values for "disabled" select fields
          values.zone = data.zone;
          values.duration = data.duration;
        }
        const res = await financialApi.put(financial_id, values);
        msg = res.data;
      } else {
        const res = await financialApi.post(values);
        financial_id = res.financial_id;
        msg = res.data;
      }

      if (!financialForm.hasErrors()) {
        $(document).trigger('message', ['success', msg]);
        $.spa.navigate('/financials', false);
      }
    });

    $('main .main').html(financialForm.render() + Footer.render());
    financialForm.activate();
    Footer.activate(this);

  }

}