const $ = window.jQuery;

import Page from './Page';
import Form from '../../../core/js/components/Form/Form';
import TextField from '../../../core/js/components/Form/TextField';
import EmailField from '../../../core/js/components/Form/EmailField';
import PasswordField from '../../../core/js/components/Form/PasswordField';
import OptionField from '../../../core/js/components/Form/OptionField';
import SubmitField from '../../../core/js/components/Form/SubmitField';
import FileField from '../../../core/js/components/Form/FileField';
import HiddenField from '../../../core/js/components/Form/HiddenField';
import NumberField from '../components/Form/NumberField';
import TextareaField from '../components/Form/TextareaField';
import SelectBooleanField from '../../../core/js/components/Form/SelectBooleanField';
import ScaryJqueryConfirm from '../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm';
import ScarySelect2 from '../components/Plugins/ScarySelect2';
import SettingsApi from "../api/SettingsApi";
import RolesApi from '../../../core/js/api/RolesApi';
import UserApi from '../../../core/js/api/UserApi';
import FileApi from '../../../core/js/api/FileApi';
import _ from 'lodash';
import Footer from '../../../core/js/components/Form/Footer';

export default class UserFormPage extends Page {
	
	constructor() {
		super();
		
		this.companies = undefined;
		this.roles = undefined;
		this.supervisors = [];
	}

	async init(user_id) {
		const rolesApi = new RolesApi();
		const settingsApi = new SettingsApi();
		const userApi = new UserApi();

		this.companies = await settingsApi.collection().catch(function(e){
			const errmsg = 'Non è stato possibile recuperare le aziende';
			$(document).trigger('message', ['error', errmsg]);
			throw errmsg;
		});
		this.companies = this.companies.data;
		
		this.supervisors = await userApi.collection().catch(function(e){
			const errmsg = 'Non è stato possibile recuperare i responsabili';
			$(document).trigger('message', ['error', errmsg]);
			throw errmsg;
		});
		this.supervisors = this.supervisors.data;
		
		this.roles = await rolesApi.roles().catch(function (e) {
			const errmsg = 'Errore nello scaricare i ruoli';
			$(document).trigger('message', [ 'error', errmsg ]);
			throw errmsg;
		});
		this.roles = this.roles.data;
		
		this
			.setTitle('Gestione utente')
			.setMeta('description', 'Dashboard for current user')
			.setBreadcrumb([
				{ label: 'Utenti', href: '/users' },
				'Gestione utente'
			]);
		
		return super.init(user_id);
	}

	async content(user_id) {
		const _page = this;

		const userForm = new Form();
		userForm
			.setAction('/user')
			.setMethod('POST')
			.setTitle('Gestisci utente')
			.addClasses('padded-container label-left');
		
		let data;
		if (user_id !== 'create') { // update user
			const userApi = new UserApi();
			const userData = await userApi.get(user_id);
			if (!userData) {
				return;	
			}
			data = userData.data;
		}

		const in_edit = typeof data !== 'undefined';

		const settings = this.getSelectCompanies(user_id !== 'create' ? data.settings : [], in_edit);

		this.setPageTitle(in_edit, userForm, 'Nuovo Utente', 'Modifica Utente');

		const enabled = new SelectBooleanField();
		enabled
			.setFieldName('enabled')
			.setLabel('Abilitato')
			.setRequired(true);
		enabled.setValue(1);
		
		const supervisor = new ScarySelect2();
		supervisor
			.setFieldName('supervisor')
			.setLabel('Responsabile')
			.setAllowClear(true);
		const supervisorOptions = [];
		const supervisorBlankOption = new OptionField();
		supervisorBlankOption
			.setValue('');
		supervisorOptions.push(supervisorBlankOption);
		this.supervisors.map(function(supervisor){
			if(supervisor.role.includes('ROLE_ADMIN') || supervisor.role.includes('ROLE_RESPONSABILE'))
			{
				const option = new OptionField();
				option
					.setValue(supervisor.id)
					.setLabel(supervisor.full_name + (supervisor.role.includes('ROLE_ADMIN') ? ' (admin)' : ''));
				if (in_edit && data.parents.includes(supervisor.id)) {
					option.setDefault(true);
				}
				supervisorOptions.push(option);
			}
		});
		supervisor.addOptions(supervisorOptions);

		const code = new TextField();
		code
			.setFieldName('code')
			.setMaxlength(255)
			.setLabel('Codice agente');
		const email = new EmailField();
		email
			.setFieldName('email')
			.setRequired(true)
			.setPlaceholder('')
			.setLabel('Email');
		const password = new PasswordField();
		password
			.setFieldName('password')
			.setPlaceholder(in_edit ? 'Password (lascia vuoto per non cambiare)' : '')
			.setLabel('Password');
		if(!in_edit){
			password.setRequired(true);
		}
		const first_name = new TextField();
		first_name
			.setFieldName('first_name')
			.setMaxlength(255)
			.setRequired(true)
			.setPlaceholder('')
			.setLabel('Nome');
		const last_name = new TextField();
		last_name
			.setFieldName('last_name')
			.setMaxlength(255)
			.setRequired(true)
			.setPlaceholder('')
			.setLabel('Cognome');
		const mobile_number = new TextField();

		mobile_number
			.setFieldName('mobile_number')
			.setMaxlength(255)
			.setLabel('Cellulare');
		const commission = new NumberField();
		commission
			.setLabel('Provvigione')
			.setFieldName('commission')
			.setCleave('prefix', ' %')
			.setCleave('tailPrefix', true)
			.addClasses('text-right')
			.setRequired(true);
		const avatar = new FileField();
		avatar
			.setFieldName('avatar')
			.setMaxSize('10MB')
			.addMimeTypes(['jpg', 'png'])
			.setLabel('Immagine di profilo');
		const id_avatar = new HiddenField();
		id_avatar
			.setFieldName('id_avatar');
		const notes = new TextareaField();
		notes
			.setFieldName('notes')
			.setLabel('Note');

		const roles = this.getSelectRoles(user_id !== 'create' ? data.roles : []);

		const submit = new SubmitField();
		submit
			.setFieldName('submit_user')
			.setValue(in_edit ? 'Aggiorna' : 'Salva');

		if (in_edit) {
			enabled.setValue(data.enabled);
			if(data.code !== null){
				code.setValue(data.code);
			}
			email.setValue(data.email);
			first_name.setValue(data.first_name);
			last_name.setValue(data.last_name);
			if(data.mobile_number !== null){
				mobile_number.setValue(data.mobile_number);
			}
			if(data.notes !== null){
				notes.setValue(data.notes);
			}
			if(data.commission !== null){
				commission.setValue(data.commission);
			}
			if(data.id_avatar !== null){
				id_avatar.setValue(data.id_avatar);
			}
			avatar.setValue(data.avatar);

			if(data.erp === '1'){
				enabled.setDisabled();
				id_company.setReadonly();
				supervisor.setReadonly();
				code.setReadonly();
				email.setReadonly();
				password.setReadonly();
				first_name.setReadonly();
				last_name.setReadonly();
				mobile_number.setReadonly();
				commission.setReadonly();
				notes.setReadonly();
				roles.setReadonly();
				avatar.setDisabled();
				submit.setDisabled();
			}
		}

		const formFields = [
			enabled,
			settings,
			supervisor,
			code,
			email,
			password,
			first_name,
			last_name,
			mobile_number,
			commission,
			notes,
			roles,
			avatar,
			id_avatar,
			submit
		];

		userForm.addFields(formFields);

		userForm.onSubmit(async function(res){
			userForm.orig_values.roles = Array.isArray(userForm.orig_values.roles) ? userForm.orig_values.roles : [ userForm.orig_values.roles ];
			
			const userApi = new UserApi();
			userApi.setForm(userForm);
			userApi.useFormData = true;

			let values = userForm.getValues();
			values.avatar = avatar.getFiles(); // replace standard "value" of FileField with FileList object

			let msg = '';
			if (in_edit) {
				values.roles = undefined;
				values.settings = data.settings; // in edit id_company field is disabled
				const res = await userApi.put(user_id, values);
				msg = res.data;
			} else {

				const settingsIds = values.settings.map(label => {
					const setting = _page.companies.find(obj => obj.name === label);
					return setting ? setting.id : null;
				});
				values.settings = settingsIds.filter(id => id !== null);

				const res = await userApi.post(values);
				msg = res.data;
				user_id = res.user_id;
			}
			
			if (userForm.hasErrors()) {
				$(document).trigger('message', [ 'error', msg ]);
			} else {
				$(document).trigger('message', [ 'success', msg ]);
				$.spa.navigate('/user/manage/' + user_id, true);
			}
		});

		$('main .main').html(userForm.render() + Footer.render());
		userForm.activate();
		Footer.activate(this);
		
		// delete avatar
		$(`.formfield.file-type .delete`).on('click', function(){
			let $targetFileContent = $(this).closest('.file-content');
			const confirmDeleteAvatar = new ScaryJqueryConfirm();
			confirmDeleteAvatar
				.setTitle('Attenzione')
				.setContent('Sei sicuro di voler cancellare il file?')
				.modalConfirm(async(data) => {
					const fileApi = new FileApi();
					await fileApi.delete($targetFileContent.attr('data-id'))
						.then(() => {
							$.spa.navigate('/user/manage/' + user_id, true);
						})
						.catch((e) => {
							$(document).trigger('message', ['error', e.responseJSON.error]);
						});
				})
				.render();
		});
	}

	getSelectRoles(defRoles) {
		
		const rolesSelect = new ScarySelect2();
		rolesSelect
			.setFieldName('role')
			.setRequired(true)
			.setLabel('Ruoli')
			.setPlaceholder('Seleziona uno o più ruoli')
			.setMultiple(true);
			
		const options = [];
		this.roles.map(function(role){
			
			const defoption = defRoles.indexOf(role) > -1;
			const option = new OptionField();
			option
				.setValue(role)
				.setLabel(role);
			if (defoption) {
				option.setDefault(true);
			}
	
			options.push(option);
		});
	
		rolesSelect.addOptions(options);
	
		return rolesSelect;
	}

	getSelectCompanies(defCompanies, in_edit) {
		const options = [];
		this.companies.map((company) => {
		  const option = new OptionField();
		  option
			.setValue(company.name)
			.setLabel(company.name);
		  if (defCompanies.indexOf(company.id) > -1) {
			option.setDefault(true);
		  }
		  options.push(option);
		});

		const companiesSelect = new ScarySelect2();
		companiesSelect
		  .setFieldName('settings')
		  .setRequired(true)
		  .setLabel('Aziende')
		  .setPlaceholder('Seleziona una o più aziende')
		  .setMultiple(true)
		  .addOptions(options);

		if(in_edit){
			companiesSelect.setDisabled(true);
		}

		return companiesSelect;
	}
	
}
