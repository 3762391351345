const $ = window.jQuery;

import Page from './Page';
import ScaryJqueryConfirm from '../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm';
import ScaryDataTable from '../components/Plugins/ScaryDataTable';
import ActionsMenu from '../components/ActionsMenu/ActionsMenu';
import ActionsMenuItem from '../components/ActionsMenu/ActionsMenuItem';
import CarepackApi from '../api/CarepackApi';
import Footer from '../../../core/js/components/Form/Footer';
import _ from 'lodash';

export default class CarepacksPage extends Page {
	
	constructor(){
		super();
		
		this.setTitle('Carepack')
			.setMeta('description', 'Carepack')
			.setBreadcrumb('Carepack');
	}
	
	content(){
		const _page = this;
		
		//
		// actions menu
		//
		
		const actions_menu = new ActionsMenu();
		actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-carepacks']);
		actions_menu.addUlClasses('actions-menu');
		
		const btn_add = new ActionsMenuItem();
		btn_add
			.addClasses(['element', 'only-icon', 'add'])
			.setTitle('Aggiungi')
			.setOn('click', () => $.spa.navigate('/carepack/manage/create'))
			.setRoles(['RD_ADMIN_CAREPACKS']);
			
		const btn_edit = new ActionsMenuItem();
		btn_edit
			.addClasses(['element', 'only-icon', 'edit', 'disabled'])
			.setTitle('Modifica')
			.setRoles(['RD_ADMIN_CAREPACKS']);
			
		const btn_delete = new ActionsMenuItem();
		btn_delete
			.addClasses(['element', 'only-icon', 'delete', 'disabled'])
			.setTitle('Elimina')
			.setRoles(['RD_ADMIN_CAREPACKS']);
			
		actions_menu.addItems([
			btn_add,
			btn_edit,
			btn_delete,
		]);

		//
		// offers table
		//
	
		// $.confirm delete
		const confirmDelete = new ScaryJqueryConfirm();
		confirmDelete
			.setTitle('Attenzione')
			.setContent('Sei sicuro di voler cancellare il carepack?');
		
		// quick-open dt row on double-click
		$(document).on('dblclick', '.dt-carepacks tbody tr', function(){
			const targetId = $(this).attr('data-id');
			if(typeof targetId !== 'undefined'){
				$.spa.navigate(`/carepack/manage/${targetId}`);
			}
		});
		
		// handle carepacks select/deselect
		const dt_carepacks_onSelectDeselect = function(e, dt, type, indexes){
			
			// check for selected rows
			const targets = [];
			if (type === 'row') {
				dt.rows({selected: true}).every(function(idx){
					if(typeof dt.row(idx).data().id !== 'undefined'){
						targets.push(dt.row(idx));
					}
				});
			}
			
			$(`#${btn_edit.name}`)
				.off('click')
				.toggleClass('disabled', targets.length === 0);
				
			$(`#${btn_delete.name}`)
				.off('click')
				.toggleClass('disabled', targets.length === 0);
			
			if(targets.length > 0){
				$(`#${btn_edit.name}:not(.disabled)`).on('click', () => $.spa.navigate(`/carepack/manage/${targets[0].data().id}`));
				$(`#${btn_delete.name}:not(.disabled)`).on('click', () => {
					confirmDelete
						.modalConfirm(async (data) => {
							const carepackId = targets[0].data().id;
							const carepackApi = new CarepackApi();
							await carepackApi.delete(carepackId)
								.then(() => {
									_page.refresh();
								})
								.catch((e) => {});
						})
						.render();
				});
			}
			
		};
		const debounced_dt_carepacks_onSelectDeselect = _.debounce(dt_carepacks_onSelectDeselect, 100);
	
		const dt_carepacks = new ScaryDataTable();
		dt_carepacks
			.setUrl('/carepack')
			.setSelect('single')
			.setOnSelect(debounced_dt_carepacks_onSelectDeselect)
			.setOnDeselect(debounced_dt_carepacks_onSelectDeselect)
			.addField('cod_erp', 'Cod ERP')
			.addField('name', 'Nome')
			.addField('duration', 'Durata')
			.addField('price', 'Prezzo', {
				render: function (data, type, row, meta) {
					return `€ ${data}`;
				}
			})
			.addClasses('dt-carepacks');
		
		// render
		$('main .main').html(actions_menu.render() + dt_carepacks.render() + Footer.render());
		dt_carepacks.activate();
		Footer.activate(this);
	}
	
}
