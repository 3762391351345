const $ = window.jQuery;

import dayjs from 'dayjs';
import _ from 'lodash';
import Swal from "sweetalert2";
import Footer from '../../../core/js/components/Form/Footer';
import Storage from '../../../core/js/utils/Storage';
import ScaryJqueryConfirm from '../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm';
import OfferApi from '../api/OfferApi';
import OfferNotificationApi from '../api/OfferNotificationApi';
import ActionsMenu from '../components/ActionsMenu/ActionsMenu';
import ActionsMenuItem from '../components/ActionsMenu/ActionsMenuItem';
import DocumentsTab from "../components/OfferDocumentsTabs/DocumentsTab";
import OfferStatusLogsTable from '../components/OfferStatusLogsTable/OfferStatusLogsTable';
import ScaryDataTable from '../components/Plugins/ScaryDataTable';
import Tab from "../components/Tabs/Tab";
import Tabs from "../components/Tabs/Tabs";
import Page from './Page';

//import DocumentGeneratorApi from "../api/DocumentGeneratorApi";

export default class OrdersPage extends Page {

	constructor() {
		super();

		this.setTitle('Ordini')
			.setMeta('description', 'Ordini')
			.setBreadcrumb('Ordini');
	}

	content() {
		const _page = this;
		const stApi = new Storage();

		//
		// actions menu
		//

		const actions_menu = new ActionsMenu();
		actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-offers']);
		actions_menu.addUlClasses('actions-menu');

		/*const btn_add = new ActionsMenuItem();
		btn_add
			.addClasses(['element', 'only-icon', 'add'])
			.setTitle('Aggiungi')
			.setOn('click', () => $.spa.navigate('/offer/manage/create'))
			.setRoles(['RD_ADMIN_OFFERS', 'RD_OWN_OFFERS']);

		const btn_edit = new ActionsMenuItem();
		btn_edit
			.addClasses(['element', 'only-icon', 'edit', 'disabled'])
			.setTitle('Modifica')
			.setRoles(['RD_ADMIN_OFFERS', 'RD_OWN_OFFERS']);

		const btn_delete = new ActionsMenuItem();
		btn_delete
			.addClasses(['element', 'only-icon', 'delete', 'disabled'])
			.setTitle('Elimina')
			.setRoles(['RD_ADMIN_OFFERS', 'RD_OWN_OFFERS']);

		const btn_pdf = new ActionsMenuItem();
		btn_pdf
			.addClasses(['element', 'only-icon', 'visibility', 'disabled'])
			.setTitle('Vedi PDF')
			.setRoles(['RD_ADMIN_USERS', 'RD_OWN_OFFERS']);
			
		const btn_offer_pdf = new ActionsMenuItem();
		btn_offer_pdf
			.addClasses(['element', 'only-icon', 'visibility', 'disabled'])
			.setTitle('Vedi Documento di Offerta PDF')
			.setRoles(['RD_ADMIN_USERS', 'RD_OWN_OFFERS']);

		const btn_docs = new ActionsMenuItem();
		btn_docs
			.addClasses(['element', 'only-icon', 'folder', 'disabled'])
			.setTitle('Docs')
			.setRoles(['RD_ADMIN_USERS', 'RD_OWN_OFFERS']);

		const btn_status = new ActionsMenuItem();
		btn_status
			.addClasses(['element', 'only-icon', 'status', 'disabled'])
			.setTitle('Cambio stati')
			.setRoles(['RD_ADMIN_USERS', 'RD_OWN_OFFERS']);
			
		const btn_send = new ActionsMenuItem();
		btn_send
			.addClasses(['element', 'only-icon', 'send', 'disabled'])
			.setTitle('Invia Offerta a Cliente')
			.setRoles(['RD_ADMIN_USERS', 'RD_OWN_OFFERS']);*/

		/*actions_menu.addItems([
			btn_add,
			btn_edit,
			btn_delete,
			btn_pdf,
			btn_offer_pdf,
			btn_docs,
			btn_status,
			btn_send
		]);*/

		actions_menu.addItems([
		]);


		//
		// documents modal
		//
		/*let tabsGroup;
		$(document).on('offer-documents/get/success', (e, data) => {
			const { agentDocuments, adminDocuments, offerId } = data;
			const agentDocumentsTab = new DocumentsTab()
				.setOfferId(offerId)
				.setDocuments(agentDocuments)
				.setTabType('AGENT');
			const agentTab = new Tab()
				.setLabel('Docs Agente')
				.setContent(agentDocumentsTab);

			const adminDocumentsTab = new DocumentsTab()
				.setOfferId(offerId)
				.setDocuments(adminDocuments)
				.setTabType('ADMIN');
			const adminTab = new Tab()
				.setLabel('Docs Amministrazione')
				.setContent(adminDocumentsTab);

			tabsGroup = new Tabs()
				.addTabs([agentTab, adminTab])
				.setInitialTab(0);
		});*/

		//
		// selected offer status logs table
		//
		/*let offerStatusLogsTable = '';
		$(document).on('offer-status-logs/get/success', (e, data) => {
			const { logs } = data;
			offerStatusLogsTable = new OfferStatusLogsTable(logs);
		});*/

		//
		// offers table
		//

		// $.confirm delete
		const confirmDelete = new ScaryJqueryConfirm();
		confirmDelete
			.setTitle('Attenzione')
			.setContent('Sei sicuro di voler cancellare l’offerta?');
		
		// $.confirm send
		const confirmSend = new ScaryJqueryConfirm();
		confirmSend
			.setTitle('Attenzione')
			.setContent('Sei sicuro di voler inviare l’Offerta all’indirizzo email del Cliente?');

		// quick-open dt row on double-click
		/*$(document).on('dblclick', '.dt-offers tbody tr', function () {
			const targetId = $(this).attr('data-id');
			if (typeof targetId !== 'undefined') {
				$.spa.navigate(`/offer/manage/${targetId}`);
			}
		});*/

		// handle dt_offers select/deselect
		/*const dt_offers_onSelectDeselect = function (e, dt, type, indexes) {

			// check for selected rows
			let targetIds = [];
			if (type === 'row') {
				dt.rows({ selected: true }).every(function (idx) {
					const targetId = $(dt.row(idx).node()).attr('data-id');
					if (typeof targetId !== 'undefined') {
						targetIds.push(parseInt(targetId));
					}
				});
				$(document).trigger('offer-documents/get', { offerId: targetIds[0] });
				$(document).trigger('offer-status-logs/get', { offerId: targetIds[0] });
			}

			$(`#${btn_edit.name}, #${btn_delete.name}, #${btn_pdf.name}, #${btn_offer_pdf.name}, #${btn_docs.name}, #${btn_status.name}, #${btn_send.name}`)
				.off('click')
				.toggleClass('disabled', targetIds.length === 0);

			if (targetIds.length > 0) {
				
				$(`#${btn_pdf.name}`).on('click', async function () {

					/*const offerId = targetIds[0];
					const tipoModuloPdf = "delivery";
					window.open("https://myo-api.localhost.com/test_pdf_generator.php?tipoModuloPdf=" + tipoModuloPdf + "&id_offer=" + offerId, "_blank");*/

					/*const offerId = targetIds[0];
					const offerApi = new OfferApi();
					const offerData = await offerApi.get(offerId);*/
					//const data = offerData.data;
					//let file_delivery_fileurl = data.file_delivery_fileurl;

					//let urlPdf = file_delivery_fileurl; 

					//console.log("data.file_delivery.fileurl:" + data.file_delivery.fileurl);

					/*if (data.file_delivery == null) {
						Swal.fire({
							customClass: 'scary-modal',
							closeButtonHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0.14 329.27 329.26"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg>',
							showCloseButton: true,
							showConfirmButton: true,
							showCancelButton: false,
							allowOutsideClick: true,
							allowEscapeKey: false,
							allowEnterKey: false,
							title: "Attenzione",
							text: "Modulo di Accettazione non trovato per Offerta Selezionata.",
							icon: "info",
						});
					}
					else {
						//console.log(data.file_delivery.fileurl);

						window.open(data.file_delivery.fileurl, "_blank");
					}
				});
				
				$(`#${btn_offer_pdf.name}`).on('click', async function () {
					const offerId = targetIds[0];
					const offerApi = new OfferApi();
					const offerData = await offerApi.get(offerId);
					if (typeof offerData?.data?.file_offer?.fileurl !== 'undefined' && offerData.data.file_offer.fileurl !== null) {
						window.open(offerData.data.file_offer.fileurl, '_blank');
					} else {
						Swal.fire({
							customClass: 'scary-modal',
							closeButtonHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0.14 329.27 329.26"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg>',
							showCloseButton: true,
							showConfirmButton: true,
							showCancelButton: false,
							allowOutsideClick: true,
							allowEscapeKey: false,
							allowEnterKey: false,
							title: 'Attenzione',
							text: 'Documento di Offerta non trovato per Offerta Selezionata.',
							icon: 'info'
						});
					}
				});

				$(`#${btn_edit.name}`).on('click', () => $.spa.navigate(`/offer/manage/${targetIds[0]}`));
				
				$(`#${btn_delete.name}`).on('click', () => {
					confirmDelete
						.modalConfirm(async (data) => {
							const offerId = targetIds[0];
							const offerApi = new OfferApi();
							await offerApi.delete(offerId)
								.then(() => {
									if (stApi.getData('current-offer') === offerId) {
										stApi.delData('current-offer');
									}
									_page.refresh();
								})
								.catch((e) => { });
						})
						.render();
				});
				
				$(`#${btn_docs.name}`).on('click', () => {

					Swal.fire({
						customClass: 'scary-modal',
						closeButtonHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0.14 329.27 329.26"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg>',
						showCloseButton: true,
						html: tabsGroup.render(),
						showConfirmButton: false,
						showCancelButton: false,
						allowOutsideClick: true,
						allowEscapeKey: false,
						allowEnterKey: false,
						willClose() {
							_page.refresh();
						}
					});
				});

				$(`#${btn_status.name}`).on('click', () => {
					Swal.fire({
						customClass: 'scary-modal scary-modal--status-logs',
						closeButtonHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0.14 329.27 329.26"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg>',
						showCloseButton: true,
						html: offerStatusLogsTable.render(),
						showConfirmButton: false,
						showCancelButton: false,
						allowOutsideClick: true,
						allowEscapeKey: false,
						allowEnterKey: false,
					});
				});
			
				$(`#${btn_send.name}`).on('click', async function(){
					confirmSend
						.modalConfirm(async (data) => {
							const offerId = targetIds[0];
							const offerApi = new OfferApi();
							const offerData = await offerApi.get(offerId);
							if (typeof offerData?.data?.file_offer !== 'undefined' && offerData.data.file_offer !== null) {
								const offerNotificationApi = new OfferNotificationApi();
								const result = await offerNotificationApi.setOfferId(offerId).setType('offer-to-customer').post()
									.then(() => {
										$(document).trigger('message', ['success', 'Offerta inviata']);
									})
									.catch((e) => {
										$(document).trigger('message', ['error', 'Si è verificato un errore']);
									});
							} else {
								Swal.fire({
									customClass: 'scary-modal',
									closeButtonHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0.14 329.27 329.26"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg>',
									showCloseButton: true,
									showConfirmButton: true,
									showCancelButton: false,
									allowOutsideClick: true,
									allowEscapeKey: false,
									allowEnterKey: false,
									title: 'Attenzione',
									text: 'Documento di Offerta non trovato per Offerta Selezionata.',
									icon: 'info',
								});
							}
						})
						.render();
				});
			
			}

		};
		const debounced_dt_offers_onSelectDeselect = _.debounce(dt_offers_onSelectDeselect, 100);*/

		const dt_orders = new ScaryDataTable();
		dt_orders
			.setUrl('/order')
			.setSelect('single')
			/*.setOnSelect(debounced_dt_offers_onSelectDeselect)
			.setOnDeselect(debounced_dt_offers_onSelectDeselect)*/
			.setRowCallback(function (row, data) {
				dt_orders.defaultRowCallback(row, data);
				/*if (typeof data.id !== 'undefined' && stApi.getData('current-offer') === data.id) { // check for current offer
					$(row).addClass('current-offer');
				}*/
			})
			.addField('cod_erp', 'Cod ERP')
			.addField('user', 'Utente')
			.addField('created_at', 'Data', {
				render: function (data, type, row, meta) {
					return dayjs(data).isValid() ? dayjs(data).format('DD/MM/YY - HH:mm') : data;
				}
			})
			.addField('company_name', 'Rag. Soc.')
			.addField('offer_status', 'Stato', {
				render: function (data, type, row, meta) {
					return `${row.offer_status_label}`;
				}
			})
			.addClasses('dt-orders')
			.setOrder([
				[2, 'desc']
			]);

		// render
		$('main .main').html(actions_menu.render() + dt_orders.render() + Footer.render());
		dt_orders.activate();
		Footer.activate();
	}

}
