import CrudApi from "../../../core/js/api/CrudApi";

export default class ImageProductApi extends CrudApi {
  crudUrl() {
    return '/imgproduct';
  }

  async deleteFile(id, dataId) {
    this.method = 'DELETE';
    this.url = this.crudUrl() + '/' + id + '/media/' + dataId;
    this.setErrorCallback(this.deleteError);

    return await this.call(this.deleteAction);
  }
}
