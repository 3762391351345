import { default as BaseScarySelect2 } from '../../../../core/plugins/select2/js/components/ScarySelect2';

export default class ScarySelect2 extends BaseScarySelect2 {
	
	constructor() {
		super();
		
		this.setLanguage('it');
	}
	
}
