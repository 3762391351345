const $ = window.jQuery;

require('datatables.net')(window, $);
require('datatables.net-autofill')(window, $);
require('datatables.net-buttons')(window, $);
require('datatables.net-buttons/js/buttons.colVis.js')(window, $);
require('datatables.net-buttons/js/buttons.html5.js')(window, $);
require('datatables.net-buttons/js/buttons.print.js')(window, $);
require('datatables.net-colreorder')(window, $);
require('datatables.net-fixedcolumns')(window, $);
require('datatables.net-fixedheader')(window, $);
require('datatables.net-keytable')(window, $);
require('datatables.net-rowgroup')(window, $);
require('datatables.net-rowreorder')(window, $);
require('datatables.net-responsive')(window, $);
require('datatables.net-scroller')(window, $);
require('datatables.net-searchbuilder')(window, $);
require('datatables.net-searchpanes')(window, $);
require('datatables.net-select')(window, $);
require('datatables.net-staterestore')(window, $);

import Component from '../../../../js/components/Component';

export default class ScaryDataTable extends Component {
	
	constructor() {
		super();
	
		this.name = 'datatables';
		this.name = this.getUniqId();
		this.fields = {};
		this.url = '';
		this.show_footer = true;
		this.responsive_details = true;
		this.responsive_priorities = {};
		
		this.select = false;
		
		this.rowCallback = this.defaultRowCallback;
		this.initComplete = this.defaultInitComplete;
		this.columnDefs = false;
		this.order = false;
		
		this.on_select = false;
		this.on_deselect = false;
		this.on_draw = false;
	
		this.language = 'en';
		this.translations = {
			en: {}
		};
	
		this.table = undefined;

		this.pagelen = 10;
		this.lenchanghe = true;
		this.numpag = true;
		
		this.ajax_data = {};
	}
	
	addField(name, label, params = null) {
		if(params !== null && typeof params === 'object' && !Array.isArray(params)){
			params.data = name;
			if(typeof params.name === 'undefined'){
				params.name = name;
			}
			if(typeof params.title === 'undefined'){
				params.title = label;
			}
		} else {
			params = {
				data: name,
				name: name,
				title: label
			};
		}
		this.fields[name] = params;
		
		return this;
	}
	
	removeField(name) {
		this.fields[name] = undefined;
		
		return this;
	}
	
	setResponsiveDetails(details) {
		this.responsive_details = details;
	
		return this;
	}
	
	setResponsivePriority(name, priority) {
		this.responsive_priorities[name] = priority;
		
		return this;
	}
	
	_getHeaders() {
		const fields = [];
		const _this = this;
		Object.keys(this.fields).forEach(function(field){
			const responsive_priority = typeof _this.responsive_priorities[field] !== 'undefined' ? ` data-priority="${_this.responsive_priorities[field]}"`  : '';
			if(_this.fields[field]){
				fields.push(`<th${responsive_priority}>${_this.fields[field].title}</th>`);
			}
		});
		return fields.join('');
	}
	
	_getColumns() {
		const columns = [];
		const _this = this;
		Object.keys(this.fields).forEach(function(field){
			if(_this.fields[field]){
				columns.push(_this.fields[field]);
			}
		});
		return columns;
	}

	setUrl(url) {
		this.url = url;
		
		return this;
	}
	
	setShowFooter(show) {
		this.show_footer = show;
		
		return this;
	}
	
	setSelect(select) {
		this.select = select;
		
		return this;
	}
	
	setRowCallback(rowCallback){
		this.rowCallback = rowCallback;
		
		return this;
	}

	setInitComplete(initComplete){
		this.initComplete = initComplete;
		
		return this;
	}
	
	defaultRowCallback(row, data){
		if(typeof data.id !== 'undefined'){
			$(row).attr('data-id', data.id);
		}
	}

	defaultInitComplete(){
	}
	
	setColumnDefs(columnDefs){
		this.columnDefs = columnDefs;
		
		return this;
	}
	
	setOrder(order){
		this.order = order;
		
		return this;
	}
	
	_getOrder() {
		return this.order ? this.order : [[0, 'asc']]; // default value according to https://datatables.net/reference/option/order
	}

	setOnSelect(on_select) {
		this.on_select = on_select;
		
		return this;
	}
	
	setOnDeselect(on_deselect) {
		this.on_deselect = on_deselect;
		
		return this;
	}
	
	setOnDraw(on_draw){
		this.on_draw = on_draw;

		return this;
	}

	setLanguage(language) {
		this.language = language;
		
		return this;
	}
	
	setTranslation(field, string = undefined, language = undefined) {
		const lang = language || this.language;
		
		if (typeof field !== 'string') {
			this.translations[lang] = field;
		} else {
			this.translations[lang][field] = string;
		}
		
		return this;
	}

	setPageLen(pagelen){
		this.pagelen = pagelen;
		return this;
	}

	setLenChanghe(lenchanghe){
		this.lenchanghe = lenchanghe;
		return this;
	}

	setNumPag(numpag){
		this.numpag = numpag;
		return this;
	}
	
	setAjaxData(dataOrCallback){
		this.ajax_data = dataOrCallback;
		
		return this;
	}
	
	getTable() {
		return this.table;
	}
	
	render() {
		const headers = this._getHeaders();
		const footer = this.show_footer ? `<tfoot><tr>${headers}</tr></tfoot>` : '';
		return `<table id="${this.name}" class="${this.classes.join(' ')}">
			<thead>
				<tr>${headers}</tr>    
			</thead>
			${footer}
		</table>`;
	}

	activate() {
		const params = {
			processing: true,
			serverSide: true,
			responsive: this.responsive_details ? true : { details: false },
			ajax: {
				url: API_URL + this.url,
				xhrFields: { withCredentials: true },
				error: function (data, textStatus, errorThrown) {
					$(document).trigger('message', ['error', data.responseJSON.error]);
				},
				data: this.ajax_data
			},
			destroy: true,
			columns: this._getColumns(),
			rowCallback: this.rowCallback,
			order: this._getOrder(),
			select: this.select,
			language: this.translations[this.language],
			initComplete: this.initComplete,
			pageLength: this.pagelen,
			lengthChange: this.lenchanghe,
			paging: this.numpag
		};
		if(this.columnDefs){
			params.columnDefs = this.columnDefs;
		}

		this.table = $('table#' + this.name).DataTable(params);

		if (this.on_select) {
			this.table.on('select', this.on_select);
			this.table.on('deselect', this.on_deselect);
		}
		if(this.on_draw){
			this.table.on('draw', this.on_draw);
		}
	}
}
