export default class Opener {

	constructor() {
		this.url = 'about:blank';
		this.target = '_top';
	}
	
	setUrl(url) {
		this.url = url;
		
		return this;
	}
	
	setTarget(target) {
		this.target = target;
		
		return this;
	}
	
	static open(url = false, target = false) {
		setTimeout(() => { // workaround for Safari
			window.open(url ? url : this.url, target ? target : this.target);
		});
	}

}
