import Component from '../../../../core/js/components/Component';

const $ = window.jQuery;

export default class Tabs extends Component {

    constructor(){
        super();
        this.tabs = [];
        this.initalTab = 0;
    }

    setInitialTab(index) {
        if (index > this.tabs.length - 1) {
            throw Error(`The index ${index} is not valid with ${this.tabs.length} tabs`);
        }
        this.initalTab = index;
        return this;
    }

    addTabs(tabs) {
        this.tabs.push(...tabs);
        this.initListeners();
        return this;
    }

    activate() {
        super.activate();
        console.log("activate");
        // TODO: aggiungere listeners qui
    }

    initListeners() {
        this.tabs.forEach((tab) => {
            $(document).on('click', `[data-tab="${tab.name}"]`, (e) => {

                // Switch active class
                $('.tabs-group__header--active').removeClass('tabs-group__header--active');
                $(e.target).addClass('tabs-group__header--active');

                // Show tab content
                $('.tabs-group__content--active').removeClass('tabs-group__content--active');
                const targetTab = $(`#${e.target.dataset.tab}`);
                targetTab.addClass('tabs-group__content--active');
            });
        });
    }

    headingsRenderer() {
        return this.tabs.map((tab, i) => {
            const isActive = i === this.initalTab;
            return `
                <button 
                  class="tabs-group__header ${isActive ? 'tabs-group__header--active' : ''}"
                  data-tab="${tab.name}"
                >
                  ${tab.label}
                </button>
            `;
        }).join('');
    }

    render(){
        const tabsContentHtml = this.tabs.map((tab, i) => {
            return tab
                .removeClasses('tabs-group__content--active')
                .addClasses(i === this.initalTab ? 'tabs-group__content--active' : '')
                .render();
        }).join('');

        return `
            <div class="tabs-group">
              <div class="tabs-group__headers">
                ${this.headingsRenderer()}
              </div>
              <div class="tabs-group__body">
                ${tabsContentHtml}
              </div>
            </div>
        `;
    }

}
