const $ = window.jQuery;

import FormField from './FormField';

export default class CheckboxField extends FormField {
	
	constructor(){
		super();
		
		this.name = 'input';
		this.setType('checkbox');
		
		this.checked = false;
	}
	
	setChecked(value){
		this.checked = Boolean(value);
		
		return this;
	}
	
	getValue() {
		if(!$(`#${this.name}`).is(':checked')){
			return null;
		}
		return super.getValue();
	}
	
	render(){
		const isChecked = this.checked ? ' checked' : '';
		return this.wrap(`<input id="${this.name}" class="${this.classes.join(' ')}" type="${this.type}" name="${this.field_name}" value="${this.value}"${isChecked}>`);
	}
}
