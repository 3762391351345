import { default as BaseUserRolesApi } from "../../../core/js/api/UserRolesApi";

export default class UserRolesApi extends BaseUserRolesApi {

	crudUrl() {
		return super.crudUrl();
	}

	deleteError(data, textStatus, errorThrown) {
	}

}
