const $ = window.jQuery;

import Component from '../../../../core/js/components/Component';
import _ from 'lodash';

export default class Accordion extends Component {

	constructor() {
		super();
		this.name = this.getUniqId();
		this.field_name = '';

		this.title = undefined;
		this.items = [];

		this.noValueFieldTypes = ['image', 'submit', 'button'];

		this.dataAttribute = {};

		this.showArrowIcon = false;
		this.showDocumentsCount = false;
		this.documentsCount = 0;
		
		this.formsInvalidFields = [];
		this.debounced_handleFormsInvalidFields = _.debounce(this.handleFormsInvalidFields, 500);
	}

	setDataAttribute(key, value) {
		this.dataAttribute = { key, value };
		return this;
	}

	setFieldName(name) {
		this.field_name = name;

		return this;
	}

	addTitle(title) {
		this.title = title;

		return this;
	}

	addItem(item) {
		this.items.push(item);

		return this;
	}

	getValues() {
		const values = {};
		this.items.forEach((item) => {
			if (item.content) {
				if (typeof item.content.getValues === 'function') { // components supporting getValues() function
					$.extend(values, item.content.getValues());
				} else if (typeof item.content.getValue === 'function') { // components supporting getValue() function
					if (typeof item.content.type !== 'undefined' && this.noValueFieldTypes.indexOf(item.content.type) === -1) { // excluding FormFields without value
						const value = item.getValue();
						if (value !== null) {
							values[item.content.field_name] = value;
						}
					}
				}
			}
		}, this);
		return values;
	}

	setShowArrowIcon(show) {
		this.showArrowIcon = show;
		return this;
	}

	setDocumentsCount(count) {
		this.documentsCount = count;
		return this;
	}

	setShowDocumentsCount(show) {
		this.showDocumentsCount = show;
		return this;
	}

	render() {
		let html = '';
		this.items.forEach((item) => {
			html += `<li class="item">
				<h4>
					<label>${item.label}</label>
					<div class="accordion-icons">
						${
							this.showDocumentsCount  ?
								`<div class="attachments-count"><img src="/assets/images/attachment.svg"><span>${this.documentsCount || '-'}</span></div>`
								: ''
							}					
						${this.showArrowIcon ? '<img src="/assets/images/chevron-down-black.svg" class="open-accordion" alt="open">' : ''}
					</div>
				</h4>
				<section>${(typeof item.content === 'object' && typeof item.content.render === 'function') ? item.content.render() : item.content}</section>
			</li>`;
		});

		$(document).on('click', `#${this.name} li > h4`, function () {
			$(this).closest('.item').toggleClass('open').siblings().removeClass('open');
		});


		let dataAttributeHtml = '';
		const { key, value } = this.dataAttribute;
		if (key && value) {
			dataAttributeHtml = `data-${key}="${value}"`;
		}

		return `<div id="${this.name}" class="accordion-container ${this.classes.join(' ')}" ${dataAttributeHtml}>
			${typeof this.title === 'undefined' ? '' : '<header>' + this.title + '</header>'}
			<ul class="accordion">
			${html}
			</ul>
		</div>`;
	}

	activate() {
		const _this = this;
		
		// activate every Component included in the items
		this.items.forEach(function (item) {
			if (typeof item.content === 'object' && typeof item.content.activate === 'function') {
				item.content.activate();
			}
		});
		
		// catch HTML5 invalid fields of forms included in the accordion
		$('input, textarea, select', `#${this.name}`).on('invalid', function(e){
			_this.formsInvalidFields.push(e.target);
			_this.debounced_handleFormsInvalidFields();
		});
	}

	validate() {
		// validate every Component included in the items
		this.items.forEach(function (item) {
			if (typeof item.content === 'object' && typeof item.content.validate === 'function') {
				item.content.validate();
			}
		});
	}
	
	handleFormsInvalidFields(){
		$('input, textarea, select', `#${this.name}`).removeClass('invalid');
		if(this.formsInvalidFields.length > 0){
			const target = this.formsInvalidFields[0];
			this.formsInvalidFields = [];
			$(target).addClass('invalid').closest('.accordion > .item').closest('.accordion > .item').addClass('open').siblings().removeClass('open'); // open accordion item with invalid field
			target.focus(); // focus on invalid field
		}
	}

}
