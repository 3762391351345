/**
 * Abstract Class Api
 *
 * Extend it to create your own Api calls.
 * Must implement method call() where success_cb is the callback in case of success ajax call
 */
const $ = window.jQuery;

export default class Api {

  constructor(method, url, data) {
    this.method = '';
    this.url = '';
    this.data = {};
    this.error_cb = undefined;
    this.finally_cb = undefined;
    this.target_element = undefined;
    this.form = undefined;
    this.dynamic_param = undefined;
    this.url_suffix = undefined;
	this.useFormData = false;
    this.showAjaxLoader = true;

    if (this.constructor === Api) {
      throw new Error("You cannot instantiate Api abstract class");
    }
    if (typeof method !== 'undefined' && typeof url !== 'undefined') {
      [ this.method, this.url, this.data ] = [ method, url, typeof data === 'undefined' ? {} : data ];
    }
  }

  setShowAjaxLoader(showAjaxLoader) {
    this.showAjaxLoader = showAjaxLoader;
    return this;
  }

  async __call(success_cb, returnXHR = false) {
    const error_cb = typeof this.error_cb === 'function' ? this.error_cb : this.__errorCallback;
    const target_element = typeof this.target_element === 'string' ? this.target_element : 'body';
    const _this = this;
    let result = {};
	let params = {
      url: API_URL +
        this.url +
        (typeof this.dynamic_param !== 'undefined' ? '/' + this.dynamic_param : '') +
        (typeof this.url_suffix !== 'undefined' ? this.url_suffix : ''),
      type: this.method,
      data: this.data,
      xhrFields: { withCredentials: true },
      beforeSend: function (data, settings) {
        if (_this.showAjaxLoader) {
          $(target_element).addClass('loading');
        }
      },
      error: function (data, textStatus, errorThrown) {
		if (data.status === 401) { // unauthorized: not logged in
			window.PROFILE = undefined;
			$(document).trigger('login'); // back to login screen
			return false;
		}
        result = error_cb.bind(_this)(JSON.parse(data.responseText), textStatus, errorThrown);
      },
      success: function (data, textStatus, jqXHR) {
        if (jqXHR.status != 204) {
          $('document').trigger({
            type: 'content-loaded',
            method: _this.method,
            url: _this.url,
            data,
          });
        }
        result = success_cb.bind(_this)(data);
      },
      complete: function (data, textStatus) {
        if (_this.showAjaxLoader) {
          $(target_element).removeClass('loading');
		}
        if (typeof _this.finally_cb === 'function') {
          _this.finally_cb.bind(_this)(data, textStatus);
        }
      },
    };
	if(this.useFormData){
		let formData = new FormData();
		for(let fieldName in this.data){
			if(Array.isArray(this.data[fieldName]) || this.data[fieldName] instanceof FileList){ // array or FileList
				for(let key in this.data[fieldName]){
					formData.append(fieldName + '[]', this.data[fieldName][key]);
				}
			} else { // plain
				formData.append(fieldName, this.data[fieldName]);
			}
		}
		params.data = formData;
		params.processData = false;
		params.contentType = false;
	}
	
    const xhr = await $.ajax(params).catch(e => { return e; });

    return returnXHR ? xhr : (result?.responseJSON ? result.responseJSON : result);
  }

  /**
   * Implement this method while extending this class.
   *
   * Must call protected __call(success_cb) method with a callback that handles data parameter
   */
  async call() {
    throw new Error("Please implement call() function on your " + typeof this + " class: must call protected __call(success_cb) function");
  }

  /**
   * Set your own custom error callback.
   * Should handle data, textStatus, errorThrown parameters.
   *
   * @param error_cb function
   */
  setErrorCallback(error_cb) {
    this.error_cb = error_cb;
  }

  /**
   * Set your own custom finally callback.
   * Should handle data, textStatus parameters.
   *
   * @param finally_cb function
   */
  setFinallyCallback(finally_cb) {
    this.finally_cb = finally_cb;
  }

  /**
   * Set your own target element: loading class will be attached to this element.
   *
   * @param error_cb string
   */
  setTargetElement(target_element) {
    this.target_element = target_element;
	
	return this;
  }

  setForm(form) {
    if (typeof form === 'object' && form.name) {
      this.form = form;
    } else {
      throw 'Invalid form: ' . form;
    }
  }

  setDynamicParam(param) {
    this.dynamic_param = param;

    return this;
  }

  setUrlSuffix(suffix) {
    this.url_suffix = suffix;

    return this;
  }

  __errorCallback(data, textStatus, errorThrown) {
    switch (data.statusCode) {
      case 500: // Handle server error
        $(document).trigger('500', [ this.url ]);
        break;
      case 503: // Handle server maintenance
        $(document).trigger('503', [ this.url ]);
        break;
      case 403:
        $(document).trigger('403', [ this.url ]);
        break;
      case 404:
        $(document).trigger('404', [ this.url ]);
        break;
      case 400:
        // Handle form errors
        if (this.form) {
          this.form.setErrors(data.error);
        } else {
          throw JSON.stringify(data.error);
        }
        break;
    }

    return data;
  }
}