const $ = window.jQuery;

import Component from '../../../../core/js/components/Component';

export default class Tab extends Component {

    constructor(){
        super();
        this.label = null;
        this.content = null;
        this.name = this.getUniqId();
    }

    setLabel(label) {
        this.label = label;
        return this;
    }

    setContent(content) {
        this.content = content;
        return this;
    }

    addClasses(classes) {
        return super.addClasses(classes);
    }

    render(){
        const tabContent = this.content.render();
        const tabContentClasses = this.classes.length ? this.classes.join(' ') : '';
        return `
            <div id="${this.name}" class="tabs-group__content ${tabContentClasses}">
              ${tabContent}
            </div>
        `;
    }

}
