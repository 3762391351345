const $ = window.jQuery;

import Page from './Page';
import ScaryDataTable from '../components/Plugins/ScaryDataTable';
import ActionsMenu from '../components/ActionsMenu/ActionsMenu';
import ActionsMenuItem from '../components/ActionsMenu/ActionsMenuItem';
import Footer from '../../../core/js/components/Form/Footer';
import _ from 'lodash';

export default class SettingsPage extends Page {
	
	constructor(){
		super();
		
		this.setTitle('Settaggi aziende')
			.setMeta('description', 'Impostazioni generali per aziende')
			.setBreadcrumb('Settaggi aziende');
	}
	
	content(){
		const _page = this;
		
		//
		// actions menu
		//
		
		const actions_menu = new ActionsMenu();
		actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-settings']);
		actions_menu.addUlClasses('actions-menu');
		
		const btn_edit = new ActionsMenuItem();
		btn_edit
			.addClasses(['element', 'only-icon', 'edit', 'disabled'])
			.setTitle('Modifica')
			.setRoles(['RD_ADMIN_SETTINGS']);
			
		actions_menu.addItems([
			btn_edit
		]);

		//
		// settings table
		//
		
		// quick-open dt row on double-click
		$(document).on('dblclick', '.dt-settings tbody tr', function(){
			const targetId = $(this).attr('data-id');
			if(typeof targetId !== 'undefined'){
				$.spa.navigate(`/settings/manage/${targetId}`);
			}
		});
		
		// handle dt_offers select/deselect
		const dt_settings_onSelectDeselect = function(e, dt, type, indexes){
			
			// check for selected rows
			const targets = [];
			if (type === 'row') {
				dt.rows({selected: true}).every(function(idx){
					if(typeof dt.row(idx).data().id !== 'undefined'){
						targets.push(dt.row(idx));
					}
				});
			}
			
			$(`#${btn_edit.name}`)
				.off('click')
				.toggleClass('disabled', targets.length === 0);
			
			if(targets.length > 0){
				$(`#${btn_edit.name}:not(.disabled)`).on('click', () => $.spa.navigate(`/settings/manage/${targets[0].data().id}`));
			}
			
		};
		const debounced_dt_settings_onSelectDeselect = _.debounce(dt_settings_onSelectDeselect, 100);
	
		const dt_settings = new ScaryDataTable();
		dt_settings
			.setUrl('/settings')
			.setSelect('single')
			.setOnSelect(debounced_dt_settings_onSelectDeselect)
			.setOnDeselect(debounced_dt_settings_onSelectDeselect)
			.addField('id', 'ID')
			.addField('name', 'Nome')
			.addClasses('dt-settings');
		
		// render
		$('main .main').html(actions_menu.render() + dt_settings.render() + Footer.render());
		dt_settings.activate();
		Footer.activate(this);
	}
	
}
