const $ = window.jQuery;

import _ from "lodash";
import Page from "./Page";
import ActionsMenu from "../components/ActionsMenu/ActionsMenu";
import ActionsMenuItem from "../components/ActionsMenu/ActionsMenuItem";
import ScaryDataTable from "../components/Plugins/ScaryDataTable";
import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
import BrandApi from "../api/BrandApi";
import Footer from "../../../core/js/components/Form/Footer";

export default class BrandsPage extends Page {

  constructor() {
    super();
  }

  async init() {

    this.setTitle('Marchi')
      .setMeta('description', 'Marchi dei Prodotti')
      .setBreadcrumb('Marchi');

    return super.init();
  }

  content() {
	
	//
	// actions menu
	//
	
    const actions_menu = new ActionsMenu();
	actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-brands']);
	actions_menu.addUlClasses('actions-menu');
	
	const btn_add = new ActionsMenuItem();
	btn_add
		.addClasses([ 'element', 'only-icon', 'add' ])
		.setTitle('Aggiungi')
		.setOn('click', () => $.spa.navigate('/brand/manage/create'))
		.setRoles('RD_ADMIN_USERS');
		
	const btn_edit = new ActionsMenuItem();
	btn_edit
		.addClasses([ 'element', 'only-icon', 'edit', 'disabled' ])
		.setTitle('Modifica')
		.setRoles('RD_ADMIN_USERS');
		
	const btn_delete = new ActionsMenuItem();
	btn_delete
		.addClasses([ 'element', 'only-icon', 'delete', 'disabled' ])
		.setTitle('Elimina')
		.setRoles('RD_ADMIN_USERS');
		
	actions_menu.addItems([
		btn_add,
		btn_edit,
		btn_delete
	]);

	//
	// brands table
	//
	
	// $.confirm delete
	const confirmDelete = new ScaryJqueryConfirm();
	confirmDelete
		.setTitle('Attenzione')
		.setContent('Sei sicuro di voler cancellare il marchio?');
		
	// quick-open dt row on double-click
	$(document).on('dblclick', '.dt-brands tbody tr', function(){
		let targetId = parseInt($(this).closest('tr').find('td').first().text());
		$.spa.navigate(`/brand/manage/${targetId}`);
	});
		
	// handle dt_brands select/deselect
	const dt_brands_onSelectDeselect = function(e, dt, type, indexes){
		
		// check for selected rows
		let targetIds = [];
		if (type === 'row') {
			dt.rows({selected: true}).every(function(idx){
				const targetId = $(dt.row(idx).node()).attr('data-id');
				if(typeof targetId !== 'undefined'){
					targetIds.push(parseInt(targetId));
				}
			});
		}
		
		$(`#${btn_edit.name}, #${btn_delete.name}`)
			.off('click')
			.toggleClass('disabled', targetIds.length === 0);
		
		if(targetIds.length > 0){
			$(`#${btn_edit.name}`).on('click', () => $.spa.navigate(`/brand/manage/${targetIds[0]}`));
			$(`#${btn_delete.name}`).on('click', () => {
				confirmDelete
					.modalConfirm(async (data) => {
						const brandApi = new BrandApi();
						await brandApi.delete(targetIds[0])
							.then(() => {
								$.spa.navigate('/brands', true);
							})
							.catch((e) => {
								$(document).trigger('message', ['error', e.responseJSON.error]);
							});
					})
					.render();
			});
		}
		
	};
	const debounced_dt_brands_onSelectDeselect = _.debounce(dt_brands_onSelectDeselect, 100);
	
	const dt_brands = new ScaryDataTable();
	dt_brands
		.setUrl('/brand')
		.setSelect('single')
		.setOnSelect(debounced_dt_brands_onSelectDeselect)
		.setOnDeselect(debounced_dt_brands_onSelectDeselect)
		.addField('id', 'ID')
		.addField('name', 'Nome')
		.setResponsivePriority('id', 0)
		.setResponsivePriority('name', 1)
		.addClasses('dt-brands');

	// render
	$('main .main').html(actions_menu.render() + dt_brands.render() + Footer.render());
    dt_brands.activate();
	Footer.activate(this);
  }

}