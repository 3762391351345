const $ = window.jQuery;

import FormField from "./FormField";

class File {
	constructor(id, url, name, type, size) {
		this.id = id;
		this.url = url;
		this.name = name;
		this.type = type;
		this.size = size;
	}
}

export default class FileField extends FormField {

	constructor(){
		super();
	
		this.name = 'input';
		this.setType('file');
		this.maxsize = undefined;
		this.mimetypes = [];
		this.file = undefined;
	}
	
	setValue(value){
		if(value !== null && typeof value === 'object' && Object.hasOwn(value, 'fileurl')){
			this.file = new File(
				value.id,
				value.fileurl,
				value.filename_original,
				value.filetype,
				value.filesize
			);
		}
		return this;
	}
	
	setMaxSize(maxsize){
		this.maxsize = maxsize;
		return this;
	}
	
	getMaxSize(){
		return this.maxsize;
	}

	addMimeTypes(mimetypes){
		if(!Array.isArray(mimetypes)){
			mimetypes = [mimetypes];
		}
		mimetypes.forEach(function(mimetype){
			this.mimetypes.push('.' + mimetype);
		}, this);
		return this;
	}
	
	getMimeTypes(){
		return this.mimetypes;
	}
	
	getFiles(){
		if($(`#${this.name}`).length){
			return $(`#${this.name}`)[0].files;
		}
		return false;
	}

	getDefaultValue(){
		if(!(this.file instanceof File)){
			return '';
		}
		let content = '';
		switch(this.file.type){
			// images
			case 'jpg':
			case 'png':
				content = `<a class="image" title="${this.file.name}" href="${this.file.url}" target="_blank"><img src="${this.file.url}" alt="${this.file.name}"></a>`;
				break;
			// documents
			default:
				content = `<a class="file" title="${this.file.name}" href="${this.file.url}" target="_blank"><img src="/assets/images/pdf.svg" alt="${this.file.name}"></a>`;
		}
		if(!this.disabled && !this.readonly){
			content += `<a class="delete" title="Rimuovi"><img src="/assets/images/remove.svg" alt="Rimuovi"></a>`;
		}
		return `<div class="file-content" data-id="${this.file.id}">${content}</div>`;
	}

	render() {
		const description = this.placeholder.length ? `<span class="file-description">${this.placeholder}</span>` : '';
		const disabled = this.disabled ? ' disabled' : '';
		const required = this.required ? ' required' : '';
		const maxsize = this.getMaxSize() ? `<span class="file-maxsize"><label>Max:</label> ${this.getMaxSize()}</span>` : '';
		const mimetypes = this.getMimeTypes() ? `<span class="file-mimetypes">(${this.getMimeTypes().join(', ')})</span>` : '';
		const readonly = this.readonly ? ' readonly' : '';
		return this.wrap(
			`<div class="field-content">
				${this.getDefaultValue()}
				${description}
				<input class="${this.classes.join(' ')}" type="${this.type}" id="${this.name}" name="${this.field_name}[]" accept="${this.getMimeTypes().join(', ')}"${required}${disabled}${readonly}>
				<div class="file-specs">${maxsize}${mimetypes}</div>
			</div>`
		);
	}
}