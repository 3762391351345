import FormField from './FormField';
//import Cleave from 'cleave.js';

export default class SeparatorField extends FormField {
	
	constructor(){
		super();
	}

	render(){
		return this.wrap(`<hr class="${this.classes.join(' ')}">`);
	}
	
	activate(){
	}
}
