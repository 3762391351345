import RelationalForm from "./RelationalForm";

export default class ManyToManyForm extends RelationalForm {

  constructor() {
    super();

    this.type = 'manytomany';

    this.name = 'manytomany-form';
    this.name = this.getUniqId();
  }

}