import CrudApi from "../../../core/js/api/CrudApi";

export default class OfferProductShippingApi extends CrudApi {
  
  constructor() {
    super();

    this.offerId = undefined;
    this.productId = undefined;
  }

  setOfferId(offer_id) {
    this.offerId = offer_id;
    return this;
  }

  setProductId(product_id) {
    this.productId = product_id;
    return this;
  }
  
  crudUrl() {
    return '/offer/'+this.offerId+'/product/'+this.productId+'/shipping/'; 
  }

}
