import CrudApi from "../../../core/js/api/CrudApi";

export default class FinancialTypeApi extends CrudApi {

    constructor() {
        super();
        this.type = undefined;
        this.price = undefined;
        this.duration = undefined;
    }

    setType(type) {
        this.type = type;
        return this;
    }

    setPrice(price) {
        this.price = price;
        return this;
    }

    setDuration(duration) {
        this.duration = duration;
        return this;
    }

    crudUrl() {
        return '/financial/type/'+this.type+'/price/'+this.price+'/duration/'+this.duration; 
    }

}
