const $ = window.jQuery;

import Page from "./Page";
import ActionsMenu from "../components/ActionsMenu/ActionsMenu";
import ActionsMenuItem from "../components/ActionsMenu/ActionsMenuItem";
import ScaryDataTable from "../components/Plugins/ScaryDataTable";
import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
import CustomerApi from "../api/CustomerApi";
import _ from "lodash";
import Footer from "../../../core/js/components/Form/Footer";

export default class CustomersPage extends Page {

  constructor() {
    super();
  }

  async init() {

    this.setTitle('Clienti')
      .setMeta('description', 'Clienti')
      .setBreadcrumb('Clienti');

    return super.init();
  }

  content() {
	
	//
	// actions menu
	//
	
    const actions_menu = new ActionsMenu();
	actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-customers']);
	actions_menu.addUlClasses('actions-menu');
	
	const btn_add = new ActionsMenuItem();
	btn_add
		.addClasses([ 'element', 'only-icon', 'add' ])
		.setTitle('Aggiungi')
		.setOn('click', () => $.spa.navigate('/customer/manage/create'))
		.setRoles(['RD_ADMIN_CUSTOMERS', 'RD_OWN_CUSTOMERS']);
		
	const btn_edit = new ActionsMenuItem();
	btn_edit
		.addClasses([ 'element', 'only-icon', 'edit', 'disabled' ])
		.setTitle('Modifica')
		.setRoles(['RD_ADMIN_CUSTOMERS', 'RD_OWN_CUSTOMERS']);
		
	const btn_delete = new ActionsMenuItem();
	btn_delete
		.addClasses([ 'element', 'only-icon', 'delete', 'disabled' ])
		.setTitle('Elimina')
		.setRoles(['RD_ADMIN_CUSTOMERS', 'RD_OWN_CUSTOMERS']);

	/*const btn_locations = new ActionsMenuItem();
	btn_locations
		.addClasses([ 'element', 'only-icon', 'edit', 'disabled' ])
		.setTitle('Sedi')
		.setRoles('RD_ADMIN_LOCATIONS');*/
		
	actions_menu.addItems([
		btn_add,
		btn_edit,
		btn_delete
	]);

	//
	// customers table
	//
	
	// $.confirm delete
	const confirmDelete = new ScaryJqueryConfirm();
	confirmDelete
		.setTitle('Attenzione')
		.setContent('Sei sicuro di voler cancellare il cliente?');

	// quick-open dt row on double-click
	$(document).on('dblclick', '.dt-customers tbody tr', function(){
		let targetId = parseInt($(this).closest('tr').find('td').first().text());
		$.spa.navigate(`/customer/manage/${targetId}`);
	});
		
	// handle dt_customers select/deselect
	const dt_customers_onSelectDeselect = function(e, dt, type, indexes){
		
		const targets = [];
		if (type === 'row') {
			dt.rows({selected: true}).every(function(idx){
				if(typeof dt.row(idx).data().id !== 'undefined'){
					targets.push(dt.row(idx));
				}
			});
		}

		$(`#${btn_edit.name}`)
			.off('click')
			.toggleClass('disabled', targets.length === 0);

		$(`#${btn_delete.name}`)
			.off('click')
			.toggleClass('disabled', targets.length === 0 || targets[0].data().erp === '1');
		
		if (targets.length > 0) {

			$(`#${btn_edit.name}`).on('click', () => $.spa.navigate(`/customer/manage/${targets[0].data().id}`));

			$(`#${btn_delete.name}:not(.disabled)`).on('click', () => {
				confirmDelete
					.modalConfirm(async (data) => {
						const customerApi = new CustomerApi();
						await customerApi.delete(targets[0].data().id)
							.then(() => {
								$.spa.navigate('/customers', true);
							})
							.catch((e) => {
								$('body').removeClass('loading');
								$(document).trigger('message', ['error', e.responseJSON.error]);
							});
					})
					.render();
			});

		}
		
	};
	const debounced_dt_customers_onSelectDeselect = _.debounce(dt_customers_onSelectDeselect, 100);
	
	const dt_customers = new ScaryDataTable();
	dt_customers
		.setUrl('/customer')
		.setSelect('single')
		.setOnSelect(debounced_dt_customers_onSelectDeselect)
		.setOnDeselect(debounced_dt_customers_onSelectDeselect)
		.addField('id', 'ID')
		.addField('code', 'Codice')
		.addField('company_name', 'Rag. Soc.')
		.addField('activitydes', 'Attività') //aggiunto alias activitydes per traduzione enum in italiano
		.addField('vat', 'P.IVA')
		.addField('regOffice', 'Sede Legale')
		.addField('full_name', 'Agente')
		/*.addField('email', 'Agente Email')
		.addField('fiscalcode', 'C.F.')
		.addField('sdi', 'SDI')
		.addField('pec', 'PEC')*/
		/*.addField('address', 'Indirizzo ')
		.addField('city', 'Città')
		.addField('province', 'Prov.')
		.addField('country', 'Stato')*/
		//.addField('ref_offer', 'Referente')
		//.addField('ref_offer_phone', 'Telefono')
		//.addField('ref_delivery', 'Referente')
		//.addField('ref_delivery_phone', 'Telefono')
		//.addField('ref_delivery_email', 'Email')
		.setResponsivePriority('id', 0)
		.setResponsivePriority('company_name', 3)
		.addClasses('dt-customers');

	// render
	$('main .main').html(actions_menu.render() + dt_customers.render() + Footer.render());
    dt_customers.activate();
	Footer.activate(this);
  }

}