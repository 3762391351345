const $ = window.jQuery;

import Component from "../Component";
import _ from "lodash";

export default class Menu extends Component {

  constructor() {
    super();

    this.name = 'menu';
    this.ul_classes = [];
    this.items = [];
    this.child_class = 'MenuItem';

    this.child = false;

    this.item = '';
    this.id = this.getUniqId();
  }

  addItems(items) {
    if (Array.isArray(items)) {
      const _this = this;
      items.forEach(function (item) {
        _this._addItem(item);
      });
    } else {
      this._addItem(items);
    }

    return this;
  }

  _addItem(item) {
    if (typeof item === 'object' && typeof item.render === 'function') {
      if (Array.isArray(item.items)) {
        item.child = true;
      }
      this.items.push(item);
    } else {
      throw 'You have to pass a valid Component';
    }

    return this;
  }

  addUlClasses(classes) {
    if (Array.isArray(classes)) {
      this.ul_classes = _.merge(this.ul_classes, classes);
    } else {
      this.ul_classes.push(classes);
    }

    return this;
  }

  setItem(item) {
    item.menu_title = true;
    this.item = item;

    return this;
  }

  removeUlClasses(classes) {
    if (Array.isArray(classes)) {
      this.ul_classes = _.merge(this.ul_classes, classes);
    } else {
      this.ul_classes = this.ul_classes.filter(css_class => !classes.includes(css_class));
    }

    return this;
  }

  _setActive(url) {
    const _this = this;
    let is_active = false;
    this.items.forEach(function (item) {
      if (item.constructor.name === _this.child_class) {
        if (item.href === url) {
          is_active = true;
          $('#' + item.name).addClass('active');
        } else {
          $('#' + item.name).removeClass('active');
        }
      }
    });
    if (typeof this.item === 'object' && this.item.constructor.name === this.child_class) {
      if (is_active) {
        $('#' + this.item.name).addClass('active-parent');
        $('#' + this.id).parents('li').children('[data-scary="menu"]').addClass('active-parent');
      } else {
        $('#' + this.item.name).removeClass('active-parent');
        $('#' + this.id).parents('li').children('[data-scary="menu"]').removeClass('active-parent');
      }
    }

    return this;
  }

  deactivate() {
    $(window).off('init-menu');
  }

  activate() {
    const _this = this;

    $(window).on('init-menu', function (e, url) {
      _this._setActive(url);
    });

    this._setActive($.spa.currentUri());
  }

  render() {
    let code = '';

    this.items.forEach(function (item) {
      code += item.render();
    });

    const htag = this.child ? 'li' : 'nav';
    const title = this.child ? this.item.render() : '';
    const li_classes = this.child ? this.item.classes.join(' ') : this.classes.join(' ');
    
    return `
      <${htag} class="${li_classes}" id="${this.id}">
        ${title}
        <ul class="${this.ul_classes.join(' ')}">
            ${code}
        </ul>
      </${htag}>
    `;
  }

}