const $ = window.jQuery;

import TextField from '../../../../core/js/components/Form/TextField';

export default class NumberField extends TextField {
	
	constructor(){
		super();
		
		this.setType('text');
		this.inputmode = 'decimal';
		this.wrapper_classes.push('numberfield');
		
		this.min = undefined;
		this.max = undefined;
		
		this.stepper = false;
		this.step = undefined;
		
		this.useCleave = true;
		this.cleaveOptions.numeral = true;
		this.cleaveOptions.numeralThousandsGroupStyle = 'none';
		this.cleaveOptions.numeralDecimalMark = ',';
		this.cleaveOptions.delimiter = '.';
		this.cleaveOptions.numeralPositiveOnly = true;
	}
	
	setMin(value){
		this.min = value;
		this.addDataAttribute('min', value);
		
		return this;
	}
	
	setMax(value){
		this.max = value;
		this.addDataAttribute('max', value);
		
		return this;
	}
	
	setStepper(value = true){
		this.stepper = Boolean(value);
		if(this.stepper) {
			this.step = 1;
			this.addWrapperClasses('with-stepper');
		} else {
			this.step = undefined;
			this.removeWrapperClasses('with-stepper');			
		}
		
		return this;
	}
	
	setStep(value){
		this.step = parseFloat(value);
		
		return this;
	}
	
	changeByStep(addition = true){
		let oldValue = parseFloat(this.getValue());
		if(isNaN(oldValue)){
			oldValue = 0;
		}
		let newValue = addition ? oldValue + this.step : oldValue - this.step;
		
		if(typeof this.min !== 'undefined' && newValue < this.min){
			newValue = this.min;
		}
		if(typeof this.max !== 'undefined' && newValue > this.max){
			newValue = this.max;
		}
		if(this.useCleave && this.cleaveOptions.numeralPositiveOnly && newValue < 0){
			newValue = 0;
		}
		
		this.value = newValue;
		$(`#${this.name}`).val(newValue);
		this.validate();
	}
	
	preprocessValue(value){
		if(!isNaN(this.value) && this.useCleave){
			return this.value.toString().replace(/\.([^\.]*)$/, this.cleaveOptions.numeralDecimalMark + '$1'); // replace float dot for decimals with numeralDecimalMark;
		}
		return value;
	}
	
	render(){
		if(this.stepper){
			this.prepend('<span class="step step-down"></span>');
			this.append('<span class="step step-up"></span>');
		}
		return super.render();
	}
	
	activate() {
		if(this.stepper){
			const _this = this;
			$(`#${this.name}`).closest('.with-stepper').find('.step').on('click', function(){
				_this.changeByStep($(this).hasClass('step-up'));
			});
		}
		super.activate();
	}
	
	validate(){
		super.validate();
		if(this.useCleave){
			const $el = $(`#${this.name}`);
			if($el.length){
				if($el[0].reportValidity()){ // if passed default validation
					const min = $el.attr('data-min');
					const max = $el.attr('data-max');
					const value = parseFloat(this.getValue());
					if(typeof min !== 'undefined' && value < min) {
						$el[0].setCustomValidity(`Questo valore non può essere inferiore a ${min}`);
					} else if (typeof max !== 'undefined' && value > max) {
						$el[0].setCustomValidity(`Questo valore non può essere superiore a ${max}`);
					} else {
						$el[0].setCustomValidity('');
					}
				}
			}
		}
	}
	
}
