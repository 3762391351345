const $ = window.jQuery;

import Component from '../../../../core/js/components/Component';

export default class BasicGrid extends Component {

	constructor() {
		super();
		
		this.name = this.getUniqId();
		this.field_name = '';
		
		this.title = false;
		this.items = [];
		
		this.noValueFieldTypes = ['image', 'submit', 'button'];
	}

	setFieldName(name) {
		this.field_name = name;

		return this;
	}

	setTitle(title) {
		this.title = title;

		return this;
	}

	addItem(item) {
		this.items.push(item);

		return this;
	}

	getValues() {
		const values = {};
		this.items.forEach((item) => {
			if (typeof item.getValues === 'function') { // components supporting getValues() function
				$.extend(values, item.getValues());
			} else if (typeof item.getValue === 'function') { // components supporting getValue() function
				if (typeof item.type !== 'undefined' && this.noValueFieldTypes.indexOf(item.type) === -1) { // excluding FormFields without value
					const value = item.getValue();
					if (value !== null) {
						values[item.field_name] = value;
					}
				}
			}
		}, this);
		return values;
	}

	render() {
		let items = '';
		this.items.forEach(function(item){
			items += item.render();
		});
		
		const title = this.title ? `<header>${this.title}</header>` : '';
		return `<div id="${this.name}" class="basicgrid-container ${this.classes.join(' ')}">
			${title}
			<ul class="basicgrid">${items}</ul>
		</div>`;
	}

	activate() {
		// activate every Component included in the items
		this.items.forEach(function (item) {
			if (typeof item.content === 'object' && typeof item.content.activate === 'function') {
				item.content.activate();
			}
		});
	}

	validate() {
		// validate every Component included in the items
		this.items.forEach(function (item) {
			if (typeof item.content === 'object' && typeof item.content.validate === 'function') {
				item.content.validate();
			}
		});
	}

}
