const $ = window.jQuery;
import "../../lib/js/scary-router/src/jquery.spa";

export default class Page {

  constructor() {
    this.title = '';
    this.breadcrumb = [];
  }

  getTitle() {
    return this.title;
  }

  setTitle(title) {
    this.title = title;
    $(document).prop('title', title + ' ¦ ' + APPNAME);

    return this;
  }

  getMeta(name) {
    return $('meta[name="' + name + '"]').attr("content");
  }

  setMeta(name, value) {
    if ($('meta[name="' + name + '"]').length === 0) {
      $('title').after('<meta name="' + name + '">');
    }
    $('meta[name="' + name + '"]').attr("content", value);

    return this;
  }

  delMeta(name) {
    $('meta[name="' + name + '"]').remove();

    return this;
  }

  refresh() {
    $.spa.refresh();
  }

  setBreadcrumb(breadcrumb) {
    this.breadcrumb = breadcrumb;

    return this;
  }

  getBreadcrumb() {
    let breadcrumb = '', single = '';
    let _this = this;
    if (Array.isArray(this.breadcrumb)) {
      let count = 0;
      this.breadcrumb.forEach(function (elem) {
        count++;
        breadcrumb += '<li class="breadcrumb breadcrumb-' + count + '">' + _this._parseBreadcrumbLink(elem) + '</li>';
      });
      if (count === 1) {
        single = ' single';
      }
    } else {
      breadcrumb = '<li class="breadcrumb breadcrumb-1">' + this._parseBreadcrumbLink(this.breadcrumb) + '</li>';
      single = ' single';
    }

    return `<ul class="breadcrumb-wrapper${single}">${breadcrumb}</ul>`;
  }

  _parseBreadcrumbLink(breadcrumb) {
    return typeof breadcrumb === 'string' ? breadcrumb : `<a href="${breadcrumb.href}">${breadcrumb.label}</a>`;
  }
}