const $ = window.jQuery;

import Component from "../Component";
import _ from "lodash";

export default class FormField extends Component {

  constructor(props) {
    super(props);

    this.name = 'formfield';
    this.field_name = '';
    this.type = undefined;
    this.value = '';
    this.placeholder = '';
    this.required = false;
    this.disabled = false;
    this.readonly = false;
    this.wrapper_classes = [];
    this.label = '';
    this.append_code = [];
    this.prepend_code = [];
	this.data_attributes = [];
  }

  setType(type) {
    this.type = type;
    this.name = this.name + '-' + type + '-' + this.getUniqId(true);

    return this;
  }

  setValue(value) {
    this.value = value === null ? '' : value;

    return this;
  }

  setLabel(label) {
    this.label = label;

    return this;
  }

  setPlaceholder(placeholder) {
    this.placeholder = placeholder;

    return this;
  }

  setFieldName(name) {
    this.field_name = name;

    return this;
  }

  setRequired(required) {
    this.required = required;
    if (required) {
      this.addClasses('required');
      this.append('<span class="required"></span>');
    } else {
      this.removeClasses('required');
      this.append_code = this.append_code.filter(function (code) {
        return code !== '<span class="required"></span>';
      });
    }

    return this;
  }

  setDisabled() {
    this.disabled = true;

    return this;
  }

  setReadonly() {
    this.readonly = true;

    return this;
  }

  addWrapperClasses(classes) {
    if (Array.isArray(classes)) {
      this.wrapper_classes = _.merge(this.wrapper_classes, classes);
    } else {
      this.wrapper_classes.push(classes);
    }

    return this;
  }

  removeWrapperClasses(classes) {
    if (Array.isArray(classes)) {
      this.wrapper_classes = _.merge(this.wrapper_classes, classes);
    } else {
      this.wrapper_classes = this.wrapper_classes.filter(css_class => !classes.includes(css_class));
    }

    return this;
  }

	getValue() {
		if(this.disabled){
			return null;
		}
		return $(`#${this.name}`).val();
	}

  append(html) {
    this.append_code.push(html);

    return this;
  }

  prepend(html) {
    this.prepend_code.push(html);

    return this;
  }
  
	addDataAttribute(key, value) {
		this.data_attributes[key] = value;
	
		return this;
	}
	
	removeDataAttribute(key) {
		delete this.data_attributes[key];
		
		return this;
	}
	
	resetDataAttributes(){
		this.data_attributes = [];
		
		return this;
	}

  wrap(code) {
    return this.prepend_code.join(' ') + code + this.append_code.join(' ');
  }
}