import SubmitField from "../../../core/js/components/Form/SubmitField";

const $ = window.jQuery;

import Page from "./Page";
import Form from "../../../core/js/components/Form/Form";
import TextField from "../../../core/js/components/Form/TextField";
import NumberField from "../components/Form/NumberField";
import PriceField from "../components/Form/PriceField";
import FileField from "../../../core/js/components/Form/FileField";
import HiddenField from "../../../core/js/components/Form/HiddenField";
import TextareaField from "../components/Form/TextareaField";
import SettingsApi from "../api/SettingsApi";
import SeparatorField from "../../../core/js/components/Form/SeparatorField";
import Footer from "../../../core/js/components/Form/Footer";

export default class SettingsFormPage extends Page {

  constructor() {
    super();

    this
      .setTitle('Gestione azienda')
      .setMeta('description', 'Impostazioni generali dell’azienda')
      .setBreadcrumb([
        { label: 'Settaggi', href: '/settings' },
        { label: 'Gestione azienda' }
      ]);
  }

  async init(settings_id) {
    super.init();
  }

  async content(settings_id) {

    const settingsForm = new Form();

    settingsForm
      .setAction('/settings')
      .setTitle('Gestisci settaggi')
      .addClasses('padded-container label-left');
    let data;

    if (settings_id === 'create') {
      // Create form
      settingsForm.setMethod('POST');
    } else {
      // Update form
      settingsForm.setMethod('PUT');

      const settingsApi = new SettingsApi();
      const settingsData = await settingsApi.get(settings_id);
      if (settingsData) {
        data = settingsData.data;
        settingsForm.setTitle('Gestisci settaggi ' + data[0].name);
      } else {
        $(document).trigger('404', [settingsApi.url + '/' + settings_id]);
        return;
      }
    }

    const in_edit = typeof data !== 'undefined';

    const logo = new FileField();
    logo
      .setFieldName('logo')
      .setMaxSize('10MB')
      .addMimeTypes(['jpg', 'png'])
      .setLabel('Logo Aziendale<br>(per Stampe e PDF)');
    const id_logo = new HiddenField();
    id_logo
      .setFieldName('id_logo');

    const footer = new TextareaField();
    footer
      .setFieldName('footer')
      .addClasses('height_textarea_settings')
      .setPlaceholder('')
      .setLabel('Intestazione');

    const CittaAzienda = new TextField();
    CittaAzienda
      .setFieldName('CittaAzienda')
      .setMaxlength(255)
      .setPlaceholder('')
      .setLabel('Città');

    const ColoreAzienda = new TextField();
    ColoreAzienda
      .setFieldName('ColoreAzienda')
      .setMaxlength(255)
      .setPlaceholder('')
      .setLabel('Colore (#RRGGBB)');

    const FirmaAzienda = new TextField();
    FirmaAzienda
      .setFieldName('FirmaAzienda')
      .setMaxlength(255)
      .setPlaceholder('')
      .setLabel('Firma');

    const SitoAzienda = new TextField();
    SitoAzienda
      .setFieldName('SitoAzienda')
      .setMaxlength(255)
      .setPlaceholder('')
      .setLabel('URL sito');

    const EmailDestAzienda = new TextField();
    EmailDestAzienda
      .setFieldName('EmailDestAzienda')
      .setMaxlength(255)
      .setPlaceholder('')
      .setRequired(true)
      .setLabel('Email di destinazione');

    const TestoCorrierePerEmail = new TextareaField();
    TestoCorrierePerEmail
      .setFieldName('TestoCorrierePerEmail')
      .addClasses('height_textarea_settings')
      .setPlaceholder('')
      .setLabel('Testo per corriere');

    const invoice_prefix = new TextField();
    invoice_prefix
      .setFieldName('invoice_prefix')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Prefisso Offerte');

    const separator = new SeparatorField();
    separator.addClasses('hr_settings');

    const inst_included_workstations = new NumberField();
    inst_included_workstations
      .setFieldName('inst_included_workstations')
      .setRequired(true)
      .setLabel('Numero postazioni incluse');

    const inst_cost_workstation = new PriceField();
    inst_cost_workstation
      .setFieldName('inst_cost_workstation')
      /*.setCleave("numeral",true)
      .setCleave("numeralDecimalScale","2")
      .setCleave("numeralDecimalMark",".")
      .setCleave("delimiter",",")*/
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Costo aggiuntivo Postazione');

    const ztl_cost = new PriceField();
    ztl_cost
      .setFieldName('ztl_cost')
      /*.setCleave("numeral",true)
      .setCleave("numeralDecimalScale","2")
      .setCleave("numeralDecimalMark",".")
      .setCleave("delimiter",",")*/
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Costo ZTL');

    const floor_delivery_cost = new PriceField();
    floor_delivery_cost
      .setFieldName('floor_delivery_cost')
      /*.setCleave("numeral",true)
      .setCleave("numeralDecimalScale","2")
      .setCleave("numeralDecimalMark",".")
      .setCleave("delimiter",",")*/
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Costo Consegna al Piano');

    const printer_withdrawal_a3 = new PriceField();
    printer_withdrawal_a3
      .setFieldName('printer_withdrawal_a3')
      /*.setCleave("numeral",true)
      .setCleave("numeralDecimalScale","2")
      .setCleave("numeralDecimalMark",".")
      .setCleave("delimiter",",")*/
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Ritiro Stampante A3');

    const printer_withdrawal_a4 = new PriceField();
    printer_withdrawal_a4
      .setFieldName('printer_withdrawal_a4')
      /*.setCleave("numeral",true)
      .setCleave("numeralDecimalScale","2")
      .setCleave("numeralDecimalMark",".")
      .setCleave("delimiter",",")*/
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Ritiro Stampante A4');

    const offer_expiry_duration = new NumberField();
    offer_expiry_duration
      .setFieldName('offer_expiry_duration')
      /*.setCleave("numeral",true)
      .setCleave("numeralDecimalScale","2")
      .setCleave("numeralDecimalMark",".")
      .setCleave("delimiter",",")*/
      .setMaxlength(10)
      .setRequired(true)
      .setPlaceholder('')
      .setValue(1)
      .setLabel('Durata Scadenza<br>Offerta in Giorni');

    const submit = new SubmitField();
    submit
      .setFieldName('submit_product')
      .setValue(in_edit ? 'Aggiorna' : 'Salva');

    if (in_edit) {
      if (data[0].id_logo !== null) {
        id_logo.setValue(data[0].id_logo);
      }
      logo.setValue(data[0].logo);

      footer.setValue(data[0].footer);
      CittaAzienda.setValue(data[0].CittaAzienda);
      ColoreAzienda.setValue(data[0].ColoreAzienda);
      FirmaAzienda.setValue(data[0].FirmaAzienda);
      SitoAzienda.setValue(data[0].SitoAzienda);
      EmailDestAzienda.setValue(data[0].EmailDestAzienda);
      TestoCorrierePerEmail.setValue(data[0].TestoCorrierePerEmail);
      invoice_prefix.setValue(data[0].invoice_prefix);

      inst_included_workstations.setValue(data[0].inst_included_workstations);
      inst_cost_workstation.setValue(data[0].inst_cost_workstation);
      ztl_cost.setValue(data[0].ztl_cost);
      floor_delivery_cost.setValue(data[0].floor_delivery_cost);
      printer_withdrawal_a3.setValue(data[0].printer_withdrawal_a3);
      printer_withdrawal_a4.setValue(data[0].printer_withdrawal_a4);
      offer_expiry_duration.setValue(data[0].offer_expiry_duration);
    }

    const formFields = [
      logo,
      id_logo,
      footer,
      CittaAzienda,
      ColoreAzienda,
      FirmaAzienda,
      SitoAzienda,
      EmailDestAzienda,
      TestoCorrierePerEmail,
      invoice_prefix,
      separator,
      inst_included_workstations,
      inst_cost_workstation,
      ztl_cost,
      floor_delivery_cost,
      printer_withdrawal_a3,
      printer_withdrawal_a4,
      offer_expiry_duration,
      submit
    ];

    settingsForm.addFields(formFields);

    settingsForm.onSubmit(async function (res) {

      const settingsApi = new SettingsApi();
      settingsApi.setForm(settingsForm);
      settingsApi.useFormData = true; //metterlo per passare formdata

      let values = settingsForm.getValues();
      values.logo = logo.getFiles(); // replace standard "value" of FileField with FileList object

      let msg = '';
      if (in_edit) {
        const res = await settingsApi.put(settings_id, values);
        msg = res.data;
      } else {
        const res = await settingsApi.post(values);
        settings_id = res.settings_id;
        msg = res.data;
      }

      if (!settingsForm.hasErrors()) {
        $(document).trigger('message', ['success', msg]);
        $.spa.navigate('/settings/manage/' + settings_id, true);
      }
    });

    $('main .main').html(settingsForm.render() + Footer.render());
    settingsForm.activate();
    Footer.activate(this);

  }

}