const $ = window.jQuery;

import FormField from './FormField';
import Cleave from 'cleave.js';

export default class TextField extends FormField {
	
	constructor(){
		super();
		
		this.name = 'input';
		this.type = 'text';
		this.maxlength = 255;
		this.inputmode = 'text';
		
		this.useCleave = false;
		this.cleaveOptions = {
			rawValueTrimPrefix: true,
			onValueChanged: this.validate.bind(this)
		};
		this.cleaveInst = false;
	}
	
	setMaxlength(length){
		this.maxlength = length;
		
		return this;
	}
	
	useCleave(value){
		this.useCleave = Boolean(value);
		
		return this;
	}
	
	setCleave(option, value){
		this.useCleave = true;
		
		this.cleaveOptions[option] = value;
		
		return this;
	}
	
	getValue(){
		if(this.disabled){
			return null;
		}
		if(this.cleaveInst){
			return this.cleaveInst.getRawValue();
		}
		return super.getValue();
	}
	
	getFormattedValue(){
		if(this.cleaveInst){
			return this.cleaveInst.getFormattedValue();
		}
		return super.getValue();
	}
	
	preprocessValue(value){
		return value;
	}
	
	render(){
		let dataAttributes = '';
		for(const [attribute, value] of Object.entries(this.data_attributes)){
			dataAttributes += ` data-${attribute}="${value}"`;
		}
		
		const disabled = this.disabled ? ' disabled' : '';
		const readonly = this.readonly ? ' readonly' : '';
		const required = this.required ? ' required' : '';
		const value = this.preprocessValue(this.value);
		return this.wrap(`<input class="${this.classes.join(' ')}" type="${this.type}" id="${this.name}" name="${this.field_name}" value="${value}" inputmode="${this.inputmode}" maxlength="${this.maxlength}" placeholder="${this.placeholder}"${required}${disabled}${readonly}${dataAttributes}>`);
	}
	
	activate(){
		if(this.useCleave){
			this.cleaveInst = new Cleave(`#${this.name}`, this.cleaveOptions);
		}
	}
	
	validate(){
		if(this.useCleave){
			const $el = $(`#${this.name}`);
			if($el.length){
				if(this.required && this.getValue() === ''){
					$el[0].setCustomValidity('Campo richiesto');
				} else {
					$el[0].setCustomValidity('');
				}
			}
		}
	}
}
