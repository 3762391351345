const $ = window.jQuery;

import Component from '../../../../core/js/components/Component';

export default class Wizard extends Component {
	
	constructor(){
		super();
		this.name = this.getUniqId();
		
		this.title = undefined;
		this.screens = []; // [{label: '', content: Component | '', nextButton: undefined | '#id', nextHandler: undefined | function, prevButton: undefined | '#id', prevHandler: undefined | function]
		this.currentScreen = 0;
		this.menuNavigation = true; // true | false | {forward: true | false, backward: true | false}
		this.onChangeScreenCallback = undefined;
	}
	
	addTitle(title){
		this.title = title;
		
		return this;
	}
	
	addScreen(screen){
		this.screens.push(screen);
		
		return this;
	}
	
	async gotoScreen(id){
		this.currentScreen = id;
		
		// execute generic call back if present
		if(typeof this.onChangeScreenCallback === 'function'){  // execute onChangeScreen callback if present
			await this.onChangeScreenCallback();
		}
		
		// execute specific screen callback if present
		const changingScreen = this.screens[this.currentScreen];
		if(typeof changingScreen.onChange === 'function'){  // execute screen onChange callback if present
			await changingScreen.onChange();
		}
		
		this.refreshScreen();
	}
	
	prevScreen(){
		if(this.currentScreen > 0){
			this.gotoScreen(this.currentScreen - 1);
		}
	}
	
	nextScreen(){
		if(this.currentScreen < this.screens.length){
			this.gotoScreen(this.currentScreen + 1);
		}
	}
	
	refreshScreen(){
		// update "current" on menu and screen
		$(`.wizard-menu li[data-id="${this.currentScreen}"], .screen[data-id="${this.currentScreen}"]`, `#${this.name}`).addClass('current').siblings().removeClass('current');
		
		// update "clickable" on menu
		$(`.wizard-menu li`, `#${this.name}`).removeClass('clickable');
		if(this.menuNavigation !== false){ // menu navigation enabled
			if(this.menuNavigation === true || this.menuNavigation?.forward === true){ // navigate forward
				$(`.wizard-menu li[data-id="${this.currentScreen}"]`, `#${this.name}`).nextAll().addClass('clickable');
			}
			if(this.menuNavigation === true || this.menuNavigation?.backward === true){ // navigate backward
				$(`.wizard-menu li[data-id="${this.currentScreen}"]`, `#${this.name}`).prevAll().addClass('clickable');
			}
		}
	}
	
	refreshScreenContent(id){
		const _this = this;
		
		const screen = this.screens[id];
		if(screen.content){
			
			let screenHtmlContent = '';
			if(Array.isArray(screen.content)){
				screen.content.forEach((content) => {
					screenHtmlContent += (typeof content === 'object' && typeof content.render === 'function') ? content.render() : content;
				});
			} else {
				screenHtmlContent += (typeof screen.content === 'object' && typeof screen.content.render === 'function') ? screen.content.render() : screen.content;
			}
			$(`.screen[data-id="${id}"]`, `#${this.name}`).html(screenHtmlContent);
			
			// activate every Component included in the screens
			if(typeof screen.content === 'object' && typeof screen.content.activate === 'function'){
				screen.content.activate();
			}
			
			// attach actions on previous button if present
			if(typeof screen.prevButton !== 'undefined'){
				$(`${screen.prevButton}`, `#${screen.content.name}`).on('click', typeof screen.prevHandler !== 'undefined' ? screen.prevHandler : function(e){
					e.preventDefault();
					_this.prevScreen();
				});
			}
			
			// attach actions on next button if present
			if(typeof screen.nextButton !== 'undefined'){
				$(`${screen.nextButton}`, `#${screen.content.name}`).on('click', typeof screen.nextHandler !== 'undefined' ? screen.nextHandler : function(e){
					e.preventDefault();
					_this.nextScreen();
				});
			}
		}
		
	}
	
	refreshCurrentScreenContent(){
		this.refreshScreenContent(this.currentScreen);
	}
	
	setMenuNavigation(value){
		this.menuNavigation = value;
		
		return this;
	}
	
	handleMenuNavigation(){
		if(this.menuNavigation !== false){ // menu navigation enabled
			const _this = this;
			$(document).on('click.componentWizard', `#${this.name} .wizard-menu > li`, function(){
				const targetId = parseInt($(this).attr('data-id'));
				if(targetId > _this.currentScreen && (_this.menuNavigation === true || _this.menuNavigation?.forward === true)){ // navigate forward
					_this.gotoScreen(targetId);
				} else if(targetId < _this.currentScreen && (_this.menuNavigation === true || _this.menuNavigation?.backward === true)){ // navigate backward
					_this.gotoScreen(targetId);
				}
			});
		}
	}
	
	onChangeScreen(callback){
		if(typeof callback === 'function'){
			this.onChangeScreenCallback = callback;
		}
		
		return this;
	}
	
	render(){
		const _this = this;
		
		let htmlMenu = '';
		let htmlScreens = '';
		this.screens.forEach(function(item, idx){
			
			htmlMenu += `<li class="${idx === 0 ? 'current' : ''}${idx > 0 && (_this.menuNavigation === true || _this.menuNavigation?.forward === true) ? 'clickable' : ''}" data-id="${idx}">
				<label>${item.label}</label>
			</li>`;
			
			let htmlContent = '';
			if(item.content){
				if(Array.isArray(item.content)){
					item.content.forEach((content) => {
						htmlContent += (typeof content === 'object' && typeof content.render === 'function') ? content.render() : content;
					});
				} else {
					htmlContent += (typeof item.content === 'object' && typeof item.content.render === 'function') ? item.content.render() : item.content;
				}
			}
			htmlScreens += `<div class="screen${idx === 0 ? ' current' : ''}" data-id="${idx}">
				${htmlContent}
			</div>`;
		});
		
		this.handleMenuNavigation();
		
		return `<div id="${this.name}" class="wizard-container ${this.classes.join(' ')}">
			<div class="wizard">
				${typeof this.title === 'undefined' ? '' : '<header>' + this.title + '</header>'}
				<ul class="wizard-menu">
					${htmlMenu}
				</ul>
				<div class="wizard-screens">
					${htmlScreens}
				</div>
			</div>
		</div>`;
	}
	
	activate() {
		const _this = this;
		this.screens.forEach(function(screen){
			if(screen.content){
				if(Array.isArray(screen.content)){
					screen.content.forEach((content) => {

						// activate every Component included in the screens
						if(typeof content === 'object' && typeof content.activate === 'function'){
							content.activate();
						}
						
						// attach actions on previous button if present
						if(typeof screen.prevButton !== 'undefined'){
							$(`${screen.prevButton}`, `#${content.name}`).on('click', typeof screen.prevHandler !== 'undefined' ? screen.prevHandler : function(e){
								e.preventDefault();
								_this.prevScreen();
							});
						}
						
						// attach actions on next button if present
						if(typeof screen.nextButton !== 'undefined'){
							$(`${screen.nextButton}`, `#${content.name}`).on('click', typeof screen.nextHandler !== 'undefined' ? screen.nextHandler : function(e){
								e.preventDefault();
								_this.nextScreen();
							});
						}
						
					});
				}
				else
				{
					// activate every Component included in the screens
					if(typeof screen.content === 'object' && typeof screen.content.activate === 'function'){
						screen.content.activate();
					}
					
					// attach actions on previous button if present
					if(typeof screen.prevButton !== 'undefined'){
						$(`${screen.prevButton}`, `#${screen.content.name}`).on('click', typeof screen.prevHandler !== 'undefined' ? screen.prevHandler : function(e){
							e.preventDefault();
							_this.prevScreen();
						});
					}
					
					// attach actions on next button if present
					if(typeof screen.nextButton !== 'undefined'){
						$(`${screen.nextButton}`, `#${screen.content.name}`).on('click', typeof screen.nextHandler !== 'undefined' ? screen.nextHandler : function(e){
							e.preventDefault();
							_this.nextScreen();
						});
					}
				}
			}
		});
	}
	
}
