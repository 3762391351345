import CrudApi from "../../../core/js/api/CrudApi";

export default class ProductAccessoryApi extends CrudApi {
  
  constructor() {
    super();

    this.productId = undefined;
  }

  setCustomerId(product_id) {
    this.productId = product_id;
    return this;
  }
  
  crudUrl() {
    return '/product/'+this.productId+'/accessory'; 
  }

}
  