//import ProductsCatalogPage from "./ProductsCatalogPage";
//import ProductAccessoriesPage from "./ProductAccessoriesPage";
import DashboardPage from "./DashboardPage";
import UsersPage from "./UsersPage";
import UserFormPage from "./UserFormPage";
import BrandsPage from "./BrandsPage";
import BrandFormPage from "./BrandFormPage";
import ProductsPage from "./ProductsPage";
import ProductFormPage from "./ProductFormPage";
import SingleProductPage from "./SingleProductPage";
import OfferFormPage from "./OfferFormPage";
import OffersPage from "./OffersPage";
import CustomersPage from "./CustomersPage";
import CustomerFormPage from "./CustomerFormPage";
import CustomerLocationsPage from "./CustomerLocationsPage";
import CustomerLocationFormPage from "./CustomerLocationFormPage";
import OfferProductShippingsPage from "./OfferProductShippingsPage";
import OfferProductShippingFormPage from "./OfferProductShippingFormPage";
import AccessoriesPage from "./AccessoriesPage";
import AccessoryFormPage from "./AccessoryFormPage";
import AccessoriesProductPage from "./AccessoriesProductPage";
import AccessoriesAllPage from "./AccessoriesAllPage";
import CatalogPage from "./CatalogPage";
import FinancialsPage from "./FinancialsPage";
import FinancialFormPage from "./FinancialFormPage";
import ShippingTableRatesPage from "./ShippingTableRatesPage";
import ShippingTableRateFormPage from "./ShippingTableRateFormPage";
import SettingsPage from "./SettingsPage";
import SettingsFormPage from "./SettingsFormPage";
import OrdersPage from "./OrdersPage";
import CarepacksPage from "./CarepacksPage";
import CarepackFormPage from "./CarepackFormPage";

//window.ProductsCatalogPage = ProductsCatalogPage;
//window.ProductAccessoriesPage = ProductAccessoriesPage;
window.DashboardPage = DashboardPage;
window.UsersPage = UsersPage;
window.UserFormPage = UserFormPage;
window.BrandsPage = BrandsPage;
window.BrandFormPage = BrandFormPage;
window.ProductsPage = ProductsPage;
window.ProductFormPage = ProductFormPage;
window.SingleProductPage = SingleProductPage;
window.OffersPage = OffersPage;
window.OfferFormPage = OfferFormPage;
window.CustomersPage = CustomersPage;
window.CustomerFormPage = CustomerFormPage;
window.CustomerLocationsPage = CustomerLocationsPage;
window.CustomerLocationFormPage = CustomerLocationFormPage;
window.OfferProductShippingsPage = OfferProductShippingsPage;
window.OfferProductShippingFormPage = OfferProductShippingFormPage;
window.AccessoriesPage = AccessoriesPage;
window.AccessoryFormPage = AccessoryFormPage;
window.AccessoriesProductPage = AccessoriesProductPage;
window.AccessoriesAllPage = AccessoriesAllPage;
window.CatalogPage = CatalogPage;
window.FinancialsPage = FinancialsPage;
window.FinancialFormPage = FinancialFormPage;
window.ShippingTableRatesPage = ShippingTableRatesPage;
window.ShippingTableRateFormPage = ShippingTableRateFormPage;
window.SettingsPage = SettingsPage;
window.SettingsFormPage = SettingsFormPage;
window.OrdersPage = OrdersPage;
window.CarepacksPage = CarepacksPage;
window.CarepackFormPage = CarepackFormPage;
