import Component from "../Component";
import _ from "lodash";

export default class MenuItem extends Component {

  constructor() {
    super();

    this.name = 'menu-';
    this.wrapper_classes = [];
    this.href = '';
    this.title = '';
    this.roles = [];

    this.menu_title = false;
  }

  setLink(href) {
    this.href = href;

    return this;
  }

  setTitle(text) {
    this.title = text;
    this.name += text.replace(/[^A-Za-z0-9]/g, '').toLowerCase() + '-' + this.getUniqId(true);

    return this;
  }

  setRoles(roles) {
    this.roles = Array.isArray(roles) ? roles : [ roles ];
  }

  render() {
    const liopen = !this.menu_title ? `<li class="${this.wrapper_classes.join(' ')}">` : '';
    const liclose = !this.menu_title ? `</li>` : '';
    return this.hasAccess() ? `
      ${liopen}
        <a id="${this.name}" class="${this.classes.join(' ')}" href="${this.href}" data-scary="menu">${this.title}</a>
      ${liclose}
    ` : '';
  }

  hasAccess() {
    return (this.roles.length > 0 && _.intersection(this.roles, window.PROFILE.roles).length > 0) || this.roles.length === 0;
  }

  addWrapperClasses(classes) {
    if (Array.isArray(classes)) {
      this.wrapper_classes = _.merge(this.wrapper_classes, classes);
    } else {
      this.wrapper_classes.push(classes);
    }

    return this;
  }

  removeWrapperClasses(classes) {
    if (Array.isArray(classes)) {
      this.wrapper_classes = _.merge(this.wrapper_classes, classes);
    } else {
      this.wrapper_classes = this.wrapper_classes.filter(css_class => !classes.includes(css_class));
    }

    return this;
  }

}