const $ = window.jQuery;

import Page from './Page';
import Form from '../../../core/js/components/Form/Form';
import TextField from '../../../core/js/components/Form/TextField';
import OptionField from '../../../core/js/components/Form/OptionField';
import SubmitField from '../../../core/js/components/Form/SubmitField';
import PriceField from '../components/Form/PriceField';
import ScarySelect2 from '../components/Plugins/ScarySelect2';
import Footer from '../../../core/js/components/Form/Footer';
import CarepackApi from '../api/CarepackApi';

export default class CarepackFormPage extends Page {

	constructor() {
		super();
	}

	async init(carepack_id) {
		this
			.setTitle('Gestione carepack')
			.setMeta('description', 'Gestione carepack')
			.setBreadcrumb([
				{ label: 'Carepack', href: '/carepacks' },
				'Gestione carepack'
			]);

		return super.init(carepack_id);
	}

	async content(carepack_id) {
		const _page = this;

		const mainForm = new Form();
		mainForm
			.setAction('/carepack')
			.setMethod('POST')
			.setTitle('Gestisci carepack')
			.addClasses('padded-container label-left');

		let data;
		if (carepack_id !== 'create') { // update carepack
			const carepackApi = new CarepackApi();
			const carepack = await carepackApi.get(carepack_id);
			if (!carepack) {
				return;
			}
			data = carepack.data;
		}

		const in_edit = typeof data !== 'undefined';

		this.setPageTitle(in_edit, mainForm, 'Nuovo Carepack', 'Modifica Carepack');

		const name = new TextField();
		name
			.setFieldName('name')
			.setLabel('Nome')
			.setRequired(true);

		const durationOptions = [];
		['24', '36', '48', '60', '72'].forEach(duration => {
			const option = new OptionField();
			option
				.setValue(duration)
				.setLabel(`${duration} mesi`);
			if (data?.duration === duration) {
				option.setDefault(true);
			}
			durationOptions.push(option);
		});

		const duration = new ScarySelect2();
		duration
			.setFieldName('duration')
			.setPlaceholder('Seleziona un valore')
			.setLabel('Durata')
			.setMinimumResultsForSearch(10)
			.addOptions(durationOptions)
			.setRequired(true);

		const price = new PriceField();
		price
			.setFieldName('price')
			.setLabel('Prezzo')
			.setRequired(true);

		const cod_erp = new TextField();
		cod_erp
			.setFieldName('cod_erp')
			.setLabel('Codice ERP');

		const submit = new SubmitField();
		submit
			.setFieldName('submit_carepack')
			.setValue(in_edit ? 'Aggiorna' : 'Salva');

		if (in_edit) {
			name.setValue(data.name);
			price.setValue(data.price);
			cod_erp.setValue(data.cod_erp);
		}

		mainForm.addFields([
			cod_erp,
			name,
			duration,
			price,
			submit,
		]);

		mainForm.onSubmit(async function (result) {
			const carepackApi = new CarepackApi();
			carepackApi.setForm(mainForm);

			let res, values = mainForm.getValues();
			if (in_edit) {
				res = await carepackApi.put(carepack_id, values);
			} else {
				res = await carepackApi.post(values);
			}
			if (mainForm.hasErrors()) {
				$(document).trigger('message', ['error', res.data]);
			} else {
				$(document).trigger('message', ['success', res.data]);
				$.spa.navigate('/carepacks');
			}
		});

		$('main .main').html(mainForm.render() + Footer.render());
		mainForm.activate();
		Footer.activate(this);
	}
}
