import FormField from '../../../../core/js/components/Form/FormField';

export default class TextareaField extends FormField {
	
	constructor() {
		super();
	
		this.setType('textarea');
		
		this.maxlength = false;
	}
	
	setMaxlength(length){
		this.maxlength = length;
		
		return this;
	}
	
	render() {
		const maxlength = this.maxlength ? ` maxlength="${this.maxlength}"` : '';
		const disabled = this.disabled ? ' disabled' : '';
		const readonly = this.readonly ? ' readonly' : '';
		const required = this.required ? ' required' : '';
		return this.wrap(`<textarea id="${this.name}" class="${this.classes.join(' ')}" name="${this.field_name}" placeholder="${this.placeholder}"${maxlength}${disabled}${readonly}${required}>${this.value}</textarea>`);
	}
}
