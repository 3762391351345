const $ = window.jQuery;

import Menu from "../../../../core/js/components/Menu/Menu";

export default class ActionsMenu extends Menu {

  constructor() {
    super();

    this.child_class = 'ActionsMenuItem';
  }

  render() {
    let code = '';
    this.items.forEach(function (item) {
      code += item.render();
    });
    
    return `
      <div class="${this.classes.join(' ')}">
        <ul class="${this.ul_classes.join(' ')}">
            ${code}
        </ul>
      </div>
    `;
  }

}