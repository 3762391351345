import FormField from "./FormField";

export default class ImageField extends FormField {

  constructor() {
    super();

    this.name = 'input';
    this.setType('image');
    this.image = '';
  }

  setImage(image_path) {
    this.image = image_path;
  }

  render() {
    const disabled = this.disabled ? ' disabled' : '';
    const readonly = this.readonly ? ' readonly' : '';
    return this.wrap('<input type="' + this.type + '" src="' + this.image + '" id="' + this.name + '" class="' +
      this.classes.join(' ') + '" title="' + this.field_name + '" value="' + this.value + '" ' + disabled + readonly + '/>');
  }
}