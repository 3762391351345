const $ = window.jQuery;

import Component from "../Component";
import _ from "lodash";

export default class Form extends Component {

  constructor() {
    super();

    this.name = this.getUniqId();
    this.fields = [];
    this.action = '';
    this.method = 'POST';
    this.enctype = 'multipart/form-data';
    this.orig_values = {};
    this.errors = {};
    this.title = '';
    this.child = false;
    this.field_name = '';
	this.noValueFieldTypes = ['image', 'submit', 'button'];

    this.addClasses('form with-validation');
  }

  addFields(fields) {
    if (Array.isArray(fields)) {
      const _this = this;
      fields.forEach(function (field) {
        _this._addField(field);
      });
    } else {
      this._addField(fields);
    }

    return this;
  }

  _addField(field) {
    if (typeof field === 'object' && typeof field.render === 'function') {
      if (field.constructor?.name === 'Form') {
        field.child = true;
      }
      this.fields.push(field);
    } else {
      throw 'You have to pass a valid Component';
    }
    if (field.constructor?.name !== 'Form' && field.constructor?.name !== 'Accordion') {
      this.orig_values[field.field_name] = Array.isArray(field.value) && field.value.length === 1 ? field.value.pop() : field.value;
    }

    return this;
  }

  setFieldName(field_name) {
    this.field_name = field_name;

    return this;
  }

  setAction(action) {
    this.action = action;

    return this;
  }

  setMethod(method) {
    this.method = method;

    return this;
  }

  setEnctype(enctype) {
    this.enctype = enctype;

    return this;
  }

  setId(id) {
    this.name = id;

    return this;
  }

  setTitle(title) {
    this.title = title;

    return this;
  }

	getValues(){
		const values = {};
		this.fields.forEach((field) => {
			if(typeof field.getValues === 'function'){ // components supporting getValues() function
				$.extend(values, field.getValues());
			} else if (this.noValueFieldTypes.indexOf(field.type) === -1) { // exclude FormFields without value
				const value = field.getValue();
				if(value !== null){
					values[field.field_name] = value;
				}
			}
		}, this);
		return values;
	}

	onSubmit(submit_cb) {
		const _this = this;
		$(document).on('submit', `#${this.name}`, function(e){
			e.preventDefault();
			_this.clearErrors();
			_this.fields.forEach((field) => {
				if(typeof field.validate === 'function'){ // components has custom validation method
					field.validate();
				}
			});
			if(!this.reportValidity()){
				return false;
			}
			const data = {};
			$(this).serializeArray().forEach(function(input){
				data[input.name] = input.value;
			});
			submit_cb(data);
		});
		
		return this;
	}

  setErrors(errors) {
    const _this = this;
    this.errors = errors;

    if (errors._global) {
      const view_errors = $('<ul></ul>');
      errors._global.forEach(function (global_error) {
        $(view_errors).append('<li>' + global_error + '</li>');
      });

      $(view_errors).wrapInner('<ul></ul>');

      $('#' + this.name + ' .formerror').addClass('error').html($(view_errors).html());

      errors._global = undefined;
    }

    Object.keys(errors).forEach(function (error) {
      if (errors.hasOwnProperty(error) && errors[error]) {
        const view_errors = $('<ul></ul>');
        errors[error].forEach(function (field_error) {
          $(view_errors).append('<li>' + field_error + '</li>');
        });

        $(view_errors).wrapInner('<ul></ul>');

        $('#' + _this.name + ' [name="' + error + '"]').siblings('.fielderror').addClass('error').html($(view_errors).html());
      }
    });

    return this;
  }

  hasErrors() {
    return Object.keys(this.errors).length > 0;
  }

  clearErrors() {
    this.errors = {};
    $('#' + this.name + ' .formerror, #' + this.name + ' .fielderror').removeClass('error').html('');
    $('input, textarea, select', `#${this.name}`).removeClass('invalid');

    return this;
  }

  getDifference(field) {
    const values = this.getValues();
    if (!_.isEqual(this.orig_values[field], values[field])) {
      return Array.isArray(this.orig_values[field]) ? _.difference(this.orig_values[field], values[field]) : [ this.orig_values[field], values[field] ];
    }

    return false;
  }

  renderFields(fields) {
    const _this = this;
    let code = '';
    let array_key = '';

    fields.forEach(function (field) {
      // if (field.constructor.name.indexOf('File') === 0) {
      //   _this.setMethod('x-www-form-urlencoded');
      // }
      const field_array_key = field.field_name.replace(/[^\[]*/, '');
      if (array_key !== field_array_key) {
        const increment_class = 'relational-field-' + field_array_key.replace(/^\[([^\]]+)\]$/, '$1');
        code += array_key.length > 0 ? `</div><div class="relational-field ${increment_class}">` : `<div class="relational-field ${increment_class}">`;
        array_key = field_array_key;
      }
      if (typeof field.getValues === 'function') {
        // this is Form component
        code += field.render();
      } else {
        if (field.constructor?.name === 'TextField') {
          field.setType('text');
        }
        code += '<div id="field-' + field.name + '" class="formfield ' + field.type + '-type ' + field.wrapper_classes.join(' ') + '">';
        if (field.label.length > 0) {
          code += `<label for="${field.name}">${field.label}</label>`;
        }
        code += field.render();
        if (field.type !== 'submit') {
          code += '<div class="fielderror"></div>';
        }
        code += '</div>';
      }
    });

    if (array_key.length > 0) {
      code += '</div>';
    }

    return code;
  }

	render(){
		let code = this.renderFields(this.fields);
		
		const htag = this.child ? 'h3' : 'h2';
		const hclass = this.child ? 'fieldsettitle' : 'formtitle';
		const title = this.title ? `<div class="${hclass}"><${htag}>${this.title}</${htag}></div>` : '';
		if(this.child){
			this.removeClasses('form with-validation');
			if(this.title){
				this.addClasses('with-fieldsettitle');
			}
			code = `<fieldset id="${this.name}" class="${this.classes.join(' ')}">${title}${code}</fieldset>`;
		} else {
			code = `<form id="${this.name}" class="${this.classes.join(' ')}" action="${this.action}" method="${this.method}" enctype="${this.enctype}">${title}<div class="formerror"></div>${code}</form>`;
		}
		
		return code;
	}
  
	activate() {
		// activate every Field included in the form
		this.fields.forEach(function(field){
			field.activate();
		});
	}

	validate(){
		this.fields.forEach((field) => {
			if(typeof field.validate === 'function'){ // components has custom validation method
				field.validate();
			}
		});
	}
}