import SelectField from "./SelectField";
import OptionField from "./OptionField";

export default class SelectBooleanField extends SelectField {

  constructor() {
    super();

    this.labelFalse = 'No';
    this.labelTrue = 'Sì';
    this.defaultValue = "0";
  }

  setValues(defaultValue, labelFalse, labelTrue) {
    this.defaultValue = defaultValue;
    this.labelFalse = labelFalse || this.labelFalse;
    this.labelTrue = labelTrue || this.labelTrue;

    return this;
  }

  setValue(value) {
    this.defaultValue = value.toString();
  }

  render() {
    const optFalse = new OptionField();
    optFalse
      .setValue("0")
      .setLabel(this.labelFalse)
      .setDefault(this.defaultValue === '0');
    const optTrue = new OptionField();
    optTrue
      .setValue("1")
      .setLabel(this.labelTrue)
      .setDefault(this.defaultValue === '1');
    this.addOptions([
      optFalse,
      optTrue
    ]);

    return super.render();
  }
}
