import Api from "./Api";

export default class BaseCrudApi extends Api {
  constructor() {
    super();
    if (this.constructor == BaseCrudApi) {
      throw new Error("You cannot instantiate BaseCrudApi abstract class");
    }
  }

  /**
   * Implement this method while extending this class.
   *
   * Must return the relative API url as string
   */
  crudUrl() {
    throw new Error("Please implement crudUrl() function on your " + typeof this + " class: must return an API url");
  }

  async call(cb) {
    return await this.__call(cb);
  }

  async collection(filters) {
    this.method = 'GET';
    this.url = this.crudUrl();
    this.data = filters || {};
    this.setErrorCallback(this.collectionError);

    return await this.call(this.collectionAction);
  }

  /**
   * You can implement this method while extending this class.
   *
   * Must handle collection data
   */
  collectionAction(data) {
    return data;
  }

  /**
   * You can implement this method while extending this class.
   *
   * Must handle collection errors
   */
  collectionError(data, textStatus, errorThrown) {
    return this.__errorCallback(data, textStatus, errorThrown);
  }

  async post(data) {
    this.method = 'POST';
    this.data = data;
    this.url = this.crudUrl();
    this.setErrorCallback(this.postError);

    return await this.call(this.postAction);
  }

  /**
   * You can implement this method while extending this class.
   *
   * Must handle post data
   */
  postAction(data) {
    return data;
  }

  /**
   * You can implement this method while extending this class.
   *
   * Must handle post errors
   */
  postError(data, textStatus, errorThrown) {
    return this.__errorCallback(data, textStatus, errorThrown);
  }

  async delete(id) {
    this.method = 'DELETE';
    this.url = this.crudUrl() + '/' + id;
    this.setErrorCallback(this.deleteError);

    return await this.call(this.deleteAction);
  }

  /**
   * You can implement this method while extending this class.
   *
   * Must handle success delete action
   */
  deleteAction(data) {
    return data;
  }

  /**
   * You can implement this method while extending this class.
   *
   * Must handle delete errors
   */
  deleteError(data, textStatus, errorThrown) {
    return this.__errorCallback(data, textStatus, errorThrown);
  }
}