const $ = window.jQuery;

import FormField from './FormField';
import _ from 'lodash';

export default class SelectField extends FormField {
	
	constructor(){
		super();
	
		this.name = 'select';
		this.setType('options');
		this.options = [];
		this.multiple = false;
		this.value = [];
	}

	addOptions(options){
		if (Array.isArray(options)) {
			options.forEach(function(option){
				this._addOption(option);
			}, this);
		} else {
			this._addOption(options);
		}
		
		return this;
	}

	_addOption(option){
		if (typeof option === 'object' && typeof option.render === 'function') {
			this.options.push(option);
		} else {
			throw 'You have to pass a valid Component';
		}
		
		if (option.defaultopt) {
			this.value.push(option.value);
		}
		
		return this;
	}
	
	removeOption(value){
		this.options = this.options.filter(option => option.value !== value);
		
		return this;
	}
	
	resetOptions(){
		this.options = [];
		
		return this;
	}
  
	setMultiple(multiple){
		this.multiple = multiple;
    
		return this;
	}

	setValue(value) {
		this.options.map(function(option){
			if (option.value === value) {
				option.setDefault(true);
			}
		});
		return super.setValue(value);
	}

	onChange(cb) {
		const _this = this;
		$(document).on('change', '#' + this.name, function (e) {
			cb.bind(_this)(_this.getValue());
		});

		return this;
	}

	getValue() {
		if(this.disabled){
			return null;
		}
		let value = $('#' + this.name).val();
		if (!Array.isArray(value)) {
			const option = _.find(this.options, function(option){
				return option.value == value;
			});
			value = this.castValue(option);
		} else {
			let values = [];
			const _this = this;
			const options = _.filter(this.options, function (option) {
				return value.indexOf(option.value) > -1;
			});
			options.forEach(function (option) {
				values.push(_this.castValue(option));
			});
			value = values;
		}
		return value;
	}

	castValue(option) {
		switch (option.dataType) {
			case 'string':
				return option.value;
			case 'number':
				return option.value.toString().match(/\./) ? parseFloat(option.value) : parseInt(option.value);
			default:
				return option.value;
		}
	}
  
	render() {
		const classes = this.classes.join(' ');
		const options = [];
		this.options.forEach(function(option){
			options.push(option.render());
		});
		const req = this.required ? ' required' : '';
		const multiple = this.multiple === false ? '' : ` multiple="${this.multiple}"`;
		const disabled = this.disabled ? ' disabled' : '';
		const readonly = this.readonly ? ' readonly' : '';
	
		return this.wrap(`<div class="select-container${req}"><select name="${this.field_name}" id="${this.name}"${multiple} class="${classes}"${req}${disabled}${readonly}>
			${options.join('')}
		</select></div>`);
	}

}
