import { default as BaseScaryDataTable } from "../../../../core/plugins/datatables/js/components/ScaryDataTable";

const it_IT = require("../../../../core/plugins/datatables/lib/js/translations/it-IT.json");

export default class ScaryDataTable extends BaseScaryDataTable {

  constructor() {
    super();

    this.setLanguage('it');
    this.setTranslation(it_IT);
  }

}