import TextField from "./TextField";

export default class HiddenField extends TextField {

  constructor() {
    super();

    this.setType('hidden');
  }

}