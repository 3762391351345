const $ = window.jQuery;

import Page from './Page';
import Storage from '../../../core/js/utils/Storage';
import ScaryJqueryConfirm from '../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm';
import ScaryDataTable from '../components/Plugins/ScaryDataTable';
import ActionsMenu from '../components/ActionsMenu/ActionsMenu';
import ActionsMenuItem from '../components/ActionsMenu/ActionsMenuItem';
import ShippingTableRateApi from '../api/ShippingTableRateApi';
import Footer from '../../../core/js/components/Form/Footer';
import _ from 'lodash';

export default class ShippingTableRatesPage extends Page {
	
	constructor(){
		super();
		
		this.setTitle('Tariffe di Spedizione')
			.setMeta('description', 'Tariffe di spedizione')
			.setBreadcrumb('Costi e Tariffe');
	}
	
	content(){
		const _page = this;
		
		//
		// actions menu
		//
		
		const actions_menu = new ActionsMenu();
		actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-shippingtablerates']);
		actions_menu.addUlClasses('actions-menu');
		
		const btn_add = new ActionsMenuItem();
		btn_add
			.addClasses(['element', 'only-icon', 'add'])
			.setTitle('Aggiungi')
			.setOn('click', () => $.spa.navigate('/shipping-tablerate/manage/create'))
			.setRoles(['RD_ADMIN_SHIPPINGTABLERATES']);
			
		const btn_edit = new ActionsMenuItem();
		btn_edit
			.addClasses(['element', 'only-icon', 'edit', 'disabled'])
			.setTitle('Modifica')
			.setRoles(['RD_ADMIN_SHIPPINGTABLERATES']);
			
		const btn_delete = new ActionsMenuItem();
		btn_delete
			.addClasses(['element', 'only-icon', 'delete', 'disabled'])
			.setTitle('Elimina')
			.setRoles(['RD_ADMIN_SHIPPINGTABLERATES']);
			
		actions_menu.addItems([
			btn_add,
			btn_edit,
			btn_delete
		]);

		//
		// offers table
		//
	
		// $.confirm delete
		const confirmDelete = new ScaryJqueryConfirm();
		confirmDelete
			.setTitle('Attenzione')
			.setContent('Sei sicuro di voler cancellare la tariffa?');
		
		// quick-open dt row on double-click
		$(document).on('dblclick', '.dt-shippingtablerates tbody tr', function(){
			const targetId = $(this).attr('data-id');
			if(typeof targetId !== 'undefined'){
				$.spa.navigate(`/shipping-tablerate/manage/${targetId}`);
			}
		});
		
		// handle dt_offers select/deselect
		const dt_shippingtablerates_onSelectDeselect = function(e, dt, type, indexes){
			
			// check for selected rows
			const targets = [];
			if (type === 'row') {
				dt.rows({selected: true}).every(function(idx){
					if(typeof dt.row(idx).data().id !== 'undefined'){
						targets.push(dt.row(idx));
					}
				});
			}
			
			$(`#${btn_edit.name}`)
				.off('click')
				.toggleClass('disabled', targets.length === 0);
				
			$(`#${btn_delete.name}`)
				.off('click')
				.toggleClass('disabled', targets.length === 0 || targets.some(row => row.data().is_system === '1')); // disable delete also if there are "system" tablerates selected
			
			if(targets.length > 0){
				$(`#${btn_edit.name}:not(.disabled)`).on('click', () => $.spa.navigate(`/shipping-tablerate/manage/${targets[0].data().id}`));
				$(`#${btn_delete.name}:not(.disabled)`).on('click', () => {
					confirmDelete
						.modalConfirm(async (data) => {
							const tableRateId = targets[0].data().id;
							const shippingTableRateApi = new ShippingTableRateApi();
							await shippingTableRateApi.delete(tableRateId)
								.then(() => {
									_page.refresh();
								})
								.catch((e) => {});
						})
						.render();
				});
			}
			
		};
		const debounced_dt_shippingtablerates_onSelectDeselect = _.debounce(dt_shippingtablerates_onSelectDeselect, 100);
	
		const dt_shippingtablerates = new ScaryDataTable();
		dt_shippingtablerates
			.setUrl('/shipping-tablerate')
			.setSelect('single')
			.setOnSelect(debounced_dt_shippingtablerates_onSelectDeselect)
			.setOnDeselect(debounced_dt_shippingtablerates_onSelectDeselect)
			.addField('country', 'Nazione', {
				render: function(data, type, row, meta){
					return data.label === null ? 'Tutte' : data.label;
				}
			})
			.addField('region', 'Provincia', {
				render: function(data, type, row, meta){
					return data.label === null ? 'Tutte' : data.label;
				}
			})
			.addField('condition_value', 'Peso (a partire da)', {
				searchable: false
			})
			.addField('price', 'Consegna', {
				searchable: false
			})
			.addField('inst_included_workstations', 'Installazione', {
				searchable: false
			})
			.addField('carepack', 'CarePack', {
				searchable: false
			})
			.addClasses('dt-shippingtablerates');
		
		// render
		$('main .main').html(actions_menu.render() + dt_shippingtablerates.render() + Footer.render());
		dt_shippingtablerates.activate();
		Footer.activate(this);
	}
	
}
