import CrudApi from '../../../core/js/api/CrudApi';

export default class OfferGenerateDocumentApi extends CrudApi {

	constructor() {
		super();
		
		this.offerId = undefined;
		this.type = undefined;
	}

	setOfferId(offerId) {
		this.offerId = offerId;
		
		return this;
	}
	
	setType(value) {
		this.type = value;
		
		return this;
	}

	crudUrl() {
		return `/offer/${this.offerId}/generate-document/${this.type}`;
	}
	
	postError(data, textStatus, errorThrown) {
	}
}
