import FormField from "./FormField";

export default class SubmitField extends FormField {

  constructor() {
    super();

    this.name = 'input';
    this.setType('submit');
  }

  render() {
    const disabled = this.disabled ? ' disabled' : '';
    const readonly = this.readonly ? ' readonly' : '';
    return this.wrap('<input type="' + this.type + '" id="' + this.name + '" class="' +
      this.classes.join(' ') + '" title="' + this.value + '" value="' + this.value + '" ' + disabled + readonly + '/>');
  }
}