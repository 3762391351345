/**
 * Created by cwasser on 09.04.16.
 */
/*jshint esversion: 9 */
var _ = require('lodash');
const {errors} = require("jshint/src/messages");
/**
 * spa/Router.js
 * @description This component is responsible for the routing within the jQuery SPA plugin.
 * It will dispatch routes to callbacks and is the only entry point for all routes. It is communicating
 * with the jQuery SPA History and jQuery SPA Data components to do AJAX requests or manipulate the URL / History
 * if needed.
 * @author Christian Wasser <admin@chwasser.de>
 * @type {{configModule, navigate, createResource, updateResource, deleteResource, addRoute, removeRoute}}
 */
module.exports = (function( $ ) {
    // 'use strict';
    //----------------- BEGIN MODULE SCOPE VARIABLES ----------------------
    var defaults = {
            routes : [],
            routeDefaultOptions : {
                isResource : false,
                httpMethod : 'GET',
                shouldTriggerStateUpdate : false,
                useHistoryStateFallback : false,
                data : {}
            }
        },
        stateMap = $.extend( true, {}, defaults),
        phaseClasses = {},
        settablePropertyMap = {
            routes : false,
            routeDefaultOptions : false
        },

        Data = require('./Data'),
        History = require('./History'),
        Util = require('./Util'),

        _mergeRouteOptions, _checkRoute, _findRoute, _getRoute, _performDataRequest, _wrapCallbackForResource,

        navigate, createResource, updateResource, deleteResource, getResource,
        addRoute, removeRoute, hasRoute, refresh, currentRoute, configModule;

    //----------------- END MODULE SCOPE VARIABLES ------------------------
    //----------------- BEGIN INTERNAL METHODS ----------------------------
    /**
     * @description Returns the final optional route options for a single route and makes
     *      sure that each necessary property is set for a route.
     * @return object the merged options, default route options overwritten by the user
     * @param {object} userOptions       - An object with user options for a single route
     * @private
     */
    _mergeRouteOptions = function ( userOptions ) {
        // Prevent any misbehaviour of the data property usage on addRoute(),
        // the data property can only be set by the Data component or via
        // createResource() and updateResource() calls.
        if( ! $.isEmptyObject( userOptions.data ) ) {
            userOptions.data = {};
        }
        return $.extend( true, {}, stateMap.routeDefaultOptions, userOptions );
    };

    /**
     * @description Check if a route is matching a regex to disallow malformed routes in
     *      this component.
     * @param {string} route - The route string to be checked.
     * @returns {boolean}   - If the route matches with the internal regex.
     *      * true          - The route string matches.
     *      * false         - The route does not match with the regex.
     * @private
     */
    _checkRoute = function ( route ) {
        // Regex for simple URL checking, matches all '/...(/...)'
        return /^\/[a-zA-Z0-9]*(\/[a-zA-Z0-9]*)?/g.test( route );
    };

    /**
     * @description Find the index of the given route and httpMethod string and returns it.
     * @return {int}        - The index of the route.
     *      * -1            - The component does not contain the given route.
     *      * 0 ... n       - The index of the route object in this component.
     * @private
     * @param {string} route - Any route string to look for.
     * @param {string} httpMethod    - Any HTTP method connected with the given route, allowed methods are
     *                  findable within the jQuery SPA Data component.
     */
    _findRoute = function ( route, httpMethod ) {
        // Do not use $.inArray() here, because the array contains objects
        // return _.findIndex(
        //     stateMap.routes,
        //     {
        //         route : route,
        //         httpMethod : (typeof httpMethod === 'undefined' ) ? 'GET' : httpMethod
        //     }
        // );
        return _.findIndex(
            stateMap.routes,
            function (obj) {
              if (typeof obj.route !== 'string') {
                return false;
              }
              let routeRegex = new RegExp('^' + obj.route.replaceAll('/', '\/').replaceAll(/\/{[^}\/]+}(\/)?/ig, '\/[^\/]+$1') + '$', 'i');

              return route.match(routeRegex) && httpMethod === (typeof obj.httpMethod === 'undefined' ? 'GET' : obj.httpMethod);
            }
        );
    };

    /**
     * @description Get a deep copy of a route object of this component for the given route string
     *      and HTTP method string.
     * @return {object}     - A copy of the route object with the given route and httpMethod or
     *                  an empty javascript object if the component does not contain a corresponding
     *                  object.
     * @param {string} route    - Any route string to look for to deliver the route object.
     * @param {string} httpMethod - Any HTTP method connected with the given route, allowed methods are
     *                  findable within the jQuery SPA Data component.
     * @private
     */
    _getRoute = function ( route, httpMethod ) {
        var index = _findRoute( route, httpMethod );
        if ( index >= 0 ) {
            return $.extend( true, {}, stateMap.routes[index] );
        }
        return {};
    };

    /**
     * @description This method will wrap the callback of the given route object into one more callback,
     *      to perform an AJAX request via the jQuery SPA Data component.
     * @param {object} routeObj     - The route object from the state map of this component.
     * @returns {Function}          - The wrapped callback for the given route object, which is ready to perform
     *                  an AJAX request on call of the callback.
     * @private
     */
    _wrapCallbackForResource = function ( routeObj ) {
        // This needs to be done, otherwise the routeObj would call itself again in the AJAX callback
        var routeCopy = $.extend( true, {}, routeObj );

        return function () {
            Data.performRequest(
                routeCopy.route,
                routeCopy.httpMethod,
                // This needs to be done, otherwise the routeObj would call itself again in the AJAX callback (Endless loop)
                routeCopy.callback,
                {
                    shouldTriggerStateUpdate : routeCopy.shouldTriggerStateUpdate,
                    useHistoryStateFallback : routeCopy.useHistoryStateFallback,
                    data : routeCopy.data
                }
            );
        };
    };

    /**
     * @description Will wrap the callback of the given route object into an AJAX callback. It also
     *      checks additionally the HTTP method of the route object to set some History specific
     *      flags. This method is used by all data related functions of the Router component.
     * @param {object} routeObj     - The route object from the state map of this component.
     * @param {object} data         - Additional data javascript object, which should be used as data for the
     *              AJAX request.
     * @returns {Function}  - The wrapped callback for the given route object, which is ready
     *              to perform an AJAX request on call of the callback.
     * @private
     */
    _performDataRequest = function ( routeObj, data ) {
        if ( typeof data === 'undefined' ) {
            throw 'jQuery SPA Error: Can not perform data retrieval without any data';
        }
        routeObj.data = data;

        return _wrapCallbackForResource( routeObj );
    };

    /**
     * @description This listener will be registered instantly and will listen on specific events from the
     * jQuery SPA History component. When the event is triggered, this will execute the callback
     * after the History manipulation.
     */
    $(window).on('jQuery.spa.locationChange', async function ( event, obj ) {
        // This function will only be called by the History, so it will always be an resource with GET,
        // because URL changes happens only to reflect another state than before.
        let routeObj = _getRoute( obj.route, 'GET' );
		    let params = {};
        let paramsValues = obj.route.split('/').filter(item => item.length > 0);

        if (typeof routeObj.route === 'string' && routeObj.route.length > 0) {
          let paramRegexp = new RegExp('{[^}]+}');

          routeObj.route.split('/').forEach(function (route_elem, i) {
            if (route_elem.match(paramRegexp)) {
              params[route_elem.slice(1, -1)] = paramsValues[i - 1];
            }
          });

          // Any data retrieval wanted for this URL from the server?
          if (routeObj.isResource) {
            routeObj.callback = _performDataRequest(routeObj, {});
          }

          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          if (urlParams.toString().length > 0) {
            params.urlParams = urlParams;
          }

          stateMap.currentRoute = {
            route: obj.route,
            query: queryString
          };

          // Handle route phases
          const phases = [
            'exit',
            'exit-page',
            'init-page',
            'init',
            'content-page',
            'content'
          ];
          const exitCb = typeof window[window.exitCallback] === 'function' ? new window[window.exitCallback] : undefined;
          const actualCb = new window[routeObj.callback];

          for (let phase of phases) {
            if (phase.indexOf('exit') === 0 && typeof window.exitCallback === 'undefined') {
              continue;
            }
            if (phase.indexOf('init-page') === 0) {
              // Trigger an event to inform of the actual URI
              $(window).trigger('actual-url', [ obj.route ])
            }
            // Check if system call
            const callback = phase.indexOf('exit') === 0 ? window.exitCallback : routeObj.callback;
            /*jshint ignore:start*/
            const callbackObj = phase.indexOf('exit') === 0 ? exitCb : actualCb;
            if (phase.match(/-page$/)) {
              switch (phase) {
                case 'init-page':
                  if (typeof phaseClasses.Init === 'function') {
                    await executePhase(phaseClasses.Init, callback.name, params);
                  }
                  break;
                case 'content-page':
                  if (typeof phaseClasses.Content === 'function') {
                    await executePhase(phaseClasses.Content, callback.name, params);
                  }

                  window.exitCallback = routeObj.callback;
                  break;
                case 'exit-page':
                  if (typeof phaseClasses.Exit === 'function') {
                    await executePhase(phaseClasses.Exit, callback.name, params);
                  }
                  break;
              }
            } else if (typeof callbackObj[phase] === 'function') {
              // set previous page's callback if exit call
              await callbackObj[phase].apply(callbackObj, Object.values(params));
            } else if (phase === 'content') {
              throw new Error("You must implement at least content() in your page class " + typeof routeObj.callback);
            }
            /*jshint ignore:end*/
          }
        } else {
          $(document).trigger('404', [ obj.route ]);
        }
    });

    async function executePhase(PhaseClass, callback, params) {
      const phase = new PhaseClass();
      const fns = Object.getOwnPropertyNames(PhaseClass.prototype).filter(item => typeof phase[item] === 'function' && item !== 'constructor');
      fns.sort();
      for (const fn of fns) {
        await phase[fn].apply(phase, [ callback, params ]);
      }
    }
    //----------------- END INTERNAL METHODS ------------------------------
    //----------------- BEGIN PUBLIC METHODS ------------------------------
    /**
     * @description This method will navigate to the given route string, this means that it will
     *      change the URL and use the jQuery SPA History component. Additionally it will perform
     *      an AJAX GET request depending on the configuration for the given route in this component.
     * @param {string} route            - The route string to navigate to.
     */
    navigate = function (route, force) {
        if ( _findRoute( route, 'GET' ) >= 0 ) {
            // a route for the given string is defined, changing the URL relies always on the GET
            History.navigate(route, force);
            return;
        }
        throw 'jQuery SPA Error: The given route is not defined within the plugin';
    };

    /**
     * @description Refresh current uri
     */
    refresh = function () {
      if (typeof stateMap.currentRoute !== 'undefined') {
        navigate(currentRoute(), true);
      }
    }

    /**
     * @description This function will get an resource from the, in the jQuery SPA Data component
     *      configured, server. This requires an existing configuration for the given route within
     *      the Router component. If a route for GET with the given route string is configured,
     *      it will perform an AJAX request but no jQuery SPA History function call at all.
     *      Additionally it will modify automatically the copy of the route configuration to
     *      prevent any jQuery SPA History calls.
     * @param {string} route                - The route string for getting the resource.
     */
    getResource = function ( route ) {
        var routeObj = _getRoute( route, 'GET' ),
            execute;

        if ( !$.isEmptyObject( routeObj ) ) {
            // For only data retrieval without any URL change modify the options for this route
            // object copy.
            routeObj.shouldTriggerStateUpdate = false;
            routeObj.useHistoryStateFallback = false;

            execute = _performDataRequest( routeObj, {} );
            execute();
        } else {
            throw 'jQuery SPA Error: No configuration found for the given route';
        }

    };

    /**
     * @description This function will create an resource on the, in the jQuery SPA Data component
     *      configured, server. This requires an existing configuration for the given route within
     *      the Router component. If a route for POST with the given route string is configured,
     *      it will perform an AJAX request but no jQuery SPA History function call at all.
     * @param {string} route            - The route string for creating the resource.
     * @param {object} data             - An javascript object containing the data to use for the AJAX request.
     */
    createResource = function ( route, data ) {
        var routeObj, execute;

        if ( typeof data === 'undefined' || $.isEmptyObject( data ) ) {
            throw 'jQuery SPA Error: You must not call createResource() without any data';
        }
        routeObj = _getRoute( route, 'POST' );
        if ( !$.isEmptyObject( routeObj ) ) {
            execute = _performDataRequest( routeObj, data );
            execute();
        } else {
            throw 'jQuery SPA Error: No configuration found for the given route';
        }
    };

    /**
     * @description This function will update an resource on the, in the jQuery SPA Data component
     *      configured, server. This requires an existing configuration for the given route within
     *      the Router component. If a route for PUT with the given route string is configured,
     *      then it will perform an AJAX request but no jQuery SPA History function call at all.
     * @param {string} route            - The route string for updating the resource.
     * @param {object} data             - An javascript object containing the data to use for the AJAX request.
     */
    updateResource = function ( route, data ) {
        var routeObj, execute;
        if ( typeof data === 'undefined' || $.isEmptyObject( data ) ) {
            throw 'jQuery SPA Error: You must not call updateResource() without any data';
        }
        routeObj = _getRoute( route, 'PUT' );
        if ( !$.isEmptyObject( routeObj ) ) {
            execute = _performDataRequest( routeObj, data );
            execute();
        } else {
            throw 'jQuery SPA Error: No configuration found for the given route';
        }
    };

    /**
     * @description This function will delete an resource on the, in the jQuery SPA Data component
     *      configured, server. This requires an existing configuration for the given route within
     *      the Router component. If a route for DELETE with the given route string is configured,
     *      then it will perform an AJAX request but no jQuery History function call at all.
     * @param {string} route            - The route for the resource to delete.
     */
    deleteResource = function ( route ) {
        var routeObj = _getRoute( route, 'DELETE'),
            execute;
        if ( !$.isEmptyObject( routeObj ) ) {
            execute = _performDataRequest ( routeObj, {} );
            execute();
        } else {
            throw 'jQuery SPA Error: No configuration found for the given route';
        }
    };

    /**
     * @description This function will add a new route configuration to the jQuery SPA Router
     *      component. Required parameters are the route string and the corresponding callback
     *      for this route. Optional you can also give some options to define optional things
     *      for the new route configuration. By default it will always be an simple GET route
     *      without any AJAX calls.
     * @param {string} route            - The route string for the new configuration.
     * @param {Function} callback       - The callback function which should be executed after executing
     *      the route via Router.navigate() or any other resource related calls.
     * @param {object} options          - Optional options javascript object for the new route configuration.
     *      By default it will use a GET route without any data retrieval. Allowed options are:
     *      * options.isResource : boolean      - If true, then it will perform an AJAX request
     *                      on executing the route.
     *                                          - If false, then it will not perform any AJAX
     *                      request.
     *      * options.httpMethod : string       - Allowed are 'GET', 'POST', 'PUT' and 'DELETE'.
     *                      It defines the connected HTTP method for this route.
     *      * options.shouldTriggerStateUpdate : boolean    - If true, then it will update the
     *                      jQuery History state for GET routes only. This state can be used as
     *                      a fallback data retrieval.
     *      * options.useHistoryStateFallback : boolean     - If true, the it will use the jQuery
     *                      History component state for the data retrieval if the AJAX request fails
     *                      for GET routes.
     * @example Router.addRoute(
     *      'some/route',
     *      function(data) {},
     *      {
     *          isResource : true,
     *          httpMethod : 'GET',
     *          useHistoryStateFallback : true,
     *          shouldTriggerStateUpdate : true
     *      }
     * );
     */
    addRoute = function ( route, callback, options ) {
        var mergedOptions;

        if ( _checkRoute( route ) ){
            // Route pattern is ok, check callback
            if (!callback) {
                throw 'Router.addRoute: Missing callback or given callback is not a function';
            }
            // merge the given options from the user with the default options,
            // to make sure that all necessary properties have at least the default value
            mergedOptions = _mergeRouteOptions( options );

            stateMap.routes.push({
                route : route,
                callback : callback,
                isResource : mergedOptions.isResource,
                httpMethod : mergedOptions.httpMethod,
                useHistoryStateFallback : mergedOptions.useHistoryStateFallback,
                shouldTriggerStateUpdate : mergedOptions.shouldTriggerStateUpdate
            });
        } else {
            throw 'Router.addRoute: Given route ' + route + ' is invalid';
        }
    };

    /**
     * @description This function will check if the Router component contains already an
     *      existing route entry for the given route string and HTTP method.
     * @param {string} route            - The route to looking for in the configuration.
     * @param {string} httpMethod       - The connected HTTP method to the route to looking for
     *      the configuration in the Router component.
     * @returns {boolean}
     *      * true                      - The Router component contains an existing route entry
     *          for the given route and HTTP method.
     *      * false                     - The Router component does not contain yet an existing
     *          route configuration for the given route and HTTP method.
     */
    hasRoute = function ( route, httpMethod ) {
        var found = _findRoute( route, httpMethod );
        return found >= 0;
    };

    /**
     * @description This function will remove an existing route configuration from the jQuery
     *      SPA Router component.
     * @param {string} route            - The route string to identify the route configuration to remove.
     * @param {string} httpMethod       - Additional HTTP method string to identify the route configuration
     *      to remove.
     */
    removeRoute = function ( route, httpMethod ) {
        var index = _findRoute( route, httpMethod );
        if ( index >= 0 ) {
            stateMap.routes.splice( index, 1 );
        }
    };

    currentRoute = function (routeOnly) {
      return routeOnly ? stateMap.currentRoute.route : stateMap.currentRoute.route + (typeof stateMap.currentRoute.query !== 'undefined' && stateMap.currentRoute.query.length > 0 ? '?' + stateMap.currentRoute.query : '');
    }

    // --------------------- BEGIN CONFIG ---------------------------------
    /**
     * @description This function will configure the jQuery SPA Router component with some
     *      options from outside. A call of this function is not required, because by default
     *      the component will contain a default configuration.
     * @param {object} options          - An javascript object which contains the options to configure
     *      this component. So far there is no existing configuration necessary and also not
     *      available for this component.
     */
    configModule = function ( options ) {
        if ( typeof options !== 'object' || options === null ) {
            throw 'SPA Router needs a JavaScript Object to be configured';
        }

        Util.setStateMap({
            stateMap : stateMap,
            settablePropertyMap : settablePropertyMap,
            inputMap : options
        });
    };
    //----------------- END PUBLIC METHODS --------------------------------

    return {
      configModule : configModule,
      navigate : navigate,
      getResource : getResource,
      createResource : createResource,
      updateResource : updateResource,
      deleteResource : deleteResource,
      addRoute : addRoute,
      removeRoute : removeRoute,
      hasRoute : hasRoute,
      currentRoute: currentRoute,
      refresh: refresh
    };
}( window.jQuery ));
