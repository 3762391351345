import { default as BaseUserApi } from "../../../core/js/api/UserManagerApi";

export default class UserManagerApi extends BaseUserApi {

	constructor(id_manager) {
		super();
	
		this.id_manager = id_manager;
	  }

	crudUrl() {
		return super.crudUrl(this.id_manager);
	}

	deleteError(data, textStatus, errorThrown) {
	}

}
