const $ = window.jQuery;

import OfferApi from '../api/OfferApi';
import OfferRowApi from '../api/OfferRowApi';
import Storage from '../../../core/js/utils/Storage';

$(function(){
	
	$(document).on('offer-refreshcartbtn', async function(e){
		const stApi = new Storage();
		const currentOfferId = stApi.getData('current-offer-' + PROFILE.id);
		
		const $btn = $('.btn-offer', '.top-menu');
		if(typeof currentOfferId === 'undefined'){ // without current offer (set to "create offer")
			$btn.removeAttr('data-offer');
		} else { // with current offer (set to "edit offer")
			const offerRowApi = new OfferRowApi();
			const rows = await offerRowApi.setOfferId(parseInt(currentOfferId)).collection().catch(function(e){
				$(document).trigger('message', ['error', 'Non è stato possibile recuperare le righe d’offerta']);
				return false;
			});
			$('.offer-rows-count > span', $btn).text((Array.isArray(rows.data) && rows.data.length) ? rows.data.map(row => row.qty).reduce((a, b) => a + b) : 0);
			$btn.attr('data-offer', currentOfferId);
		}
	});
	
	$(document).on('offer-addtocart', async function(e, productData, qty = 1, offerId = null){
		
		/* productData
		{
			id: productId,
			(optional) configurations: [
				{code: '', productId: 0, value: '', price: ''},
				...
			]
		}
		*/
		
		qty = parseInt(qty);
		
		if(Number.isInteger(productData)){ // if productData is the productId
			productData = {
				id: productData
			};
		}
		if(typeof productData.id === 'undefined'){
			return false;
		}
		
		if(offerId === null){
			const stApi = new Storage();
			const currentOfferId = stApi.getData('current-offer-' + PROFILE.id);
			if(typeof currentOfferId === 'undefined'){ // without current offer
				//  create a new one
				const offerApi = new OfferApi();
				const newOffer = await offerApi.post({
					'cod_erp': '',
					'id_company': stApi.getData('current-company-' + PROFILE.id),
				});
				offerId = newOffer.user_id; // add to new offer
				stApi.setData('current-offer-' + PROFILE.id, offerId); // set it as current
			} else { // with current offer
				offerId = currentOfferId; // add to current offer
			}
		}
		
		const offerRowApi = new OfferRowApi();
		try {
			await offerRowApi.setOfferId(offerId).post({
				product: productData,
				id_location: null,
				id_shipping: null,
				qty: qty
			});
		} catch(error) {
			$(document).trigger('message', ['error', error.responseJSON.error]);
			return false;
		}
		$(document).trigger('offer-refreshcartbtn');

		$('.header-select-company select').prop('disabled', true);
		
		$(document).trigger('message', ['success', 'Prodotto aggiunto all’offerta']);
	});
	
});
