const $ = window.jQuery;

import _ from "lodash";
import Page from "./Page";
import ActionsMenu from "../components/ActionsMenu/ActionsMenu";
import ActionsMenuItem from "../components/ActionsMenu/ActionsMenuItem";
import ScaryDataTable from "../components/Plugins/ScaryDataTable";
import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
import AccessoryApi from "../api/AccessoryApi";
import Footer from "../../../core/js/components/Form/Footer";

export default class AccessoriesPage extends Page {

  constructor() {
    super();

    this.setTitle('Accessori')
      .setMeta('description', 'Accessori nel sistema')
      .setBreadcrumb('Accessori');
  }

  content() {
	
	//
	// actions menu
	//
	
    const actions_menu = new ActionsMenu();
	actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-accessories']);
	actions_menu.addUlClasses('actions-menu');
	
	const btn_add = new ActionsMenuItem();
	btn_add
		.addClasses([ 'element', 'only-icon', 'add' ])
		.setTitle('Aggiungi')
		.setOn('click', () => $.spa.navigate('/accessory/manage/create'))
		.setRoles('RD_ADMIN_USERS');
		
	const btn_edit = new ActionsMenuItem();
	btn_edit
		.addClasses([ 'element', 'only-icon', 'edit', 'disabled' ])
		.setTitle('Modifica')
		.setRoles('RD_ADMIN_USERS');
		
	const btn_delete = new ActionsMenuItem();
	btn_delete
		.addClasses([ 'element', 'only-icon', 'delete', 'disabled' ])
		.setTitle('Elimina')
		.setRoles('RD_ADMIN_USERS');
		
	actions_menu.addItems([
		btn_add,
		btn_edit,
		btn_delete
	]);

	//
	// products table
	//
	
	// $.confirm delete
	const confirmDelete = new ScaryJqueryConfirm();
	confirmDelete
		.setTitle('Attenzione')
		.setContent('Sei sicuro di voler cancellare l\'accessorio?');

	// quick-open dt row on double-click
	$(document).on('dblclick', '.dt-accessories tbody tr', function(){
		let targetId = parseInt($(this).closest('tr').find('td').first().text());
		$.spa.navigate(`/accessory/manage/${targetId}`);
	});
		
	// handle dt_products select/deselect
	const dt_accessories_onSelectDeselect = function(e, dt, type, indexes){
		
		// check for selected rows
		let targetIds = [];
		if (type === 'row') {
			dt.rows({selected: true}).every(function(idx){
				const targetId = $(dt.row(idx).node()).attr('data-id');
				if(typeof targetId !== 'undefined'){
					targetIds.push(parseInt(targetId));
				}
			});
		}
		
		$(`#${btn_edit.name}, #${btn_delete.name}`)
			.off('click')
			.toggleClass('disabled', targetIds.length === 0);
		
		if(targetIds.length > 0){
			$(`#${btn_edit.name}`).on('click', () => $.spa.navigate(`/accessory/manage/${targetIds[0]}`));
			$(`#${btn_delete.name}`).on('click', () => {
				confirmDelete
					.modalConfirm(async (data) => {
						const accessoryApi = new AccessoryApi();
						await accessoryApi.delete(targetIds[0])
							.then(() => {
								$.spa.navigate('/accessories', true);
							})
							.catch((e) => {
								$(document).trigger('message', ['error', e.responseJSON.error]);
							});
					})
					.render();
			});
		}
		
	};
	const debounced_dt_accessories_onSelectDeselect = _.debounce(dt_accessories_onSelectDeselect, 100);
	
	const dt_accessories = new ScaryDataTable();
	dt_accessories
		.setUrl('/accessory')
		.setSelect('single')
		.setOnSelect(debounced_dt_accessories_onSelectDeselect)
		.setOnDeselect(debounced_dt_accessories_onSelectDeselect)
		.setRowCallback(function(row, data){
			dt_accessories.defaultRowCallback(row, data);

			if ( data.available == 0 ) {
				$('.lblDispo', row).html( '<img src=\'/assets/images/no.png\'>' );
			}
			if ( data.available == 1 ) {
				$('.lblDispo', row).html( '<img src=\'/assets/images/si.png\'>' );
			}
		})
		.setColumnDefs([
			{targets: 8, className: "lblDispo"},
			{targets: 6, data: "description", render: function ( data, type, row, meta ) {
				return type === 'display' && data.length > 20 ?
				  '<span title="'+data+'">'+data.substr( 0, 20 )+'...</span>' :
				  data;
			  }
		  	}
		])
		.addField('id', 'ID')
		.addField('cat', 'Catalogo')
		.addField('brand', 'Marchio')
		.addField('name', 'Nome')
		.addField('artcode_erp', 'Codice ERP')
		//.addField('type', 'Tipologia')
		.addField('description', 'Descrizione')
		.addField('posdes', 'Posizione')
		.addField('weight_format', 'Peso Kg')
		.addField('available', 'Disponibile')
		.addField('base_price_format', 'Prezzo Vs €')
		/*.addField('transfer_price', 'Prezzo Vendita')
		.addField('cc_bn_base_price', 'Cc BN Prezzo Base')
		.addField('cc_bn_transfer_price', 'Cc BN Prezzo Vendita')
		.addField('cc_color_base_price', 'Cc ColorePrezzo Base')
		.addField('cc_color_transfer_price', 'Cc Colore Prezzo Vendita')
		.addField('cc_light_base_price', 'Cc Prezzo Base Leggero')
		.addField('cc_light_transfer_price', 'Cc Prezzo Vendita Leggero')
		.addField('cc_max_bn', 'Cc Max Copie BN')
		.addField('cc_max_color', 'Cc Max Copie Colore')
		.addField('cc_suggested_bn', 'Cc Copie BN Suggerite')
		.addField('cc_suggested_color', 'Cc Copie Colori Suggerite')*/
		.addField('sort', 'Ordine Cat. Prod.')
		//.setResponsivePriority('id', 0)
		.setResponsivePriority('name', 0)
		.setResponsivePriority('artcode_erp', 1)
		.addClasses('dt-accessories');
		//.addClasses('dt-products');
		//.setResponsiveDetails(false)
		

	// render
	$('main .main').html(actions_menu.render() + dt_accessories.render() + Footer.render());
    dt_accessories.activate();
	Footer.activate(this);
	
  }

}
