const $ = window.jQuery;

const spa_route = function (e) {
  const href = $(this).attr('href') || $(this).find('a').attr('href');
  if (href && !href.match(/^#/) && !href.match(/^http(s)?:\/\//)) {
    e.preventDefault();
    e.stopImmediatePropagation();

    $.spa.navigate(href);
  }
};

export default spa_route;