import CrudApi from "../../../core/js/api/CrudApi";

export default class OfferDocumentsApi extends CrudApi {

    constructor() {
        super();
        this.offerId = undefined;
    }

    setOfferId(offerId) {
        this.offerId = offerId;
        return this;
    }

    crudUrl() {
        return `/offer/${this.offerId}/document`;
    }

}
