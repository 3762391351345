const $ = window.jQuery;

import Page from './Page';
import Form from '../../../core/js/components/Form/Form';
import SubmitField from '../../../core/js/components/Form/SubmitField';
import TextField from '../../../core/js/components/Form/TextField';
import EmailField from '../../../core/js/components/Form/EmailField';
import TextareaField from '../components/Form/TextareaField';
import SelectBooleanField from '../../../core/js/components/Form/SelectBooleanField';
import OptionField from '../../../core/js/components/Form/OptionField';
import HiddenField from '../../../core/js/components/Form/HiddenField';
import Footer from '../../../core/js/components/Form/Footer';
import ScarySelect2 from '../components/Plugins/ScarySelect2';
import CustomerLocationApi from '../api/CustomerLocationApi';
import CountryApi from '../api/CountryApi';
import RegionApi from '../api/RegionApi';

export default class CustomerLocationFormPage extends Page {

	constructor() {
		super();

		this.data = undefined;
		this.countries = undefined;
		this.regions = undefined;
	}

	async init(customer_id) {
		const ragsoc = await this.getRagSocCustomer(customer_id);

		this
			.setTitle('Gestione Sede del Cliente')
			.setMeta('description', 'Gestione Sede del Cliente')
			.setBreadcrumb([
				{ label: 'Clienti', href: '/customers' },
				{ label: ragsoc, href: `/customer/manage/${customer_id}` },
				{ label: 'Elenco Sedi', href: `/customer/manage/${customer_id}/locations` },
				'Sede'
			]);

		return super.init(customer_id);
	}

	async content(customer_id, location_id) {
		const _page = this;

		const customerLocationForm = new Form();
		customerLocationForm
			.setAction(`/customer/manage/${customer_id}/location`) 
			.setTitle('Gestisci Sedi del Cliente')
			.addClasses('padded-container label-left');

		if (location_id === 'create') { // Create form
			customerLocationForm.setMethod('POST');
		} else { // Update form
			customerLocationForm.setMethod('PUT');
			const customerLocationsApi = new CustomerLocationApi();
			const customerLocationData = await customerLocationsApi.setCustomerId(customer_id).get(location_id);
			if (customerLocationData) {
				this.data = customerLocationData.data;
			} else {
				return;
			}
		}

		const in_edit = typeof this.data !== 'undefined';

		this.setPageTitle(in_edit, customerLocationForm, 'Nuova Sede del Cliente', 'Modifica Sede del Cliente');
		
		await this.retrieveCountries();
		await this.retrieveRegions(in_edit ? this.data.id_country : null);

		const id_customer = new HiddenField();
		id_customer
			.setFieldName('id_customer')
			.setValue(customer_id);

		const name = new TextField();
		name
		.setLabel('Descrizione')
		.setFieldName('name')
		.setRequired(true);

		const type = new ScarySelect2();
		type
			.setFieldName('type')
			.setLabel('Tipo')
			.setRequired(true)
			.setMultiple(false);
		const optionsPos = [];
	
		const optionsPos0 = new OptionField();
		optionsPos0
			.setValue("")
			.setLabel("Seleziona un valore")
			.setDefault(this.data?.type === "");
		const optionsPos1 = new OptionField();
		optionsPos1
			.setValue("Legale")
			.setLabel("Legale")
			.setDefault(this.data?.type === "Legale");
		const optionsPos2 = new OptionField();
		optionsPos2
			.setValue("Amministrativa")
			.setLabel("Amministrativa")
			.setDefault(this.data?.type === "Amministrativa");
		const optionsPos3 = new OptionField();
		optionsPos3
			.setValue("Operativa")
			.setLabel("Operativa")
			.setDefault(this.data?.type === "Operativa");
		const optionsPos4 = new OptionField();
		optionsPos4
			.setValue("Altro")
			.setLabel("Altro")
			.setDefault(this.data?.type === "Altro");
		optionsPos.push(optionsPos0);
		optionsPos.push(optionsPos1);
		optionsPos.push(optionsPos2);
		optionsPos.push(optionsPos3);
		optionsPos.push(optionsPos4);
		type.addOptions(optionsPos);

		const id_country = new ScarySelect2();
		id_country
			.setFieldName('id_country')
			.setLabel('Nazione')
			.setAllowClear(true)
			.setRequired(true);
		const countryOptions = [];
		const countryBlankOption = new OptionField();
		countryBlankOption
			.setValue('');
		countryOptions.push(countryBlankOption);
		this.countries.map((country) => {
			const option = new OptionField();
			option
				.setValue(country.iso2)
				.setLabel(country.label);
			if (this.data?.id_country === country) {
				option.setDefault(true);
			}
			else if(country.iso2 == "IT" && !in_edit){
				option.setDefault(true);
			}
			countryOptions.push(option);
		});
		id_country.addOptions(countryOptions);
		
		const id_region = new ScarySelect2();
		id_region
			.setFieldName('id_region')
			.setLabel('Provincia')
			.setAllowClear(true)
			.setWidth('100%');
		const regionOptions = [];
		const regionBlankOption = new OptionField();
		regionBlankOption
			.setValue('');
		regionOptions.push(regionBlankOption);
		this.regions.map((region) => {
			const option = new OptionField();
			option
				.setValue(region.id)
				.setLabel(region.label);
			if (this.data?.id_region === region) {
				option.setDefault(true);
			}
			regionOptions.push(option);
		});
		id_region.addOptions(regionOptions);
		if(this.regions.length) {
			id_region
				.setRequired(true);
		} else {
			id_region
				.addClasses('required') // fake, just for render()
				.append('<span class="required"></span>') // fake, just for render()
				.addWrapperClasses('hidden'); 
		}
		
		const region_other = new TextField();
		region_other
			.setLabel('Provincia')
			.setFieldName('region_other');
		if(this.regions.length) {
			region_other
				.addClasses('required') // fake, just for render()
				.append('<span class="required"></span>') // fake, just for render()
				.addWrapperClasses('hidden');
		} else {
			region_other
				.setRequired(true);
		}

		const address = new TextField();
		address
			.setLabel('Indirizzo')
			.setFieldName('address')
			.setRequired(true);

		const city = new TextField();
		city
			.setLabel('Città')
			.setFieldName('city')
			.setRequired(true);

		const zip = new TextField();
		zip
			.setLabel('CAP')
			.setFieldName('zip')
			.setRequired(true);

		const openingTime = new TextField();
		openingTime
			.setLabel('Orario di Apertura')
			.setFieldName('opening_time')
			.setRequired(false);

		const ztl = new SelectBooleanField();
		ztl
			.setLabel('ZTL')
			.setFieldName('ztl')
			.setRequired(false);

		const ref_name = new TextField();
		ref_name
			.setLabel('Nome Referente')
			.setFieldName('ref_name')
			.setRequired(false);
		
		const ref_email = new EmailField();
		ref_email
			.setLabel('Email Referente')
			.setFieldName('ref_email')
			.setRequired(false);
		
		const ref_phone = new TextField();
		ref_phone
			.setLabel('Telefono Referente')
			.setFieldName('ref_phone')
			.setRequired(false);

		const notes = new TextareaField();
		notes
			.setLabel('Note')
			.setFieldName('notes');

		const submit = new SubmitField();
		submit
			.setFieldName('submit_location')
			.setValue(in_edit ? 'Aggiorna' : 'Salva');

		if(in_edit) {
			name.setValue(this.data.name);
			if(this.data.id_country !== null){
				id_country.setValue(this.data.id_country);
			}
			if(this.data.id_region !== null){
				id_region.setValue(this.data.id_region);
			}
			region_other.setValue(this.data.region_other);
			address.setValue(this.data.address);
			city.setValue(this.data.city);
			zip.setValue(this.data.zip);
			openingTime.setValue(this.data.opening_time);
			ztl.setValue(this.data.ztl);
			ref_name.setValue(this.data.ref_name);
			ref_email.setValue(this.data.ref_email);
			ref_phone.setValue(this.data.ref_phone);
			notes.setValue(this.data.notes);

			if(this.data.erp === '1'){
				id_customer.setReadonly();
				name.setReadonly();
				type.setReadonly();
				id_country.setReadonly();
				id_region.setReadonly();
				region_other.setReadonly();
				city.setReadonly();
				zip.setReadonly();
				address.setReadonly();
				// openingTime is editable
				// ztl is editable
				ref_name.setReadonly();
				ref_email.setReadonly();
				ref_phone.setReadonly();
				// notes is editable
			}
		}
    
		customerLocationForm.addFields([
			id_customer,
			name,
			type,
			id_country,
			id_region,
			region_other,
			city,
			zip,
			address,
			openingTime,
			ztl,
			ref_name,
			ref_email,
			ref_phone,
			notes,
			submit
		]);

		customerLocationForm.onSubmit(async function(res) {
			
			const customerLocationApi = new CustomerLocationApi();
			customerLocationApi.setForm(customerLocationForm);
		
			let values = customerLocationForm.getValues();
		
			let msg = '';
			if (in_edit) {
				const res = await customerLocationApi.setCustomerId(customer_id).put(location_id, values);
				msg = 'Sede Cliente modificata con successo';
			} else {
				const res = await customerLocationApi.setCustomerId(customer_id).post(values);
				location_id = res.data.id;
				msg = res.data.message;
			}
			if (!customerLocationForm.hasErrors()) {
				$(document).trigger('message', [ 'success', msg ]);
				$.spa.navigate(`/customer/manage/${customer_id}/locations`); 
			}
		});

		$('main .main').html(customerLocationForm.render() + Footer.render());
		customerLocationForm.activate();
		Footer.activate(this);

		if(!in_edit){
			$(document).ready(async function() {

				//console.log("init1");

				await _page.retrieveRegions('IT');
			
				// update regions on Component
				const regionOptions = [];
				let regionOptionsHtml = '';
				const regionBlankOption = new OptionField();
				regionBlankOption
					.setValue('');
				regionOptions.push(regionBlankOption);
				regionOptionsHtml += regionBlankOption.render();
				_page.regions.map((region) => {
					const option = new OptionField();
					option
						.setValue(region.id)
						.setLabel(region.label);
					if (_page.data?.id_region === region) {
						option.setDefault(true);
					}
					regionOptions.push(option);
					regionOptionsHtml += option.render();
				});
				id_region.resetOptions();
				id_region.addOptions(regionOptions);

				//console.log("init2");
				
				// update regions on DOM
				$(`#${id_region.name}`).html(regionOptionsHtml);
				
				// toggler id_region/region_other fields based on the presence of regions in the database for the selected country
				if(_page.regions.length) {
					id_region.required = true;
					$(`#${id_region.name}`).prop('required', true).closest('.formfield').removeClass('hidden');
					region_other.required = false;
					$(`#${region_other.name}`).prop('required', false).val('').closest('.formfield').addClass('hidden');
				} else {
					id_region.required = false;
					$(`#${id_region.name}`).prop('required', false).val('').closest('.formfield').addClass('hidden');
					region_other.required = true;
					$(`#${region_other.name}`).prop('required', true).closest('.formfield').removeClass('hidden');
				}

				//console.log("init3");

			});
		}

		// handle country change
		$(`#${id_country.name}`).on('change', async function(){
			// retrieve new regions

			//console.log("valore iniziale stato:" + $(this).val());

			await _page.retrieveRegions($(this).val());
			
			// update regions on Component
			const regionOptions = [];
			let regionOptionsHtml = '';
			const regionBlankOption = new OptionField();
			regionBlankOption
				.setValue('');
			regionOptions.push(regionBlankOption);
			regionOptionsHtml += regionBlankOption.render();
			_page.regions.map((region) => {
				const option = new OptionField();
				option
					.setValue(region.id)
					.setLabel(region.label);
				if (_page.data?.id_region === region) {
					option.setDefault(true);
				}
				regionOptions.push(option);
				regionOptionsHtml += option.render();
			});
			id_region.resetOptions();
			id_region.addOptions(regionOptions);
			
			// update regions on DOM
			$(`#${id_region.name}`).html(regionOptionsHtml);
			
			// toggler id_region/region_other fields based on the presence of regions in the database for the selected country
			if(_page.regions.length) {
				id_region.required = true;
				$(`#${id_region.name}`).prop('required', true).closest('.formfield').removeClass('hidden');
				region_other.required = false;
				$(`#${region_other.name}`).prop('required', false).val('').closest('.formfield').addClass('hidden');
			} else {
				id_region.required = false;
				$(`#${id_region.name}`).prop('required', false).val('').closest('.formfield').addClass('hidden');
				region_other.required = true;
				$(`#${region_other.name}`).prop('required', true).closest('.formfield').removeClass('hidden');
			}
		});

	}
  
	async retrieveCountries() {
		const countryApi = new CountryApi();
		const countries = await countryApi.collection().catch(function (e) {
			const errmsg = 'Non è stato possibile recuperare le nazioni';
			$(document).trigger('message', ['error', errmsg]);
			throw errmsg;
		});
		this.countries = countries.data;
	}
	
	async retrieveRegions(countryId = null) {
		const regionApi = new RegionApi();
		const regions = await regionApi.setCountryId(countryId).collection().catch(function (e) {
			const errmsg = 'Non è stato possibile recuperare le regioni';
			$(document).trigger('message', ['error', errmsg]);
			throw errmsg;
		});
		this.regions = regions.data;
	}
  
}
