const $ = window.jQuery;

import Page from "./Page";
import Form from "../../../core/js/components/Form/Form";
import TextField from "../../../core/js/components/Form/TextField";
import NumberField from "../components/Form/NumberField";
import PriceField from "../components/Form/PriceField";
import OptionField from "../../../core/js/components/Form/OptionField";
import FileField from "../../../core/js/components/Form/FileField";
import SubmitField from "../../../core/js/components/Form/SubmitField";
import ProductApi from "../api/ProductApi";
import SettingsApi from "../api/SettingsApi";
import ProductBrandsApi from "../api/ProductBrandsApi";
import HiddenField from "../../../core/js/components/Form/HiddenField";
import ButtonField from "../components/Form/ButtonField";
import TextareaField from "../components/Form/TextareaField";
import SelectBooleanField from '../../../core/js/components/Form/SelectBooleanField';
import ScarySelect2 from "../components/Plugins/ScarySelect2";
import FileApi from "../../../core/js/api/FileApi";
import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
import ImageProductApi from "../api/ImageProductApi";
import CarepackApi from '../api/CarepackApi';
import Footer from "../../../core/js/components/Form/Footer";

export default class ProductFormPage extends Page {

  constructor() {
    super();

    this.companies = undefined;
    this.brands = undefined;

    this
      .setTitle('Gestione prodotti')
      .setMeta('description', 'Gestione prodotti')
      .setBreadcrumb([
        { label: 'Prodotti', href: '/products' },
        'Gestione prodotti'
      ]);
  }

  async init(product_id) {

    const settingsApi = new SettingsApi();
    this.companies = await settingsApi.collection().catch(function (e) {
      const errmsg = 'Non è stato possibile recuperare le aziende';
      $(document).trigger('message', ['error', errmsg]);
      throw errmsg;
    });
    this.companies = this.companies.data;

    const productBrandsApi = new ProductBrandsApi();
    this.brands = await productBrandsApi.brands().catch(function (e) {
      const errmsg = 'Errore nello scaricare i marchi';
      $(document).trigger('message', ['error', errmsg]);
      throw errmsg;
    });
    this.brands = this.brands.data;

    const carepackApi = new CarepackApi();
    this.carepacks = await carepackApi.collection().catch(function (e) {
      const errmsg = 'Non è stato possibile recuperare i carepack';
      $(document).trigger('message', ['error', errmsg]);
      throw errmsg;
    });
    this.carepacks = this.carepacks.data;

    return super.init();
  }

  async content(product_id) {
    const _page = this;

    const productForm = new Form();

    productForm
      .setAction('/product')
      .setTitle('Gestisci prodotto')
      .addClasses('padded-container label-left');
    let data;

    if (product_id === 'create') {
      // Create form
      productForm.setMethod('POST');
    } else {
      // Update form
      productForm.setMethod('PUT');

      const productApi = new ProductApi();
      const productData = await productApi.get(product_id);
      if (productData) {
        data = productData.data;
      } else {
        $(document).trigger('404', [productApi.url + '/' + product_id]);
        return;
      }
    }

    const in_edit = typeof data !== 'undefined';

    const settings = this.getSelectCompanies(product_id !== 'create' ? data[0].settings : []);

    const cmbBrands = this.getSelectBrands(product_id !== 'create' ? data[0].id_brand : undefined);

    const carepacks = this.getSelectCarepacks(product_id !== 'create' ? data[0].carepacks : []);

    const image = new FileField();
    image
      .setFieldName('image')
      .setMaxSize('10MB')
      .addMimeTypes(['jpg', 'png'])
      .setLabel('Immagine del prodotto');
    const id_image = new HiddenField();
    id_image
      .setFieldName('id_image');

    const datasheet = new FileField();
    datasheet
      .setFieldName('datasheet')
      .setMaxSize('10MB')
      .addMimeTypes(['pdf'])
      .setLabel('Scheda Tecnica del prodotto');
    const id_datasheet = new HiddenField();
    id_datasheet
      .setFieldName('id_datasheet');

    const enabled = new SelectBooleanField();
    enabled
      .setFieldName('enabled')
      .setLabel('Abilitato')
      .setRequired(true);

    const name = new TextField();
    name
      .setFieldName('name')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Nome');

    const artcode_erp = new TextField();
    artcode_erp
      .setFieldName('artcode_erp')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Codice ERP');

    const type = new ScarySelect2();
    type
      .setFieldName('type')
      .setLabel('Tipologia')
      .setMinimumResultsForSearch(3)
      .setPlaceholder('Seleziona un valore')
      .setRequired(true)
      .setMultiple(false);
    const optionsType = [];

    const optionType0 = new OptionField();
    optionType0
      .setValue('')
      .setLabel("")
      .setDefault(data?.available === "");
    const optionType1 = new OptionField();
    optionType1
      .setValue('MFP')
      .setLabel("MFP")
      .setDefault(data?.available === "MFP");
    const optionType2 = new OptionField();
    optionType2
      .setValue('PRT')
      .setLabel("PRT")
      .setDefault(data?.available === "PRT");
    optionsType.push(optionType0);
    optionsType.push(optionType1);
    optionsType.push(optionType2);
    type.addOptions(optionsType);

    const description = new TextareaField();
    description
      .setFieldName('description')
      .setRequired(false)
      .setPlaceholder('')
      .setLabel('Descrizione Prodotto');

    const datasheet_html = new TextareaField();
    datasheet_html
      .setFieldName('datasheet_html')
      .setRequired(false)
      .setPlaceholder('')
      .setLabel('Descrizione Scheda Tecnica<br>(Html per PDF Offerta)');

    const format = new ScarySelect2();
    format
      .setFieldName('format')
      .setLabel('Formato')
      .setMinimumResultsForSearch(3)
      .setPlaceholder('Seleziona un valore')
      .setRequired(true)
      .setMultiple(false);
    const optionsFormat = [];

    const optionsFormat0 = new OptionField();
    optionsFormat0
      .setValue('')
      .setLabel("")
      .setDefault(data?.available === "");
    const optionsFormat1 = new OptionField();
    optionsFormat1
      .setValue('A3')
      .setLabel("A3")
      .setDefault(data?.available === "A3");
    const optionsFormat2 = new OptionField();
    optionsFormat2
      .setValue('A4')
      .setLabel("A4")
      .setDefault(data?.available === "A4");
    optionsFormat.push(optionsFormat0);
    optionsFormat.push(optionsFormat1);
    optionsFormat.push(optionsFormat2);
    format.addOptions(optionsFormat);

    const color = new ScarySelect2();
    color
      .setFieldName('color')
      .setLabel('Colore')
      .setMinimumResultsForSearch(3)
      .setPlaceholder('Seleziona un valore')
      .setRequired(true)
      .setMultiple(false);
    const optionsColor = [];

    const optionsColor0 = new OptionField();
    optionsColor0
      .setValue('')
      .setLabel("")
      .setDefault(data?.available === "");
    const optionsColor1 = new OptionField();
    optionsColor1
      .setValue('Bianco e Nero')
      .setLabel("Bianco e Nero")
      .setDefault(data?.available === "Bianco e Nero");
    const optionsColor2 = new OptionField();
    optionsColor2
      .setValue('Colore')
      .setLabel("Colore")
      .setDefault(data?.available === "Colore");
    optionsColor.push(optionsColor0);
    optionsColor.push(optionsColor1);
    optionsColor.push(optionsColor2);
    color.addOptions(optionsColor);

    const available = new ScarySelect2();
    available
      .setFieldName('available')
      .setLabel('Disponibile')
      .setMinimumResultsForSearch(3)
      .setRequired(true)
      .setMultiple(false);
    const optionsAva = [];

    const optionAva1 = new OptionField();
    optionAva1
      .setValue("0")
      .setLabel("No")
      .setDefault(data?.available === "0");
    const optionAva2 = new OptionField();
    optionAva2
      .setValue("1")
      .setLabel("Si")
      .setDefault(data?.available === "1");
    optionsAva.push(optionAva1);
    optionsAva.push(optionAva2);
    available.addOptions(optionsAva);

    const base_price = new PriceField();
    base_price
      .setFieldName('base_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('Prezzo Vs');

    const cc_bn_base_price = new PriceField();
    cc_bn_base_price
      .setFieldName('cc_bn_base_price')
      //.setCleave("numeral",true)
      .setCleave("numeralDecimalScale", "4")
      //.setCleave("numeralDecimalMark",".")
      //.setCleave("delimiter",",")
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Vs BN');

    const cc_color_base_price = new PriceField();
    cc_color_base_price
      .setFieldName('cc_color_base_price')
      //.setCleave("numeral",true)
      .setCleave("numeralDecimalScale", "4")
      //.setCleave("numeralDecimalMark",".")
      //.setCleave("delimiter",",")
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Vs Colore');

    const cc_max_bn = new NumberField();
    cc_max_bn
      .setFieldName('cc_max_bn')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Max Copie BN');

    const cc_max_bn_hidden = new HiddenField();
    cc_max_bn_hidden
      .setFieldName('cc_max_bn')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Max Copie BN');

    const cc_max_color = new NumberField();
    cc_max_color
      .setFieldName('cc_max_color')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Max Copie Colore');

    const cc_suggested_bn = new NumberField();
    cc_suggested_bn
      .setFieldName('cc_suggested_bn')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Copie BN Suggerite');

    const cc_suggested_color = new NumberField();
    cc_suggested_color
      .setFieldName('cc_suggested_color')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Copie Colore Suggerite');

    const weight = new TextField();
    weight
      .setFieldName('weight')
      .setCleave("numeral", true)
      .setCleave("numeralDecimalScale", "2")
      .setCleave("numeralDecimalMark", ".")
      .setCleave("delimiter", ",")
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Peso Kg');

    let sort;

    if (in_edit) {
      sort = new NumberField();
      sort
        .setFieldName('sort')
        .setMaxlength(50)
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Posizione in Categoria');
    }
    else {
      sort = new HiddenField();
      sort.setFieldName('sort').setValue(0);
    }

    const id_catalog = new HiddenField();
    id_catalog.setFieldName('id_catalog').setValue(DEFAULT_CATEGORY);

    const submit = new SubmitField();
    submit
      .setFieldName('submit_product')
      .setValue(in_edit ? 'Aggiorna' : 'Salva');

    const btn_accessories = new ButtonField();
    btn_accessories
      .setFieldName('button_accessory')
      .setValue('Gestisci Accessori')
      .onClick('/product/' + product_id + '/accessoriesProduct');

    if (!data || data.relationship === 'product') {
      const cc_bn_base_price_hidden = new HiddenField();
      cc_bn_base_price_hidden.setFieldName('cc_bn_base_price').setValue(0);

      const cc_color_base_price_hidden = new HiddenField();
      cc_color_base_price_hidden.setFieldName('cc_color_base_price').setValue(0);

      const cc_bn_transfer_price_hidden = new HiddenField();
      cc_bn_base_price_hidden.setFieldName('cc_bn_base_price').setValue(0);

      const cc_color_transfer_price_hidden = new HiddenField();
      cc_color_transfer_price_hidden.setFieldName('cc_color_transfer_price').setValue(0);

      const cc_light_base_price_hidden = new HiddenField();
      cc_light_base_price_hidden.setFieldName('cc_light_base_price').setValue(0);

      const cc_light_transfer_price_hidden = new HiddenField();
      cc_light_transfer_price_hidden.setFieldName('cc_light_transfer_price').setValue(0);

      const cc_max_color_hidden = new HiddenField();
      cc_max_color_hidden.setFieldName('cc_max_color').setValue(0);

      const cc_suggested_bn_hidden = new HiddenField();
      cc_suggested_bn_hidden.setFieldName('cc_suggested_bn').setValue(0);

      const cc_suggested_color_hidden = new HiddenField();
      cc_suggested_color_hidden.setFieldName('cc_suggested_color').setValue(0);
    }

    if (in_edit) {
      name.setValue(data[0].name);
      if (data[0].id_image !== null) {
        id_image.setValue(data[0].id_image);
      }
      image.setValue(data[0].image);

      if (data[0].id_datasheet !== null) {
        id_datasheet.setValue(data[0].id_datasheet);
      }
      datasheet.setValue(data[0].datasheet);

      artcode_erp.setValue(data[0].artcode_erp);
      type.setValue(data[0].type);
      description.setValue(data[0].description);
      datasheet_html.setValue(data[0].datasheet_html);
      format.setValue(data[0].format);
      color.setValue(data[0].color);
      weight.setValue(data[0].weight);
      available.setValue(data[0].available);
      base_price.setValue(data[0].base_price);
      //transfer_price.setValue(data[0].transfer_price);
      cc_bn_base_price.setValue(data[0].cc_bn_base_price);
      //cc_bn_transfer_price.setValue(data[0].cc_bn_transfer_price);
      cc_color_base_price.setValue(data[0].cc_color_base_price);
      //cc_color_transfer_price.setValue(data[0].cc_color_transfer_price);
      //cc_light_base_price.setValue(data[0].cc_light_base_price);
      //cc_light_transfer_price.setValue(data[0].cc_light_transfer_price);
      cc_max_bn.setValue(data[0].cc_max_bn);
      cc_max_color.setValue(data[0].cc_max_color);
      cc_suggested_bn.setValue(data[0].cc_suggested_bn);
      cc_suggested_color.setValue(data[0].cc_suggested_color);
      enabled.setValue(data[0].enabled);
      sort.setValue(data[0].sort);
      //btn_accessories.addClassHref('hrefButtonAcc is_visibile');
      //btn_accessories.addClasses(['btn_accessories','is_visibile']);
      btn_accessories.addClasses(['btn_accessories', 'is_visibile']);
    }
    else {
      //btn_accessories.addClassHref('hrefButtonAcc is_invisibile');
      //btn_accessories.addClasses(['btn_accessories','is_invisibile']);
      btn_accessories.addClasses(['btn_accessories', 'is_invisibile']);
    }

    const formFields = [
      id_catalog,
      submit.clone(),
      btn_accessories.clone(),
      enabled,
      settings,
      cmbBrands,
      name,
      image,
      id_image,
      datasheet,
      id_datasheet,
      artcode_erp,
      type,
      description,
      datasheet_html,
      format,
      color,
      weight,
      available,
      base_price,
      cc_bn_base_price,
      cc_color_base_price,
      cc_max_bn,
      cc_max_color,
      cc_suggested_bn,
      cc_suggested_color,
      carepacks,
      sort,
      submit,
      btn_accessories
    ];

    productForm.addFields(formFields);

    productForm.onSubmit(async function (res) {

      const productApi = new ProductApi();
      productApi.setForm(productForm);
      productApi.useFormData = true; //metterlo per passare formdata

      let values = productForm.getValues();
      values.image = image.getFiles(); // replace standard "value" of FileField with FileList object
      values.datasheet = datasheet.getFiles(); // replace standard "value" of FileField with FileList object
      
      const settingsIds = values.settings.map(label => {
        const setting = _page.companies.find(obj => obj.name === label);
        return setting ? setting.id : null;
      });
      values.settings = settingsIds.filter(id => id !== null);

      const carepacksIds = values.carepacks.map(label => {
        const carepack = _page.carepacks.find(obj => obj.name === label);
        return carepack ? carepack.id : null;
      });
      values.carepacks = carepacksIds.filter(id => id !== null);

      let msg = '';
      if (in_edit) {
        const res = await productApi.put(product_id, values);
        msg = res.data;
      } else {
        const res = await productApi.post(values);
        product_id = res.product_id;
        msg = res.data;
      }

      if (!productForm.hasErrors()) {
        $(document).trigger('message', ['success', msg]);
        $.spa.navigate('/product/manage/' + product_id, true);
      }
    });

    //$('main .main').html(btn_accessories.render() + productForm.render() + Footer.render());
    $('main .main').html(productForm.render() + Footer.render());
    productForm.activate();
    Footer.activate(this);

    // delete image product
    $(`.formfield.file-type .delete`).on('click', function () {
      let $targetFileContent = $(this).closest('.file-content');
      const confirmDeleteImageProd = new ScaryJqueryConfirm();
      confirmDeleteImageProd
        .setTitle('Attenzione')
        .setContent('Sei sicuro di voler cancellare il media del prodotto ?')
        .modalConfirm(async (data) => {
          const fileApi = new FileApi();
          await fileApi.delete($targetFileContent.attr('data-id')) //cancellazione immagine dalla tabella file
            .then(() => {
              deleteImgProd($targetFileContent.attr('data-id')); //cancellazione della immagine dalla tabella product
            })
            .catch((e) => {
              $(document).trigger('message', ['error', e.responseJSON.error]);
            });
        })
        .render();
    });

    async function deleteImgProd(dataId) {
      const imageProductApi = new ImageProductApi();
      await imageProductApi.deleteFile(product_id, dataId)
        .then(() => {
          $.spa.navigate('/product/manage/' + product_id, true);
        })
        .catch((e) => {
          $(document).trigger('message', ['error', e.responseJSON.error]);
        });
    }
  }

  getSelectBrands(defbrand) {
    const brandsSelect = new ScarySelect2();
    brandsSelect
      .setFieldName('id_brand')
      .setRequired(true)
      .setLabel('Marchi')
      .setPlaceholder('Seleziona un valore')
      .setMultiple(false);
    const emptyopt = new OptionField();
    emptyopt
      .setValue('')
      .setLabel('Seleziona');
    brandsSelect.addOptions(emptyopt);
    const options = [];

    this.brands.map(function (brand) {
      const option = new OptionField();
      option
        .setValue(brand.id)
        .setLabel(brand.name);
      if (brand.id === defbrand) {
        option.setDefault(true);
      }

      options.push(option);
    });

    brandsSelect.addOptions(options);

    return brandsSelect;
  }

  getSelectCompanies(defCompanies) {

    const options = [];
    this.companies.map((company) => {
      const option = new OptionField();
      option
        .setValue(company.name)
        .setLabel(company.name);
      if (defCompanies.indexOf(company.id) > -1) {
        option.setDefault(true);
      }
      options.push(option);
    });

    const companiesSelect = new ScarySelect2();
    companiesSelect
      .setFieldName('settings')
      .setRequired(true)
      .setLabel('Aziende')
      .setPlaceholder('Seleziona una o più aziende')
      .setMultiple(true)
      .addOptions(options);

    return companiesSelect;
  }

  getSelectCarepacks(defCarepacks) {

    const options = [];
    this.carepacks.map((carepack) => {
      const option = new OptionField();
      option
        .setValue(carepack.name)
        .setLabel(carepack.name);
      if (defCarepacks.indexOf(carepack.id) > -1) {
        option.setDefault(true);
      }
      options.push(option);
    });

    const carepacksSelect = new ScarySelect2();
    carepacksSelect
      .setFieldName('carepacks')
      .setRequired(true)
      .setLabel('Carepack abbinati')
      .setPlaceholder('Seleziona una o più carepack')
      .setMultiple(true)
      .addOptions(options);

    return carepacksSelect;
  }
}