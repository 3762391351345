import OfferApi from "../api/OfferApi";

const $ = window.jQuery;

import FileApi from "../../../core/js/api/FileApi";
import OfferDocumentsApi from "../api/OfferDocumentsApi";
import OfferStatusLogsApi from "../api/OfferStatusLogsApi";

$(function () {

    $(document).on('offer-documents/get', async function (e, params) {

        const { offerId } = params;

        if (!offerId) {
            return;
        }

        const offerDocumentsApi = new OfferDocumentsApi();

        const { data: agentDocuments } = await offerDocumentsApi
            .setOfferId(offerId)
            .setShowAjaxLoader(false)
            .collection({ scope: 'OFFER_AGENT_DOCS' });

        const { data: adminDocuments } = await offerDocumentsApi
            .setOfferId(offerId)
            .setShowAjaxLoader(false)
            .collection({ scope: 'OFFER_ADMIN_DOCS' });

        const offerApi = new OfferApi();
        const {data: offer} = await offerApi.setShowAjaxLoader(false).get(offerId);

        const offerStatusLogApi = new OfferStatusLogsApi();
        const {data: statusLogs} = await offerStatusLogApi.setShowAjaxLoader(false).setOfferId(offerId).get('');

        $(document).trigger('offer-documents/get/success', { agentDocuments, adminDocuments, offerId, offer, statusLogs });

    });

    $(document).on('offer-documents/delete', async function (e, params) {
        const { documentId, offerId } = params;
        const offerDocumentsApi = new OfferDocumentsApi();
        await offerDocumentsApi
            .setOfferId(offerId) // non utilizzato nelle query
            .delete(documentId);
    });

    $(document).on('offer-documents/download', async function (e, params) {
        const { documentId, offerId } = params;
        const fileApi = new FileApi();
        await fileApi.get(documentId);
    });

});