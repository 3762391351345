import NumberField from './NumberField';

export default class PriceField extends NumberField {
	
	constructor(){
		super();
		
		this.wrapper_classes.push('pricefield');
		
		this.cleaveOptions.prefix = '€ ';
	}
	
}
