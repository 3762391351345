const $ = window.jQuery;

import Component from "../../../../core/js/components/Component";

export default class OfferStatusLogsTable extends Component {

    constructor(logs = []) {
        super();
        this.logs = logs;
    }

    renderLogRow(log) {
        return `
            <tr class="logs-table__row">
                <td>${log.user}</td>
                <td>${log.label}</td>
                <td>${log.financial ? log.financial.name : '-'}</td>
                <td>${log.notes || '-'}</td>
                <td>${log.created_at}</td>
            </tr>
        `;
    }

    render() {
        const logsHtml = this.logs.map(log => this.renderLogRow(log)).join(' ');

        return !!this.logs.length ? `
            <table class="logs-table">
                <thead class="logs-table__headings">
                    <tr>
                        <th>Utente</th>
                        <th>Stato</th>
                        <th>Finanziaria</th>
                        <th>Note</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    ${logsHtml}
                </tbody>
            </table>
        ` : `<p>Nessun cambio di stato registrato</p>`;
    }
}
