import { default as BaseUserApi } from "../../../core/js/api/UserApi";

export default class UserApi extends BaseUserApi {

	crudUrl() {
		return super.crudUrl();
	}

	deleteError(data, textStatus, errorThrown) {
	}

}
