const $ = window.jQuery;

import _ from 'lodash';
import Page from "./Page";
import GridList from "../../../core/js/components/View/GridList";
import CatalogApi from "../api/CatalogApi";
import FinancialTypeApi from "../api/FinancialTypeApi";
import Storage from '../../../core/js/utils/Storage';
import Footer from "../../../core/js/components/Form/Footer";

export default class CatalogPage extends Page {

  constructor() {
    super();
  }

  async init() {

    this
      .setTitle('Catalogo')
      .setMeta('description', 'Catalog dei Prodotti')
      .setBreadcrumb('Catalogo');

    return super.init();
  }

  getResolution() {
    /*const realWidth = window.screen.width * window.devicePixelRatio;
    const realHeight = window.screen.height * window.devicePixelRatio;*/
    const realWidth = screen.width;
    const realHeight = screen.height;
    alert(`Your screen resolution is: ${realWidth} x ${realHeight}`);
  }

  async content(id_catalog) {

    const stApi = new Storage();
	  
	  $('#scary').addClass('loading'); // handle loading
	  
    const financialTypeApi = new FinancialTypeApi();
    const financialTypeData1 = await financialTypeApi.setShowAjaxLoader(false).setType('best').setPrice('1001').setDuration(60).get();
    const financialTypeData2 = await financialTypeApi.setShowAjaxLoader(false).setType('best').setPrice('2501').setDuration(60).get();
    const financialTypeData3 = await financialTypeApi.setShowAjaxLoader(false).setType('best').setPrice('10001').setDuration(60).get();
    const financialTypeData4 = await financialTypeApi.setShowAjaxLoader(false).setType('best').setPrice('25001').setDuration(60).get();
    const financialTypeData5 = await financialTypeApi.setShowAjaxLoader(false).setType('best').setPrice('50001').setDuration(60).get();

    let letLblAll = "Tutti";
    let orderArray = ["sort","name","base_price", "available"];
    let functionalityArray = [letLblAll, "MFP", "PRT"];
    let typologyArray = [letLblAll, "Colore", "Bianco e Nero"];
    let formatArray = [letLblAll,"A3","A4"];

    let company = stApi.getData('current-company-' + PROFILE.id);
    if(!company){
      company = null;
    }

    const gridlist = new GridList();
    gridlist
      .setApi(new CatalogApi())
	  .setDisplayAjaxLoader(false)
      .setDynamicParam(id_catalog)
      .setOrdering(orderArray, "sort", "asc")
      .setFunctionality(functionalityArray, letLblAll)
      .setTypology(typologyArray, letLblAll)
      .setFormat(formatArray, letLblAll)
      .setCompany(company)
      .addClasses("grid")
      .setCellCallback(function (record) {

        let pathImgUrl = record.pathimg;
        let lblPathImgUrl = "";

        if(pathImgUrl == null){
          lblPathImgUrl = "/assets/images/no-image.png";
        }
        else{
          lblPathImgUrl = record.pathimg;
        }

        const stApi = new Storage();

        const prezzoBase = parseFloat(record.base_price_format);
		
		// switch financial based on product price
		let coefficient, multiplier;
		if(prezzoBase > 50000){
			coefficient = financialTypeData5.data.coefficient;
			multiplier = financialTypeData5.data.multiplier;
		} else if(prezzoBase >= 25000){
			coefficient = financialTypeData4.data.coefficient;
			multiplier = financialTypeData4.data.multiplier;
		} else if(prezzoBase >= 10000){
			coefficient = financialTypeData3.data.coefficient;
			multiplier = financialTypeData3.data.multiplier;
		} else if(prezzoBase >= 2500){
			coefficient = financialTypeData2.data.coefficient;
			multiplier = financialTypeData2.data.multiplier;
		} else {
			coefficient = financialTypeData1.data.coefficient;
			multiplier = financialTypeData1.data.multiplier;
		}

        const prezzoCatalogo = _.round(prezzoBase * parseFloat(coefficient) / parseFloat(multiplier), 2).toString().replace('.', ',');
        
        let prezziCatalogo;
        if(stApi.getData('privacy-price-agent') === "off"){
          prezziCatalogo = `<div class='prezzo'><span>a partire da</span>&nbsp;<font class="prezzoCatalogo">&euro; ${prezzoCatalogo}</font> <span>+ IVA 22%</span></div>`;
        }
        else if(stApi.getData('privacy-price-agent') === "on"){
          prezziCatalogo = `<div class='prezzo'>&nbsp;</span></div>`;
        }

        let lblAvailable = "";
        if(record.available == '1') {
          lblAvailable = "&nbsp;&nbsp;<img style='margin-top:8px; margin-left: 8px' src='/assets/images/si.png' width='15px' height='15px'>";
          lblAvailable += "&nbsp;&nbsp;<span style='color:green; margin-top:8px'>Disponibile</span>";
        }
        if(record.available == '0') {
          lblAvailable = "&nbsp;&nbsp;<img style='margin-top:8px; margin-left: 8px' src='/assets/images/no.png' width='15px' height='15px'>";
          lblAvailable += "&nbsp;&nbsp;<span style='color:red; margin-top:8px'>Non Disponibile</span>";
        }

        return `
          <div class="marchio">${record.brand}</div>
          <div class="titolo"><a title="Vai a Scheda Prodotto ${record.name}" href="/product/view/${record.id}">${record.name}</a>${lblAvailable}</div>
          <a title="Vai a Scheda Prodotto ${record.name}" class="img pointer" href="/product/view/${record.id}">
            <img alt="Vai a Scheda Prodotto ${record.name}" src="${lblPathImgUrl}" />
          </a>
          <div class="info">${prezziCatalogo}</div>
        `;
      })
      .run()
      .then(function (gl) {

        $('main .main').html(gl.render() + Footer.render(true));
        Footer.activate(new CatalogPage());
		
		$('#scary').removeClass('loading'); // handle loading

        $('.info .offerta img').off('click').on('click', function(){
          //console.log("product_id: " + record.id);
          $(document).trigger('offer-addtocart', [$(this).attr('data-product')]);
        });

      });
  }
}