const $ = window.jQuery;

import Wizard from '../Wizard/Wizard';
import Form from '../../../../core/js/components/Form/Form';
import SubmitField from '../../../../core/js/components/Form/SubmitField';
import ButtonField from '../Form/ButtonField';
import Storage from "../../../../core/js/utils/Storage";

export default class MultistepForm extends Wizard {
	
	constructor(){
		super();
		
		this.addClasses('multistepform');
		this.menuNavigation = {forward: false, backward: true};
	}
	
	preparePrevNextButtons(screen){
		
		const idx = this.screens.indexOf(screen);
		const stepForm = screen.content;
		
		const formActionsFieldset = new Form();
		formActionsFieldset
			.addClasses('form-actions');
		
		// add back button if not on 1st screen (unless already explicitly specified)
		if(idx > 0 && typeof screen.prevButton === 'undefined'){
			const prev = new ButtonField();
			prev
				.addClassHref('prev')
				.setValue('Indietro');
			formActionsFieldset.addFields(prev);
			screen.prevButton = `#${prev.name}`;
		}
		
		// add next/submit button (unless already explicitly specified)
		if(typeof screen.nextButton === 'undefined'){
			const isSubmit = (idx === this.screens.length - 1); // last screen is "submit"
			const next = new SubmitField();
			next
				.addClasses(isSubmit ? 'submit' : 'next')
				.setValue(isSubmit ? 'Salva' : 'Avanti');
			formActionsFieldset.addFields(next);	
			screen.nextButton = `#${next.name}`;
		}
		
		if(formActionsFieldset.fields.length){
			stepForm.addFields(formActionsFieldset);
		}
		
		// on nextButton click submit the form
		screen.nextHandler = function(e){
			e.preventDefault();
			const $form = $(`#${stepForm.name}`);
			if($form[0].reportValidity()){
				$form.submit();
			}
		};

	}
	
	addScreen(screen){
		if(typeof screen.content !== 'object' || (typeof screen.content === 'object' && screen.content.constructor?.name !== 'Form')){
			throw 'Invalid content: must be a Form component';
		}
		return super.addScreen(screen);
	}
	
	refreshScreenContent(id){
		this.screens[id].prevButton = undefined;
		this.screens[id].nextButton = undefined;
		this.preparePrevNextButtons(this.screens[id]);
		
		return super.refreshScreenContent(id);
	}
	
	render(){
		this.screens.forEach(function(screen){
			if(screen.content){
				this.preparePrevNextButtons(screen);
			}
		}, this);
		
		return super.render();
	}
	
}
