const $ = window.jQuery;

import { default as random_string, gen } from "../utils/random_string";

/**
 * Abstract Class Component
 *
 * Extend it to create components.
 * You must implement your own render() function which must return html of your component
 */
export default class Component {

  constructor() {
    this.token_length = 8; // Fixed value

    this.name = '';
    this.classes = [];

    if (this.constructor == Component) {
      throw new Error("You cannot instantiate Component abstract class");
    }
  }

  addClasses(classes) {
    if (typeof classes === 'string') {
      this.classes.push(classes);
    } else if (Array.isArray(classes)) {
      this.classes = this.classes.concat(classes);
    }

    return this;
  }

  removeClasses(classes) {
    if (typeof classes === 'string') {
      this.classes = $.grep(this.classes, function(css_classes) {
        return css_classes != classes;
      });
    } else if (Array.isArray(classes)) {
      this.classes = this.classes.filter(css_class => !classes.includes(css_class));
    }

    return this;
  }

  getUniqId(noprefix) {
    return (!noprefix ? (this.name.length > 0 ? this.name : (this.constructor.name).toLowerCase()) + '-' : '') + random_string(this.token_length, [
        ...gen(48,57),
        ...gen(97,122),
        ...gen(65,90)
    ]);
  }

  clone() {
    let new_component = Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    new_component.name = new_component.name.substring(0, new_component.name.length - this.token_length) + this.getUniqId(true);

    return new_component;
  }

  render() {
      throw new Error("Please implement render() function on your " + typeof this + " class: must return the html of your component");
  }

  activate() {
    // Implement this function as a post render helper
  }

  deactivate() {
    // Implement this function as a post render helper
  }

  refresh() {
    // Implement this function if the component needs to be refreshed: probably will use jquery to update component
  }

  install() {
    // should be used when the rendering of the object addresses a ScaryJS (or your own app) dom element, as a structural action
  }
  
  validate() {
    // implement this function if the component needs to be validated with a custom logic. If it's a FormField, probably using setCustomValidity.
  }
}
