const $ = window.jQuery;

import Component from "../Component";

export default class OptionField extends Component {

  constructor() {
    super();

    this.name = this.getUniqId();
    this.defaultopt = false;
    this.label = '';
    this.value = '';
    this.dataType = undefined;
    this.disabled = false;
  }

  setLabel(label) {
    this.label = label;

    return this;
  }

  setValue(value) {
    this.value = value;
    this.dataType = typeof value;

    return this;
  }

  setDefault(defaultopt) {
    this.defaultopt = defaultopt;

    return this;
  }

  setDisabled(disabled) {
    this.disabled = disabled;
    return this;
  }

  render() {
    const def = this.defaultopt ? ' selected' : '';
    const disabled = this.disabled ? `disabled="${this.disabled}"` : '';
    return `
      <option value="${this.value}"${def} ${disabled}>${this.label}</option>
    `;
  }

}