import SubmitField from "../../../core/js/components/Form/SubmitField";

const $ = window.jQuery;

import Page from "./Page";
import Form from "../../../core/js/components/Form/Form";
import TextField from "../../../core/js/components/Form/TextField";
import EmailField from "../../../core/js/components/Form/EmailField";
import HiddenField from "../../../core/js/components/Form/HiddenField";
import _ from "lodash";
import CustomerApi from "../api/CustomerApi";
import UserApi from "../api/UserApi";
import SelectField from "../../../core/js/components/Form/SelectField";
import OptionField from "../../../core/js/components/Form/OptionField";
import ButtonField from "../components/Form/ButtonField";
import ScarySelect2 from "../components/Plugins/ScarySelect2";
import Footer from "../../../core/js/components/Form/Footer";
import CustomerLocationApi from "../api/CustomerLocationApi";
import ImageField from "../../../core/js/components/Form/ImageField";
import DateField from '../components/Form/DateField';

export default class CustomerFormPage extends Page {

  constructor() {
    super();

    this.users = undefined;

    
  }

  async init(customer_id) {
    
    //inizio - utenti
    const usersApi = new UserApi();

    this.users = await usersApi.collection().catch(function (e) {
      const errmsg = 'Errore nello scaricare gli utenti';
      $(document).trigger('message', [ 'error', errmsg ]);

      throw errmsg;
    });

    this.users = this.users.data;
    //fine - utenti

    this
      .setTitle('Gestione cliente')
      .setMeta('description', 'Gestione cliente')
      .setBreadcrumb([
        { label: 'Clienti', href: '/customers' },
        'Gestione cliente'
      ]);

    return super.init(customer_id);
  }

  async content(customer_id) {
    const customerForm = new Form();
    customerForm
      .setAction('/customer')
      .setTitle('Gestisci clienti')
      .addClasses('padded-container label-left');
    let data;
    
    if (customer_id === 'create') {
      // Create form
      customerForm.setMethod('POST');
    } else {
      // Update form
      customerForm.setMethod('PUT');

      const customerApi = new CustomerApi();
      const customerData = await customerApi.get(customer_id);
      if (customerData) {
        data = customerData.data;
      } else {
        return;
      }
    }

    const in_edit = typeof data !== 'undefined';


    let dataCL;
    const customerLocationApi = new CustomerLocationApi();
    const customerLocationData = await customerLocationApi.setCustomerId(customer_id).getWithoutId();
    if (customerLocationData) {
      dataCL = customerLocationData.data;
    } else {
      return;
    }
    //console.log("customer_id: " + customer_id);
    //console.log("dataCL: " + dataCL.length);

    let totaleSedi = dataCL.length;

    let titleWarnImg = "";
    let warningImg = "";

    //se il cliente non ha nessuna sede, facciamo vedere warning con scritta
    if(totaleSedi == 0){
      titleWarnImg = "AGGIUNGI ALMENO UNA SEDE";
      warningImg = '&nbsp;&nbsp;<span><img title="'+titleWarnImg+'" alt="'+titleWarnImg+'" style="display:unset" src="/assets/images/warning.svg" width="30px" height="30px"></span>';
      warningImg += '&nbsp;<span style="font-size:15px; color: red;"><b>'+titleWarnImg+'</b></span>';
    }

    this.setPageTitle(in_edit, customerForm, 'Nuovo Cliente', 'Modifica Cliente' + warningImg);

    let id_user;

    if(PROFILE.roles.indexOf('RD_ADMIN_CUSTOMERS') > -1){

      //console.log("id_user: " + data.id_user.id);

      //id_user = this.getSelectUsers(customer_id !== 'create' ? data.id_user : undefined);
      id_user = this.getSelectUsers(customer_id !== 'create' ? data.id_user : '');
    }
    else{
      id_user = new HiddenField();
      id_user
      .setFieldName('id_user')
      .setValue(PROFILE.id);
    }

    const code_customer = new TextField();
    code_customer
      .setFieldName('code')
      .setRequired(false)
      .setPlaceholder('')
      .setLabel('Codice Cliente');

    const companyName = new TextField();
      companyName
      .setFieldName('company_name')
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Ragione Sociale');

    const activity = new ScarySelect2();
    activity
        .setFieldName('activity')
        .setRequired(true)
        .setPlaceholder("Seleziona un valore")
        .setLabel('Tipo Attività');

    let activityVal = ['','company','pro','pa','firm','association'];
    let activityLbl = ['Seleziona','Azienda','Professionista','PA','Studio Associato','Associazione'];

    this.addOptionsToSelectField(activity,activityVal.length,activityVal,activityLbl);

    const address = new TextField();
        address
        .setFieldName('address')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Indirizzo e Civico');

    const city = new TextField();
        city
        .setFieldName('city')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Città');

    const province = new TextField();
        province
        .setFieldName('province')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Provincia');
    
    /*const country = new TextField();
        country
        .setFieldName('country')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Stato');*/

    const country = new ScarySelect2();
    country
      .setFieldName('country')
      .setLabel('Stato')
      .setMinimumResultsForSearch(3)
      .setRequired(true)
      .setMultiple(false);
    const optionsCountry = [];

    const optionsCountry1 = new OptionField();
    optionsCountry1
      .setValue('IT')
      .setLabel("IT")
      .setDefault(data?.available === "IT");
    const optionsCountry2 = new OptionField();
    optionsCountry2
      .setValue('RSM')
      .setLabel("RSM")
      .setDefault(data?.available === "RSM");
    optionsCountry.push(optionsCountry1);
    optionsCountry.push(optionsCountry2);
    country.addOptions(optionsCountry);

    const ref_offer = new TextField();
        ref_offer
        .setFieldName('ref_offer')
        .setRequired(false)
        .setPlaceholder('')
        .setLabel('Referente Offerta');

    const ref_delivery = new TextField();
        ref_delivery
        .setFieldName('ref_delivery')
        .setRequired(false)
        .setPlaceholder('')
        .setLabel('Referente Consegna');

    /*const ref_offer_phone = new TextField();
        ref_offer_phone
        .setFieldName('ref_offer_phone')
        .setRequired(false)
        .setPlaceholder('')
        .setLabel('Referente Offerta Telefono');

    const ref_delivery_phone = new TextField();
        ref_delivery_phone
        .setFieldName('ref_delivery_phone')
        .setRequired(false)
        .setPlaceholder('')
        .setLabel('Referente Consegna Telefono');

    const ref_delivery_email = new TextField();
      ref_delivery_email
      .setFieldName('ref_delivery_email')
      .setRequired(false)
      .setPlaceholder('')
      .setLabel('Referente Consegna Email');*/

    const vat = new TextField();
        vat
        .setFieldName('vat')
        .setRequired(false)
        .setPlaceholder('')
        .setLabel('P.IVA');

    const fiscalcode = new TextField();
        fiscalcode
        .setFieldName('fiscalcode')
        .setRequired(false)
        .setPlaceholder('')
        .setLabel('C.F.');

    const sdi = new TextField();
        sdi
        .setFieldName('sdi')
        .setRequired(false)
        .setPlaceholder('')
        .setLabel('SDI');

    const pec = new EmailField();
        pec
        .setFieldName('pec')
        .setRequired(false)
        .setPlaceholder('')
        .setLabel('PEC');

    const tel = new TextField();
    tel
      .setFieldName('tel')
      .setRequired(false)
      .setPlaceholder('')
      .setLabel('Telefono');

    const cell = new TextField();
    cell
      .setFieldName('cell')
      .setRequired(false)
      .setPlaceholder('')
      .setLabel('Cellulare');

    const email = new TextField();
    email
      .setFieldName('email')
      .setRequired(false)
      .setPlaceholder('')
      .setLabel('Email');

    const iban = new TextField();
    iban
      .setFieldName('iban')
      .setRequired(false)
      .setPlaceholder('')
      .setLabel('IBAN');

	const constitution_date = new DateField();
	constitution_date
		.setFieldName('constitution_date')
		.setLabel('Data Costituzione');

    const submit = new SubmitField();
    submit
      .setFieldName('submit_customer')
      .setValue(in_edit ? 'Aggiorna' : 'Salva');

    const btn_locations = new ButtonField();
    btn_locations
      .setFieldName('button_location')
      .setValue('Gestisci Sedi')
      .onClick(`/customer/manage/${customer_id}/locations`);

    

	if (in_edit) {
		code_customer.setValue(data.code);
		this.getSelectUsers(data.id_user);
		companyName.setValue(data.company_name);
		activity.setValue(data.activity);
		vat.setValue(data.vat);
		fiscalcode.setValue(data.fiscalcode);
		sdi.setValue(data.sdi);
		pec.setValue(data.pec);
		tel.setValue(data.tel);
		cell.setValue(data.cell);
		email.setValue(data.email);
		iban.setValue(data.iban);
		if(data.constitution_date){
			constitution_date.setValue(data.constitution_date.split(' ')[0]);
		}
		
		btn_locations.addClasses(['btn_sedi','is_visibile']);

    if(data.erp === '1'){
      id_user.setReadonly();
      code_customer.setReadonly();
      companyName.setReadonly();
      activity.setReadonly();
      vat.setReadonly();
      fiscalcode.setReadonly();
      sdi.setReadonly();
      pec.setReadonly();
      tel.setReadonly();
      cell.setReadonly();
      email.setReadonly();
      iban.setReadonly();
      constitution_date.setReadonly();
      submit.setDisabled();
    }
    

	} else{
		btn_locations.addClasses(['btn_sedi','is_invisibile']);
	}

    /*const formFields = [
      id_user,
      code_customer,
      companyName,
      activity,
      address,
      city,
      province,
      country,
      ref_offer,
      ref_delivery,
      ref_offer_phone,
      ref_delivery_phone,
      vat,
      fiscalcode,
      sdi,
      pec,
      submit,
    ];*/

    const formFields = [
      submit.clone(),
      btn_locations.clone(),
      id_user,
      code_customer,
      companyName,
      activity,
      vat,
      fiscalcode,
      sdi,
      pec,
      tel,
      cell,
      email,
      iban,
      constitution_date,
      submit,
      btn_locations,
    ];

    customerForm.addFields(formFields);

    customerForm.onSubmit(async function (res) {
      customerForm.orig_values.roles = Array.isArray(customerForm.orig_values.roles) ? customerForm.orig_values.roles : [ customerForm.orig_values.roles ];

      const customerApi = new CustomerApi();
      customerApi.setForm(customerForm);

      let values = customerForm.getValues();

      let msg = '';
      let disclaimer = '';
      if (in_edit) {
        const res = await customerApi.put(customer_id, values);
        msg = 'Cliente modificato con successo';
      } else {
        const res = await customerApi.post(values);
        customer_id = res.data.id;
        msg = res.data.message;
        disclaimer = res.disclaimer;
        //msg = 'Cliente inserito con successo';
      }

      if (!customerForm.hasErrors()) {
          $(document).trigger('message', [ 'success', msg ]);

          //disclaimer in rosso per aggiungere una nuova sede se non ce
          if(disclaimer != ""){
            $(document).trigger('message', [ 'error', disclaimer]); 
          }
          
          $.spa.navigate('/customer/manage/' + customer_id, true);
          //window.scrollTo({top: 0});
          window.scrollTo(0, 0);
      }
    });

    //render
    //$('main .main').html(btn_locations.render() + customerForm.render() + Footer.render());
    $('main .main').html(customerForm.render() + Footer.render());
	  customerForm.activate();
    Footer.activate(this);
	
  }

  getSelectUsers(defuser) {
    const usersSelect = new ScarySelect2();
    //const usersSelect = new SelectField();
    usersSelect
      .setFieldName('id_user')
      .setRequired(true)
      .setPlaceholder("Seleziona un valore")
      .setLabel('Agente');
    
    if(defuser === ''){
      const emptyopt = new OptionField();
      emptyopt
      .setValue('')
      .setLabel('Seleziona');
      usersSelect.addOptions(emptyopt);
    }
    
    const options = [];

    this.users.map(function (user) {
      const option = new OptionField();
      option
        .setValue(user.id)
        .setLabel(user.full_name + " ("+ user.email +")");
        //.setLabel(user.first_name + " " + user.last_name + " ("+ user.email +")");

      
      //console.log("user.id: '" + user.id + "' - defuser: '" + defuser + "'");
    
      if (user.id === defuser) {
        //console.log("set default - user id: " + user.id + " - defuser: " + defuser);
        option.setDefault(true);
      }

      options.push(option);
    });

    usersSelect.addOptions(options);

    return usersSelect;
  }
}