const $ = window.jQuery;
import '../../../core/lib/js/scary-router/src/jquery.spa';

import { default as BasePage } from '../../../core/js/pages/Page';
import { vars } from '../../../core/js/App';
import CustomerApi from '../api/CustomerApi';
import ProductApi from '../api/ProductApi';
import OptionField from '../../../core/js/components/Form/OptionField';
import Storage from '../../../core/js/utils/Storage';
import AccessoryProductApi from '../api/AccessoryProductApi';
import SettingsApi from "../api/SettingsApi";
import SelectField from '../../../core/js/components/Form/SelectField';
import HiddenField from "../../../core/js/components/Form/HiddenField";

export default class Page extends BasePage {
	
	init() {
		const _page = this;
		const stApi = new Storage();
	  
		$('header .middle .header-breadcrumbs').html(this.getBreadcrumb());
		$('header .middle .header-breadcrumbs ul li:not(:last)').append('<span class="separator">&nbsp;</span>');
		$('#messages .errors_4xx').html('');
	
		// set "page-*" class on <body>
		if(typeof vars.url !== 'undefined'){
			$('body').addClass('page' + vars.url.replaceAll('/', '-'));
		}

		// menu toggle
		$('body').toggleClass('menu-closed', stApi.getData('menu-status') === 'closed');
		$('.menu-toggler').off('click').on('click', function(){
			if($('body').hasClass('menu-closed')){
				$('body').removeClass('menu-closed');
				stApi.setData('menu-status', 'open');
			} else {
				$('body').addClass('menu-closed');
				stApi.setData('menu-status', 'closed');
			}
		});

        $('body').toggleClass('with-status-selector', stApi.getData('OFFER_STATUS_SELECTOR_OPENED'));

    //inizio - privacy visibility price for agent
    //console.log("privacy pages inizio : " + stApi.getData('privacy-price-agent'));

    if(stApi.getData('privacy-price-agent') === undefined || stApi.getData('privacy-price-agent') === null || stApi.getData('privacy-price-agent') === ""){
      stApi.setData('privacy-price-agent', 'off');
    }

    //console.log("privacy pages settata a : " + stApi.getData('privacy-price-agent'));
    //fine - privacy visibility price for agent
		
		$('.user-menu-toggler').off('click').on('click', function(){ $('body').toggleClass('user-menu-open'); });
		
		$('.page-refresh').off('click').on('click', this.refresh);
		
		// offer button
		$('.top-menu .btn-offer').off('click').on('click', function(){
			const offerId = $(this).attr('data-offer');
			$.spa.navigate(`/offer/manage/${typeof offerId === 'undefined' ? 'create' : offerId}`);
		});
		$(document).trigger('offer-refreshcartbtn'); // refresh cart btn
		
		// close offer button
		$('.top-menu .btn-offer-close').off('click').on('click', function(e){
			e.stopPropagation();
			stApi.delData('current-offer-' + PROFILE.id);
      $('.header-select-company select').prop('disabled', false);
			if($('body').is('[class*="page-offer-manage-"]')){ // if in Offer manage page
				$.spa.navigate('/offers'); // move to Offers page
			} else {
				_page.refresh(); // otherwise refresh page
			}
		});

    // add select company
    this.addSelectCompaniesBlock(_page, stApi);

		// Init menu - set active trail
		$(window).trigger('init-menu', [ $.spa.currentRoute() ]);

        // Offer status
        //new OfferStatusSelector().init(); //commentata perchè se attiva non va piu il click sulle voci di menu
	}
  
	exit() {
		// unset "page-*" class on <body>
		if(typeof vars.url !== 'undefined'){
			$('body').removeClass('page' + vars.url.replaceAll('/', '-'));
		}
        const stApi = new Storage();
        $('body').removeClass('with-status-selector');
        stApi.setData('OFFER_STATUS_SELECTOR_OPENED', false);
	}

  setPageTitle(in_edit, form, titleNew, titleEdit){
    if(in_edit){
      form.setTitle(titleEdit);
    }
    else{
      form.setTitle(titleNew);
    }
  }

  async getRagSocCustomer(customer_id){
    let dataC;
    let ragsoc;

    const customerApi = new CustomerApi();
    const customerData = await customerApi.get(customer_id);
    if (customerData) {
      dataC = customerData.data;
      ragsoc = dataC.company_name;
    } else {
      return;
    }

    return ragsoc;
  }

  async getProductName(product_id){
    let dataP;
    let nameP;

    const productApi = new ProductApi();
    const productData = await productApi.get(product_id);
    if (productData) {

      //console.log("name: " + productData.data[0].name);

      dataP = productData.data[0];
      nameP = dataP.name;
    } else {
      return;
    }

    return nameP;
  }

  addOptionsToSelectField(select, num_options, values, labels){

    for(var o=0; o<num_options; o++){

      let opt = new OptionField();
      opt
        .setValue(values[o])
        .setLabel(labels[o]);

      select.addOptions(opt);

    }

  }

  async getCountAccProd(id_product1, id_product2, azione){
	
    let dataRT;
    let countRT;

    let values = {id_product1, id_product2, azione };
  
    const accessoryProductApi = new AccessoryProductApi();
    const accessoryProductData = await accessoryProductApi.post(values);

    if (accessoryProductData) {

      //console.log("recordsTotal: " + accessoryProductData.recordsTotal);

      dataRT = accessoryProductData;
      countRT = dataRT.recordsTotal;
    } else {
      return;
    }

    return countRT;
  }

  async getCountTotAcc(id_product1, id_product2, azione){
	
    let dataAll;
    let countAll;

    let values = {id_product1, id_product2, azione };
  
    const accessoryProductApi = new AccessoryProductApi();
    const accessoryProductData = await accessoryProductApi.post(values);

    if (accessoryProductData) {

      //console.log("totaleAll: " + accessoryProductData.recordsTotal);

      dataAll = accessoryProductData;
      countAll = dataAll.recordsTotal;
    } else {
      return;
    }

    return countAll;
  }

  async addSelectCompaniesBlock(_page, _stApi) {

    const currentOfferId = _stApi.getData('current-offer-' + PROFILE.id);

		let currentCompany = _stApi.getData('current-company-' + PROFILE.id);
    if(currentCompany === undefined) {
      currentCompany = PROFILE.settings[0];
      _stApi.setData('current-company-' + PROFILE.id, currentCompany);
    }

    if(PROFILE.settings.length > 1){

      const settingsApi = new SettingsApi();
      const companies = await settingsApi.collection().catch(function(e){
        const errmsg = 'Non è stato possibile recuperare le aziende';
        $(document).trigger('message', ['error', errmsg]);
        throw errmsg;
      });

      const options = [];
      companies.data.map((company) => {
        if(PROFILE.settings.includes(company.id)){
          const option = new OptionField();
          option
            .setValue(company.id)
            .setLabel(company.name);
            if(company.id === parseInt(currentCompany)){
              option.setDefault(true);
            }
          options.push(option);
        }
      });
  
      const userCompaniesSelect = new SelectField();
      userCompaniesSelect
        .setFieldName('current-company')
        .onChange(e => {
          currentCompany = parseInt(e);
          _stApi.setData('current-company-' + PROFILE.id, currentCompany);
          _page.refresh();
        })
        .addOptions(options);

      if(typeof currentOfferId !== 'undefined'){
        userCompaniesSelect.setDisabled();
      }
  
      $('header .middle .header-select-company').html(userCompaniesSelect.render());

    } else {

      const userCompany = new HiddenField();
      userCompany
        .setFieldName('current-company')
        .setValue(currentCompany);
  
      $('header .middle .header-select-company').html(userCompany.render());

    }

  }

}
