const $ = window.jQuery;

import CrudApi from '../../../core/js/api/CrudApi';

export default class DashboardApi extends CrudApi {
    constructor() {
        super();
		
		this.type = undefined;
        this.userId = undefined;
		this.parameters = new Map();
    }
	
	setType(value) {
		this.type = value;
		
		return this;
	}
	
    setUserId(value) {
        this.userId = value;
		
        return this;
    }
	
    setDateStart(value) {
        this.parameters.set('date_start', value);
		
        return this;
    }
	
    setDateEnd(value) {
        this.parameters.set('date_end', value);
		
        return this;
    }
	
    crudUrl() {
		let url = `/dashboard/${this.type}/${this.userId}`;
		if(this.parameters.size){
			let first = true;
			this.parameters.forEach((value, key) => {
				url += `${first ? '?' : '&'}${key}=${value}`;
				first = false;
			});
		}
        return url;
    }
	
}
