import SubmitField from "../../../core/js/components/Form/SubmitField";

const $ = window.jQuery;

import Page from "./Page";
import Form from "../../../core/js/components/Form/Form";
import TextField from "../../../core/js/components/Form/TextField";
import NumberField from "../components/Form/NumberField";
import PriceField from "../components/Form/PriceField";
import RolesApi from "../../../core/js/api/RolesApi";
import SelectField from "../../../core/js/components/Form/SelectField";
import OptionField from "../../../core/js/components/Form/OptionField";
import FileField from "../../../core/js/components/Form/FileField";
import AccessoryApi from "../api/AccessoryApi";
import ProductBrandsApi from "../api/ProductBrandsApi";
import HiddenField from "../../../core/js/components/Form/HiddenField";
import TextareaField from "../components/Form/TextareaField";
import ManyToManyForm from "../../../core/js/components/Form/ManyToManyForm";
import ScarySelect2 from "../components/Plugins/ScarySelect2";
import FileApi from "../../../core/js/api/FileApi";
import ImageProductApi from "../api/ImageProductApi";
import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
import Footer from "../../../core/js/components/Form/Footer";

export default class AccessoryFormPage extends Page {

  constructor() {
    super();

    this.roles = undefined;
    this.brands = undefined;

    this
      .setTitle('Gestione accessori')
      .setMeta('description', 'Gestione accessori')
      .setBreadcrumb([
        { label: 'Accessori', href: '/accessories' },
        'Gestione accessori'
      ]);
  }

  async init(product_id) {
    super.init();

    //inizio - ruoli
    const rolesApi = new RolesApi();

    this.roles = await rolesApi.roles().catch(function (e) {
      const errmsg = 'Errore nello scaricare i ruoli';
      $(document).trigger('message', [ 'error', errmsg ]);

      throw errmsg;
    });

    this.roles = this.roles.data;
    //fine - ruoli

    //inizio - marchi
    const productBrandsApi = new ProductBrandsApi();

    this.brands = await productBrandsApi.brands().catch(function (e) {
      const errmsg = 'Errore nello scaricare i marchi';
      $(document).trigger('message', [ 'error', errmsg ]);

      throw errmsg;
    });

    this.brands = this.brands.data;
    //fine - marchi
  }

  async content(accessory_id) {
    const accessoryForm = new Form();
    const subProductForm = new ManyToManyForm();

    accessoryForm
      .setAction('/accessory')
      .setTitle('Gestisci accessorio')
      .addClasses('padded-container label-left');
      //.addClasses('fullwidth label-left');
    let data;

    if (accessory_id === 'create') {
      // Create form
      accessoryForm.setMethod('POST');
    } else {
      // Update form
      accessoryForm.setMethod('PUT');

      const accessoryApi = new AccessoryApi();
      const accessoryData = await accessoryApi.get(accessory_id);
      if (accessoryData) {
        data = accessoryData.data;
      } else {
        $(document).trigger('404', [ accessoryApi.url + '/' + accessory_id ]);
        return;
      }
    }

    const in_edit = typeof data !== 'undefined';

    /*const image = new FileField();
    image
      .setFieldName('image')
      .setPlaceholder('Inserisci una immagine')
      .setLabel('Immagine')
      .setMaxSize('2MB')
      .addMimeTypes(['jpg','png','jpeg']);*/

    const image = new FileField();
    image
      .setFieldName('image')
      .setMaxSize('10MB')
      .addMimeTypes(['jpg', 'png'])
      .setLabel('Immagine del accessorio');
    const id_image = new HiddenField();
    id_image
      .setFieldName('id_image');

    const name = new TextField();
    name
      .setFieldName('name')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Nome');
    
    const artcode_erp = new TextField();
    artcode_erp
      .setFieldName('artcode_erp')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Codice ERP');

    /*const type = new TextField();
    type
      .setFieldName('type')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Tipologia');*/

    const description = new TextareaField();
    description
      .setFieldName('description')
      .setRequired(false)
      .setPlaceholder('')
      .setLabel('Descrizione Prodotto');

    const weight = new TextField();
    weight
      .setFieldName('weight')
      .setCleave("numeral",true)
      .setCleave("numeralDecimalScale","2")
      .setCleave("numeralDecimalMark",".")
      .setCleave("delimiter",",")
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Peso Kg');
    
    const position = new ScarySelect2();
    position
      .setFieldName('position')
      .setLabel('Posizione')
      .setRequired(false)
      .setMultiple(false);
    const optionsPos = [];

    const optionsPos0 = new OptionField();
    optionsPos0
      .setValue("")
      .setLabel("")
      .setDefault(data?.available === "");
    const optionsPos1 = new OptionField();
    optionsPos1
      .setValue("top")
      .setLabel("Alimentazione originali")
      .setDefault(data?.available === "top");
    const optionsPos2 = new OptionField();
    optionsPos2
      .setValue("inner")
      .setLabel("Comunicazione")
      .setDefault(data?.available === "inner");
    const optionsPos3 = new OptionField();
    optionsPos3
      .setValue("right")
      .setLabel("Finitura")
      .setDefault(data?.available === "right");
    const optionsPos4 = new OptionField();
    optionsPos4
      .setValue("bottom")
      .setLabel("Alimentazione carta")
      .setDefault(data?.available === "bottom");
    optionsPos.push(optionsPos0);
    optionsPos.push(optionsPos1);
    optionsPos.push(optionsPos2);
    optionsPos.push(optionsPos3);
    optionsPos.push(optionsPos4);
    position.addOptions(optionsPos);

    /*const format = new TextField();
    format
      .setFieldName('format')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Formato');*/
    
    const available = new SelectField();
    available
      .setFieldName('available')
      .setLabel('Disponibile')
      .setRequired(true)
      .setMultiple(false);
    const optionsAva = [];

    const optionAva1 = new OptionField();
    optionAva1
      .setValue("0")
      .setLabel("No")
      .setDefault(data?.available === "0");
    const optionAva2 = new OptionField();
    optionAva2
      .setValue("1")
      .setLabel("Si")
      .setDefault(data?.available === "1");
    optionsAva.push(optionAva1);
    optionsAva.push(optionAva2);
    available.addOptions(optionsAva);

    const base_price = new PriceField();
    base_price
      .setFieldName('base_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('Prezzo Vs');

    /*const transfer_price = new PriceField();
    transfer_price
      .setFieldName('transfer_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('Prezzo Vendita');

    const cc_bn_base_price = new PriceField();
    cc_bn_base_price
      .setFieldName('cc_bn_base_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Base BN');

    const cc_bn_transfer_price = new PriceField();
    cc_bn_transfer_price
      .setFieldName('cc_bn_transfer_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Vendita BN');

    const cc_color_base_price = new PriceField();
    cc_color_base_price
      .setFieldName('cc_color_base_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Base Colore');

    const cc_color_transfer_price = new PriceField();
    cc_color_transfer_price
      .setFieldName('cc_color_transfer_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Vendita Colore');

    const cc_light_base_price = new PriceField();
    cc_light_base_price
      .setFieldName('cc_light_base_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Base Leggero');

    const cc_light_transfer_price = new PriceField();
    cc_light_transfer_price
      .setFieldName('cc_light_transfer_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Vendita Leggero');

    const cc_max_bn = new NumberField();
    cc_max_bn
      .setFieldName('cc_max_bn')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Max Copie BN');

    const cc_max_bn_hidden = new HiddenField();
    cc_max_bn_hidden
      .setFieldName('cc_max_bn')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Max Copie BN');

    const cc_max_color = new NumberField();
    cc_max_color
      .setFieldName('cc_max_color')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Max Copie Colore');

    const cc_suggested_bn = new NumberField();
    cc_suggested_bn
      .setFieldName('cc_suggested_bn')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Copie BN Suggerite');

    const cc_suggested_color = new NumberField();
    cc_suggested_color
      .setFieldName('cc_suggested_color')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Copie Colore Suggerite');*/

    /*const sort = new NumberField();
    sort
      .setFieldName('sort')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Posizione in Categoria');*/

    let sort;

    if(in_edit){
      sort = new NumberField();
      sort
        .setFieldName('sort')
        .setMaxlength(50)
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Posizione in Categoria');
    }
    else{
      sort = new HiddenField();
      sort.setFieldName('sort').setValue(0);
    }
    
    const id_catalog = new HiddenField();
    id_catalog.setFieldName('id_catalog').setValue(DEFAULT_CATEGORY);

    const submit = new SubmitField();
    submit
      .setFieldName('submit_product')
      .setValue(in_edit ? 'Aggiorna' : 'Salva');

    //console.log(data);

    const cmbBrands = this.getSelectBrands(accessory_id !== 'create' ? data[0].id_brand : undefined);

    /*if (!data || data.relationship === 'accessory') {
      const cc_bn_base_price_hidden = new HiddenField();
      cc_bn_base_price_hidden.setFieldName('cc_bn_base_price').setValue(0);

      const cc_color_base_price_hidden = new HiddenField();
      cc_color_base_price_hidden.setFieldName('cc_color_base_price').setValue(0);

      const cc_bn_transfer_price_hidden = new HiddenField();
      cc_bn_base_price_hidden.setFieldName('cc_bn_base_price').setValue(0);

      const cc_color_transfer_price_hidden = new HiddenField();
      cc_color_transfer_price_hidden.setFieldName('cc_color_transfer_price').setValue(0);

      const cc_light_base_price_hidden = new HiddenField();
      cc_light_base_price_hidden.setFieldName('cc_light_base_price').setValue(0);

      const cc_light_transfer_price_hidden = new HiddenField();
      cc_light_transfer_price_hidden.setFieldName('cc_light_transfer_price').setValue(0);

      const cc_max_color_hidden = new HiddenField();
      cc_max_color_hidden.setFieldName('cc_max_color').setValue(0);

      const cc_suggested_bn_hidden = new HiddenField();
      cc_suggested_bn_hidden.setFieldName('cc_suggested_bn').setValue(0);

      const cc_suggested_color_hidden = new HiddenField();
      cc_suggested_color_hidden.setFieldName('cc_suggested_color').setValue(0);

    }*/

    if (in_edit) {
      name.setValue(data[0].name);
      //image.setValue(data[0].image);

      if(data[0].id_image !== null){
				id_image.setValue(data[0].id_image);
			}
			image.setValue(data[0].image);

      artcode_erp.setValue(data[0].artcode_erp);
      //type.setValue(data[0].type);
      description.setValue(data[0].description);
      weight.setValue(data[0].weight);
      position.setValue(data[0].position);
      available.setValue(data[0].available);
      base_price.setValue(data[0].base_price);
      //transfer_price.setValue(data[0].transfer_price);
      sort.setValue(data[0].sort);
    }

    const formFields = [
      id_catalog,
      submit.clone(),
      cmbBrands,
      name,
      image,
      id_image,
      artcode_erp,
      //type,
      description,
      position,
      weight,
      available,
      base_price,
      sort,
      submit
    ];

    /*if (!data || data.relationship === 'accessory') {
      formFields.push(subProductForm);
    }*/

    accessoryForm.addFields(formFields);

    accessoryForm.onSubmit(async function (res) {

      const accessoryApi = new AccessoryApi();
      accessoryApi.setForm(accessoryForm);
      accessoryApi.useFormData = true; //metterlo per passare formdata

      let values = accessoryForm.getValues();
      values.image = image.getFiles(); // replace standard "value" of FileField with FileList object

      let msg = '';
      if (in_edit) {
        const res = await accessoryApi.put(accessory_id, values);
        //msg = 'Accessorio modificato con successo';
        msg = res.data;



      } else {
        const res = await accessoryApi.post(values);
        accessory_id = res.accessory_id;
        msg = res.data;
      }

      if (!accessoryForm.hasErrors()) {
        $(document).trigger('message', [ 'success', msg ]);
        $.spa.navigate('/accessories', true);
      }
    });

    $('main .main').html(accessoryForm.render() + Footer.render());
    accessoryForm.activate();
    Footer.activate(this);

    // delete image accessory
		$(`.formfield.file-type .delete`).on('click', function(){
			let $targetFileContent = $(this).closest('.file-content');
			const confirmDeleteImageProd = new ScaryJqueryConfirm();
			confirmDeleteImageProd
				.setTitle('Attenzione')
				.setContent('Sei sicuro di voler cancellare immagine del accessorio ?')
				.modalConfirm(async(data) => {
					const fileApi = new FileApi();
					await fileApi.delete($targetFileContent.attr('data-id')) //cancellazione immagine dalla tabella file
						.then(() => {
              deleteImgProd(); //cancellazione della immagine dalla tabella product - accessory
						})
						.catch((e) => {
							$(document).trigger('message', ['error', e.responseJSON.error]);
						});
				})
				.render();
		});

    async function deleteImgProd(){
      const imageProductApi = new ImageProductApi();
      await imageProductApi.delete(accessory_id)
      .then(() => {
        $.spa.navigate('/accessory/manage/' + accessory_id, true);
      })
      .catch((e) => {
        $(document).trigger('message', ['error', e.responseJSON.error]);
      });
    }

  }

  getSelectBrands(defbrand) {
    const brandsSelect = new ScarySelect2();
    brandsSelect
      .setFieldName('id_brand')
      .setRequired(true)
      .setLabel('Marchi')
      .setMultiple(false);
    const emptyopt = new OptionField();
    emptyopt
      .setValue('')
      .setLabel('Seleziona');

    brandsSelect.addOptions(emptyopt);
    const options = [];

    this.brands.map(function (brand) {
      const option = new OptionField();
      option
        .setValue(brand.id)
        .setLabel(brand.name);
      if (brand.id === defbrand) {
        option.setDefault(true);
      }

      options.push(option);
    });

    brandsSelect.addOptions(options);

    return brandsSelect;
  }
}