const $ = window.jQuery;

import FormField from '../../../../core/js/components/Form/FormField';

export default class ContentField extends FormField {
	
	constructor() {
		super();
		
		this.setType('content');
		
		this.content = '';
	}
	
	setContent(content) {
		this.content = content;
		
		return this;
	}
	
	render() {
		return this.content;
	}
}
