import FormField from '../../../../core/js/components/Form/FormField';

export default class DateField extends FormField {
	
	constructor() {
		super();
	
		this.setType('date');
	}
	
	render() {
		const disabled = this.disabled ? ' disabled' : '';
		const readonly = this.readonly ? ' readonly' : '';
		const required = this.required ? ' required' : '';
		return this.wrap(`<input id="${this.name}" class="${this.classes.join(' ')}" type="${this.type}" name="${this.field_name}" value="${this.value}" placeholder="${this.placeholder}"${disabled}${readonly}${required}>`);
	}
}
