const $ = window.jQuery;

import Component from '../../../../core/js/components/Component';
import FileField from "../../../../core/js/components/Form/FileField";
import Form from "../../../../core/js/components/Form/Form";
import OfferDocumentsApi from "../../api/OfferDocumentsApi";
import Accordion from "../Accordion/Accordion";
import Opener from "../../utils/Opener";

export default class DocumentsTab extends Component {

    constructor() {
        super();
        this.documents = [];
        this.actions = [];
        this.offerId = null;
        this.tabType = null;
        this.permissions = {
            canDelete: false,
            canUpload: false,
            canDownload: false,
        };
        this.customerType = null;
        this.statusLogsKeys = [];
    }

    setOfferId(id) {
        this.offerId = id;
        return this;
    }

    setDocuments(documents) {
        this.documents = documents.filter(document => !document.hidden);
        return this;
    }

    setTabType(type) {
        this.tabType = type.toUpperCase();
        const userPermissions = window.PROFILE.roles;
        const canDelete = userPermissions.includes(`RD_OFFER_DOCUMENTS_${type}_DELETE`);
        const canUpload = userPermissions.includes(`RD_OFFER_DOCUMENTS_${type}_UPLOAD`);
        const canDownload = userPermissions.includes(`RD_OFFER_DOCUMENTS_${type}_DOWNLOAD`);
        this.permissions = { canDelete, canUpload, canDownload };
        return this;
    }

    setCustomerType(type) {
        this.customerType = type;
        return this;
    }

    setStatusLogs(logs) {
        this.statusLogsKeys = logs.map(log => log.id_key);
        return this;
    }

    createFileRow(file) {
        return `
            <div class="documents-tab__doc" data-fileid="${file.id}" data-url="${file.fileurl}">
              <span class="documents-tab__filename">${file.filename_original}</span>
              <div class="documents-tab__actions">
                ${this.permissions.canDelete ? '<img src="/assets/images/actions/delete.svg" alt="delete" data-action="delete">' : ''}
                ${this.permissions.canDownload ? '<img src="/assets/images/actions/download.svg" alt="download" data-action="download">' : ''}
              </div>
            </div>
        `;
    }

    renderFileRows(collectionType) {
        const fileRowsHtml = collectionType.files.map((file) => this.createFileRow(file)).join('');
        return `<div class="documents-tab__docs">${fileRowsHtml}</div>`;
    }

    renderAccordion(collectionType) {
        const { label, id, key } = collectionType;

        let accordionContent = this.renderFileRows(collectionType);

        if (["constitutive_act", "registration_card_copy"].includes(key) && this.customerType !== 'pro') {
            return '';
        }

        if (["signed_loan_agreement"].includes(key) && !this.statusLogsKeys.includes('AUTOFINANZIAMENTO_APPROVATO')) {
            return '';
        }

        const documentInput = new FileField()
            .setFieldName('document')
            .setMaxSize('10MB')
            .addClasses('documents-tab__form')
            .addMimeTypes(['jpg', 'png', 'pdf']);

        const documentsForm = new Form().addFields([documentInput]);

        documentsForm.onSubmit(async () => {

            const offerDocumentsApi = new OfferDocumentsApi();
            offerDocumentsApi
                .setShowAjaxLoader(false)
                .setOfferId(this.offerId)
                .setForm(documentsForm);
            offerDocumentsApi.useFormData = true;

            const collectionTypeId = $(`#${documentInput.name}`)
                .closest('[data-collection-type-id]')
                .attr('data-collection-type-id');

            const values = {
                document: documentInput.getFiles(),
                id_offer: this.offerId,
                id_type: collectionTypeId
            };

            const { data } = await offerDocumentsApi.post(values);
            $(document).trigger(`offer-documents/upload/success/${this.tabType}`, { file: data.file, collectionTypeId });

            this.updateDocumentsCount(collectionTypeId);
        });

        $(document).off(`change.${id}`).on(`change.${id}`, `#${documentInput.name}`, (e) => {
            $(`#${documentsForm.name}`).submit();
        });

        $(document)
            .off(`click.doc-actions-${this.tabType}-${id}`)
            .on(`click.doc-actions-${this.tabType}-${id}`, `[data-collection-type-id="${id}"] .documents-tab__doc img[data-action]`, async (e) => {
            const $fileRow = $(e.target).closest('.documents-tab__doc');
            const fileId = $fileRow.attr('data-fileid');
            const fileUrl = $fileRow.attr('data-url');
            const actionType = $(e.target).attr('data-action');

            switch (actionType) {
                case 'delete':
                    $(document).trigger('offer-documents/delete', { documentId: fileId, offerId: this.offerId });
                    $fileRow.remove();
                    this.updateDocumentsCount(id);
                    break;
                case 'download':
					Opener.open(fileUrl);
                    break;
                default:
                    break;
            }

        });

        $(document).off(`offer-documents/upload/success/${this.tabType}`).on(`offer-documents/upload/success/${this.tabType}`, (e, params) => {
            const { file, collectionTypeId: typeId } = params;
            const $accordion = $(`.accordion-container[data-collection-type-id="${typeId}"]`);
            const $documentsContainer = $accordion.find('.documents-tab__docs').first();
            const newFileRowHtml = this.createFileRow(file);
            $documentsContainer.append(newFileRowHtml);
            $(`#${documentsForm.name}`).get(0).reset();
        });

        if (this.permissions.canUpload) {
            accordionContent += documentsForm.render();
        }

        return new Accordion()
            .setFieldName(label.trim())
            .addClasses(`accordion-container--documents`)
            .setDataAttribute('collection-type-id', id)
            .addItem({ label, content: accordionContent })
            .setShowArrowIcon(true)
            .setShowDocumentsCount(true)
            .setDocumentsCount(collectionType.files.length)
            .render();
    }

    updateDocumentsCount(collectionTypeId) {
        const $collectionTypeEl = $(`.accordion-container--documents[data-collection-type-id=${collectionTypeId}]`);
        const $filesElements = $collectionTypeEl.find('.documents-tab__filename');
        const attachmentsCountEl = $collectionTypeEl.find('.attachments-count span')[0];
        $(attachmentsCountEl).text($filesElements.length || '-');
    }

    render() {

        const documentsAccordions = this.documents
            .map(collectionType => this.renderAccordion(collectionType))
            .join('');

        return `
            <div class="documents-tab__types">
              ${documentsAccordions}
            </div>
        `;
    }

}