import BaseCrudApi from "./BaseCrudApi";

export default class CrudApi extends BaseCrudApi {
  constructor() {
    super();
    if (this.constructor == CrudApi) {
      throw new Error("You cannot instantiate CrudApi abstract class");
    }
  }

  async get(id) {
    this.method = 'GET';
    this.url = this.crudUrl() + '/' + id;
    this.setErrorCallback(this.getError);

    return await this.call(this.getAction);
  }

  async getWithoutId() {
    this.method = 'GET';
    this.url = this.crudUrl();
    this.setErrorCallback(this.getError);

    return await this.call(this.getAction);
  }

  async getWithAction(action) {
    this.method = 'GET';
    this.url = this.crudUrl() + '/' + action;
    this.setErrorCallback(this.getError);

    return await this.call(this.getAction);
  }

  async getWithActionAndUser(action, idutente, ruolo) {
    this.method = 'GET';
    this.url = this.crudUrl() + '/' + action + '/' + idutente + '/' + ruolo;
    this.setErrorCallback(this.getError);

    return await this.call(this.getAction);
  }

  async getWithActionAndFilters(action, idutente, ruolo, datada, dataa, tranneme = "") {
    this.method = 'GET';
    this.url = this.crudUrl() + '/' + action + '/' + idutente + '/' + ruolo + '/' + datada + '/' + dataa + '/' + tranneme;
    this.setErrorCallback(this.getError);

    return await this.call(this.getAction);
  }

  /**
   * You can implement this method while extending this class.
   *
   * Must handle get data
   */
  getAction(data) {
    return data;
  }

  /**
   * You can implement this method while extending this class.
   *
   * Must handle get errors
   */
  getError(data, textStatus, errorThrown) {
    this.__errorCallback(data, textStatus, errorThrown);
  }

  async put(id, data) {
    this.method = 'POST';
    this.data = data;
    this.url = this.crudUrl() + '/' + id;
    this.setErrorCallback(this.putError);

    return await this.call(this.putAction);
  }

  /**
   * You can implement this method while extending this class.
   *
   * Must handle success edit action
   */
  putAction(data) {
    return data;
  }

  /**
   * You can implement this method while extending this class.
   *
   * Must handle edit errors
   */
  putError(data, textStatus, errorThrown) {
    return this.__errorCallback(data, textStatus, errorThrown);
  }

  async patch(id, data) {
    this.method = 'POST';
    this.data = data;
    this.url = this.crudUrl() + '/' + id;
    this.setErrorCallback(this.patchError);

    return await this.call(this.patchAction);
  }

  /**
   * You can implement this method while extending this class.
   *
   * Must handle success edit action
   */
  patchAction(data) {
    return data;
  }

  /**
   * You can implement this method while extending this class.
   *
   * Must handle edit errors
   */
  patchError(data, textStatus, errorThrown) {
    return this.__errorCallback(data, textStatus, errorThrown);
  }
}