import HiddenField from "./HiddenField";

const $ = window.jQuery;

import Form from "./Form";
import Storage from '../../utils/Storage';

export default class Footer extends Form {

  constructor() {
    super();

  }

  static activate(page) {

    const stApi = new Storage();

    $(`#chkPrivacy`).on('click', function(){

      if(stApi.getData('privacy-price-agent') === "on"){
        stApi.setData('privacy-price-agent', 'off');
      }
      else if(stApi.getData('privacy-price-agent') === "off"){
        stApi.setData('privacy-price-agent', 'on');
      }
      page.refresh();
    });
  }

  static render(privacy = false) {
    
    const stApi = new Storage();
	
    let footerPrivacy = "<div class='footerPrivacy'>";

    footerPrivacy += "<div class='creditsFooter'>";
    footerPrivacy += "<a id='credits' href='https://www.webscapesolutions.it' title='Webscape Solutions' target='_blank'>";
    footerPrivacy += "<span>Credits:</span><img src='/assets/images/webscape_solutions.png' alt='Webscape Solutions'>";
    footerPrivacy += "</a>";
    footerPrivacy += "</div>";

    if(privacy){
      footerPrivacy += "<div class='privacyFooter'>";
      footerPrivacy += "<label class='switch'>";

      if(stApi.getData('privacy-price-agent') === "off"){
        footerPrivacy += "<input type='checkbox' id='chkPrivacy'>";
      }
      else if(stApi.getData('privacy-price-agent') === "on"){
        footerPrivacy += "<input type='checkbox' id='chkPrivacy' checked>";
      }

      footerPrivacy += "<span class='slider round'></span></label>";
      footerPrivacy += "<lalel class='privacyLabel'>PRIVACY</label>";
      footerPrivacy += "</div>";
    }

    footerPrivacy += "</div>";

    return footerPrivacy;
  }
}