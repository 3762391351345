import CrudApi from '../../../core/js/api/CrudApi';

export default class OfferRowApi extends CrudApi {
	constructor(){
		super();
		
		this.offerId = undefined;
	}
	
	setOfferId(offer_id){
		this.offerId = offer_id;
		
		return this;
	}
  
	crudUrl(){
		return `/offer/${this.offerId}/row`;
	}
	
	postError(data, textStatus, errorThrown) {
	}
}
