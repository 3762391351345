const $ = window.jQuery;

import Menu from "../../../core/js/components/Menu/Menu";
import MenuItem from "../../../core/js/components/Menu/MenuItem";

export default async function menu() {
  const menu_container = new Menu();
  menu_container.addUlClasses('menu');

  const dashboard = new MenuItem();
  dashboard
    .addWrapperClasses(['element', 'dashboard'])
    .addClasses('left-menu')
    .setLink('/')
    .setTitle('Board');

  const users = new MenuItem();
  users
    .addWrapperClasses(['element', 'utenti'])
    .addClasses('left-menu')
    .setLink('/users')
    .setTitle('Utenti')
    .setRoles('RD_ADMIN_USERS');

  const customers = new MenuItem();
  customers
    .addWrapperClasses(['element', 'customers'])
    .addClasses('left-menu')
    .setLink('/customers')
    .setTitle('Clienti')
    .setRoles(['RD_ADMIN_CUSTOMERS', 'RD_OWN_CUSTOMERS']);

  const offers = new MenuItem();
  offers
    .addWrapperClasses(['element', 'offerte'])
    .addClasses('left-menu')
    .setLink('/offers')
    .setTitle('Offerte')
    .setRoles(['RD_ADMIN_OFFERS', 'RD_SUB_OFFERS', 'RD_OWN_OFFERS']);

  const orders = new MenuItem();
  orders
    .addWrapperClasses(['element', 'ordini'])
    .addClasses('left-menu')
    .setLink('/orders')
    .setTitle('Ordini')
    .setRoles(['RD_ADMIN_ORDERS', 'RD_OWN_ORDERS']);

  const products = new MenuItem();
  products
    .addWrapperClasses(['element', 'prodotti'])
    .addClasses('left-menu')
    .setTitle('Prodotti')
    .setRoles(['RD_ADMIN_PRODUCTS']);

  const products_brands = new MenuItem();
  products_brands
    .addWrapperClasses(['element', 'marchi'])
    .addClasses('left-menu')
    .setLink('/brands')
    .setTitle('Marchi')
    .setRoles(['RD_ADMIN_PRODUCTS']);

  const products_list = new MenuItem();
  products_list
    .addWrapperClasses(['element', 'prodotti'])
    .addClasses('left-menu')
    .setLink('/products')
    .setTitle('Prodotti')
    .setRoles(['RD_ADMIN_PRODUCTS']);

  const accessories_list = new MenuItem();
  accessories_list
    .addWrapperClasses(['element', 'accessori'])
    .addClasses('left-menu')
    .setLink('/accessories')
    .setTitle('Accessori')
    .setRoles(['RD_ADMIN_PRODUCTS']);

  const products_catalog = new MenuItem();
  products_catalog
    .addWrapperClasses(['element', 'catalogo'])
    .addClasses('left-menu')
    .setLink('/catalog/' + DEFAULT_CATEGORY)
    .setTitle('Catalogo')
    .setRoles(['RD_ADMIN_CATALOG', 'RD_OWN_CATALOG']);

  const financials_list = new MenuItem();
  financials_list
    .addWrapperClasses(['element', 'finanziarie'])
    .addClasses('left-menu')
    .setLink('/financials')
    .setTitle('Finanziarie')
    .setRoles(['RD_ADMIN_FINANCIAL']);

  const shipping_tablerates = new MenuItem();
  shipping_tablerates
    .addWrapperClasses(['element', 'shipping-tablerates'])
    .addClasses('left-menu')
    .setLink('/shipping-tablerates')
    .setTitle('Costi e Tariffe')
    .setRoles(['RD_ADMIN_SHIPPINGTABLERATES']);

  const carepacks = new MenuItem();
  carepacks
    .addWrapperClasses(['element', 'carepacks'])
    .addClasses('left-menu')
    .setLink('/carepacks')
    .setTitle('Carepack')
    .setRoles(['RD_ADMIN_CAREPACKS']);

  const settings_list = new MenuItem();
  settings_list
    .addWrapperClasses(['element', 'settings'])
    .addClasses('left-menu')
    .setLink('/settings')
    .setTitle('Settaggi aziende')
    .setRoles(['RD_ADMIN_SETTINGS']);

  /*const menu_products = new Menu();
  menu_products
  .addUlClasses('submenu')
  .setItem(products);
	
  menu_products.addItems([
    products_brands,
    products_list
  ]);*/

  menu_container.addItems([
    dashboard,
    users,
    customers,
    offers,
    orders,
    //menu_products,
    products_catalog,
    products_list,
    accessories_list,
    products_brands,
    financials_list,
    shipping_tablerates,
    carepacks,
    settings_list
  ]);

  $('nav').replaceWith(menu_container.render());

  return menu_container;
}
