import CrudApi from '../../../core/js/api/CrudApi';

export default class CarepackAPi extends CrudApi {
	crudUrl() {
		return '/carepack';
	}
	
	deleteError(data, textStatus, errorThrown) {
	}
}
