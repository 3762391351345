import CrudApi from '../../../core/js/api/CrudApi';

export default class ShippingTableRateApi extends CrudApi {
	crudUrl() {
		return '/shipping-tablerate';
	}
	
	deleteError(data, textStatus, errorThrown) {
	}
}
