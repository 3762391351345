const $ = window.jQuery;
import "../../../core/lib/js/scary-router/src/jquery.spa";

import Menu from "../../../core/js/components/Menu/Menu";
import Form from "../../../core/js/components/Form/Form";
import EmailField from "../../../core/js/components/Form/EmailField";
import PasswordField from "../../../core/js/components/Form/PasswordField";
import ImageField from "../../../core/js/components/Form/ImageField";
import AuthApi from "../../../core/js/api/AuthApi";

// Custom events
import "./message";
import "./offer";
import "./offer-status";
import "./offer-documents";
import menu from "../utils/menu";
import spa_route from "../../../core/js/utils/spa_route";
import Page from "../../../core/js/pages/Page";

$(function() {

  /** Handle ERRORS **/
  $(document).on('router-error', function (e, status) {
    $(document).trigger('message', [ 'error', 'Non riesco a connettermi al server per scaricare i dati iniziali' ]);
  });

  $(document).on('500', function (e, route) {
    $(document).trigger('message', [ 'error', 'Errore 500 del server: ' + route ]);
  });

  $(document).on('503', function (e, route) {
    $(document).trigger('message', [ 'warning', 'Server in manutenzione, aggiorna la pagina - ' + route ]);
  });

  $(document).on('404', function (e, route) {
    $(document).trigger('message', [ 'errors_4xx', '<div>Pagina non trovata<br /><small>' + route + '</small></div>' ]);
  });

  $(document).on('403', function (e, route) {
    $(document).trigger('message', [ 'error', '<div>Accesso vietato<br /><small>' + route + '</small></div>' ]);
  });
  /** End ERRORS **/

  /** EVENTS **/
  // $(document).on('init', function (e) {
  //   // handle app init, triggered once at first load
  // });
  // $(document).on('content-loaded', function (e, method, url, data) {
  //   // triggered every time a success ajax call is made
  // });
  $(document).on('login', function (e) {
    const loginForm = new Form();
    const emailField = new EmailField();
    emailField
      .setRequired(true)
      .addWrapperClasses('box')
      .setFieldName('email')
      .setPlaceholder('Email');

    const passwordField = new PasswordField();
    passwordField
      .setRequired(true)
      .addWrapperClasses('box')
      .setFieldName('password')
      .setPlaceholder('Password');

    const submitButton = new ImageField();
    submitButton
      .setFieldName('Login')
      .addWrapperClasses('box')
      .setValue('Login')
      .addClasses('button btn-submit')
      .setImage('/assets/images/right-arrow.png');

    loginForm.addFields([ emailField, passwordField, submitButton ]);

    $('body').addClass('screen-login');

    $('#scary').load('login.html', {}, function () {
      // Set page title
      Page.prototype.setTitle('Accedi');

      $('div.login').html(loginForm.render());

      loginForm.onSubmit(function (data) {
        const loginApi = new AuthApi();
        loginApi.setForm(loginForm);

        loginApi.login(data.email, data.password).then(function (data) {
          if (data.statusCode < 400) {
            $('body').removeClass('screen-login');
          }
        }).catch(function (e) {
          throw e;
        });
      });
    });
  });
  $(document).on('profile-loaded', async function (e, profile) {
    // Menu instantiation
    Menu.prototype.deactivate();
    const menu_container = await menu();
    menu_container.activate();

    // Route links using scary-router
    $(document)
      .off('click', 'a')
      .on('click', 'a', spa_route);
    $(document)
      .off('click', 'nav li.element')
      .on('click', 'nav li.element', () => { window.scrollTo({top: 0}); })
      .on('click', 'nav li.element', spa_route);

    $(document).one('click', '.btn-logout', function () {
      const logoutApi = new AuthApi();
      logoutApi.logout().then(function (data) {
        if (data.statusCode < 400) {
          $(document).trigger('login');
        } else {
          throw 'Error doing logout: ' + data.statusCode;
        }
      }).catch((e) => {
        throw e;
      });
    });
  });
  /** End EVENTS **/

});