import Api from "../../../core/js/api/Api";

export default class AccessoryPositionApi extends Api {
  constructor() {
    super(undefined, undefined, undefined);
  }

  async call(cb) {
    return await this.__call(cb);
  }

  async accessories(product_id, position) {
    this.method = 'GET';
    this.url = '/product/'+product_id+'/position/'+position;

    return await this.call((data) => {
      return data;
    });
  }
}
