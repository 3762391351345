import CrudApi from '../../../core/js/api/CrudApi';

export default class RegionApi extends CrudApi {
	
	constructor(){
		super();
		
		this.countryId = null;
	}
	
	setCountryId(country_id){
		this.countryId = country_id;
		
		return this;
	}
	
	crudUrl() {
		return '/region' + (typeof this.countryId === null ? '' : `?id_country=${this.countryId}`);
	}
}
