const $ = window.jQuery;

import _ from "lodash";
import Page from "./Page";
import ActionsMenu from "../components/ActionsMenu/ActionsMenu";
import ActionsMenuItem from "../components/ActionsMenu/ActionsMenuItem";
import ScaryDataTable from "../components/Plugins/ScaryDataTable";
import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
import ProductApi from "../api/ProductApi";
import FinancialApi from "../api/FinancialApi";
import Footer from "../../../core/js/components/Form/Footer";

export default class ProductsPage extends Page {

  constructor() {
    super();

    this.setTitle('Finanziarie')
      .setMeta('description', 'Finanziarie nel sistema')
      .setBreadcrumb('Finanziarie');
  }

  content() {
	
	//
	// actions menu
	//
	
    const actions_menu = new ActionsMenu();
	actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-financials']);
	actions_menu.addUlClasses('actions-menu');
	
	const btn_add = new ActionsMenuItem();
	btn_add
		.addClasses([ 'element', 'only-icon', 'add' ])
		.setTitle('Aggiungi')
		.setOn('click', () => $.spa.navigate('/financial/manage/create'))
		.setRoles('RD_ADMIN_USERS');
		
	const btn_edit = new ActionsMenuItem();
	btn_edit
		.addClasses([ 'element', 'only-icon', 'edit', 'disabled' ])
		.setTitle('Modifica')
		.setRoles('RD_ADMIN_USERS');
		
	const btn_delete = new ActionsMenuItem();
	btn_delete
		.addClasses([ 'element', 'only-icon', 'delete', 'disabled' ])
		.setTitle('Elimina')
		.setRoles('RD_ADMIN_USERS');
		
	actions_menu.addItems([
		btn_add,
		btn_edit,
		btn_delete
	]);

	//
	// products table
	//
	
	// $.confirm delete
	const confirmDelete = new ScaryJqueryConfirm();
	confirmDelete
		.setTitle('Attenzione')
		.setContent('Sei sicuro di voler cancellare la finanziaria?');

	// quick-open dt row on double-click
	$(document).on('dblclick', '.dt-financials tbody tr', function(){
		let targetId = parseInt($(this).closest('tr').find('td').first().text());
		$.spa.navigate(`/financial/manage/${targetId}`);
	});
		
	// handle dt_products select/deselect
	const dt_financials_onSelectDeselect = function(e, dt, type, indexes){
		const _page = this;
		
		// check for selected rows
		const targets = [];
		if (type === 'row') {
			dt.rows({selected: true}).every(function(idx){
				if(typeof dt.row(idx).data().id !== 'undefined'){
					targets.push(dt.row(idx));
				}
			});
		}
			
		$(`#${btn_edit.name}`)
			.off('click')
			.toggleClass('disabled', targets.length === 0);
			
		$(`#${btn_delete.name}`)
			.off('click')
			.toggleClass('disabled', targets.length === 0 || targets.some(row => row.data().is_system === '1')); // disable delete also if there are "system" financials selected
			
		if(targets.length > 0){
			$(`#${btn_edit.name}:not(.disabled)`).on('click', () => $.spa.navigate(`/financial/manage/${targets[0].data().id}`));
			$(`#${btn_delete.name}:not(.disabled)`).on('click', () => {
				confirmDelete
					.modalConfirm(async (data) => {
						const financialId = targets[0].data().id;
						const financialApi = new FinancialApi();
						await financialApi.delete(financialId)
							.then(() => {
								$.spa.navigate('/financials', true);
							})
							.catch((e) => {});
					})
					.render();
			});
		}
		
	};
	const debounced_dt_financials_onSelectDeselect = _.debounce(dt_financials_onSelectDeselect, 100);
	
	const dt_financials = new ScaryDataTable();
	dt_financials
		.setUrl('/financial')
		.setSelect('single')
		.setOnSelect(debounced_dt_financials_onSelectDeselect)
		.setOnDeselect(debounced_dt_financials_onSelectDeselect)
		.setInitComplete(function(){
			this.api().columns(1).every( async function () {

				//console.log("every financial");

				//inizio parte nuova

				// retrieve all financials
				const financialApi = new FinancialApi();
				const financials = await financialApi.collection().catch(function(e){
					const errmsg = 'Non è stato possibile recuperare le finanziarie';
					$(document).trigger('message', ['error', errmsg]);
					throw errmsg;
				});
				this.financials = financials.data;
				//console.log(_.uniq(this.financials.map(c => c.name)));
				
				//fine parte nuova

				var column = this;
				var select = $('<select><option value="">Seleziona una valore</option></select>')
					.appendTo( '#select_financial' )
					.on( 'change', function () {
						
						var val = $.fn.dataTable.util.escapeRegex(
							$(this).val()
						);

						/*column
							.search( val ? '^'+val+'$' : '', true, false )
							.draw();*/

						column
							.search( val , true, false )
							.draw();
					} );
				
				/*column.data().unique().sort().each( function ( d, j ) {
					select.append( '<option value="'+d+'">'+d+'</option>' );
				} );*/

				//inizio parte nuova

				_.uniq(this.financials.map(c => c.name)).sort().forEach( function ( d) {
					select.append( '<option value="'+d+'">'+d+'</option>' );
				} );

				//fine parte nuova
				
			} );
		})
		.addField('id', 'ID')
		.addField('name', 'Nome')
		.addField('zone', 'Fascia €')
		.addField('duration', 'Durata')
		.addField('coefficient_format', 'Coefficiente')
		.addField('multiplier', 'Moltiplicatore')
		.addField('coefficient_quality_format', 'Coeff. Qualità')
		.setResponsivePriority('name', 0)
		.addClasses('dt-financials');
	
	let labelFinancial = "Finanziaria:&nbsp;&nbsp;";
	let $htmlSelectFinancial = "<div class='selectFinancial' id='select_financial'>"+labelFinancial+"</div>";

	// render
	$('main .main').html($htmlSelectFinancial + actions_menu.render() +  dt_financials.render() + Footer.render());
    dt_financials.activate();
	Footer.activate(this);
	
  }

}
