import CrudApi from '../../../core/js/api/CrudApi';

export default class OfferStatusListApi extends CrudApi {

	constructor() {
		super();
	}

	crudUrl() {
		return '/offer-status';
	}
}
