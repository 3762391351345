import Swal from "sweetalert2";

const $ = window.jQuery;

import OfferStatusApi from "../api/OfferStatusApi";
import OfferStatusLogsApi from "../api/OfferStatusLogsApi";

$(function () {

    // Handle offer status change
    $(document).on('offer/status-change', async function (e, params) {
        e.preventDefault();
        e.stopPropagation();

        const { id: statusId, id_key, label } = params.status;

        const offerStatusApi = new OfferStatusApi()
            .setOfferId(params.id_offer)
            .setShowAjaxLoader(false);

        try {

            console.log("statusLogs", params.statusLog);
            const statusLogsIds = params.statusLog.map(s => s.id_offer_status);
            const noAutofinStatus = params.statusList.find(s => s.id_key === 'RICHIESTA_AUTOFINANZIAMENTO_NO');
            const autofinApprovStatus = params.statusList.find(s => s.id_key === 'AUTOFINANZIAMENTO_APPROVATO');
            if (id_key === 'INVIATA' && !statusLogsIds.includes(autofinApprovStatus.id)) {
                offerStatusApi.post({
                    id_user: window.PROFILE.id,
                    id_financial: params.id_financial,
                    id_offer: params.id_offer,
                    id_offer_status: noAutofinStatus.id,
                    notes: params.notes,
                });
            }

            setTimeout(() => {
                offerStatusApi.post({
                    id_user: window.PROFILE.id,
                    id_financial: params.id_financial,
                    id_offer: params.id_offer,
                    id_offer_status: statusId,
                    notes: params.notes,
                });
            }, 50);

            const currentStatusElement = $('.timeline__status--current');
            let selectedStatusElement = $(`.timeline__status[data-status="${id_key}"]`);

            const oneOfApprovedStates = ['APPROVATA', 'RIFIUTATA', 'KO'].includes(id_key);
            if (oneOfApprovedStates) {
                selectedStatusElement = $(`.timeline__status[data-status="APPROVATA"]`);
            }

            const oneOfWonStates = ['VINTA', 'PERSA'].includes(id_key);
            if (oneOfWonStates) {
                selectedStatusElement = $(`.timeline__status[data-status="VINTA"]`);
            }

            currentStatusElement.removeClass('timeline__status--current');
            currentStatusElement.removeClass('timeline__status--next');
            currentStatusElement.addClass('timeline__status--previous');

            selectedStatusElement.removeClass('timeline__status--previous');
            selectedStatusElement.removeClass('timeline__status--next');
            selectedStatusElement.addClass('timeline__status--current');

            $('.offer-status-label').text(label);

            Swal.close();

            $.spa.refresh();

        } catch (err) {
            console.log("error", err);
        }

    });

    $(document).on('offer-status-logs/get', async (e, params) => {
        const { offerId } = params;
        const offerStatusLogsApi = new OfferStatusLogsApi()
            .setShowAjaxLoader(false)
            .setOfferId(offerId);
        const { data: offerStatusLogs } = await offerStatusLogsApi.collection();
        $(document).trigger('offer-status-logs/get/success', { logs: offerStatusLogs });
    });

});