const $ = window.jQuery;

import _ from 'lodash';
import dayjs from 'dayjs';
import Page from './Page';
import Footer from '../../../core/js/components/Form/Footer';
import Storage from '../../../core/js/utils/Storage';
import Form from '../../../core/js/components/Form/Form';
import OptionField from '../../../core/js/components/Form/OptionField';
import DateField from '../components/Form/DateField';
import ScarySelect2 from '../components/Plugins/ScarySelect2';
import UserApi from '../api/UserApi';
import DashboardApi from '../api/DashboardApi';
import UserManagerApi from '../api/UserManagerApi';

export default class DashboardPage extends Page {

	constructor() {
		super();
	
		this.users = undefined;
		this.userId = PROFILE.id;
		this.dateStart = dayjs().format('YYYY-01-01');
		this.dateEnd = dayjs().format('YYYY-MM-DD');
		this.priceFormatter = new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'});
	}

	async init() {
		
		if(PROFILE.roles.includes('RD_ADMIN_OFFERS')){
			await this.caricaUtentiAdmin();
		} else if(PROFILE.roles.includes('RD_SUB_OFFERS')){
			await this.caricaUtentiResponsabile();
		} else {
			this.users = [];
		}
		
		this
			.setTitle('Dashboard')
			.setMeta('description', 'Dashboard of user')
			.setBreadcrumb('Dashboard');
		
		return super.init();
	}

	async caricaUtentiAdmin(){
		const usersApi = new UserApi();
		this.users = await usersApi.collection().catch(function (e) {
			const errmsg = 'Errore nel recuperare gli utenti';
			$(document).trigger('message', [ 'error', errmsg ]);
			throw errmsg;
		});
		this.users = this.users.data;
	}

	async caricaUtentiResponsabile(){
		const userManagerApi = new UserManagerApi(PROFILE.id);
		this.users = await userManagerApi.collection().catch(function (e) {
			const errmsg = 'Errore nello recuperare gli utenti';
			$(document).trigger('message', [ 'error', errmsg ]);
			throw errmsg;
		});
		this.users = this.users.data;
	}

	async content() {
		const _this = this;
		const stApi = new Storage();
		
		const avatar = PROFILE.avatar !== null ? PROFILE.avatar : '/assets/images/utente.png';
		
		//
		// search form
		//
		
		const searchForm = new Form();
		searchForm
			.setAction('/');
		
		if(!PROFILE.roles.includes('RD_OWN_OFFERS')){
			const usersSelect = this.getUsersSelect();
			searchForm.addFields(usersSelect);
		}
		
		const startDate = new DateField();
		startDate
			.setFieldName('date_start')
			.setLabel('Dal')
			.setValue(this.dateStart)
			.addWrapperClasses('date-start');
		searchForm.addFields(startDate);
			
		const endDate = new DateField();
		endDate
			.setFieldName('date_end')
			.setLabel('Al')
			.setValue(this.dateEnd)
			.addWrapperClasses('date-end');
		searchForm.addFields(endDate);
			
		//
		// render
		//
		
		let html = '';
		
		//if(stApi.getData('privacy-price-agent') === 'off'){
			html += `<div class="searchform">
				${searchForm.render()}
			</div>`;
		//}
		
		html += `<div class="profile">
			<div class="avatar">
				<img src="${avatar}">
			</div>
			<h3 class="fullname">${PROFILE.full_name}</h3>
			<div class="email">
				${PROFILE.email}
			</div>
			<div class="roles">
				${this.readableRole(PROFILE.roles)}
			</div>
		</div>`;
		
		html = `<div class="top">${html}</div>`;
			
		//if(stApi.getData('privacy-price-agent') === 'off'){
			html += `<div class="dashboard-boxes stats-boxes">
				<div class="box box-offers_sent">
					<h3>Offerte inviate / emesse</h3>
					<span data-result="offers-sent">-</span>
				</div>
				<div class="box box-offers_sent">
					<h3>Offerte in attesa contratti finanziaria</h3>
					<span data-result="offers-waiting_contracts">-</span>
				</div>
				<div class="box box-offers_sent">
					<h3>Provvigioni maturate</h3>
					<span data-result="commissions">-</span>
				</div>
			</div>`;
        //}
			
		html += `<div class="dashboard-boxes actions-boxes">
			<div class="box box-create-offer">
				<h3>Offerte</h3>
				<a class="button" href="/offer/manage/create">Nuova Offerta</a>
			</div>
		</div>`;
	
		//render
		$('main .main').html(`<div class="dashboard">${html}</div>` + Footer.render(true));
		searchForm.activate();
		Footer.activate(this);
		
		// get stats
		await this.getStats();
		
		// handle date change event
		$(`#${startDate.name}`).on('change', async function(e){
			_this.dateStart = $(this).val();
			await _this.getStats();
		});
		$(`#${endDate.name}`).on('change', async function(e){
			_this.dateEnd = $(this).val();
			await _this.getStats();
		});
	}
  
	async getStats(){
		const stApi = new Storage();
		
		const dashboardApi = new DashboardApi();
		const offersSent = await dashboardApi.setType('offers-sent').setUserId(this.userId).setDateStart(this.dateStart + ' 00:00:00').setDateEnd(this.dateEnd + ' 23:59:59').getWithoutId();
		const offersWaitingContracts = await dashboardApi.setType('offers-waiting_contracts').setUserId(this.userId).setDateStart(this.dateStart + ' 00:00:00').setDateEnd(this.dateEnd + ' 23:59:59').getWithoutId();
		const commissions = await dashboardApi.setType('commissions').setUserId(this.userId).setDateStart(this.dateStart + ' 00:00:00').setDateEnd(this.dateEnd + ' 23:59:59').getWithoutId();
		
		$('[data-result="offers-sent"]').text(stApi.getData('privacy-price-agent') === 'off' ? offersSent.data : '-');
		$('[data-result="offers-waiting_contracts"]').text(stApi.getData('privacy-price-agent') === 'off' ? offersWaitingContracts.data : '-');
		$('[data-result="commissions"]').text(stApi.getData('privacy-price-agent') === 'off' ? this.priceFormatter.format(_.round(commissions.data, 2)) : '-');
	}
	
	getUsersSelect(){
		const _this = this;
		
		const options = [];
		
		const optSelf = new OptionField();
		optSelf
			.setValue(PROFILE.id)
			.setLabel('Me stesso')
			.setDefault(true);
		options.push(optSelf);
		
		const optAll = new OptionField();
		optAll
			.setValue('-1')
			.setLabel('Tutti (tranne me)');
		options.push(optAll);
		
		this.users.map(function(user){
			if(user.id !== PROFILE.id){ // skip self as already added using optSelf
				const option = new OptionField();
				option
					.setValue(user.id)
					.setLabel(`${user.full_name} (${_this.readableRole(user.role)})`);
				options.push(option);
			}
		});
		
		const select = new ScarySelect2();
		select
			.setFieldName('id_user')
			.setPlaceholder('Seleziona un valore')
			.setLabel(PROFILE.roles.includes('RD_OWN_OFFERS') ? 'Dashboard di' : 'Guarda la dashboard di')
			.addWrapperClasses('select-user')
			.addOptions(options);
		
		select.onChange(async function(e){
			_this.userId = parseInt(e);
			await _this.getStats();
		});
		
		return select;
	}
	
	readableRole(roles){
		let label = 'Agente';
		if(roles.includes('ROLE_ADMIN') || roles.includes('RD_ADMIN_OFFERS')){
			label = 'Amministratore';
		} else if (roles.includes('ROLE_RESPONSABILE') || roles.includes('RD_SUB_OFFERS')){
			label = 'Responsabile';
		}
		return label;
	}
	
}
